import { FC, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsLoadedLocaleKeys } from 'store/localization/selectors';
import { ROUTES } from 'shared/constants/routes';
import { breadcrumbOptions, LinkOptionRouteType } from 'shared/constants/routeLinkOptions';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

import './PageContentHeader.scss';

const noBorderPages: Record<string, true | undefined> = {
  [ROUTES.files]: true,
  [ROUTES.logs]: true,
  [ROUTES.sbpExpensesJournal]: true,
  [ROUTES.sbpExpenses]: true,
  [ROUTES.customVestingSchedules]: true,
  [ROUTES.employeeStatus]: true,
  [ROUTES.grantValuation]: true,
};

const noHeaderPages: Record<string, { header?: true; subHeader?: true } | undefined> = {
  [ROUTES.shareRegister]: { header: true, subHeader: true },
  [ROUTES.manageEmployeeContracts]: { header: true, subHeader: true },
  [ROUTES.customVestingSchedules]: { header: true, subHeader: true },
  [ROUTES.employeeStatus]: { header: true, subHeader: true },
  [ROUTES.performanceHurdles]: { header: true, subHeader: true },
  [ROUTES.sbpExpensesJournal]: { header: true, subHeader: true },
  [ROUTES.logs]: { header: true, subHeader: true },
  [ROUTES.grantValuation]: { header: true, subHeader: true },
  [ROUTES.files]: { header: true, subHeader: true },
  [ROUTES.sbpExpenses]: { header: true, subHeader: true },
  [ROUTES.sbpExpenseReports]: { header: true, subHeader: true },
};

const PageContentHeader: FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isLoadedLocaleKeys = useSelector(selectIsLoadedLocaleKeys);

  const breadcrumbs = useMemo(() => {
    let newBreadcrumbs = [breadcrumbOptions[ROUTES.home]] as LinkOptionRouteType[];
    if (pathname !== ROUTES.home) {
      const breadcrumb = breadcrumbOptions[pathname];
      let parentsBreadcrumbs: LinkOptionRouteType[] = [];
      newBreadcrumbs.forEach((element) => {
        element.label = breadcrumbOptions[pathname]?.groupHeader;
      });
      if (breadcrumb) {
        if (breadcrumb.parents?.length) {
          parentsBreadcrumbs = breadcrumb.parents.map((route) => breadcrumbOptions[route]);
        }

        newBreadcrumbs = [...newBreadcrumbs, ...parentsBreadcrumbs, breadcrumb];
      }
    } else {
      newBreadcrumbs.forEach((element) => {
        element.label = 'Home.PageTitle';
      });
    }

    return newBreadcrumbs;
  }, [pathname]);

  const { pageHeaderText, pageSubHeaderText } = useMemo(() => {
    let pageHeader: ReactNode = '';
    let pageSubHeader: ReactNode = '';

    if (breadcrumbs.length > 1) {
      const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1];

      pageHeader = lastBreadcrumb?.labelForBradCrumbs || lastBreadcrumb?.label || '';
      pageSubHeader = lastBreadcrumb?.groupSubHeader || '';
    }

    return {
      pageHeaderText: pageHeader,
      pageSubHeaderText: pageSubHeader,
    };
  }, [breadcrumbs]);

  return (
    <>
      {isLoadedLocaleKeys && <Breadcrumbs items={breadcrumbs} />}
      {breadcrumbs.length > 1 && (
        <div className={`pageContentHeader${noBorderPages[pathname] ? ' no-borders' : ''}`}>
          {!!pageHeaderText && !noHeaderPages[pathname]?.header && (
            <h2 className="pageContentHeader__pageName">{isLoadedLocaleKeys ? t(pageHeaderText as string) : ''}</h2>
          )}
          {!!pageSubHeaderText && !noHeaderPages[pathname]?.subHeader && (
            <h5 className="pageContentHeader__pageGroupName">
              {isLoadedLocaleKeys ? t(pageSubHeaderText as string) : ''}
            </h5>
          )}
        </div>
      )}
    </>
  );
};

export default PageContentHeader;
