import { useState, useCallback } from 'react';
import useIsMounted from 'shared/hooks/useIsMounted';
import { FilesContainerName } from 'shared/models/enums.dl';
import type { PrivateFile, PublicFile } from 'shared/models/models.dl';
import FileService from 'shared/services/bl/files.service';

export interface UseDownloadPrivateFileReturnType {
  downloadPrivateFile: (file?: PrivateFile | PublicFile) => Promise<void>;
  isDownLoading: boolean;
}

export const useDownloadPrivateFile = (): UseDownloadPrivateFileReturnType => {
  const isMounted = useIsMounted();

  const [isDownLoading, setIsDownLoading] = useState(false);

  const downloadPrivateFile: UseDownloadPrivateFileReturnType['downloadPrivateFile'] = useCallback(
    async (file): Promise<void> => {
      if (!file?.uid || file.containerName === FilesContainerName.PublicFiles) return;
      setIsDownLoading(true);
      await FileService.downloadPrivateFile(file);
      isMounted(() => setIsDownLoading(false));
    },
    [isMounted]
  );

  return { downloadPrivateFile, isDownLoading };
};
