import { getInitialPaginatedTableData, GridColumnConfig } from 'shared/configs/grid.config';
import Utils from 'shared/shared.utils';
import type { ExpenseOutlookTableData } from 'shared/models/models.bl';
import { Types } from './types';
import type * as Interfaces from './interfaces';

const defaultFilter = {
  id: '',
  text: 'SbpExpensesReports.SummaryReportPanel.ExpenseOutlookTab.GrantDropdown.DefaultOption',
};

const initialPaginatedTableData = getInitialPaginatedTableData<ExpenseOutlookTableData>(
  GridColumnConfig.ExpenseOutlookViewConfig
);

const initialState: Interfaces.StoreType = {
  generatedReport: null,
  loading: false,
  data: [],
  headers: null,
  chartData: [],
  filters: [defaultFilter],
  paginatedTableData: initialPaginatedTableData,
  filtersLoading: false,
  chartLoading: false,
};

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action: Interfaces.ExpenseOutlookActions = {} as Interfaces.ExpenseOutlookActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_GENERATED_REPORT: {
      return { ...state, generatedReport: action.payload };
    }
    case Types.SET_DATA_LOADING: {
      return { ...state, loading: action.payload };
    }
    case Types.SET_TABLE_DATA: {
      const { getRows, getHeaders } = Utils.getCorrectTableDataFromResponse<ExpenseOutlookTableData>(action.payload);
      const data = getRows(state.data);
      const headers = getHeaders(state.headers);
      return data !== state.data || headers !== state.headers
        ? {
            ...state,
            data,
            headers,
          }
        : state;
    }
    case Types.SET_TABLE_FILTERS: {
      const filters = Utils.getCorrectTableFiltersFromResponse(action.payload)(state.filters);
      return { ...state, filters, filtersLoading: false };
    }
    case Types.SET_CHART_DATA: {
      const chartData = Utils.getCorrectChartDataFromResponse(action.payload)(state.chartData);
      return { ...state, chartData, chartLoading: false };
    }
    case Types.SET_PAGINATED_TABLE_DATA: {
      let paginatedTableData = action.payload || initialPaginatedTableData;
      if (!paginatedTableData.tableData) {
        paginatedTableData = {
          ...paginatedTableData,
          tableData: initialPaginatedTableData.tableData,
        };
      }
      return {
        ...state,
        loading: false,
        paginatedTableData,
      };
    }
    case Types.SET_FILTERS_LOADING: {
      return { ...state, filtersLoading: action.payload };
    }
    case Types.SET_CHART_LOADING: {
      return { ...state, chartLoading: action.payload };
    }
    default:
      return state;
  }
}
