import { FC, useMemo } from 'react';
import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ISelected } from 'components/MipTable/MipTable';
import FormCheckbox from 'components/FormCheckbox/FormCheckbox';

interface IProps extends GridCellProps {
  selected: ISelected;
  setSelected: (param: ISelected) => void;
  selectedKey: string;
  disabled: boolean;
}

const CellCheckbox: FC<IProps> = ({ selected, setSelected, dataItem, selectedKey, disabled }) => {
  const isInList = useMemo(() => selected.keys.includes(dataItem[selectedKey]), [selected.keys, dataItem, selectedKey]);

  const onChangeSelectedHandler = () => {
    setSelected({
      ...selected,
      keys: isInList
        ? selected.keys.filter((id) => id !== dataItem[selectedKey])
        : [...selected.keys, dataItem[selectedKey]],
    });
  };

  return (
    <td className="cellCheckbox">
      <FormCheckbox
        disabled={disabled}
        checked={(!selected.deleteAll && isInList) || (selected.deleteAll && !isInList)}
        onChange={onChangeSelectedHandler}
        label="Test"
      />
    </td>
  );
};

export default CellCheckbox;
