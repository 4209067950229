import { Types } from './types';
import type * as Interfaces from './interfaces';

export const setUser = (user?: Interfaces.CurrentUserPayloadType['user']['user']) =>
  ({
    type: Types.SET_USER,
    payload: { user: user || null },
  } as const);

export const setUserToken = (tokenObj?: Interfaces.CurrentUserPayloadType['tokenObj']['tokenObj']) =>
  ({
    type: Types.SET_TOKEN,
    payload: { tokenObj: tokenObj || null },
  } as const);

export const setUserSettings = (settings: Interfaces.CurrentUserPayloadType['settings']) =>
  ({
    type: Types.SET_SETTINGS,
    payload: settings,
  } as const);
