import { FC, useState, useEffect, useRef } from 'react';
import { Form } from '@progress/kendo-react-form';
import { IUpdatePerformanceHurdleEstimate } from 'shared/models/models.bl';
import { useTranslation } from 'react-i18next';
import * as Models from '../../../shared/shared.models';
import ModalLayout from '../../ModalLayout';
import { validateSubHurdleWeight, validationDateMsg } from './helper';
import './UpdateEstimate.scss';
import { convertDateToISO, formatToDateObject } from '../helpers';
import EstimateTable from './EstimateTable';

const initialValues: Models.BM.IUpdatePerformanceHurdleEstimate = {
  performanceHurdleId: 'string',
  performanceSubHurdle: [],
};

interface IProps {
  visible: boolean;
  onCancel: () => void;
  title: string;
  data: IUpdatePerformanceHurdleEstimate | null;
  onConfirm: (data: any) => Promise<any>;
  isLoading?: boolean;
  validationMessage: string;
}
const UpdateEstimate: FC<IProps> = ({ onConfirm, validationMessage, data, visible, onCancel, title, isLoading }) => {
  const { t } = useTranslation();
  const subHurdleLength: any = data?.performanceSubHurdle[0].estimate.length;
  const refForm = useRef<Form>(null);
  const header: any = [{ path: 'date', name: 'Estimated Date', type: 'date' }];
  const [columns, setColumns]: any = useState([]);
  const tableRowData: any = [];
  const [finalResult, setFinalResult]: any = useState(
    data?.performanceSubHurdle[0]?.estimate[subHurdleLength - 1]?.isFinalized
  );
  const [subhurdleDetail, setSubhurdleDetail]: any = useState(tableRowData);
  const estimatedData: IUpdatePerformanceHurdleEstimate = initialValues;
  const [errorMsg, setErrorMsg]: any = useState('');
  const [isValid, setIsValid]: any = useState(false);
  const addRowObj: any = { Delete: '' };
  let inputValidity: boolean;
  let initialValidity: boolean;
  let dateValidity: boolean;

  const subHurdleEstimateTableHeader = () => {
    data?.performanceSubHurdle.map((element, index) => {
      const insertData = {
        path: `subHurdle${index + 1}`,
        name: element.subHurdleName,
        type: 'text',
        unit: element.unit,
      };
      return header.push(insertData);
    });
    header.push({ path: 'delete', name: '', type: 'button' });
    setColumns(header);
  };

  const subHurdleEstimateTableData = () => {
    new Array(subHurdleLength).fill('').map((element, index) => {
      const subHurdleObj = {
        date: {
          date: formatToDateObject(data?.performanceSubHurdle[0].estimate[index].dateOfEstimate),
          initialRender: false,
        },
        subHurdle1: {
          weight: data?.performanceSubHurdle[0]?.estimate[index]?.estimateValue,
          initialRender: false,
        },
        subHurdle2: {
          weight: data?.performanceSubHurdle[1]?.estimate[index]?.estimateValue,
          initialRender: false,
        },
        subHurdle3: {
          weight: data?.performanceSubHurdle[2]?.estimate[index]?.estimateValue,
          initialRender: false,
        },
        subHurdle4: {
          weight: data?.performanceSubHurdle[3]?.estimate[index]?.estimateValue,
          initialRender: false,
        },
        subHurdle5: {
          weight: data?.performanceSubHurdle[4]?.estimate[index]?.estimateValue,
          initialRender: false,
        },
        flag: data?.performanceSubHurdle[0]?.estimate[index]?.isFinalized,
        delete: '',
        unit: data?.performanceSubHurdle[index]?.unit,
      };
      return tableRowData.push(subHurdleObj);
    });
  };
  const checkInputValidaity = (currentValue: boolean) => currentValue === true;

  const checkinitialValidity = (currentValue: boolean) => currentValue === false;
  const checkSubhurdle = (subhurdleData: any) => {
    const inputValidationFlag: any = [];
    const initialRenderFlag: any = [];
    if (subhurdleData.length !== 0) {
      subhurdleData.forEach((element: any) => {
        Object.keys(element).forEach((key) => {
          if (element[key]?.weight !== undefined && key !== 'date') {
            inputValidationFlag.push(validateSubHurdleWeight(element[key]?.weight, element[key]?.initialRender));
            initialRenderFlag.push(element[key]?.initialRender);
          }
          if (element[key]?.date === null && key === 'date') {
            initialRenderFlag.push(element[key]?.initialRender);
          }
        });
      });
    }
    inputValidity = inputValidationFlag.every(checkInputValidaity);
    dateValidity = validationDateMsg(subhurdleData);
    initialValidity = initialRenderFlag.every(checkinitialValidity);
    if (dateValidity || dateValidity === undefined) {
      setErrorMsg('');
    } else {
      setErrorMsg(t('PerformanceHurdle.UpdateEstimate.DateValidation'));
    }
  };
  const subHurdleEstimateAddRow = (flag: any) => {
    setFinalResult(flag);
    columns.forEach((element: any) => {
      addRowObj.date = { date: null, initialRender: true };
      if (element.path !== 'date' && element.path !== 'delete') {
        addRowObj[element.path] = { weight: '', initialRender: true };
      }
      addRowObj.flag = flag;
    });
    setSubhurdleDetail([...subhurdleDetail, addRowObj]);
  };
  useEffect((): any => {
    subHurdleEstimateTableHeader();
    subHurdleEstimateTableData();
  }, []);
  useEffect((): any => {
    if (data?.performanceSubHurdle[0].estimate.length === 0 && columns.length !== 0) {
      subHurdleEstimateAddRow(false);
    }
  }, [columns]);
  useEffect((): any => {
    checkSubhurdle(subhurdleDetail);
    if (dateValidity && inputValidity && initialValidity) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [subhurdleDetail]);

  const subHurdleEstimateRowRemove = (index: any) => {
    const list = [...subhurdleDetail];
    if (list.length - 1 === index && finalResult === true) {
      setFinalResult(false);
    }
    list.splice(index, 1);
    setSubhurdleDetail(list);
  };

  const subHurdleEstimate = (subhurdle: any, hurdleName: string) => {
    const hurdle = hurdleName;
    subhurdleDetail.map((element: any) => {
      return subhurdle.estimate.push({
        dateOfEstimate: convertDateToISO(element.date.date as Date) as string,
        estimateValue: Number(element[hurdle].weight),
        isFinalized: element.flag,
      });
    });
  };
  const subHurdleEstimateValueChange = (value: any, index: number, type: string, name: string) => {
    const list: any = [...subhurdleDetail];
    if (finalResult && index === list.length - 1) {
      list[index].flag = finalResult;
    } else {
      list[index].flag = false;
    }
    if (type === 'date') {
      const dateValue = new Date(value);
      dateValue.setDate(1);
      list[index][name].date = dateValue;
      list[index][name].initialRender = false;
    } else {
      list[index][name].weight = value;
      list[index][name].initialRender = false;
    }
    setSubhurdleDetail(list);
  };

  const setEstimateData = () => {
    estimatedData.performanceHurdleId = data?.performanceHurdleId;
    const pushArray: any = [];
    data?.performanceSubHurdle.forEach((element, index) => {
      const subhurdle: any = {
        subHurdleName: data?.performanceSubHurdle[index]?.subHurdleName,
        unit: data?.performanceSubHurdle[index]?.unit,
        estimate: [],
      };
      subHurdleEstimate(subhurdle, `subHurdle${index + 1}`);
      pushArray.push(subhurdle);
    });
    estimatedData.performanceSubHurdle = pushArray;
  };

  const handleConfirm = () => {
    setEstimateData();
    if (isLoading) return;
    // data to save
    onConfirm(estimatedData).then(() => {});
    // @ts-ignorev\
    refForm?.current?.onSubmit?.();
  };
  const handleCancel = () => {
    onCancel();
  };
  return visible ? (
    <ModalLayout
      className="update-performance-hurdle-modal"
      title={title}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      confirmDisabled={isLoading || isValid}
      confirmBtnTitle={t('PerformanceHurdle.UpdateEstimateModal.SaveButton')}
      cancelBtnTitle={t('PerformanceHurdle.UpdateEstimateModal.CancelButton')}
      confirmLoading={isLoading}
    >
      <EstimateTable
        columns={columns}
        subhurdleDetail={subhurdleDetail}
        subHurdleEstimateValueChange={subHurdleEstimateValueChange}
        subHurdleEstimateRowRemove={subHurdleEstimateRowRemove}
        initialValues={initialValues}
        subHurdleEstimateAddRow={subHurdleEstimateAddRow}
        finalResult={finalResult}
        validationMessage={validationMessage}
        errorMsg={errorMsg}
        isValid={isValid}
        data={data}
        refForm={refForm}
      />
    </ModalLayout>
  ) : null;
};
export default UpdateEstimate;
