import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import history from 'appHistory';

import * as Models from '../shared.models';
import { ROUTES } from '../constants/routes';
import LoaderService from '../services/bl/loader.service';
import ApiUrlsService from '../services/bl/api-urls.service';
import ToasterService, { toasterMessages, DefaultResponseErrorText } from '../services/bl/toaster.service';
import CommonService from '../services/bl/common.service';
import AuthenticationService from '../services/bl/authentication.service';

let isToastClose = false;
class GlobalInterceptor {
  private static get toasterBlackList(): string[] {
    // INFO: the resulting api may be different if the geocode API returns a geocode different from the original
    return [
      ApiUrlsService.getUserProfile(),
      ApiUrlsService.getUserGeoCode(),
      ApiUrlsService.clearUserCash(),
      ApiUrlsService.getNotificationList(),
    ];
  }

  private static get globalLoaderWhiteList(): string[] {
    // INFO: the resulting api may be different if the geocode API returns a geocode different from the original
    return [
      ApiUrlsService.getUserProfile(),
      ApiUrlsService.getUserGeoCode(),
      ApiUrlsService.clearUserCash(),
      ApiUrlsService.getReportList(),
    ];
  }

  private static async getCorrectErrorMessage(data?: any): Promise<string> {
    if (data instanceof Blob) {
      const text = await data.text();
      return text.replace(/^"|"$/g, '');
    }
    const getMsg = (val: any): string => (typeof val === 'string' ? val : '');
    const result = getMsg(data?.message) || getMsg(data);

    if (result === DefaultResponseErrorText.NetworkError || result === 'Network Error') {
      return DefaultResponseErrorText.ServiceUnavailable;
    }
    if (result === 'Request aborted') {
      return DefaultResponseErrorText.RequestAborted;
    }
    return result;
  }

  private request = (): number => {
    return axios.interceptors.request.use(this.requestHandler, this.errorHandler);
  };

  private response = (): number => {
    return axios.interceptors.response.use(this.responseHandler, this.errorHandler);
  };

  private requestHandler = (request: AxiosRequestConfig): AxiosRequestConfig => {
    if (request.url) {
      if (
        CommonService.LikeSomeInList(GlobalInterceptor.globalLoaderWhiteList, request.url, true) ||
        /files\/\d+\/download$/.test(request.url)
      ) {
        LoaderService.request(request);
      }
    }

    const token: string = AuthenticationService.getToken();
    if (!request.headers) {
      request.headers = {};
    }
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    if (request.headers && !request.headers.Accept) {
      request.headers.Accept = 'application/json';
    }
    if (request.headers && !request.headers['Content-Type']) {
      request.headers['Content-Type'] = 'application/json';
    }
    // INFO: need to be in minutes (just for BE)
    request.headers.TimezoneOffset = `${-new Date().getTimezoneOffset()}`;
    return request;
  };

  private responseHandler = (response: AxiosResponse): AxiosResponse => {
    LoaderService.response();
    if (response?.config?.url) {
      const { url } = response.config;

      if (/\/upload(\?.*)?$/.test(url) && !response?.data?.error) {
        ToasterService.success('SnackBarMessage.ProcessingUploadFile');
      } else if (/\/export(\?.*)?$/.test(url)) {
        ToasterService.warn('SnackBarMessage.ExportedFileAvailableFilesMenuCompleted');
      } else {
        const message = toasterMessages[url];

        if (message) {
          ToasterService.success(message);
        }
      }
    }
    return response;
  };

  private errorHandler = async (error: AxiosError): Promise<AxiosError | undefined> => {
    LoaderService.response();
    if (axios.isCancel(error)) return Promise.reject(error);
    if (error?.response) {
      const {
        status,
        data,
        config: { url },
      } = error.response;
      const errorMessage = await GlobalInterceptor.getCorrectErrorMessage(data);

      switch (status) {
        case Models.BE.HttpStatus.BadRequest: {
          const isLocalesUrl = url === ApiUrlsService.getLocales();

          if (errorMessage && !isLocalesUrl) {
            if (url?.includes('employeeContracts')) isToastClose = true;
            ToasterService.error(errorMessage, false, isToastClose);
          } else if (!isLocalesUrl) {
            ToasterService.error(DefaultResponseErrorText.BadRequest);
          }

          return Promise.reject(error);
        }
        case Models.BE.HttpStatus.Unauthorized: {
          if (url === ApiUrlsService.getLocaleKeys()) {
            break;
          }
          ToasterService.error(errorMessage || DefaultResponseErrorText.Unauthorized, true);
          AuthenticationService.logOut();
          history.replace(ROUTES.landing);
          return Promise.reject();
        }
        case Models.BE.HttpStatus.Forbidden: {
          ToasterService.error(errorMessage || DefaultResponseErrorText.Forbidden);
          history.replace(ROUTES.landing);
          return Promise.reject();
        }
        case Models.BE.HttpStatus.NotFound: {
          if (!!url && !CommonService.LikeSomeInList(GlobalInterceptor.toasterBlackList, url)) {
            ToasterService.error(errorMessage || DefaultResponseErrorText.NotFound);
          }
          return Promise.reject(error);
        }
        case Models.BE.HttpStatus.Conflict:
        case Models.BE.HttpStatus.ServerError: {
          ToasterService.error(DefaultResponseErrorText.SomethingWentWrong);
          return Promise.reject();
        }
        case Models.BE.HttpStatus.PreconditionFailed: {
          return Promise.reject();
        }
        case Models.BE.HttpStatus.ServiceUnavailable: {
          ToasterService.error(DefaultResponseErrorText.ServiceUnavailable);
          return Promise.reject();
        }
        default: {
          if (!!url && !CommonService.LikeSomeInList(GlobalInterceptor.toasterBlackList, url, true)) {
            ToasterService.error(status > 500 ? DefaultResponseErrorText.SomethingWentWrong : errorMessage);
          }
          break;
        }
      }
    } else if (error?.config?.url) {
      const { url } = error.config;
      const errorMessage = await GlobalInterceptor.getCorrectErrorMessage(error);
      if (
        errorMessage === DefaultResponseErrorText.ServiceUnavailable || // INFO: === localization key
        errorMessage === 'Service is temporarily unavailable'
      ) {
        ToasterService.error(DefaultResponseErrorText.ServiceUnavailable, true);
        return Promise.reject(error);
      }
      if (!CommonService.LikeSomeInList(GlobalInterceptor.toasterBlackList, url, true)) {
        ToasterService.error(errorMessage);
      }
    }
    return Promise.reject(error);
  };

  public init(): void {
    this.request();
    this.response();
  }
}

export default new GlobalInterceptor();
