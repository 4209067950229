import * as Models from '../shared.models';

export const getSelectOptions = (optionValues: Models.DM.SelectOption['id'][]): Models.DM.SelectOption[] =>
  optionValues.reduce((acc, value) => {
    if (!!value || value === 0) {
      acc.push({
        id: value,
        text: `${value}`,
      });
    }
    return acc;
  }, [] as Models.DM.SelectOption[]);
