import { useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react';
import { useAppSelector } from 'shared/hooks/storeHooks';
import { removeFromLocalStorage } from 'shared/utils/localStorage';
import {
  USER_ACTIVITY_EVENTS,
  USER_MAX_INACTIVITY_TIME_MS,
  USER_MAX_INACTIVITY_ANSWER_WAITING_TIME_EXPIRES_STORAGE_KEY,
  USER_MAX_INACTIVITY_TIME_EXPIRES_STORAGE_KEY,
  USER_MAX_INACTIVITY_ANSWER_WAITING_TIME_MS,
} from './SessionTimeoutModal.constants';
import { getMMSSFromMS } from './SessionTimeoutModal.helpers';
import IdleTimer from './IdleTimer';

type CloseInactivityModalType = Dispatch<SetStateAction<void>>;

const userIdleTimer = new IdleTimer({
  timeoutMS: USER_MAX_INACTIVITY_TIME_MS,
  expiresStorageKey: USER_MAX_INACTIVITY_TIME_EXPIRES_STORAGE_KEY,
})
  .addDomElement('root')
  .addEventNamesToListen(USER_ACTIVITY_EVENTS);

const userAnswerIdleTimer = new IdleTimer({
  timeoutMS: USER_MAX_INACTIVITY_ANSWER_WAITING_TIME_MS,
  expiresStorageKey: USER_MAX_INACTIVITY_ANSWER_WAITING_TIME_EXPIRES_STORAGE_KEY,
});

const useInactivityModalOpenedState = (): {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  close: CloseInactivityModalType;
  isAuthenticated: boolean;
} => {
  const isAuthenticated = useAppSelector(({ currentUser }) => !!currentUser.user);
  const [isOpen, setIsOpen] = useState(false);

  const close: CloseInactivityModalType = useCallback(() => {
    removeFromLocalStorage(USER_MAX_INACTIVITY_ANSWER_WAITING_TIME_EXPIRES_STORAGE_KEY);
    setIsOpen((prev) => {
      if (prev && isAuthenticated) {
        // INFO: if modal was opened before need to start listen user idle time again
        userIdleTimer.startInterval();
      }
      return false;
    });
  }, [isAuthenticated]);

  useEffect(() => {
    close();

    const modalOpenedFromDifferentTabListener = (e: StorageEvent): void => {
      if (e.key === USER_MAX_INACTIVITY_ANSWER_WAITING_TIME_EXPIRES_STORAGE_KEY) {
        if (!e.newValue) {
          close();
        } else if (!e.oldValue) {
          userIdleTimer.cleanUp();
          setIsOpen(true);
        }
      }
    };

    if (isAuthenticated) {
      window.addEventListener('storage', modalOpenedFromDifferentTabListener, true);
    } else {
      window.removeEventListener('storage', modalOpenedFromDifferentTabListener, true);
    }

    return () => {
      window.removeEventListener('storage', modalOpenedFromDifferentTabListener, true);
      close();
    };
  }, [isAuthenticated, close]);

  return {
    isOpen,
    setIsOpen,
    close,
    isAuthenticated,
  };
};

export const useUserActivityListener = (): {
  isInactivityModalOpen: boolean;
  closeInactivityModal: CloseInactivityModalType;
} => {
  const { isAuthenticated, isOpen, setIsOpen, close } = useInactivityModalOpenedState();

  useEffect(() => {
    userIdleTimer.addOnExpired(() => {
      setIsOpen(true);
      userIdleTimer.cleanUp();
    });

    if (isAuthenticated) {
      userIdleTimer.startInterval();
    } else {
      userIdleTimer.cleanUp();
    }

    return userIdleTimer.cleanUp;
  }, [isAuthenticated]);

  return { isInactivityModalOpen: isOpen, closeInactivityModal: close };
};

export const useAutoLogoutTimer = (logout: () => void): string => {
  const [timer, setTimer] = useState(USER_MAX_INACTIVITY_ANSWER_WAITING_TIME_MS);

  useEffect(() => {
    userAnswerIdleTimer.addOnExpired(logout).addOnRemainingTimeChanged(setTimer);

    userAnswerIdleTimer.startInterval();

    return userAnswerIdleTimer.cleanUp;
  }, [logout]);

  return getMMSSFromMS(timer);
};
