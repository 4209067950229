import { FC, useState, useLayoutEffect } from 'react';
import { Router, RouterProps } from 'react-router-dom';
import type { BrowserHistory } from 'history';

const AppRouter: FC<Omit<RouterProps, 'navigator' | 'navigationType' | 'location'> & { history: BrowserHistory }> = ({
  history,
  ...props
}) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};

export default AppRouter;
