import styles from './UserMenuSideBar.module.css';

type Props = { children: JSX.Element };

export function Sidebar({ children }: Props): JSX.Element {
  return (
    <>
      <div className={styles.Overlay} />
      <div className={styles.Sidebar}>{children}</div>
    </>
  );
}
