import type * as Interfaces from './interfaces';
import { Types } from './types';

const initialState = {} as Interfaces.StoreType;

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action = {} as Interfaces.DataExportingActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_IS_EXPORTING: {
      const { data, view } = action.payload;

      return {
        ...state,
        [view]: data,
      };
    }
    default:
      return state;
  }
}
