import { useRef, useCallback, RefObject } from 'react';
import type { VariableSizeList as List } from 'react-window';
import type { INotification } from '../interface';

type UseVirtualListAutoSizerReturnType = {
  setSize: (index: number, size: number) => void;
  getSize: (index: number) => number;
};

export default (listRef: RefObject<List<INotification>>): UseVirtualListAutoSizerReturnType => {
  const sizeMap = useRef<Record<number, number>>({});
  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current?.resetAfterIndex(index);
  }, []);
  const getSize = useCallback((index: number) => {
    return sizeMap.current[index] || 120;
  }, []);

  return {
    getSize,
    setSize,
  };
};
