import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMounted from './useIsMounted';
import type {
  IUploadFileResponse,
  IUploadFileDbResultResponse,
  IUploadFileResponseWithError,
} from '../models/models.bl';
import { parseErrorResponse } from '../utils/parseErrorResponse';

export interface IFileUploadModalData {
  visible: boolean;
  title: string;
  text: string;
}

export const initialFileUploadWarnModal: IFileUploadModalData = {
  visible: false,
  title: '',
  text: '',
};

export interface IOptions {
  showResultModal?: boolean;
}

interface IFileUploadResultModalData extends IUploadFileDbResultResponse {
  relatedRowsUpdated?: {
    key: string;
    rowsUpdated: number;
  };
}

export type OnFileUploadType = (file: File, ...rest: any) => Promise<IUploadFileResponse>;
export type HandleFileUploadType = (...props: Parameters<OnFileUploadType>) => Promise<void>;

interface IState {
  loading: boolean;
  result: IFileUploadResultModalData | null;
  errors: string[];
}

export interface IStateWithReset extends IState {
  reset: () => void;
}
type UseUploaderReturnType = [upload: HandleFileUploadType, state: IStateWithReset];
type UseUploaderType = (onFileUpload: OnFileUploadType, options?: IOptions) => UseUploaderReturnType;

const parseUploadResponse = (response?: IUploadFileResponse): IFileUploadResultModalData | null => {
  if (typeof response === 'number') {
    return {
      rowsAdded: 0,
      rowsAddedOrUpdated: response,
      rowsDeleted: 0,
      rowsUpdated: 0,
    };
  }
  if (!response || response?.constructor?.name !== 'Object') return null;
  if (!(response as IUploadFileResponseWithError)?.dbResult) {
    return response as IUploadFileDbResultResponse;
  }

  const result = response as IUploadFileResponseWithError;
  const relatedRowsUpdatedKey = Object.keys(result).find(
    (key) => key !== 'dbResult' && key.startsWith('dbResult')
  ) as keyof IUploadFileResponseWithError;
  if (!relatedRowsUpdatedKey) return result.dbResult;
  return {
    ...result.dbResult,
    relatedRowsUpdated: {
      key: relatedRowsUpdatedKey,
      rowsUpdated:
        (result[relatedRowsUpdatedKey] as IUploadFileResponseWithError['dbResultRelatedKey'])?.rowsUpdated || 0,
    },
  };
};

const initialState: IState = {
  loading: false,
  result: null,
  errors: [],
};

export const useUploader: UseUploaderType = (onFileUpload, options) => {
  const [localState, setState] = useState(initialState);
  const { t } = useTranslation();

  const isMounted = useIsMounted();

  const upload: HandleFileUploadType = useCallback(async (file, ...rest) => {
    try {
      if (!file) {
        throw new Error('Please choose file for uploading');
      }
      setState((prev) => ({
        ...prev,
        loading: true,
        errors: initialState.errors,
      }));
      const responseData = await onFileUpload(file, ...rest);
      const errorText = (responseData as IUploadFileResponseWithError)?.error;
      if (errorText) throw new Error(errorText);
      isMounted(() => {
        setState((prev) => ({
          ...prev,
          loading: false,
          result: options?.showResultModal ? parseUploadResponse(responseData) : initialState.result,
        }));
      });
    } catch (error) {
      const errorList = parseErrorResponse(error, t);
      isMounted(() => {
        setState((prev) => ({
          ...prev,
          loading: false,
          errors: errorList.length ? errorList : initialState.errors,
        }));
      });
      await Promise.reject(errorList);
    }
  }, []);

  const reset = useCallback(() => {
    setState(initialState);
  }, []);

  const state: IStateWithReset = useMemo(
    () => ({
      ...localState,
      reset,
    }),
    [localState]
  );

  return [upload, state];
};
