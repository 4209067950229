import { Types } from './types';
import type * as Interfaces from './interfaces';

export const actions = {
  setCurrentEmployeeContractId: (payload: Interfaces.PayloadType['currentEmployeeContractId']) =>
    ({
      type: Types.SET_CURRENT_EMPLOYEE_CONTRACT_ID,
      payload,
    } as const),
};

export default {
  ...actions,
};
