interface IGetClassNameOption {
  check: any;
  classNameSuffix: string;
  isFirst?: boolean;
}
const getClassName = (mainClassName: string, { check, classNameSuffix, isFirst }: IGetClassNameOption): string =>
  check ? `${isFirst ? '' : ' '}${mainClassName}__${classNameSuffix}` : '';

export const makeAdditionalClassNamesGenerator =
  (mainClassName: string) =>
  (options: IGetClassNameOption[]): string =>
    options.reduce((acc, item) => `${acc}${getClassName(mainClassName, item)}`, '');
