import { setToLocalStorage, getFromLocalStorage } from '../../utils/localStorage';
import type { CurrentEnv } from '../../models/models.bl';
import { GeoCode } from '../../models/models.dl';

export default class ConfigService {
  private static apiUrls: Record<GeoCode, string> = (window as any).ApiConfig.API_URLS;

  private static currentEnv: CurrentEnv = (window as any).ApiConfig.CURRENT_ENV;

  private static defaultGeoCode: GeoCode = (window as any).ApiConfig.DEFAULT_REGION;

  static get DefaultGeoCode(): GeoCode {
    return getFromLocalStorage<GeoCode>('defaultRegion') || this.defaultGeoCode;
  }

  static get GeoRelatedCode(): GeoCode {
    return getFromLocalStorage<GeoCode>('geoCode') || this.DefaultGeoCode;
  }

  static get ApiUrls(): Record<GeoCode, string> {
    return this.apiUrls;
  }

  static get CurrentEnv(): CurrentEnv {
    return this.currentEnv;
  }

  static SetGeoCode(geoCode: GeoCode): void {
    this.defaultGeoCode = geoCode;
    setToLocalStorage('geoCode', geoCode);
  }
}
