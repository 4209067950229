export enum UserRole {
  Reviewer = 'Reviewer',
  Preparer = 'Preparer',
}

export enum FilesContainerName {
  PublicFiles = 'helpdocs',
  Reports = 'reports',
  Templates = 'templates',
}

export enum TemplateFileCode {
  ShareRegister = 'OptionsGrantedTemplate',
  CustomVesting = 'CustomVestingTemplate',
  Employee = 'EmployeeStatusTemplate',
  Provision = 'ProvisionTemplate',
  Valuation = 'ValuationReferenceTemplate',
}

export enum PublicFileCode {
  Notices = 'Notices',
  PrivacyPolicy = 'PrivacyPolicy',
  TermsOfUse = 'TermsOfUse',
  Cookie = 'Cookie',
}

export enum SumRowKey {
  'SUM of Vested' = 'SUM of Vested',
  'SUM of Reverse' = 'SUM of Reverse',
  'SUM TOTAL' = 'SUM TOTAL',
}

export enum UserSettingsDateFormat {
  MM_DD_YYYY = 'MM/dd/yyyy',
  YYYY_MM_DD = 'yyyy/MM/dd',
  DD_MM_YYYY = 'dd/MM/yyyy',
  DD_MMMM_YYYY = 'dd MMMM yyyy',
}

export enum UserSettingsNumberFormat {
  '1,000,000.00' = ',.',
  '1.000.000,00' = '.,',
  '1 000 000,00' = ' ,',
}

export enum FiltersTypes {
  Entities = 'entities',
  DepartmentAs = 'departmentAs',
  DepartmentBs = 'departmentBs',
  DepartmentCs = 'departmentCs',
}

export enum ExpenseReportStatus {
  New = 'New',
  InProgress = 'InProgress',
  Failed = 'Failed',
  Success = 'Success',
  Canceled = 'Canceled',
  Final = 'Final',
}

export enum FileUploadingState {
  New = 'New',
  InProgress = 'InProgress',
  Success = 'Success',
  Failed = 'Failed',
}

export enum ChartStatus {
  Vested = 'Vested',
  Total = 'Total',
  Reverse = 'Reverse',
}

export enum DataExportingView {
  shareRegister = 'shareRegister',
  customVesting = 'customVesting',
  grandValuation = 'grandValuation',
  employeeStatus = 'employeeStatus',
  sbpExpensesReportsMyIncentivePlanSummary = 'sbpExpensesReportsMyIncentivePlanSummary',
  sbpExpensesReportsSummaryTotal = 'sbpExpensesReportsSummaryTotal',
  sbpExpensesReportsEmployeeView = 'sbpExpensesReportsEmployeeView',
  sbpExpensesReportsGeographicalView = 'sbpExpensesReportsGeographicalView',
  sbpExpensesReportsDepartmentView = 'sbpExpensesReportsDepartmentView',
  sbpExpensesReportsPlanView = 'sbpExpensesReportsPlanView',
  sbpExpensesReportsDetailed = 'sbpExpensesReportsDetailed',
  sbpExpensesReportsProvisionExpense = 'sbpExpensesReportsProvisionExpense',
  sbpExpensesReportsTrueUpExpense = 'sbpExpensesReportsTrueUpExpense',
  journalEntries = 'journalEntries',
  activityLogs = 'activityLogs',
  sbpExpensesReportsExpenseOutlookView = 'sbpExpensesReportsExpenseOutlookView',
}
