import axios from 'axios';
import { store } from 'store';
import { StoreActions } from 'store/actions';
import ApiUrlsService from '../bl/api-urls.service';
import * as Models from '../../shared.models';
import ConfigService from '../bl/config.service';
import { getFromLocalStorage } from '../../utils/localStorage';
import { USER_LOCALE } from '../../../localization/helpers';
import { ILocaleOption } from '../../models/models.dl';

export default class UserDataService {
  private static getUserGeoCode(): Promise<Models.DM.GeoCode> {
    return axios.get(ApiUrlsService.getUserGeoCode()).then(({ data }) => {
      ConfigService.SetGeoCode(data);
      return data;
    });
  }

  static getUserProfile = (): Promise<Models.DM.UserGetDto> => {
    return this.getUserGeoCode()
      .then(async () => {
        store.dispatch(StoreActions.Localization.setIsLoadingLocaleKeys(true));

        // Backend gets information about user locale by Bearer token,
        // that's why locale code is optional parameter in 'getUserProfile' API
        const [userData] = await Promise.all([
          axios.get(ApiUrlsService.getUserProfile()).then(({ data }) => data),
          store.dispatch(StoreActions.Localization.getLocaleKeys()),
        ]);

        // If a new user (or old without locale) selected locale on Login Page, fetch localeKeys by selected locale
        if (!userData?.settings?.locale) {
          const userLocale = getFromLocalStorage(USER_LOCALE) as ILocaleOption;
          if (userLocale) {
            await store.dispatch(StoreActions.Localization.getLocaleKeys(userLocale.id));
          }
        }
        return userData;
      })
      .then((data) => {
        store.dispatch(StoreActions.Localization.setIsLoadedLocaleKeys(true));
        store.dispatch(StoreActions.Localization.setIsLoadingLocaleKeys(false));

        return data;
      });
  };

  static saveUserSettings(settings: Models.DM.UserSettings): Promise<Models.DM.UserSettings> {
    return axios.post(ApiUrlsService.saveUserSettings(), settings).then(() => settings);
  }

  static clearUserCash(): Promise<void> {
    return axios
      .post(ApiUrlsService.clearUserCash())
      .then(({ data }) => data)
      .catch(() => {});
  }
}
