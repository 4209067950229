import { Types } from './types';
import type * as Interfaces from './interfaces';

export default function redirectToReducer(
  state: Interfaces.StoreType = '',
  action: Interfaces.RedirectToActions = {} as Interfaces.RedirectToActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_REDIRECT_TO: {
      return action.payload;
    }
    default:
      return state;
  }
}
