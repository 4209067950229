import { Types } from './types';
import type * as Interfaces from './interfaces';

export const toggleNotificationCenter = (isOpen?: Interfaces.StoreType['isOpen']) =>
  ({
    type: Types.SET_IS_OPEN,
    payload: isOpen,
  } as const);

export const reloadRelatedData = (dataKey: Interfaces.RelatedDataKeyForReload) =>
  ({
    type: Types.RELOAD_RELATED_DATA,
    payload: dataKey,
  } as const);
