export enum StoreName {
  CurrentUser = 'currentUser',
  RedirectTo = 'redirectTo',
  Loader = 'loader',
  Sidebar = 'sidebar',
  UserPanel = 'userPanel',
  Expenses = 'expenses',
  Reports = 'reports',
  Files = 'files',
  NotificationCenter = 'notificationCenter',
  FileUploading = 'fileUploading',
  Localization = 'localization',
  DataExporting = 'dataExporting',
  ManagerEmployeeContracts = 'managerEmployeeContracts',
}
