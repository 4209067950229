// eslint-disable-next-line
import { AxiosRequestConfig } from 'axios';
import { store } from '../../../store';
import { setIsLoading } from '../../../store/loader/actions';

const requestList: any[] = [];

class BlackListItem {
  public methods: Array<string>;

  public url?: string;

  public regex?: RegExp;

  constructor(methods: Array<string>, url?: string, regex?: RegExp) {
    this.methods = methods;
    this.url = url;
    this.regex = regex;
  }
}

const loaderBlackList: Array<BlackListItem> = [new BlackListItem(['POST'])];

export default class LoaderService {
  static request(request: AxiosRequestConfig): void {
    requestList.push(request);

    if (!this.canChangeLoaderStatus(request)) {
      if (!store.getState().loader && requestList.length >= 1) {
        store.dispatch(setIsLoading(true));
      }
    }
  }

  static response(): void {
    requestList.splice(0, 1);
    if (requestList.length === 0) {
      store.dispatch(setIsLoading(false));
    }
  }

  private static canChangeLoaderStatus(request: AxiosRequestConfig): boolean {
    return loaderBlackList.some((item: BlackListItem): boolean => {
      return this.findMethod(item, request);
    });
  }

  private static findMethod(item: BlackListItem, request: AxiosRequestConfig): boolean {
    return item.methods.some((method: string) => {
      return method === request.method;
    });
  }
}
