import { FC, ReactNode } from 'react';

interface IProps {
  href?: string;
  className?: string;
  children?: ReactNode;
}

const FooterLink: FC<IProps> = ({ className, href, children }) => (
  <a href={href} target="_blank" rel="noreferrer" className={`footer__link ${className}`}>
    {children || href}
  </a>
);

export default FooterLink;
