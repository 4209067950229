import { FC, useEffect, useState, useRef, cloneElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropDownList,
  DropDownListProps,
  DropDownListChangeEvent as ListChangeEvent,
  DropDownListFilterChangeEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';

import LabelText from '../Label';

import './SingleSelectControl.scss';

const chevronDownIconClass = 'k-i-arrow-chevron-down';
const chevronUpIconClass = 'k-i-arrow-chevron-up';
const defaultPopupSettings = {
  className: 'SingleSelectPopup',
};

export type DropDownListChangeEvent = ListChangeEvent;
interface IProps {
  isRequiredIcon?: boolean;
  onChange?: (value: DropDownListChangeEvent) => void;
  defaultOption?: any;
  placeholder?: string;
  optional?: string;
  defaultItem?: any;
}

const SingleSelectControl: FC<IProps & Omit<DropDownListProps, 'onFilterChange' | 'filter'>> = ({
  isRequiredIcon = false,
  onChange,
  label,
  optional,
  placeholder,
  value,
  defaultOption,
  defaultItem,
  filterable = false,
  data,
  popupSettings = {},
  ...props
}) => {
  const { t } = useTranslation();
  const selectFieldRef = useRef<DropDownList>(null);
  const prevIconClassName = useRef<string>(chevronDownIconClass);
  const [ddValue, setValue] = useState<DropDownListChangeEvent['value']>(null);
  const [iconClass, setIconClass] = useState<string>(chevronDownIconClass);
  const [filterValue, setFilterValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<any[]>(data || []);

  useEffect(() => {
    prevIconClassName.current = iconClass;
  }, [iconClass]);

  const handleChange = (e: DropDownListChangeEvent) => {
    const { value: inputValue } = e;
    setValue(inputValue);

    if (onChange) {
      onChange(e);
    }
  };

  const handleClose = () => {
    setFilterValue('');
    setFilteredData([...(data || [])]);
    setIconClass(chevronDownIconClass);
  };

  const handleOpen = () => {
    setIconClass(chevronUpIconClass);
  };

  const filterData = (filter: FilterDescriptor) => {
    const allData = [...(data || [])];
    return filterBy(allData, filter);
  };

  const handleFilterChange = ({ filter }: DropDownListFilterChangeEvent) => {
    setFilterValue(filter.value || '');
    setFilteredData(filterData(filter));
  };

  useEffect(() => {
    if (defaultOption) {
      setValue(defaultOption);
    }
  }, [defaultOption]);

  useEffect(() => {
    if ((defaultOption?.text || ddValue?.text) && selectFieldRef.current) {
      selectFieldRef.current.actionElement?.setAttribute('title', ddValue?.text || defaultOption?.text);
    }
  }, [defaultOption, ddValue]);

  useEffect(() => {
    if (data) {
      setFilteredData(data);
    }
  }, [data]);

  const itemRenderer = useCallback((li: React.ReactElement<HTMLLIElement>, { dataItem }: ListItemProps) => {
    return cloneElement(li, { ...li.props, title: dataItem?.text || '' });
  }, []);

  const isSelectedValuePlaceholder = !!placeholder && (ddValue?.text || defaultItem?.text) === placeholder;

  const valueRenderer = useCallback(
    (element: React.ReactElement<HTMLSpanElement>, optionData: any) => {
      return isSelectedValuePlaceholder || !optionData?.text
        ? element
        : cloneElement(element, { ...element.props, title: optionData.text });
    },
    [isSelectedValuePlaceholder]
  );

  const listNoDataRender: DropDownListProps['listNoDataRender'] = useCallback(
    (element) => {
      return cloneElement(element, element.props, t('Common.FormDropdown.NoDataFound'));
    },
    [t]
  );

  return (
    <div className={`select-control ${isSelectedValuePlaceholder ? 'select-control--placeholder' : ''}`}>
      {!!label && <LabelText valid={props.valid} label={label} isRequiredIcon={isRequiredIcon} optional={optional} />}

      <DropDownList
        {...props}
        ref={selectFieldRef}
        data={filteredData}
        filter={filterValue}
        filterable={filterable}
        onFilterChange={handleFilterChange}
        iconClassName={iconClass}
        value={ddValue}
        onChange={handleChange}
        textField="text"
        dataItemKey="id"
        onOpen={handleOpen}
        onClose={handleClose}
        defaultItem={defaultItem}
        popupSettings={{ ...defaultPopupSettings, ...popupSettings }}
        itemRender={itemRenderer}
        valueRender={valueRenderer}
        listNoDataRender={listNoDataRender}
      />
    </div>
  );
};
export default SingleSelectControl;
