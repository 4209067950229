import { TFunction } from 'react-i18next';

type ResultType = string[];

const filterStrings = (item: any): boolean => !!item && typeof item === 'string';

const getErrorsFromString = (value: string, t: TFunction): ResultType => (value ? [t(value)] : []);

const getErrorsFromArray = (arr: any[], t: TFunction): ResultType => {
  return arr.reduce((acc: string[], val: any) => {
    if (filterStrings(val)) {
      acc.push(t(val));
    }

    return acc;
  }, []);
};

const getErrorsFromObject = (obj: Record<string, any>, t: TFunction): ResultType =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      const errorsStr: string = getErrorsFromArray(value, t).join(', ');
      if (errorsStr) {
        acc.push(`${key}: ${errorsStr}`);
      }
    }
    return acc;
  }, [] as ResultType);

const getErrors = (err: any, t: TFunction): ResultType => {
  if (typeof err === 'string') return getErrorsFromString(err, t);
  if (Array.isArray(err)) return getErrorsFromArray(err, t);
  if (typeof err === 'object') return getErrorsFromObject(err, t);
  return [];
};

export const parseErrorResponse = (err: any, t: TFunction): string[] => {
  let errors: string[] = getErrors(
    err?.response?.data?.errors || err?.response?.data?.error || err?.response?.data || err,
    t
  );
  if (!errors.length) {
    errors = [err?.message ? t(err.message) : t('Common.SomethingWentWrong')];
  }
  return errors;
};
