import axios from 'axios';
import { FileUploadingState } from 'shared/models/enums.dl';
import { IFileUploadingApiEntity } from 'shared/models/models.bl';
import ApiUrlsService from './api-urls.service';

export default class FileUploading {
  static getFileUploadingState(type: IFileUploadingApiEntity): Promise<FileUploadingState | undefined> {
    return axios.get(ApiUrlsService.getFileUploadingState(type)).then(({ data }) => data);
  }

  static checkIsUploadingStatus(status?: FileUploadingState): boolean {
    return status === FileUploadingState.New || status === FileUploadingState.InProgress;
  }
}
