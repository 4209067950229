import type { ReactNode } from 'react';
import type { OIDCUser } from 'OIDCAuth/models/OIDCUser';
import type { TFunction } from 'react-i18next';
import { ExtractedFields } from 'Pages/EmployeeContractDetails/constants';
import { UploadStatus } from 'Pages/ManageEmployeeContracts/CellsManageEmployeeContracts/CellUploadStatus/constatnts';
import type * as formatDate from '../utils/dateFormatter';
import type { SumRowKey, ExpenseReportStatus, FilesContainerName } from './enums.dl';
import type { ApiEntity, ReportsApiEntity } from './enums.bl';
import type { SelectOption, UserGetDto } from './models.dl';

export interface IPropsBase {
  className?: string;
}

export interface ShareRegisterTableData {
  uid: string;
  id: string;
  optionholder: string;
  optionsGranted?: number | null;
  grantDate?: string | null;
  vestingSchedule: string | null;
  vestingCommencementDate?: string | null;
  employeeId: string | null;
  performanceHurdleId?: any | null;
  location: string | null;
  grantPlan: string | null;
  vestingFunction: string | null;
  forfeitureRate?: number | null;
  fileName: string | null;
  // leave: string | null;
  // department?: string | null;
}

export interface IGrantRequestData
  extends Pick<
    Required<ShareRegisterTableData>,
    'id' | 'optionholder' | 'vestingSchedule' | 'employeeId' | 'location' | 'grantPlan'
  > {
  uid?: string;
  performanceHurdleId: any;
  optionsGranted: number;
  grantDate: string;
  vestingCommencementDate: string;
  forfeitureRate: number;
}

export interface INewGrantIdValidationResponse {
  isCustomVestingScheduleExists: boolean;
  scheduleName: string;
}

export interface CustomVestingScheduleTableData {
  uid: string;
  grantId: string;
  scheduleName?: string | null;
  grantLife?: number | null;
  vestingCommencement?: string | null;
  grantDate?: string | null;
  totalOptions?: number | null;
  cliff?: string | null;
  cliffDuration?: string | null;
  cliffShares?: number | null;
  linearVesting?: string | null;
  vestingMonths?: string | null;
  vestingUnits: IScheduleGeneratedData[];
  leave?: string | null;
  leaveStart?: string | null;
  leaveEnd?: string | null;
  fileName?: string | null;
}

export interface IScheduleDataForGenerating {
  vestingCommencement: string;
  cliff: string;
  cliffDuration: number;
  grantLife: number;
  linearVesting: string;
  cliffShares: number;
  totalOptions: number;
}

export interface IScheduleGeneratedData {
  date: string;
  unit: number;
}

export interface IScheduleData {
  uid?: string;
  grantId: string;
  scheduleName: string;
  vestingCommencement: Date | null;
  grantDate: Date | null;
  cliff: 'Yes' | 'No';
  cliffDuration: number;
  grantLife: number;
  linearVesting: 'Yes' | 'No';
  cliffShares: number;
  totalOptions: number;
  leave: 'Yes' | 'No';
  leaveStartDate: Date | null;
  leaveEndDate: Date | null;
  vestingData: IScheduleGeneratedData[];
}

export interface EmployeeStatusTableData {
  id: string;
  optionHolder: string;
  terminationType?: string | null;
  terminationDate?: string | null;
  startDate?: string | null;
  location?: string | null;
  status?: string | null;
  entity?: string | null;
  departmentA?: string | null;
  departmentB?: string | null;
  departmentC?: string | null;
  leaveStartDate?: string | null;
  leaveEndDate?: string | null;
  fileName?: string | null;
}
export interface IEmployeeStatusData
  extends Omit<EmployeeStatusTableData, 'terminationDate' | 'startDate' | 'leaveStartDate' | 'leaveEndDate'> {
  terminationDate: Date | string | null;
  startDate: Date | string | null;
  leaveStartDate: Date | string | null;
  leaveEndDate: Date | string | null;
  isNew?: boolean;
}
export interface ActivityLogsTableData {
  timeStamp: string;
  details: string;
  user: string;
}

export interface GenerateExpensesTableData {
  reportTimeStamp: string;
  grantId: string;
  optionholder?: string | null;
  grantDate?: string | null;
  terminationDate?: string | null;
  terminationType?: string | null;
  location?: string | null;
  grantScheme?: string | null;
  vestingSchedule?: string | null;
  schemeValuation?: string | null;
  vestPercentage?: number | null;
  vestNo?: number | null;
  vestM?: number | null;
  optionValue?: number | null;
  vestingSatisfied?: string | null;
  status?: string | null;
  optionsGranted?: number | null;
  vestingCommencementDate?: string | null;
  months?: number | null;
  vestingFunction?: string | null;
  forfeitureRate?: number | null;
  department?: string | null;
}

type ReportStatus = 'Vested' | 'Reverse';

type WithSumRowKey = SumRowKey | string | null;

export interface SummaryTotalTableData {
  grantDate?: WithSumRowKey;
  grantScheme?: `'---` | string | null;
  location?: `'---` | string | null;
  status?: `'---` | ReportStatus;
}
export interface EmployeeViewTableData extends Omit<SummaryTotalTableData, 'grantDate'> {
  optionholder?: WithSumRowKey;
  grantDate?: string | null;
  schemeValuation?: string | null;
  department?: string | null;
  grantId?: string | null;
}

export type GeographicalViewTableData = SummaryTotalTableData;
export interface DepartmentViewTableData extends SummaryTotalTableData {
  GrantPlan: string;
}
export type ExpenseOutlookTableData = SummaryTotalTableData;
export type PlanViewTableData = SummaryTotalTableData;
export interface DataForFinalizationResponse {
  employeeStatusFiles: string | null;
  grantValuationsFiles: string | null;
  shareRegisterFiles: string | null;
  isProvisionCalculationComplete: boolean;
  listProvisionExpensesPerMonth: string | string[] | null;
  listTrueUpExpensesPerMonth: string | string[] | null;
}

// for Store
export interface DataForFinalization extends DataForFinalizationResponse {
  totalProvisions: number;
  totalTrueUp: number;
}
//
export interface DataForFinalizationRequest {
  reportUId: string;
  reportName: string;
  previousReportName: string;
  reportDate: string;
  shareRegisterFiles: string;
  employeeStatusFiles: string;
  grantValuationsFiles: string;
  selectedMonthExpense: number;
  ytdExpense: number;
  provisions: number;
  trueUpExpense: number;
}
export interface IProvisionalExpenseTableDataResponse {
  reportUId: string;
  reportTimeStamp: string;
  provisionDate?: string | null;
  employeeId?: string | null;
  employeeName?: string | null;
  offerDate?: string | null;
  startDate?: string | null;
  options?: number | null;
  valuation?: number | null;
  vestingSchedule?: string | null;
  provisionExpenseValue?: number | null;
  inSchedule?: string | null;
  location?: string | null;
  plan?: string | null;
  fileName?: string | null;
}
export interface IProvisionalExpenseChartDataResponse {
  reportUId: string;
  provisionExpenseValue?: number;
  provisionDate: string;
  location: string;
  plan: string;
}
export interface IProvisionalExpenseFiltersResponse {
  dates: string[] | null;
  locations: string[] | null;
  schemes: string[] | null;
}
export interface IProvisionalExpenseFiltersRequest {
  provisionDate?: string;
  location?: string;
  plan?: string;
}
export type IProvisionalExpenseTableDataRequest = ITableDataInfoRequest & IProvisionalExpenseFiltersRequest;
export interface IProvisionalExpenseCalculationResult {
  hasInSchedule: boolean;
  hasInvalidProvisionDate: boolean;
  provisionDate: string;
  totalProvisionExpense: number;
  totalProvisionExpensePerLocation: string[] | null;
}

export interface IExpensesJournalExportToExcelRequest extends IExpensesJournalSelectedOptions {
  exportData: string;
}

export interface IExpensesJournalSelectedOptions extends IExpensesJournalFilters {
  reportUId: string;
  monthYear: Date;
}

export interface IExpensesJournalFilters {
  locations: string[];
  schemes: string[];
  entities: string[];
  departmentAs: string[];
  departmentBs: string[];
  departmentCs: string[];
}

export interface IDepartmentFilters {
  entities: string[];
  departmentAs: string[];
  departmentBs: string[];
  departmentCs: string[];
}

export interface IExpensesJournalCalculatedData {
  currentMonthExpense: number;
  provisionCurrentMonth: number;
  provisionPreviousMonth: number;
  trueUpCurrentMonth: number;
}

export interface AvailableReport {
  reportUId: string;
  reportTimeStamp: string;
  reportName: string; // formatted timestamp
  status: ExpenseReportStatus | null;
}

export interface ICalculationDate extends SelectOption {
  id: string;
  status: AvailableReport['status'];
}

export interface GrantValuationTableData {
  plan: string;
  fileName?: string | null;
}

export interface WithHeaderTableResponseAndTotal<T> {
  tableData: {
    data: T[];
    header?: ColumnConfiguration<T>[];
  };
  totalCount: number;
}

export interface WithHeaderTableResponse<T> {
  data: T[];
  header?: ColumnConfiguration<T>[];
}

export interface ITableDataInfoRequest {
  searchBy: string;
  orderBy: string;
  asc: boolean;
  pageNumber: number;
  pageSize: number;
}

export interface ITableDataInfo {
  SearchBy: string;
  OrderBy: string;
  Asc: boolean;
  PageNumber: number;
  ItemsPerPageCount: number;
  TotalCount: number;
}

export interface ColumnConfiguration<T> {
  name: keyof T;
  title: string;
  sortable?: boolean | null;
  sortDir?: string | null;
  format?: string | null;
  formatMask?: string | null;
  cls?: string | null;
  clsColumn?: string | null;
  size?: number | null;
  show?: boolean | null;
  isLink?: boolean;
  isColumnTitleLink?: boolean;
  template?: string | null;
  thousandSeparator?: string | null;
  decimalSeparator?: string | null;
  renderCell?: (row: T, t: TFunction) => ReactNode;
  dateFormat?: formatDate.DateFormat;
}

export type ResponseTableDataType<T> = WithHeaderTableResponse<T> | T[] | null;

export interface PaginatedResponseTableDataType<T> {
  tableData: ResponseTableDataType<T>;
  totalCount: number;
}
export interface UserProfile {
  user: UserGetDto | null;
  tokenObj: OIDCUser | null;
}
export interface IValidator {
  validate(value: any): ValidationData;
}

export class ValidationData {
  constructor(public errorText: Array<string> = [], public isValid: boolean = false) {}
}
export interface FormControl {
  value: string;
  errorText: string[];
  isValid: boolean;
  isTouched: boolean;
  validators: IValidator[];
}

export type FormControls = {
  [fieldname: string]: FormControl;
};
export interface IValuationObj {
  Year: string;
  Name: string;
  Date: Date;
  Valuation: string;
  Valuations: string;
}

export interface IPerformanceVestingCondition {
  hurdleFrom: string;
  hurdleTo: string;
  vestingFrom: string;
  vestingTo: string;
}

export interface IPerformanceSubHurdle {
  shUid: string;
  subHurdleName: string;
  weightage: string;
  unit: string;
  performanceSubHurdleVesting: IPerformanceVestingCondition[];
}

export interface IPerformanceHurdle {
  uid: string;
  performanceHurdleId: string;
  performanceSubHurdle: IPerformanceSubHurdle[];
}
export interface IUpdatePerformanceHurdleEstimate {
  performanceHurdleId: string | undefined;
  performanceSubHurdle: IperformanceHurdleEstimation[];
}
export interface IperformanceHurdleEstimation {
  subHurdleName: string;
  unit: string;
  estimate: IPerformanceHurdleEstimate[];
}
export interface IPerformanceHurdleEstimate {
  dateOfEstimate: string | null | Date;
  estimateValue: number;
  isFinalized: boolean;
}
export interface IOldHeaderData {
  year: number;
  name: string;
}
export interface INewHeaderResultData extends IOldHeaderData {
  previousYear: number;
  previousName: string;
}

export interface ICalculateValuation {
  SharePrice: string;
  MaturityTime: string;
  ExercisePrice: string;
  RiskFreeRate: string;
  Volatility: string;
  DividendYield: string;
}

export interface IGrantValuationData {
  scheme: string;
  valuations: string;
  isNew: boolean;
}

export interface ICalculateValuationData {
  sharePrice: number;
  timeToMaturity: number;
  exercisePrice: number;
  riskFreeRate: number;
  volatility: number;
  dividendYield: number;
}

export interface ITrueUpCalculationRequest {
  originalReportUId: string;
  updatedReportUId: string;
  trueUpStartDate: string;
  trueUpEndDate: string;
  reportUId: string;
}

export interface ITrueUpCalculationResponse {
  success: boolean;
}

export interface ITrueUpInfoResponse {
  employeesInReport1NotIn2: string;
  employeesInReport2NotIn1: string;
  grantsInReport1NotIn2: string;
  grantsInReport2NotIn1: string;
  locations: string[];
  newReportUID: string;
  previousReportUID: string;
  reportStartDate: string;
  reportEndDate: string;
  schemes: string[];
  success: boolean;
  trueUpExpense: number;
}

export interface TrueUpReportTableData {
  grantDate?: string | null;
  grantPlan?: string | null;
  location?: string | null;
  status?: string | null;
}

export interface ITrueUpExportRequest {
  reportUId: string;
  location: string;
  grantPlan: string;
}

export interface ITrueUpFilterProps {
  location: string;
  plan: string;
}

export interface ITrueUpTableRequest extends ITrueUpFilterProps, ITableDataInfoRequest {
  reportUId: string;
}

export interface IExpensesCalculationValidationResponse {
  currentReportCountExceeded: boolean;
  grantValuationCount: number;
  schemesNotInValuationTable: string[];
  unspecifiedPerformanceHurdleCount: number;
  unspecifiedVestingFunctionCount: number;
  validVestingFunctionCount: number;
}

export interface IExpensesTableDataResponse {
  header: unknown[];
  data: GenerateExpensesTableData[];
}

export interface IUploadFileDbResultResponse {
  rowsAdded: number;
  rowsAddedOrUpdated: number;
  rowsDeleted: number;
  rowsUpdated: number;
}

export type IExpensesSaveResponse = IUploadFileDbResultResponse;

/**
 * `dbResultRelatedKey` - Starts with dbResult and ends with 'RelatedKey' (can be ShareRegister or ValuationRef as example).
 * Uses as placeholder key for real related key which starts with dbResult
 * */
export interface IUploadFileResponseWithError {
  dbResult: IUploadFileDbResultResponse;
  dbResultRelatedKey?: IUploadFileDbResultResponse;
  error?: string;
}

export type IUploadFileResponse = IUploadFileDbResultResponse | IUploadFileResponseWithError | number;

export interface IExpensesSaveReportsResponse {
  CurrentReportCountExceeded: boolean;
  dbResult: IExpensesSaveResponse;
  TimeStamp: string;
}

export interface IPaginatedTableResponseWithHeaders<T> {
  tableData: { data: T[]; header: ColumnConfiguration<T>[] };
  totalCount: number;
}

export interface IPaginatedTableResponse<T> {
  tableData: T[];
  totalCount: number;
}

export interface IExportSummaryReportRequest {
  selectedMonthExpense: number;
  ytdExpense: number;
  ytdExpenseName: string;
  provisions: number;
  currencySymbol: string;
}

/**
 * `month` - integer, from 1 to {@link IGenericVestingScheduleResponseData.months months}
 *
 * `unit` - positive, can be decimal
 */
export interface IGenericVestingScheduleResponseUnit {
  month: number;
  unit: number;
}

export interface IGenericVestingScheduleResponseData {
  uid: string;
  scheduleName: string;
  months: number;
  genericVestingScheduleUnits: IGenericVestingScheduleResponseUnit[];
}

export interface IGenericVestingScheduleRequestData extends Omit<IGenericVestingScheduleResponseData, 'uid'> {
  uid: string | null;
  previousScheduleName: string | null;
}

export interface IGenericVestingScheduleDeleteOrUpdateValidationResponseData {
  assigned: boolean;
}

export type CurrentEnv = 'DEV' | 'QA' | 'LOAD' | 'STAGE' | 'PROD';

export type IFileUploadingApiEntity =
  | 'ShareRegister'
  | 'employee'
  | 'valuation'
  | 'provisionExpense'
  | 'CustomVestingSchedule';

export type IExportingDataApiEntity = ApiEntity | `${ApiEntity.Reports}/${ReportsApiEntity}`;

export type IFileUploadingView =
  | 'shareRegister'
  | 'grandValuation'
  | 'employeeStatus'
  | 'provisionExpense'
  | 'customVesting';

export interface IBeforeUploadWarningModalData {
  visible: boolean;
  title: string;
  text: string;
}

export interface IExportedAndPublicFilesRequestData extends ITableDataInfoRequest {
  containerNames: Array<FilesContainerName.Reports | FilesContainerName.PublicFiles>;
}
export interface ExpenseReportProgressDataResponse {
  status: ExpenseReportStatus;
  createdDate: string;
  percent: number;
  firstName: string;
  lastName: string;
  calculationNotRun: boolean;
}
export interface PerformanceHurdlesTableData {
  performanceHurdleId: string;
  createdDate: string;
  subHurdleName: string;
  updatedBy: string;
  updatedDate: string;
}
export interface IPerformanceHurdlesTableResponse<T> {
  tableData: T[];
  totalCount: number;
}
export interface IUpdateResultResponse {
  rowsAdded: number;
  rowsAddedOrUpdated: number;
  rowsDeleted: number;
  rowsUpdated: number;
}

export interface ManageEmployeeContractsTableData {
  uid: string;
  uploadDate: string;
  uploadStatus: UploadStatus;
  location: string;
  grantPlan: string;
  forfeitureRate: number;
  fileName: string;
}

export interface UploadManageEmployeeContracts {
  forfeitureRate: number;
  grantPlan: string;
  location: string;
  filesCount: number;
}

export interface InputsEmployeeContracts {
  grantPlan: string;
  location: string;
}

export interface EmployeeContractDetailsSave {
  uid: string;
  optionHolder: string;
  optionsGranted: string;
  grantDate: string;
  vestingCommencementDate: string;
  vestingPeriodEndDate: string;
  vestingSchedule: number;
  employeeId: string;
  forfeitureRate: number;
  grantPlan: string;
  location: string;
  fileName: string;
}

export interface EmployeeContractDetailsData {
  forfeitureRate: number;
  grantPlan: string;
  location: string;
  uid: string;
  fileName: string;
  extractedFileData: ExtractedFileData[];
}

interface ExtractedFileData {
  entityName: ExtractedFields;
  entityValue: string;
  pageNumber: number;
  lineNumber: number;
  boundingBox: number[];
  pageWidth: number;
  pageHeight: number;
}
