import * as BM from '../models/models.bl';
import * as DM from '../models/models.dl';
import Utils from '../shared.utils';

export const DefaultItemsPerPage = 5;

export const PageSizes = Utils.getSelectOptions([5, 10, 25, 50, 100]);

export const getInitialPaginatedTableData = <T>(
  initialConfig: BM.ColumnConfiguration<T>[]
): BM.IPaginatedTableResponseWithHeaders<T> => ({
  tableData: {
    data: [],
    header: initialConfig,
  },
  totalCount: 0,
});

export const defaultPagingRequestData: BM.ITableDataInfoRequest = {
  searchBy: '',
  orderBy: '',
  asc: true,
  pageNumber: 1,
  pageSize: DefaultItemsPerPage,
};

const bgCyanFgWhiteClassName = 'bg-cyan fg-white'; // need to for colored headers. Such class comes with response data headers to.

enum CommonColumnName {
  id = 'id',
  startDate = 'startDate',
  leaveStartDate = 'leaveStartDate',
  leaveEndDate = 'leaveEndDate',
  grantDate = 'grantDate',
  terminationDate = 'terminationDate',
  terminationType = 'terminationType',
  optionHolder = 'optionHolder',
  optionsGranted = 'optionsGranted',
  type = 'type',
  status = 'status',
  vestingSchedule = 'vestingSchedule',
  vestingCommencementDate = 'vestingCommencementDate',
  employeeId = 'employeeId',
  location = 'location',
  vestingFunction = 'vestingFunction',
  forfeitureRate = 'forfeitureRate',
  performanceHurdleId = 'performanceHurdleId',
  department = 'department',
  departmentA = 'departmentA',
  departmentB = 'departmentB',
  departmentC = 'departmentC',
  entity = 'entity',
  leave = 'leave',
  fileName = 'fileName',
  grantId = 'grantId',
  grantPlan = 'grantPlan',
  grantPlanNew = 'grantPlanNew',
  schemeValuation = 'schemeValuation',
  reportTimeStamp = 'reportTimeStamp',
  plan = 'plan',
}

const commonColumnConfigs: Record<CommonColumnName, BM.ColumnConfiguration<Record<any, any>>> = {
  [CommonColumnName.startDate]: {
    name: 'startDate',
    title: 'Start Date',
    dateFormat: Utils.formatDate.DateFormat.MonthDayYear,
  },
  [CommonColumnName.leaveStartDate]: {
    name: 'leaveStartDate',
    title: 'Leave Start Date',
    cls: bgCyanFgWhiteClassName,
    dateFormat: Utils.formatDate.DateFormat.MonthDayYear,
  },
  [CommonColumnName.leaveEndDate]: {
    name: 'leaveEndDate',
    title: 'Leave End Date',
    cls: bgCyanFgWhiteClassName,
    dateFormat: Utils.formatDate.DateFormat.MonthDayYear,
  },
  [CommonColumnName.grantDate]: {
    name: 'grantDate',
    title: 'Grant Date',
    dateFormat: Utils.formatDate.DateFormat.MonthDayYear,
  },
  [CommonColumnName.terminationDate]: {
    name: 'terminationDate',
    title: 'Termination Date',
    dateFormat: Utils.formatDate.DateFormat.MonthDayYear,
  },
  [CommonColumnName.terminationType]: {
    name: 'terminationType',
    title: 'Termination Type',
  },
  [CommonColumnName.optionHolder]: {
    name: 'optionholder',
    title: 'Optionholder',
  },
  [CommonColumnName.optionsGranted]: {
    name: 'optionsGranted',
    title: 'Options Granted',
  },
  [CommonColumnName.type]: {
    name: 'type',
    title: 'Type',
  },
  [CommonColumnName.status]: {
    name: 'status',
    title: 'Status',
    renderCell: (row, t): string => (row.status ? t(row.status) : ''),
  },
  [CommonColumnName.vestingSchedule]: {
    name: 'vestingSchedule',
    title: 'Vesting Schedule',
  },
  [CommonColumnName.vestingCommencementDate]: {
    name: 'vestingCommencementDate',
    title: 'Vesting Commencement Date',
    dateFormat: Utils.formatDate.DateFormat.MonthDayYear,
  },
  [CommonColumnName.employeeId]: {
    name: 'employeeId',
    title: 'Employee ID',
  },
  [CommonColumnName.location]: {
    name: 'location',
    title: 'Location',
  },
  [CommonColumnName.vestingFunction]: {
    name: 'vestingFunction',
    title: 'Vesting Function',
  },
  [CommonColumnName.forfeitureRate]: {
    name: 'forfeitureRate',
    title: 'Forfeiture Rate',
  },
  [CommonColumnName.performanceHurdleId]: {
    name: 'performanceHurdleId',
    title: 'Performance Hurdle ID',
  },
  [CommonColumnName.department]: {
    name: 'department',
    title: 'Department',
  },
  [CommonColumnName.departmentA]: {
    name: 'departmentA',
    title: 'Department A',
    renderCell: (row, t): string => (row.departmentA ? t(row.departmentA) : ''),
  },
  [CommonColumnName.departmentB]: {
    name: 'departmentB',
    title: 'Department B',
    renderCell: (row, t): string => (row.departmentB ? t(row.departmentB) : ''),
  },
  [CommonColumnName.departmentC]: {
    name: 'departmentC',
    title: 'Department C',
    renderCell: (row, t): string => (row.departmentC ? t(row.departmentC) : ''),
  },
  [CommonColumnName.entity]: {
    name: 'entity',
    title: 'Entity',
    renderCell: (row, t): string => (row.entity ? t(row.entity) : ''),
  },
  [CommonColumnName.leave]: {
    name: 'leave',
    title: 'Leave',
    renderCell: (row, t): string => (row.leave ? t(row.leave) : ''),
  },
  [CommonColumnName.fileName]: {
    name: 'fileName',
    title: 'File Name',
    cls: bgCyanFgWhiteClassName,
    renderCell: (row, t): string => (row.fileName ? t(row.fileName) : ''),
  },
  [CommonColumnName.grantId]: {
    name: 'grantId',
    title: 'Grant ID',
  },
  [CommonColumnName.id]: {
    name: 'id',
    title: 'Id',
    isLink: true,
  },
  [CommonColumnName.grantPlan]: {
    name: 'grantScheme',
    title: 'Grant Plan',
  },
  [CommonColumnName.grantPlanNew]: {
    name: 'grantPlan',
    title: 'Grant Plan',
  },
  [CommonColumnName.schemeValuation]: {
    name: 'schemeValuation',
    title: 'Scheme Valuation',
    dateFormat: Utils.formatDate.DateFormat.MonthYear,
  },
  [CommonColumnName.reportTimeStamp]: {
    name: 'reportTimeStamp',
    title: 'Report Time Stamp',
  },
  [CommonColumnName.plan]: {
    name: 'plan',
    title: 'Plan',
    dateFormat: Utils.formatDate.DateFormat.FullYearFullMonth,
  },
};

export class GridColumnConfig {
  public static ShareRegisterConfig: Array<BM.ColumnConfiguration<BM.ShareRegisterTableData>> = [
    commonColumnConfigs[CommonColumnName.id],
    commonColumnConfigs[CommonColumnName.optionHolder],
    commonColumnConfigs[CommonColumnName.optionsGranted],
    commonColumnConfigs[CommonColumnName.grantDate],
    commonColumnConfigs[CommonColumnName.vestingSchedule],
    commonColumnConfigs[CommonColumnName.vestingCommencementDate],
    commonColumnConfigs[CommonColumnName.employeeId],
    {
      ...commonColumnConfigs[CommonColumnName.location],
      cls: bgCyanFgWhiteClassName,
    },
    {
      ...commonColumnConfigs[CommonColumnName.grantPlanNew],
      cls: bgCyanFgWhiteClassName,
    },
    {
      ...commonColumnConfigs[CommonColumnName.vestingFunction],
      cls: bgCyanFgWhiteClassName,
    },
    {
      ...commonColumnConfigs[CommonColumnName.forfeitureRate],
      cls: bgCyanFgWhiteClassName,
    },
    // {
    //   ...commonColumnConfigs[CommonColumnName.department],
    //   cls: bgCyanFgWhiteClassName,
    // },
    // {
    //   ...commonColumnConfigs[CommonColumnName.leave],
    //   cls: bgCyanFgWhiteClassName,
    // },
    commonColumnConfigs[CommonColumnName.fileName],
  ];

  public static VestingScheduleConfig: Array<BM.ColumnConfiguration<BM.CustomVestingScheduleTableData>> = [
    {
      ...commonColumnConfigs[CommonColumnName.grantId],
      isLink: true,
      title: 'CustomVestingSchedules.CustomVestingTable.GrantIdColumnTitle',
    },
    {
      name: 'scheduleName',
      title: 'CustomVestingSchedules.CustomVestingTable.VestingScheduleColumnTitle',
    },
    {
      name: 'grantLife',
      title: 'CustomVestingSchedules.CustomVestingTable.GrantLifeColumnTitle',
    },
    {
      name: 'vestingCommencement',
      title: 'CustomVestingSchedules.CustomVestingTable.VestingCommencementColumnTitle',
      dateFormat: Utils.formatDate.DateFormat.MonthDayYear,
    },
    {
      ...commonColumnConfigs[CommonColumnName.grantDate],
      title: 'CustomVestingSchedules.CustomVestingTable.GrantDateColumnTitle',
    },
    {
      name: 'totalOptions', // INFO: title must be Initial Options but key stay as totalOptions
      title: 'CustomVestingSchedules.CustomVestingTable.InitialOptionsColumnTitle',
    },
    {
      name: 'cliff',
      title: 'CustomVestingSchedules.CustomVestingTable.CliffColumnTitle',
      renderCell: (row, t): string => (row.cliff ? t(row.cliff) : ''),
    },
    {
      name: 'cliffDuration',
      title: 'CustomVestingSchedules.CustomVestingTable.CliffDurationColumnTitle',
    },
    {
      name: 'cliffShares',
      title: 'CustomVestingSchedules.CustomVestingTable.CliffSharesColumnTitle',
    },
    {
      name: 'linearVesting',
      title: 'CustomVestingSchedules.CustomVestingTable.LinearVestingColumnTitle',
      renderCell: (row, t): string => (row.linearVesting ? t(row.linearVesting) : ''),
    },
    {
      ...commonColumnConfigs[CommonColumnName.leave],
      title: 'CustomVestingSchedules.CustomVestingTable.LeaveColumnTitle',
    },
    {
      name: 'leaveStart',
      title: 'CustomVestingSchedules.CustomVestingTable.LeaveStartDateColumnTitle',
    },
    {
      name: 'leaveEnd',
      title: 'CustomVestingSchedules.CustomVestingTable.LeaveEndDateColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.fileName],
      title: 'CustomVestingSchedules.CustomVestingTable.FileNameColumnTitle',
    },
  ];

  public static GenericVestingScheduleConfig: Array<BM.ColumnConfiguration<BM.IGenericVestingScheduleResponseData>> = [
    {
      name: 'scheduleName',
      title: 'CustomVestingSchedules.GenericVestingTable.ScheduleNameColumnTitle',
      isLink: true,
    },
    {
      name: 'months',
      title: 'CustomVestingSchedules.GenericVestingTable.MonthsColumnTitle',
    },
  ];

  public static EmployeeStatusConfig: Array<BM.ColumnConfiguration<BM.EmployeeStatusTableData>> = [
    { ...commonColumnConfigs[CommonColumnName.id], title: 'ID' },
    {
      name: 'optionHolder',
      title: 'Optionholder',
    },
    commonColumnConfigs[CommonColumnName.terminationType],
    commonColumnConfigs[CommonColumnName.terminationDate],
    commonColumnConfigs[CommonColumnName.startDate],
    commonColumnConfigs[CommonColumnName.location],
    commonColumnConfigs[CommonColumnName.entity],
    commonColumnConfigs[CommonColumnName.departmentA],
    commonColumnConfigs[CommonColumnName.departmentB],
    commonColumnConfigs[CommonColumnName.departmentC],
    commonColumnConfigs[CommonColumnName.leaveStartDate],
    commonColumnConfigs[CommonColumnName.leaveEndDate],
    commonColumnConfigs[CommonColumnName.fileName],
  ];

  public static GrantValuationConfig: Array<BM.ColumnConfiguration<BM.GrantValuationTableData>> = [
    {
      ...commonColumnConfigs[CommonColumnName.plan],
      title: 'GrantValuation.Table.PlanColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.fileName],
      title: 'GrantValuation.Table.FileNameColumnTitle',
    },
  ] as BM.ColumnConfiguration<BM.GrantValuationTableData>[];

  public static FilesConfig: Array<BM.ColumnConfiguration<DM.FileMetaData>> = [
    {
      name: 'fileName',
      title: 'Files.Table.NameColumnTitle',
      isLink: true,
    },
    {
      name: 'size',
      title: 'Files.Table.SizeColumnTitle',
    },
    {
      name: 'createdDate',
      title: 'Files.Table.DateCreatedColumnTitle',
    },
  ];

  public static ActivityLogsConfig: Array<BM.ColumnConfiguration<BM.ActivityLogsTableData>> = [
    {
      name: 'timeStamp',
      title: 'ActivityLogs.Table.TimestampColumnTitle',
      dateFormat: Utils.formatDate.DateFormat.FullWeekdayFullMonthDayHoursMinutesSeconds,
    },
    {
      name: 'details',
      title: 'ActivityLogs.Table.DetailsColumnTitle',
      renderCell: (row, t): string => (row.details ? t(row.details) : ''),
    },
    {
      name: 'user',
      title: 'ActivityLogs.Table.UserColumnTitle',
    },
  ];

  public static GenerateExpensesConfig: Array<BM.ColumnConfiguration<BM.GenerateExpensesTableData>> = [
    commonColumnConfigs[CommonColumnName.reportTimeStamp],
    commonColumnConfigs[CommonColumnName.grantId],
    commonColumnConfigs[CommonColumnName.optionHolder],
    commonColumnConfigs[CommonColumnName.grantDate],
    commonColumnConfigs[CommonColumnName.terminationDate],
    commonColumnConfigs[CommonColumnName.terminationType],
    commonColumnConfigs[CommonColumnName.location],
    commonColumnConfigs[CommonColumnName.grantPlan],
    commonColumnConfigs[CommonColumnName.vestingSchedule],
    commonColumnConfigs[CommonColumnName.schemeValuation],
    {
      name: 'vestPercentage',
      title: 'Vest (%)',
    },
    {
      name: 'vestNo',
      title: 'Vest (No)',
    },
    {
      name: 'vestM',
      title: 'Vest (m)',
    },
    {
      name: 'optionValue',
      title: 'Option Value',
    },
    {
      name: 'vestingSatisfied',
      title: 'Vesting Satisfied',
      dateFormat: Utils.formatDate.DateFormat.MonthYear,
    },
    commonColumnConfigs[CommonColumnName.status],
    commonColumnConfigs[CommonColumnName.optionsGranted],
    commonColumnConfigs[CommonColumnName.vestingCommencementDate],
    {
      name: 'months',
      title: 'Months',
    },
    commonColumnConfigs[CommonColumnName.vestingFunction],
    commonColumnConfigs[CommonColumnName.forfeitureRate],
    commonColumnConfigs[CommonColumnName.departmentA],
    commonColumnConfigs[CommonColumnName.departmentB],
    commonColumnConfigs[CommonColumnName.departmentC],
    commonColumnConfigs[CommonColumnName.entity],
  ];

  public static SummaryTotalConfig: Array<BM.ColumnConfiguration<BM.SummaryTotalTableData>> = [
    {
      ...commonColumnConfigs[CommonColumnName.grantDate],
      title: 'SbpExpenseReports.SummaryTotalTab.Tables.GrantDateColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.grantPlan],
      title: 'SbpExpenseReports.SummaryTotalTab.Tables.GrantPlanColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.location],
      title: 'SbpExpenseReports.SummaryTotalTab.Tables.LocationColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.status],
      title: 'SbpExpenseReports.SummaryTotalTab.Tables.StatusColumnTitle',
    },
  ];

  public static EmployeeViewConfig: Array<BM.ColumnConfiguration<BM.EmployeeViewTableData>> = [
    {
      ...commonColumnConfigs[CommonColumnName.optionHolder],
      title: 'SbpExpenseReports.EmployeeViewTab.Tables.OptionHolderColumnTitle}',
    },
    {
      ...commonColumnConfigs[CommonColumnName.grantDate],
      title: 'SbpExpenseReports.EmployeeViewTab.Tables.GrantDateColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.grantPlan],
      title: 'SbpExpenseReports.EmployeeViewTab.Tables.GrantPlanColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.schemeValuation],
      title: 'SbpExpenseReports.EmployeeViewTab.Tables.SchemeValuationColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.location],
      title: 'SbpExpenseReports.EmployeeViewTab.Tables.LocationColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.departmentA],
      title: 'SbpExpenseReports.EmployeeViewTab.Tables.DepartmentAColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.departmentB],
      title: 'SbpExpenseReports.EmployeeViewTab.Tables.DepartmentBColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.departmentC],
      title: 'SbpExpenseReports.EmployeeViewTab.Tables.DepartmentCColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.entity],
      title: 'SbpExpenseReports.EmployeeViewTab.Tables.EntityColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.status],
      title: 'SbpExpenseReports.EmployeeViewTab.Tables.StatusColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.grantId],
      title: 'SbpExpenseReports.EmployeeViewTab.Tables.GrantIdColumnTitle',
    },
  ];

  public static GeographicalViewConfig: Array<BM.ColumnConfiguration<BM.GeographicalViewTableData>> = [
    {
      ...commonColumnConfigs[CommonColumnName.grantDate],
      title: 'SbpExpenseReports.GeographicalViewTab.Tables.GrantDateColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.grantPlan],
      title: 'SbpExpenseReports.GeographicalViewTab.Tables.GrantPlanColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.location],
      title: 'SbpExpenseReports.GeographicalViewTab.Tables.LocationColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.status],
      title: 'SbpExpenseReports.GeographicalViewTab.Tables.StatusColumnTitle',
    },
  ];

  public static DepartmentViewConfig: Array<BM.ColumnConfiguration<BM.DepartmentViewTableData>> = [
    {
      ...commonColumnConfigs[CommonColumnName.grantDate],
      title: 'SbpExpenseReports.DepartmentViewTab.Tables.GrantDateColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.grantPlan],
      title: 'SbpExpenseReports.DepartmentViewTab.Tables.GrantPlanColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.location],
      title: 'SbpExpenseReports.DepartmentViewTab.Tables.LocationColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.status],
      title: 'SbpExpenseReports.DepartmentViewTab.Tables.StatusColumnTitle',
    },
  ];

  public static PlanViewConfig: Array<BM.ColumnConfiguration<BM.PlanViewTableData>> = [
    {
      ...commonColumnConfigs[CommonColumnName.grantDate],
      title: 'SbpExpenseReports.PlanViewTab.Tables.GrantDateColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.grantPlan],
      title: 'SbpExpenseReports.PlanViewTab.Tables.GrantPlanColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.location],
      title: 'SbpExpenseReports.PlanViewTab.Tables.LocationColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.status],
      title: 'SbpExpenseReports.PlanViewTab.Tables.StatusColumnTitle',
    },
  ];

  public static ProvisionalExpenseConfig: Array<BM.ColumnConfiguration<BM.IProvisionalExpenseTableDataResponse>> = [
    {
      ...commonColumnConfigs[CommonColumnName.reportTimeStamp],
      title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.ReportTimeStampColumnTitle',
    },
    {
      name: 'provisionDate',
      title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.ProvisionDateColumnTitle',
      dateFormat: Utils.formatDate.DateFormat.MonthYear,
    },
    commonColumnConfigs[CommonColumnName.employeeId],
    { name: 'employeeName', title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.EmployeeNameColumnTitle' },
    {
      name: 'offerDate',
      title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.OfferDateColumnTitle',
      dateFormat: Utils.formatDate.DateFormat.MonthDayYear,
    },
    {
      ...commonColumnConfigs[CommonColumnName.startDate],
      title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.StartDateColumnTitle',
    },
    { name: 'options', title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.OptionsColumnTitle' },
    { name: 'valuation', title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.ValuationColumnTitle' },
    {
      ...commonColumnConfigs[CommonColumnName.vestingSchedule],
      title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.VestingScheduleColumnTitle',
    },
    {
      name: 'provisionExpenseValue',
      title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.ProvisionExpenseColumnTitle',
    },
    { name: 'inSchedule', title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.InScheduleColumnTitle' },
    {
      ...commonColumnConfigs[CommonColumnName.location],
      title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.LocationColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.plan],
      title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.PlanColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.fileName],
      title: 'SbpExpensesReports.ProvisionalExpensePanel.Table.FileNameColumnTitle',
    },
  ];

  public static TrueUpReportConfig: Array<BM.ColumnConfiguration<BM.TrueUpReportTableData>> = [
    {
      ...commonColumnConfigs[CommonColumnName.grantDate],
      title: 'SbpExpensesReports.ExpenseTrueUpPanel.Tables.GrantDateColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.grantPlan],
      title: 'SbpExpensesReports.ExpenseTrueUpPanel.Tables.GrantPlanColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.location],
      title: 'SbpExpensesReports.ExpenseTrueUpPanel.Tables.LocationColumnTitle',
    },
    {
      ...commonColumnConfigs[CommonColumnName.status],
      title: 'SbpExpensesReports.ExpenseTrueUpPanel.Tables.StatusColumnTitle',
    },
  ] as BM.ColumnConfiguration<BM.TrueUpReportTableData>[];
  // todo: will be updated for the appropriate table columns of expense outlook

  public static ExpenseOutlookViewConfig: Array<BM.ColumnConfiguration<BM.ExpenseOutlookTableData>> = [];
}
