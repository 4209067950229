import { FC, useState, useEffect, useCallback, useRef } from 'react';
import Services from 'shared/shared.services';
import CustomHooks from 'shared/shared.hooks';
import { Selectors } from 'store/selectors';
import NotificationWrapper from './NotificationWrapper';
import type { INotification } from './core/interface';
import { NotificationConnectionProvider } from './core/SignalRNotification';
// import { runMocSocketNotification } from './core/mocNotifications';
import NotificationService from './core/notification.service';
import Notification from './Notification/Notification';

const maxNotificationListLength = 20;

const NotificationCenter: FC = () => {
  const refetchNeeded = useRef(false);
  const firstFetch = useRef(true);
  const loadingRef = useRef(true);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [loading, setLoading] = useState<boolean>(loadingRef.current);
  const isOpen = CustomHooks.storeHooks.useAppSelector(Selectors.Main.selectIsNotificationCenterOpen);
  const isMounted = CustomHooks.useIsMounted();

  const toggleLoading = (value: boolean) => {
    loadingRef.current = value;
    setLoading(value);
  };

  useEffect(() => {
    if ((isOpen && refetchNeeded.current && !loadingRef.current) || firstFetch.current) {
      if (firstFetch.current) firstFetch.current = false;
      toggleLoading(true);
      NotificationService.getList()
        .then((data) => {
          isMounted(() => {
            const resultData = data || [];
            // INFO: API have to return 20 last notifications.
            if (resultData.length > maxNotificationListLength) {
              resultData.length = maxNotificationListLength;
            }
            setNotifications(resultData);
            toggleLoading(false);
            refetchNeeded.current = false;
          });
        })
        .catch(() => {
          isMounted(() => {
            refetchNeeded.current = true;
            toggleLoading(false);
          });
        });
    }
  }, [isOpen]);

  const receiveMessage = useCallback((message: INotification) => {
    isMounted(() => {
      Services.BL.ToasterService.custom(<Notification {...message} />, {
        className: 'NotificationCenter__toast',
      });
      NotificationService.refreshMessageRelatedData(message);
      setNotifications((prev) => {
        if (prev.find((item) => item.uId === message.uId)) return prev;
        const newList = [message, ...prev];
        if (newList.length > maxNotificationListLength) newList.length = maxNotificationListLength;
        return newList;
      });
    });
  }, []);

  // INFO: next code is just for testing reload data feature by notification.
  // WARNING: Do not load it to remote repo
  /* useEffect(() => {
    const clearTimeout = runMocSocketNotification(receiveMessage);
    return clearTimeout;
  }, []); */

  return (
    <NotificationConnectionProvider receiveMessage={receiveMessage}>
      <NotificationWrapper
        notifications={notifications}
        setNotifications={setNotifications}
        virtualized={notifications.length > 50}
        loading={loading}
      />
    </NotificationConnectionProvider>
  );
};

export default NotificationCenter;
