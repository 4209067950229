import { useCallback, useEffect, useMemo, useRef, useReducer, Dispatch, SetStateAction } from 'react';
import { ITableDataInfoRequest } from 'shared/models/models.bl';
import { getPaginationMeta, PaginationState, PaginationMeta } from './paginationMeta';
import { paginationReducer } from './paginationReducer';

type UsePaginationConfig = {
  totalItems?: number;
  initialPage?: number;
  initialPageSize?: number;
};

export type PaginationActions = {
  setPage: (page: number) => void;
  setNextPage: () => void;
  setPreviousPage: () => void;
  setPageSize: (pageSize: number, nextPage?: number) => void;
  setFirstPage: () => void;
  setLastPage: () => void;
};

export type UsePaginationType = PaginationState & PaginationMeta & PaginationActions;

function usePagination(
  { totalItems = 0, initialPage = 0, initialPageSize = 0 }: UsePaginationConfig = {},
  onSetTableInfoChange?: Dispatch<SetStateAction<ITableDataInfoRequest>>
): UsePaginationType {
  const initialState = {
    totalItems,
    pageSize: initialPageSize,
    currentPage: initialPage,
  };

  const [paginationState, dispatch] = useReducer(paginationReducer, initialState);
  const initialMountRef = useRef<boolean>(true);

  const totalItemsRef = useRef(totalItems);
  totalItemsRef.current = totalItems;

  useEffect(() => {
    return () => {
      if (typeof totalItemsRef.current !== 'number' || totalItems === totalItemsRef.current) {
        return;
      }

      dispatch({ type: 'SET_TOTALITEMS', totalItems: totalItemsRef.current });
    };
  }, [totalItems]);

  useEffect(() => {
    if (!initialMountRef.current) {
      if (onSetTableInfoChange) {
        onSetTableInfoChange((prev) => {
          const currentPage = paginationState.currentPage <= 0 ? 0 : paginationState.currentPage;
          if (
            prev.pageNumber - 1 !== currentPage ||
            prev.pageSize !== paginationState.pageSize /*  && paginationState.pageSize <= totalItemsRef.current */
          ) {
            return { ...prev, pageNumber: currentPage + 1, pageSize: paginationState.pageSize };
          }

          return prev;
        });
      }
    } else {
      initialMountRef.current = false;
    }
  }, [paginationState.currentPage, paginationState.pageSize, onSetTableInfoChange]);

  const paginationMeta = useMemo(() => getPaginationMeta(paginationState), [paginationState]);

  const setPage = useCallback((page: number) => {
    dispatch({
      type: 'SET_PAGE',
      page,
    });
  }, []);

  const setNextPage = useCallback(() => {
    dispatch({ type: 'NEXT_PAGE' });
  }, []);
  const setPreviousPage = useCallback(() => {
    dispatch({ type: 'PREVIOUS_PAGE' });
  }, []);
  const setPageSize = useCallback(
    (pageSize: number, nextPage = 0) => {
      dispatch({ type: 'SET_PAGESIZE', pageSize, nextPage });
    },
    [totalItems]
  );

  const setFirstPage = useCallback(() => {
    setPage(0);
  }, [setPage]);

  const setLastPage = useCallback(() => {
    setPage(paginationMeta.totalPages);
  }, [setPage, paginationMeta.totalPages]);

  return {
    ...paginationState,
    ...paginationMeta,
    setPage,
    setNextPage,
    setPreviousPage,
    setPageSize,
    setFirstPage,
    setLastPage,
  };
}

export default usePagination;
