import type * as Interfaces from './interfaces';
import { Types } from './types';

const initialState: Interfaces.StoreType = {
  shareRegister: false,
  grandValuation: false,
  employeeStatus: false,
  customVesting: false,
};

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action: Interfaces.FileUploadingActions = {} as Interfaces.FileUploadingActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_IS_UPLOADING: {
      const { value, view } = action.payload;

      return {
        ...initialState,
        [view]: value,
      };
    }
    default:
      return state;
  }
}
