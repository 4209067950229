import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import SidePanelAccordion from 'components/SidePanelAccordion/SidePanelAccordion';
import { ROUTES } from '../../shared/constants/routes';
import styles from './SidePanel.module.css';
import { manageAccountingInputs, generateAndViewExpenses } from '../../shared/constants/routeLinkOptions';

interface IProps {
  homeFlag: boolean;
  manageAcoountingFlag: boolean;
  flag: boolean;
  generateAndViewExpensesFlag: boolean;
  fileFlag: boolean;
  handleRedirect: any;
  closeNav: any;
}
const SidePanelRoute: FC<IProps> = ({
  homeFlag,
  manageAcoountingFlag,
  flag,
  generateAndViewExpensesFlag,
  fileFlag,
  handleRedirect,
  closeNav,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [routeLocation, setRouteLocation] = useState(location);
  useEffect(() => {
    setRouteLocation(location);
  }, [location]);
  return (
    <>
      {(homeFlag || fileFlag) && (
        <>
          <Link
            to={ROUTES.home}
            onClick={() => {
              handleRedirect(ROUTES.home);
              closeNav();
            }}
            className={routeLocation?.pathname?.toLowerCase() === ROUTES.home ? styles.activeHomeFlag : styles.homeFlag}
          >
            {t('Home.PageTitle')}
          </Link>
          <SidePanelAccordion
            trigger={t('Common.ManageAccountingInputs.Title')}
            className={styles.homeManageAccountingInputs}
          >
            {manageAccountingInputs.routes.map((element: any) => (
              <p>
                <Link
                  className={
                    routeLocation?.pathname?.toLowerCase() === element.to
                      ? styles.activeManageAccountingColor
                      : styles.manageAccountingColor
                  }
                  to={element.to}
                  onClick={() => {
                    handleRedirect(manageAccountingInputs.subHeader);
                    closeNav();
                  }}
                >
                  {t(element.label)}
                </Link>
              </p>
            ))}
          </SidePanelAccordion>
          <SidePanelAccordion
            trigger={t('Common.GenerateAndViewExpenses.Title')}
            className={styles.homeGenerateAndViewExpenses}
          >
            {generateAndViewExpenses.routes.map((element: any) => (
              <p>
                <Link
                  className={
                    routeLocation?.pathname?.toLowerCase() === element.to
                      ? styles.activeGenerateAndViewExpensesColor
                      : styles.generateAndViewExpensesColor
                  }
                  to={element.to}
                  onClick={() => {
                    handleRedirect(generateAndViewExpenses.subHeader);
                    closeNav();
                  }}
                >
                  {t(element.label)}
                </Link>
              </p>
            ))}
          </SidePanelAccordion>
          <Link
            to={ROUTES.files}
            onClick={() => {
              handleRedirect(ROUTES.files);
              closeNav();
            }}
            className={
              routeLocation?.pathname?.toLowerCase() === ROUTES.files ? styles.activeHomeFiles : styles.homeFiles
            }
          >
            {t('Files.PageTitle')}
          </Link>
        </>
      )}
      {manageAcoountingFlag && (
        <>
          <SidePanelAccordion
            trigger={t('Common.ManageAccountingInputs.Title')}
            className={styles.homeManageAccountingInputs}
            open={flag}
          >
            {manageAccountingInputs.routes.map((element: any) => (
              <p>
                <Link
                  to={element.to}
                  className={
                    routeLocation?.pathname?.toLowerCase() === element.to
                      ? styles.activeManageAccountingColor
                      : styles.manageAccountingColor
                  }
                  onClick={() => {
                    handleRedirect(manageAccountingInputs.subHeader);
                    closeNav();
                  }}
                >
                  {t(element.label)}
                </Link>
              </p>
            ))}
          </SidePanelAccordion>
        </>
      )}
      {generateAndViewExpensesFlag && (
        <>
          <SidePanelAccordion
            trigger={t('Common.GenerateAndViewExpenses.Title')}
            open={flag}
            className={styles.homeGenerateAndViewExpenses}
          >
            {generateAndViewExpenses.routes.map((element: any) => (
              <p>
                <Link
                  to={element.to}
                  className={
                    routeLocation?.pathname?.toLowerCase() === element.to
                      ? styles.activeGenerateAndViewExpensesColor
                      : styles.generateAndViewExpensesColor
                  }
                  onClick={() => {
                    handleRedirect(generateAndViewExpenses.subHeader);
                    closeNav();
                  }}
                >
                  {t(element.label)}
                </Link>
              </p>
            ))}
          </SidePanelAccordion>
        </>
      )}
    </>
  );
};
export default SidePanelRoute;
