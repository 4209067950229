import { IFileUploadingApiEntity, IFileUploadingView, IBeforeUploadWarningModalData } from '../models/models.bl';

export const FILE_UPLOADING_VIEW_MAP: Record<IFileUploadingApiEntity, IFileUploadingView> = Object.freeze({
  ShareRegister: 'shareRegister',
  employee: 'employeeStatus',
  valuation: 'grandValuation',
  provisionExpense: 'provisionExpense',
  CustomVestingSchedule: 'customVesting',
});

export const BeforeUploadWarningModalData: Record<IFileUploadingView, IBeforeUploadWarningModalData> = {
  shareRegister: {
    visible: true,
    title: 'ShareRegister.ProceedModal.Title',
    text: 'ShareRegister.ProceedModal.BeforeUploadDescription',
  },
  customVesting: {
    visible: true,
    title: 'CustomVestingSchedules.CustomVestingProceedModal.Title',
    text: 'CustomVestingSchedules.CustomVestingProceedModal.BeforeUploadDescription',
  },
  grandValuation: {
    visible: true,
    title: 'GrantValuation.ProceedModal.Title',
    text: 'GrantValuation.ProceedModal.BeforeUploadDescription',
  },
  employeeStatus: {
    visible: true,
    title: 'EmployeeStatus.ProceedModal.Title',
    text: 'EmployeeStatus.ProceedModal.BeforeUploadDescription',
  },
  provisionExpense: {
    visible: true,
    title: 'SbpExpensesReports.ProvisionalExpensePanel.ProceedModal.Title',
    text: 'SbpExpensesReports.ProvisionalExpensePanel.ProceedModal.BeforeUploadDescription',
  },
};
