import { useEffect, FC } from 'react';
import { pageTitle } from '../constants/pageTitles';
import { appInsights } from '../../appinsights/appInsights';

interface RouteProps {
  element?: React.ReactElement | null;
  path: string;
  title: string;
}

const RoutesWithPageTitle: FC<RouteProps> = (props) => {
  const { path, title, element } = props;
  useEffect(() => {
    const pathName = path;
    document.title = `${pageTitle.appCommonName} ${title}`;
    appInsights.trackPageView({ uri: pathName });
  }, []);
  return element || null;
};

export default RoutesWithPageTitle;
