// @ts-nocheck
import {
  ChangeEvent,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
  useRef,
  cloneElement,
  RefObject,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import {
  Grid,
  GridColumn,
  GridNoRecords,
  GridPageChangeEvent,
  GridProps,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg';
import useIsMounted from 'shared/hooks/useIsMounted';
import Loader from 'components/Loader/Loader';
import CellCheckbox from 'components/MipTable/cells/CellCheckbox/CellCheckbox';
import HeaderCellCheckbox from 'components/MipTable/headerCells/HeaderCellCheckbox/HeaderCellCheckbox';
import Input from 'components/vendor/Input';
import type { ITableDataInfoRequest } from 'shared/models/models.bl';

import Pager from './Pager';

import './MipTable.scss';

export interface ISelected {
  keys: string[];
  deleteAll: boolean;
}

interface ICustomTableRenderProps {
  selected: ISelected;
  totalRows: number;
  resetTable: () => void;
  updateTable: () => void;
}

export interface ISort {
  field: string;
  dir: string;
}

const getArrayOrNull = (data: any): any[] | null => (Array.isArray(data) ? data : null);

interface IProps extends GridProps {
  showSearch?: boolean;
  backendCall?: (data: ITableDataInfoRequest) => Promise<any>;
  initialSort?: ISort[];
  isSelectable?: boolean;
  customTableRender?: (props: ICustomTableRenderProps) => ReactNode;
  selectedKey?: string;
  data?: any[];
  total?: number;
  onTableViewParamsChange?: (data: ITableDataInfoRequest) => void;
  dataLoading?: boolean;
  searchPlaceholder?: string;
  optionalParams?: any;
  updateDataTrigger?: string;
  selectionDisabled?: boolean;
  allDataFetched?: (data: any[]) => void;
  updateRowsByExternalParams?: (data: any) => any;
}

const defaultTableWidth = { width: '100%' };
const defaultPagingState = { pageSizes: [5, 10, 25, 50, 100] };
const defaultSort: Array<SortDescriptor> = [{ field: '', dir: 'asc' }];
const initialPageState = { skip: 0, take: 5 };
const initialSelected = { keys: [], deleteAll: false };
const checkboxColWidth = 40;

const getWidthData = (
  gridRef: RefObject<Grid>
): {
  tableElement: HTMLTableElement | null;
  parentElement: HTMLDivElement | null;
  tableWidth: number;
  parentWidth: number;
  needResizeCols: boolean;
} => {
  const parentElement: HTMLDivElement | null = gridRef.current?.element?.firstChild?.firstChild || null;
  const tableElement: HTMLTableElement | null = parentElement?.firstChild || null;
  const tableWidth = tableElement?.clientWidth || 0;
  const parentWidth = parentElement?.clientWidth || 0;
  return {
    tableElement,
    parentElement,
    tableWidth,
    parentWidth,
    needResizeCols: tableWidth < parentWidth,
  };
};

const MipTable: FC<IProps> = ({
  showSearch = false,
  backendCall,
  children,
  data = [],
  allDataFetched,
  total: totalDataQTY,
  dataLoading = false,
  initialSort = defaultSort,
  isSelectable = false,
  customTableRender = null,
  selectedKey = 'id',
  searchPlaceholder = 'Common.Table.Search.Placeholder',
  optionalParams,
  updateDataTrigger,
  onTableViewParamsChange,
  selectionDisabled = false,
  updateRowsByExternalParams,
  ...rest
}) => {
  const { t } = useTranslation();
  const gridRef = useRef<Grid>(null);
  const gridContainer = useRef<HTMLDivElement>(null);
  const searchTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const firstMountRef = useRef<boolean>(true);
  const [{ sort, page, searchKeyWord }, setTableState] = useState({
    sort: initialSort,
    page: initialPageState,
    searchKeyWord: '',
  });
  const [serverData, setServerData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState<ISelected>(initialSelected);
  const [resizeColumns, setResizeColumns] = useState<number>(0);
  const isMounted = useIsMounted();
  const onSortChangeHandler = (e: GridSortChangeEvent) => {
    setTableState((prev) => ({ ...prev, sort: e.sort }));
  };

  const onPageChangeHandler = (e: GridPageChangeEvent) => {
    setTableState((prev) => ({ ...prev, page: e.page }));
  };

  const onSearchChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(() => {
      setTableState((prev) => ({ ...prev, searchKeyWord: e.value, page: { take: prev.page.take, skip: 0 } }));
    }, 500);
  }, []);

  const columns = useMemo(() => {
    const columnName = children.props.children;
    let cols = [...columnName];

    if (data.length) {
      for (let i = 1; i < Object.keys(data[0]).length; i++) {
        if (!cols.some((element) => element.props.field === Object.keys(data[0])[i])) {
          cols.push(<GridColumn key={Object.keys(data[0])[i]} field={Object.keys(data[0])[i]} width={100} />);
        }
      }
    }

    let colsTotalWidth = isSelectable ? checkboxColWidth : 0;
    const { parentWidth } = getWidthData(gridRef);
    const neededWidth = parentWidth < 1 ? 0 : parentWidth - 1;

    const initialWidths = cols.map((child) => {
      const width = (child.props.width as number) || 100;
      colsTotalWidth += width;
      return width;
    });

    const proportions: number[] | null =
      neededWidth > colsTotalWidth
        ? initialWidths.map((width) => {
            return width / colsTotalWidth;
          })
        : null;
    const colsLength = cols.length;
    const getWidth = (idx: number, minWidth = 100): number => {
      return Math.floor(proportions ? neededWidth * proportions[idx] : Math.max(neededWidth / colsLength, minWidth));
    };

    cols = cols.map((child, idx) => {
      return cloneElement(child, {
        ...child.props,
        width: getWidth(idx, child.props.width),
        key: child.props.key || `clonedCol-${idx}`,
      });
    });

    return cols;
  }, [data, resizeColumns, isSelectable]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    const { parentElement } = getWidthData(gridRef);

    const resizeThrottler = () => {
      if (!timeout)
        timeout = setTimeout(() => {
          timeout = null;
          setResizeColumns((prev) => prev + 1);
        }, 66);
    };

    const observer: ResizeObserver | null = parentElement
      ? new ResizeObserver(resizeThrottler).observe(parentElement)
      : null;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
      if (parentElement) {
        observer?.unobserve(parentElement);
      }
    };
  }, []);

  useEffect(() => {
    // INFO: this is necessary to prevent message horizontal scrolling with whole table
    if (gridRef.current?.element) {
      if (!gridContainer.current) {
        gridContainer.current = gridRef.current.element.querySelector('.k-grid-container');
      }
      if (gridContainer.current) {
        gridContainer.current.setAttribute('data-noitemsmessage', t('Common.Table.Message.NothingToShow'));
      }
    }
  }, [t]);

  const serverRequest = async () => {
    if (backendCall) {
      setIsLoading(true);
      const response = await backendCall({
        orderBy: sort[0]?.field || '',
        asc: sort[0]?.dir === 'asc',
        pageNumber: page.skip / page.take + 1,
        pageSize: page.take,
        searchBy: searchKeyWord || '',
        ...optionalParams,
      });
      if (!searchKeyWord && allDataFetched) {
        allDataFetched(
          getArrayOrNull(response?.data) ||
            getArrayOrNull(response?.data?.tableData) ||
            getArrayOrNull(response?.data?.data) ||
            []
        );
      }
      isMounted(() => {
        setServerData(response);
        setIsLoading(false);
      });
    }
  };

  const resetTable = () => {
    isMounted(() => {
      setSelected(initialSelected);

      if (page === initialPageState && sort === initialSort && searchKeyWord === '') {
        serverRequest();

        return;
      }

      setTableState({ sort: initialSort, page: initialPageState, searchKeyWord: '' });
    });
  };

  const updateTable = async () => {
    const totalCount = serverData?.data?.totalCount;
    const selectedCount = selected?.keys?.length;

    if (totalCount && selectedCount !== undefined && selected.deleteAll !== undefined) {
      setIsLoading(true);
      const itemsCount = selected.deleteAll ? selectedCount : totalCount - selectedCount;
      const maxPage = itemsCount === 0 ? 1 : Math.floor(itemsCount / page.take) + Number(!!(itemsCount % page.take));
      const currentPage = page.skip / page.take + 1;
      const pageNumber = currentPage > maxPage ? maxPage : currentPage;
      const response = await backendCall({
        orderBy: sort[0]?.field || '',
        asc: sort[0]?.dir === 'asc',
        pageNumber,
        pageSize: page.take,
        searchBy: searchKeyWord || '',
        ...optionalParams,
      });

      if (!searchKeyWord && allDataFetched) {
        allDataFetched(
          getArrayOrNull(response?.data) ||
            getArrayOrNull(response?.data?.tableData) ||
            getArrayOrNull(response?.data?.data) ||
            []
        );
      }

      isMounted(() => {
        setSelected(initialSelected);
        setServerData(response);
        setIsLoading(false);
      });
    }
  };

  useEffect(async () => {
    if (!firstMountRef.current) {
      firstMountRef.current = false;
      if (!serverRequest)
        onTableViewParamsChange?.({
          orderBy: sort[0]?.field || '',
          asc: sort[0]?.dir === 'asc',
          pageNumber: page.skip / page.take + 1,
          pageSize: page.take,
          searchBy: searchKeyWord || '',
          optionalParams,
        });
    }
  }, [sort, page, searchKeyWord, optionalParams]);

  useEffect(async () => {
    await serverRequest();
  }, [sort, page, searchKeyWord, updateDataTrigger, optionalParams]);

  useEffect(() => {
    if (updateRowsByExternalParams && serverData) {
      setServerData((prevRows) => {
        return updateRowsByExternalParams(prevRows);
      });
    }
  }, [updateRowsByExternalParams]);

  const localData = backendCall
    ? serverData?.data?.tableData
    : orderBy(data, sort).slice(page.skip, page.take + page.skip);

  const totalRows = backendCall ? serverData?.data?.totalCount || 0 : totalDataQTY ?? data.length;
  const translatedSearchPlaceholder = t(searchPlaceholder);

  return (
    <div className={`mipTableWrapper${!totalRows ? ' mipTableWrapper--noData' : ''}`}>
      {(showSearch || !!customTableRender) && (
        <div className="mipTableActions-wrap">
          <div className="mipTableActions">
            {showSearch && (
              <div className="mipTableActions-search">
                <Tooltip position="top" anchorElement="target">
                  <Input
                    onChange={onSearchChangeHandler}
                    endIcon={<SearchIcon />}
                    placeholder={translatedSearchPlaceholder}
                    title={translatedSearchPlaceholder}
                  />
                </Tooltip>
              </div>
            )}
            {!!customTableRender && (
              <div className={`mipTableActions-actions${!showSearch ? ' justActions' : ''}`}>
                {customTableRender({ selected, totalRows, resetTable, updateTable })}
              </div>
            )}
          </div>
        </div>
      )}
      <Grid
        ref={gridRef}
        className="mipTable"
        style={defaultTableWidth}
        data={localData}
        sortable={
          localData?.length && {
            allowUnsort: false,
          }
        }
        sort={localData?.length && sort}
        onSortChange={onSortChangeHandler}
        pageable={localData?.length ? defaultPagingState : false}
        skip={page.skip}
        take={page.take}
        total={totalRows}
        onPageChange={onPageChangeHandler}
        pager={Pager}
        {...rest}
      >
        <GridNoRecords>
          {/* INFO: This span is display none. Reason - scrolls with table */}
          {/* INFO: Needed just as placeholder for message from data attribute */}
          <span className="placeholderForNoItemsText" />
        </GridNoRecords>
        {isSelectable && (
          <GridColumn
            sortable={false}
            width={checkboxColWidth}
            cell={(props) => (
              <CellCheckbox
                {...props}
                selected={selected}
                setSelected={setSelected}
                selectedKey={selectedKey}
                disabled={selectionDisabled}
              />
            )}
            headerCell={(props) => (
              <HeaderCellCheckbox
                {...props}
                selected={selected}
                setSelected={setSelected}
                disabled={!totalRows || selectionDisabled}
              />
            )}
            headerClassName="notSortable"
          />
        )}
        {columns}
      </Grid>
      {(backendCall ? isLoading : dataLoading) && <Loader absolute />}
    </div>
  );
};

export default MipTable;
