import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoadedLocaleKeys } from 'store/localization/selectors';
import { ReactComponent as DeloitteLogo } from 'assets/svg/DeloitteLogo.svg';
import CustomHooks from 'shared/shared.hooks';
import { ROUTES } from 'shared/constants/routes';
import UserMenuIcon from '../UserMenuIcon/UserMenuIcon';
import AuthActions from './AuthActions/AuthActions';
import HelpCenterMenuIcon from '../HelpCenterMenuIcon/HelpCenterMenuIcon';
import NotificationCenter from '../../NotificationCenter';

import './Header.scss';

const Header: FC = () => {
  const location = useLocation();
  const isLoadedLocaleKeys = useSelector(selectIsLoadedLocaleKeys);
  const showPrivateElements = CustomHooks.usePrivateLayout();
  const showAuthActions = location.pathname === ROUTES.landing || (ROUTES.unauthorized && !showPrivateElements);

  return (
    <header className="appHeader">
      <DeloitteLogo className="DeloitteLogo" />
      <div className="appHeader__divider" />
      <div className="appHeader__headerTitle">My Incentive Plan</div>
      <div className="appHeader__actions">
        {showPrivateElements && (
          <>
            <HelpCenterMenuIcon />
            <NotificationCenter />
            <UserMenuIcon />
          </>
        )}
        {showAuthActions && isLoadedLocaleKeys && <AuthActions />}
      </div>
    </header>
  );
};

export default Header;
