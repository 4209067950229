import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Selectors } from 'store/selectors';
import { setUserSettings } from 'store/user/actions';
import SingleSelectControl, { DropDownListChangeEvent } from 'components/SingleSelectControl/SingleSelectControl';
import { DEFAULT_LOCALE, USER_LOCALE } from 'localization/helpers';
import { StoreActions } from 'store/actions';
import CustomHooks from 'shared/shared.hooks';
import { setToLocalStorage } from 'shared/utils/localStorage';
import Services from 'shared/shared.services';
import LocalizationService from 'shared/services/bl/localization.service';
import { getDataForForm } from '../../Modals/UserSettingsModal';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

interface Props {
  isOpen: boolean;
}

export const UserLanguageSelect: FC<Props> = ({ isOpen }) => {
  const { t } = useTranslation();
  const dispatch = CustomHooks.storeHooks.useAppDispatch();
  const locales = useSelector(Selectors.Localization.selectLocales);
  const isLoadedLocales = useSelector(Selectors.Localization.selectIsLoadedLocales);
  const isErrorLocales = useSelector(Selectors.Localization.selectIsErrorLocales);
  const currentUser = useSelector(Selectors.Main.selectCurrentUser);

  useEffect(() => {
    if (isOpen && !isLoadedLocales) {
      dispatch(StoreActions.Localization.getLocales());
    }
  }, [isLoadedLocales, isOpen]);

  const handleChange = useCallback(
    ({ value }: DropDownListChangeEvent) => {
      dispatch(StoreActions.Localization.getLocaleKeys(value.id));
      const userSettings = getDataForForm({ t, data: currentUser.user?.settings });
      Services.DL.UserDataService.saveUserSettings({ ...userSettings, locale: value.id });
      dispatch(setUserSettings({ locale: value.id }));
      setToLocalStorage(USER_LOCALE, value);
    },
    [currentUser]
  );

  const defaultOption = useMemo(() => {
    const userLocale = LocalizationService.getLocaleCode(isErrorLocales);
    return locales?.find((locale) => locale.id === userLocale) || DEFAULT_LOCALE;
  }, [locales, isErrorLocales, currentUser]);

  return (
    <>
      <SingleSelectControl
        label={t('UserMenu.LanguageDropdown.Label')}
        data={locales || []}
        defaultOption={isLoadedLocales ? defaultOption : null}
        onChange={handleChange}
        disabled={isErrorLocales || !isLoadedLocales}
      />
      {isErrorLocales && <ErrorMessage />}
    </>
  );
};
