import { FC } from 'react';
import { ReactComponent as LanguageLogo } from 'assets/svg/LanguageLogo.svg';

import { LoginLanguageSelect } from 'components/LanguageSelect/LoginLanguageSelect/LoginLanguageSelect';

const AuthActions: FC = () => {
  return (
    <>
      <LanguageLogo className="LanguageLogo" />
      <LoginLanguageSelect />
    </>
  );
};

export default AuthActions;
