export const USER_MAX_INACTIVITY_TIME_MS =
  (Number((window as any)?.ApiConfig?.USER_INACTIVITY_MAX_TIME_IN_MINUTES) || 25) * 60 * 1000;

export const USER_MAX_INACTIVITY_ANSWER_WAITING_TIME_MS =
  (Number((window as any)?.ApiConfig?.USER_INACTIVITY_MAX_ANSWER_WAITING_TIME_IN_MINUTES) || 5) * 60 * 1000;

export const USER_MAX_INACTIVITY_TIME_EXPIRES_STORAGE_KEY = '_userInactivityTimeExpires';
export const USER_MAX_INACTIVITY_ANSWER_WAITING_TIME_EXPIRES_STORAGE_KEY = '_userInactivityAnswerWaitingTimeExpires';

export const USER_ACTIVITY_EVENTS = [
  'click',
  'scroll',
  'touchend',
  'keyup',
  'resize',
  'mousemove',
  'load',
  'contextmenu',
];
