import axios from 'axios';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import Services from 'shared/shared.services';
import { ROUTES } from 'shared/constants/routes';
import { DataExportingView, ExpenseReportStatus } from 'shared/models/enums.dl';
import { store } from 'store';
import { StoreActions } from 'store/actions';
import type { AppState } from 'store/interfaces';
import { ConnectionEvent, NotificationArea } from './enum';
import type { INotification } from './interface';

const currentENV = Services.BL.ConfigService.CurrentEnv;
const showLogs = currentENV === 'DEV' || currentENV === 'QA' || currentENV === 'LOAD';
export default class NotificationService {
  static createHubConnection = (): HubConnection => {
    const hubConnectionBuilder = new HubConnectionBuilder().withUrl(Services.BL.ApiUrlsService.notificationsHubUrl(), {
      accessTokenFactory: () => Services.BL.AuthenticationService.getToken(),
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
    });
    if (showLogs) {
      hubConnectionBuilder.configureLogging(LogLevel.Trace);
    }
    const hubConnection = hubConnectionBuilder.build();
    hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 2; // 2min
    return hubConnection;
  };

  static setListeners = (
    hubConnection: HubConnection,
    listeners: Record<ConnectionEvent, (...args: any[]) => void>
  ): void => {
    Object.keys(listeners).forEach((key) => {
      if (key === ConnectionEvent.OnConnectionClose) {
        hubConnection.onclose(listeners[key]);
      } else {
        hubConnection.on(key, listeners[key as ConnectionEvent]);
      }
    });
  };

  static subscribe = (hubConnection: HubConnection): void => {
    hubConnection.invoke('Subscribe').catch(() => {});
  };

  static getList = (): Promise<INotification[]> => {
    return axios.get(Services.BL.ApiUrlsService.getNotificationList()).then(({ data }) => data);
  };

  static deleteOne = (id: INotification['uId']): Promise<INotification['uId']> => {
    return axios.delete(Services.BL.ApiUrlsService.deleteNotification(id)).then(() => id);
  };

  static refreshMessageRelatedData = (message: INotification): void => {
    const isCurrentRoute = (route: ROUTES) => window.location.pathname === route;
    switch (message.area) {
      case NotificationArea.ShareRegisterUpload: {
        // TODO: check if there is such message area or we have only area = "File" for upload
        if (isCurrentRoute(ROUTES.shareRegister)) {
          store.dispatch(StoreActions.NotificationCenter.reloadRelatedData('shareRegister'));
          store.dispatch(StoreActions.FileUploading.getUploadingState('ShareRegister'));
        }
        break;
      }
      case NotificationArea.CustomVestingUpload: {
        // TODO: check if there is such message area or we have only area = "File" for upload
        if (isCurrentRoute(ROUTES.customVestingSchedules)) {
          store.dispatch(StoreActions.NotificationCenter.reloadRelatedData('customVestingSchedules'));
          store.dispatch(StoreActions.FileUploading.getUploadingState('CustomVestingSchedule'));
        }
        break;
      }
      case NotificationArea.ValuationReferenceUpload: {
        // TODO: check if there is such message area or we have only area = "File" for upload
        if (isCurrentRoute(ROUTES.grantValuation)) {
          store.dispatch(StoreActions.NotificationCenter.reloadRelatedData('valuationReference'));
          store.dispatch(StoreActions.FileUploading.getUploadingState('valuation'));
        }
        break;
      }
      case NotificationArea.EmployeeStatusUpload: {
        // TODO: check if there is such message area or we have only area = "File" for upload
        if (isCurrentRoute(ROUTES.employeeStatus)) {
          store.dispatch(StoreActions.NotificationCenter.reloadRelatedData('employeeStatus'));
          store.dispatch(StoreActions.FileUploading.getUploadingState('employee'));
        }
        break;
      }
      case NotificationArea.JournalEntryCalculation: {
        if (isCurrentRoute(ROUTES.sbpExpensesJournal)) {
          if (showLogs) console.log('Please reload Journal Entry data');
        }
        break;
      }
      case NotificationArea.ProvisionCalculation:
      case NotificationArea.ProvisionUpload: {
        if (isCurrentRoute(ROUTES.sbpExpenseReports)) {
          store.dispatch(StoreActions.Reports.ProvisionalExpense.resetTableData());
          store.dispatch(StoreActions.Reports.ProvisionalExpense.setGeneratedReport(''));
          store.dispatch(StoreActions.NotificationCenter.reloadRelatedData('provisionalExpense'));
        }
        break;
      }
      case NotificationArea.SaveReports:
      case NotificationArea.SbpExpenseCalculation: {
        if (isCurrentRoute(ROUTES.sbpExpenses)) {
          const { dispatch, getState } = store;
          const { getExpenseStatus } = StoreActions.Expenses;

          if (getState().expenses.status !== ExpenseReportStatus.Canceled) {
            dispatch(getExpenseStatus());
          }
        }
        break;
      }
      case NotificationArea.Files: {
        if (isCurrentRoute(ROUTES.files)) {
          store.dispatch(StoreActions.NotificationCenter.reloadRelatedData('files'));
          break;
        }
        // --> Export notifications
        if (isCurrentRoute(ROUTES.logs)) {
          store.dispatch(StoreActions.DataExportingStatus.getExportingStatusData(DataExportingView.activityLogs));
          break;
        }
        if (isCurrentRoute(ROUTES.sbpExpensesJournal)) {
          store.dispatch(StoreActions.DataExportingStatus.getExportingStatusData(DataExportingView.journalEntries));
          break;
        }
        if (isCurrentRoute(ROUTES.employeeStatus)) {
          store.dispatch(StoreActions.DataExportingStatus.getExportingStatusData(DataExportingView.employeeStatus));
          store.dispatch(StoreActions.NotificationCenter.reloadRelatedData('employeeStatus'));
          store.dispatch(StoreActions.FileUploading.getUploadingState('employee'));
          break;
        }
        if (isCurrentRoute(ROUTES.grantValuation)) {
          store.dispatch(StoreActions.DataExportingStatus.getExportingStatusData(DataExportingView.grandValuation));
          store.dispatch(StoreActions.NotificationCenter.reloadRelatedData('valuationReference'));
          store.dispatch(StoreActions.FileUploading.getUploadingState('valuation'));
          break;
        }
        if (isCurrentRoute(ROUTES.shareRegister)) {
          store.dispatch(StoreActions.DataExportingStatus.getExportingStatusData(DataExportingView.shareRegister));
          store.dispatch(StoreActions.NotificationCenter.reloadRelatedData('shareRegister'));
          store.dispatch(StoreActions.FileUploading.getUploadingState('ShareRegister'));
          break;
        }
        if (isCurrentRoute(ROUTES.customVestingSchedules)) {
          // INFO: This will also reload the export and download statuses.
          store.dispatch(StoreActions.NotificationCenter.reloadRelatedData('customVestingSchedules'));
          break;
        }
        if (isCurrentRoute(ROUTES.sbpExpenseReports)) {
          const { reports }: AppState = store.getState();
          store.dispatch(
            StoreActions.DataExportingStatus.getExportingStatusData(DataExportingView.sbpExpensesReportsDetailed)
          );
          store.dispatch(
            StoreActions.DataExportingStatus.getExportingStatusData(
              DataExportingView.sbpExpensesReportsMyIncentivePlanSummary
            )
          );
          if (reports.provisionalExpense.generatedReport) {
            store.dispatch(
              StoreActions.DataExportingStatus.getExportingStatusData(
                DataExportingView.sbpExpensesReportsProvisionExpense
              )
            );
          }
          if (reports.trueUp.tableData) {
            store.dispatch(
              StoreActions.DataExportingStatus.getExportingStatusData(DataExportingView.sbpExpensesReportsTrueUpExpense)
            );
          }
          if (reports.summaryTotal.generatedReport) {
            store.dispatch(
              StoreActions.DataExportingStatus.getExportingStatusData(DataExportingView.sbpExpensesReportsSummaryTotal)
            );
          }
          if (reports.employeeView.generatedReport) {
            store.dispatch(
              StoreActions.DataExportingStatus.getExportingStatusData(DataExportingView.sbpExpensesReportsEmployeeView)
            );
          }
          if (reports.departmentView.generatedReport) {
            store.dispatch(
              StoreActions.DataExportingStatus.getExportingStatusData(
                DataExportingView.sbpExpensesReportsDepartmentView
              )
            );
          }
          if (reports.geographicalView.generatedReport) {
            store.dispatch(
              StoreActions.DataExportingStatus.getExportingStatusData(
                DataExportingView.sbpExpensesReportsGeographicalView
              )
            );
          }
          if (reports.planView.generatedReport) {
            store.dispatch(
              StoreActions.DataExportingStatus.getExportingStatusData(DataExportingView.sbpExpensesReportsPlanView)
            );
          }
          if (reports.expenseOutlookView.generatedReport) {
            store.dispatch(
              StoreActions.DataExportingStatus.getExportingStatusData(
                DataExportingView.sbpExpensesReportsExpenseOutlookView
              )
            );
          }
        }
        break;
      }
      default:
        break;
    }
  };
}
