import * as Models from '../shared.models';
import { getSelectOptions } from './getSelectOptions';

export const getCorrectTableDataFromResponse = <T>(
  responseData: Models.BM.ResponseTableDataType<T>
): {
  getRows: (prev: T[]) => T[];
  getHeaders: (prev: Models.BM.ColumnConfiguration<T>[] | null) => Models.BM.ColumnConfiguration<T>[] | null;
} => {
  return {
    getRows: (prev: T[]): T[] => {
      if (!responseData) return !prev?.length ? prev : [];
      let resultData = prev;
      if (Array.isArray(responseData)) resultData = responseData;
      else if (Array.isArray(responseData?.data)) resultData = responseData.data;
      return !resultData.length && !prev.length ? prev : resultData;
    },
    getHeaders: (prev: Models.BM.ColumnConfiguration<T>[] | null): Models.BM.ColumnConfiguration<T>[] | null => {
      const newHeaders = (responseData as Models.BM.WithHeaderTableResponse<T>)?.header;
      if (!Array.isArray(newHeaders)) return null;
      return !prev?.length && !newHeaders.length ? prev : newHeaders;
    },
  };
};

export const getCorrectTableFiltersFromResponse =
  (responseData?: string[] | null) =>
  (prev: Models.DM.SelectOption[]): Models.DM.SelectOption[] =>
    Array.isArray(responseData)
      ? [
          ...(prev?.[0]?.id === '' ? [prev[0]] : []),
          ...getSelectOptions(
            prev?.[0]?.id === '' ? responseData.filter((item) => item !== prev?.[0]?.text) : responseData
          ),
        ]
      : prev;

export const getCorrectChartDataFromResponse =
  <T>(responseData?: T[] | null) =>
  (prev: T[]): T[] => {
    if (!Array.isArray(responseData)) return !prev.length ? prev : [];
    return !responseData.length && !prev.length ? prev : responseData;
  };
