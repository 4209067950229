import type { AppState } from '../interfaces';
import type { StoreType } from './interfaces';

export const selectStatus = ({ expenses }: AppState): StoreType['status'] => expenses.status;

export const selectStatusLoading = ({ expenses }: AppState): StoreType['statusLoading'] => expenses.statusLoading;

export const selectValidationData = ({ expenses }: AppState): StoreType['validationData'] => expenses.validationData;

export const selectIsCancel = ({ expenses }: AppState): StoreType['isCancel'] => expenses.isCancel;

export const selectProgressData = ({ expenses }: AppState): StoreType['reportProgressData'] =>
  expenses.reportProgressData;
