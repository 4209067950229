import { combineReducers } from 'redux';
import { ReportsStoreName as StoreName } from './storeName';
import summaryTotalReducer from './summaryTotal/reducer';
import departmentViewReducer from './departmentView/reducer';
import employeeViewReducer from './employeeView/reducer';
import geographicalViewReducer from './geographicalView/reducer';
import planViewReducer from './planView/reducer';
import provisionalExpense from './provisionalExpense/reducer';
import trueUp from './trueUp/reducer';
import expenseOutlookViewReducer from './expenseOutlookView/reducer';
import { Types } from './types';
import type * as Interfaces from './interfaces';

const reportsWithoutResetReducer = combineReducers({
  [StoreName.SummaryTotal]: summaryTotalReducer,
  [StoreName.DepartmentView]: departmentViewReducer,
  [StoreName.EmployeeView]: employeeViewReducer,
  [StoreName.GeographicalView]: geographicalViewReducer,
  [StoreName.PlanView]: planViewReducer,
  [StoreName.ProvisionalExpense]: provisionalExpense,
  [StoreName.TrueUp]: trueUp,
  [StoreName.ExpenseOutlookView]: expenseOutlookViewReducer,
});

const reportsReducer = (
  state: Interfaces.ReportsState,
  action: Interfaces.ReportsActionsWithReset
): Interfaces.ReportsState => {
  return reportsWithoutResetReducer(
    action.type === Types.RESET_REPORTS_STATE ? undefined : state,
    action as Interfaces.ReportsActions
  );
};

export default reportsReducer;
