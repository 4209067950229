import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColumn } from '@progress/kendo-react-grid';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useAppDispatch, useAppSelector } from 'shared/hooks/storeHooks';
import * as Models from 'shared/shared.models';
import Services from 'shared/shared.services';
import CellEnableLink from 'components/MipTable/cells/CellEnableLink/CellEnableLink';
import CellTooltip from 'components/MipTable/cells/CellTooltip/CellTooltip';
import { tableSearchPlaceholder } from 'shared/constants/tableSearchPlaceholder';
import { useTableActiveState, IUsePageWithTableInitialOptions } from 'shared/hooks/useTableActiveState';
import { StoreActions } from 'store/actions';
import { Selectors } from 'store/selectors';

import CellDateFormat from 'components/MipTable/cells/CellDateFormat/CellDateFormat';
import TableActions from 'components/Table/TableActions/TableActions';
import MipTable from 'components/MipTable/MipTable';
import './PerformanceHurdles.scss';
import {
  IPerformanceHurdle,
  IUpdatePerformanceHurdleEstimate,
  IUpdateResultResponse,
  PerformanceHurdlesTableData,
} from 'shared/models/models.bl';
import PerformanceHurdelModal from 'components/Modals/PerformanceHurdleModal';
import { parseErrorsMessages } from 'components/Modals/helpers';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { changeModalPropertyType, phBasicData } from 'components/Modals/PerformanceHurdleModal/helper';
import UpdateEstimate from 'components/Modals/UpdateEstimate';

const defaultSortParam = [{ field: 'createdDate', dir: 'desc' }];

const PerformanceHurdles: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [deletionProps, setDeletionProps] = useState<Parameters<typeof deleteData> | null>(null);

  const [savingPH, setFlagForPHSaving] = useState<boolean>(false);
  const [validationMessageForPerformanceHurdle, setPHValidationMessage] = useState<string>('');
  const [validationMessageForUpdateEstimate, setValidationMessageForUpdateEstimate] = useState<string>('');
  const [softRefresh, setSoftRefresh] = useState<boolean>(false);
  const hardRefresh = useAppSelector(Selectors.Main.reloadData.performanceHurdles);
  const [updateEstimate, setUpdateEstimate]: any = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasEstimatesPh, setHasEstimatesPh] = useState<PerformanceHurdlesTableData>({} as PerformanceHurdlesTableData);
  const [readOnlyEditMode, setreadOnlyEditMode] = useState(false);

  const initialOptions: IUsePageWithTableInitialOptions<
    Models.BM.PerformanceHurdlesTableData,
    Models.BM.IPerformanceHurdle
  > = useMemo(
    () => ({
      deleteData: Services.BL.PerformanceHurdles.deletePerformanceHurdlesTableData,
      updateRowData: Services.BL.PerformanceHurdles.addAndUpdatePerformanceHurdleData,
    }),
    []
  );
  const {
    deleteData,
    dataDeleting,
    openRowInfoModal,
    openUpdateEstimateModal,
    rowUpdateEstimateModalData,
    rowInfoModalData,
    closeRowInfoModal,
    closeUpdateEstimateModal,
    setData,
    confirmRowInfoModal,
  } = useTableActiveState<Models.BM.PerformanceHurdlesTableData, Models.BM.IPerformanceHurdle>(initialOptions);

  // state for performance hurdle data to pass in  dialog for add or edit operation
  const [phData, setPHData] = useState(rowInfoModalData.data || phBasicData.getPhBasicData());

  const refreshTableData = (refreshType: 'hard' | 'soft') => {
    if (refreshType === 'hard') dispatch(StoreActions.NotificationCenter.reloadRelatedData('performanceHurdles'));
    else setSoftRefresh((prev) => !prev);
  };

  const confirmClearEstimates = (): void => {
    setHasEstimatesPh({} as PerformanceHurdlesTableData);
    Services.BL.PerformanceHurdles.clearPerformanceHurdleEstimateById(hasEstimatesPh?.performanceHurdleId).then(
      (resolve: IUpdateResultResponse) => {
        if (resolve.rowsDeleted > 0) {
          refreshTableData('soft');
          setreadOnlyEditMode(false);
          openRowInfoModal(hasEstimatesPh);
        }
      }
    );
  };

  const closeClearEstimateModal = () => {
    openRowInfoModal(hasEstimatesPh);
    setreadOnlyEditMode(true);
    setHasEstimatesPh({} as PerformanceHurdlesTableData);
  };

  const handleAddNewRow = useCallback(async () => {
    openRowInfoModal();
  }, [openRowInfoModal]);

  const handleDeleteChanges = (...props: Parameters<typeof deleteData>) => {
    setDeletionProps(props);
  };

  const handleConfirmDelete = (): void => {
    if (!deletionProps) return;
    deleteData(...deletionProps)();
    setDeletionProps(null);
  };

  const clearDeletionProps = () => {
    setDeletionProps(null);
  };

  const handleClosePerformanceHurdleModal = () => {
    setreadOnlyEditMode(false);
    closeRowInfoModal();
    setPHValidationMessage('');
    setPHData(phBasicData.getPhBasicData());
  };
  const handleUpdateEstimate = (modalData: IUpdatePerformanceHurdleEstimate) => {
    setIsLoading(true);
    return Services.BL.PerformanceHurdles.addOrUpdateEstimate(modalData)
      .then(() => {
        refreshTableData(modalData ? 'soft' : 'hard');
        closeUpdateEstimateModal();
        setValidationMessageForUpdateEstimate('');
        setIsLoading(false);
      })
      .catch((err) => {
        if (!err?.response?.data) return;
        let message = '';
        const { data: errorData } = err.response;

        if (errorData && typeof errorData === 'string') {
          message = t(errorData);
        } else if (errorData.errors) {
          message = `${parseErrorsMessages(errorData.errors, t)}`;
        }

        setValidationMessageForUpdateEstimate(message);
        setIsLoading(false);
      });
  };

  const handlePerformanceHurdleModal = (modalData: IPerformanceHurdle) => {
    setFlagForPHSaving(true);
    setPHValidationMessage('');
    const modifiedModal = changeModalPropertyType(modalData);
    return confirmRowInfoModal(modifiedModal)
      .then(() => {
        refreshTableData(modalData.uid ? 'soft' : 'hard');
        setPHData(phBasicData.getPhBasicData());
      })
      .catch((err) => {
        if (!err?.response?.data) return;
        let message = '';
        const { data: errorData } = err.response;

        if (errorData && typeof errorData === 'string') {
          message = t(errorData);
        } else if (errorData.errors) {
          message = `${t('ModalError.InputValidationError')}: ${parseErrorsMessages(errorData.errors, t)}`;
        }
        setPHData(modalData);
        setPHValidationMessage(message);
      })
      .finally(() => {
        setFlagForPHSaving(false);
      });
  };
  const disabledTable = dataDeleting;

  const selectionDisabled = disabledTable;
  return (
    <div>
      <MipTable
        key={`PerformancehurdlesTable-hardRefresh-${hardRefresh}`}
        updateDataTrigger={`PerformancehurdlesTable-softRefresh-${softRefresh}`}
        backendCall={Services.BL.PerformanceHurdles.getPerformanceHurdlesData}
        initialSort={defaultSortParam}
        isSelectable
        selectedKey="performanceHurdleId"
        showSearch
        searchPlaceholder={t(tableSearchPlaceholder.performanceHurdles)}
        selectionDisabled={selectionDisabled}
        customTableRender={({ selected, updateTable }) => (
          <TableActions
            onDelete={() => handleDeleteChanges(selected, updateTable)}
            deleting={dataDeleting}
            onAddNew={handleAddNewRow}
            addNewItemName={t('PerformanceHurdles.AddNewPerformanceHurdleButton')}
            deleteDisabled={(!selected.keys.length && !selected.deleteAll) || selectionDisabled}
            addNewDisabled={disabledTable}
          />
        )}
        allDataFetched={setData}
      >
        <Tooltip openDelay={100} position="right" anchorElement="target">
          <GridColumn
            field="performanceHurdleId"
            title={t('PerformanceHurdles.Table.PerformanceHurdleId')}
            width={150}
            cell={(props) =>
              CellEnableLink({
                ...props,
                onClick: (row) => {
                  setHasEstimatesPh(row.updatedDate ? row : {});
                  Services.BL.PerformanceHurdles.getPerformanceHurdleById(row.performanceHurdleId).then((res) => {
                    setPHData(res);
                    setPHValidationMessage('');
                    if (!row.updatedDate) openRowInfoModal(row);
                  });
                },
              })
            }
          />
          <GridColumn
            field="createdDate"
            title={t('PerformanceHurdles.Table.DateAdded')}
            cell={CellDateFormat}
            width={100}
          />
          <GridColumn
            field="subHurdleName"
            title={t('PerformanceHurdles.Table.SubHurdleName')}
            cell={CellTooltip}
            width={150}
          />
          <GridColumn
            field="updatedDate"
            title={t('PerformanceHurdles.Table.LastUpdated')}
            width={100}
            cell={CellDateFormat}
          />
          <GridColumn
            field=""
            title={t('PerformanceHurdles.Table.UpdateEstimate')}
            sortable={false}
            width={100}
            cell={(props) =>
              CellEnableLink({
                ...props,
                onClick: (row) => {
                  Services.BL.PerformanceHurdles.getUpdatedEstimateById(row.performanceHurdleId).then((res) => {
                    setUpdateEstimate(res.value);
                    openUpdateEstimateModal(row);
                  });
                },
              })
            }
            headerClassName="notSortable"
          />
        </Tooltip>
      </MipTable>
      {rowInfoModalData.visible && (
        <PerformanceHurdelModal
          data={phData}
          visible={rowInfoModalData.visible}
          onCancel={() => {
            setPHData(phBasicData.getPhBasicData());
            handleClosePerformanceHurdleModal();
          }}
          onConfirm={handlePerformanceHurdleModal}
          isLoading={savingPH}
          validationMessage={validationMessageForPerformanceHurdle}
          readOnlyEditMode={readOnlyEditMode}
        />
      )}
      {rowUpdateEstimateModalData.visible && (
        <UpdateEstimate
          visible={rowUpdateEstimateModalData.visible}
          data={updateEstimate}
          title={t('PerformaceHurdle.UpdateEstimate.Title')}
          onCancel={closeUpdateEstimateModal}
          onConfirm={handleUpdateEstimate}
          isLoading={isLoading}
          validationMessage={validationMessageForUpdateEstimate}
        />
      )}
      {!!deletionProps && (
        <ConfirmModal
          onCancel={clearDeletionProps}
          onConfirm={handleConfirmDelete}
          title={t('PerformanceHurdle.ConfirmDeleteModal.Title')}
          cancelBtnTitle={t('PerformanceHurdle.ConfirmDeleteModal.CancelButton')}
          confirmBtnTitle={t('PerformanceHurdle.ConfirmDeleteModal.ProceedButton')}
          visible
        >
          <p>{t('PerformanceHurdle.ConfirmDeleteModal.Description')}</p>
        </ConfirmModal>
      )}
      {Object.keys(hasEstimatesPh).length > 0 && (
        <ConfirmModal
          onCancel={closeClearEstimateModal}
          onConfirm={confirmClearEstimates}
          title={t('PerformanceHurdle.ConfirmDeleteEstimatesModal.Title')}
          cancelBtnTitle={t('PerformanceHurdle.ConfirmDeleteModal.CancelButton')}
          confirmBtnTitle={t('PerformanceHurdle.ConfirmDeleteModal.ProceedButton')}
          visible
        >
          <p>{t('PerformanceHurdle.ConfirmDeleteEstimatesModal.Description')}</p>
        </ConfirmModal>
      )}
    </div>
  );
};

export default PerformanceHurdles;
