import { FC, useEffect, useState, createContext, memo, useContext } from 'react';
import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import CustomHooks from 'shared/shared.hooks';
import type { INotification } from './interface';
import { ConnectionEvent } from './enum';
import NotificationService from './notification.service';

interface IProps {
  receiveMessage: (message: INotification) => void;
}

const useConnection = (receiveMessage: IProps['receiveMessage']): null | HubConnection => {
  const isMounted = CustomHooks.useIsMounted();
  const [connection, setConnection] = useState<null | HubConnection>(null);

  useEffect(() => {
    if (!connection) {
      const connect = NotificationService.createHubConnection();

      NotificationService.setListeners(connect, {
        [ConnectionEvent.GetNotificationMessage]: receiveMessage,
        [ConnectionEvent.OnConnectionClose]: () => {
          isMounted(() => {
            setConnection(null);
          });
        },
      });

      connect
        .start()
        .then(() => {
          if (connect.state === HubConnectionState.Connected) {
            isMounted(() => {
              NotificationService.subscribe(connect);
              setConnection(connect);
            });
          }
        })
        .catch(() => {});
    }

    return () => {
      if (connection) {
        connection.stop().catch(() => {});
      }
    };
  }, [connection]);

  return connection;
};

const NotificationContext = createContext<null | HubConnection>(null);

export const NotificationConnectionProvider: FC<IProps> = memo(({ children, receiveMessage }) => {
  const connection = useConnection(receiveMessage);
  return <NotificationContext.Provider value={connection}>{children}</NotificationContext.Provider>;
});

export const useNotificationConnection = (): null | HubConnection => useContext(NotificationContext);
