import { FC, useEffect, useState, useCallback, useRef, MouseEvent, memo, useMemo, cloneElement } from 'react';
import { DropDownList, DropDownListFilterChangeEvent, DropDownListProps } from '@progress/kendo-react-dropdowns';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import LabelText from 'components/Label';
import { CommonClassName } from '../commonClassName';
import { itemRenderer, valueRenderer, getFilteredOptions } from './helpers';
import { Chevron, OptionKey } from './enums';
import type { SingleSelectChangeEvent, SingleSelectProps } from './interfaces';

import './SingleSelect.scss';

export type DropDownListChangeEvent<T extends string | number = string | number> = SingleSelectChangeEvent<T>;

const getPopupSettings = (inModal?: boolean): DropDownListProps['popupSettings'] => ({
  className: `SingleSelectPopup${inModal ? ` ${CommonClassName.PopupInModal}` : ''}`,
});

const SingleSelect: FC<SingleSelectProps> = ({
  options: allOptions = [],
  value: outerValue,
  onChange,
  required = false,
  label,
  placeholder,
  filterable = false,
  clearable = false,
  inModal = false,
  ...props
}) => {
  const { t } = useTranslation();
  const selectChangeEventRef = useRef<SingleSelectChangeEvent>();
  const [iconClass, setIconClass] = useState<Chevron>(Chevron.Down);

  const [value, setValue] = useState<SingleSelectProps['value']>(outerValue);
  useEffect(() => {
    setValue((prev) => (prev?.id !== outerValue?.id || prev?.text !== outerValue?.text ? outerValue : prev));
  }, [outerValue]);

  const [filter, setFilter] = useState<string>('');
  const [options, setOptions] = useState<SingleSelectProps['options']>(allOptions);
  useEffect(() => {
    setOptions(allOptions);
    setFilter('');
  }, [allOptions]);

  const defaultItem = placeholder ? { text: placeholder, id: '' } : undefined;
  const showCloseIcon = clearable && !!value?.id && !props.disabled && !required;
  const popupSettings = useMemo(() => getPopupSettings(inModal), [inModal]);

  const handleChange = useCallback(
    (e: SingleSelectChangeEvent) => {
      selectChangeEventRef.current = e;
      setValue(e.value);
      onChange?.(e);
    },
    [onChange]
  );

  const handleClose = useCallback(() => {
    setFilter('');
    setOptions(allOptions);
    setIconClass(Chevron.Down);
  }, [allOptions]);

  const handleOpen = useCallback(() => {
    setIconClass(Chevron.Up);
  }, []);

  const handleFilterChange = useCallback(
    ({ filter: filterObj }: DropDownListFilterChangeEvent) => {
      setFilter(filterObj.value || '');
      setOptions(getFilteredOptions(allOptions, filterObj));
    },
    [allOptions]
  );

  const handleClear = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!showCloseIcon) return;
    const defaultOption = allOptions.find((item) => !!item && item.id === '');
    const newValue = defaultOption || null;
    setValue(newValue);
    onChange?.(
      selectChangeEventRef.current
        ? { ...selectChangeEventRef.current, value: newValue }
        : ({ value: newValue, target: { name: props.name || '' } } as SingleSelectChangeEvent)
    );
  };

  const listNoDataRender: DropDownListProps['listNoDataRender'] = useCallback(
    (element) => {
      return cloneElement(element, element.props, t('Common.FormDropdown.NoDataFound'));
    },
    [t]
  );

  return (
    <div
      className={`select-control${!value ? ' select-control--placeholder' : ''}${
        showCloseIcon ? ' select-control__clearable' : ''
      }`}
    >
      {!!label && <LabelText valid={props.valid} label={label} isRequiredIcon={required} />}

      <DropDownList
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        listNoDataRender={listNoDataRender}
        required={required}
        data={options}
        filter={filter}
        filterable={filterable}
        onFilterChange={handleFilterChange}
        iconClassName={iconClass}
        value={value}
        onChange={handleChange}
        textField={OptionKey.text}
        dataItemKey={OptionKey.id}
        onOpen={handleOpen}
        onClose={handleClose}
        defaultItem={defaultItem}
        popupSettings={popupSettings}
        itemRender={itemRenderer}
        valueRender={valueRenderer}
      />
      {showCloseIcon && (
        <div className="select-control__clearable__clear__icon" onClick={handleClear}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};
export default memo(SingleSelect);
