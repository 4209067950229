import axios from 'axios';
import ProvisionalExpenseService from 'shared/services/bl/provisionalExpense.service';
import type { IUploadFileResponse, IProvisionalExpenseChartDataResponse } from 'shared/models/models.bl';
import { Types } from './types';
import type { AsyncThunkActionType } from '../../interfaces';
import type * as Interfaces from './interfaces';
import summaryActions from '../summaryTotal/actions';

export const countProvisionExpenseValueByKey = (
  data: IProvisionalExpenseChartDataResponse[]
): Interfaces.StoreType['chartData'] & { total: number } => {
  const obj = { plans: {}, locations: {}, dates: {}, total: 0 } as Interfaces.StoreType['chartData'] & {
    total: number;
  };

  if (!Array.isArray(data)) return obj;

  return data.reduce((acc, { provisionDate: date, location, plan, provisionExpenseValue }) => {
    const value = provisionExpenseValue || 0;
    const setValue = (accKey: keyof Interfaces.StoreType['chartData'], accDataKey?: string | null) => {
      if (accDataKey) {
        acc[accKey][accDataKey] = (acc[accKey][accDataKey] || 0) + value;
      }
    };
    setValue('dates', date);
    setValue('locations', location);
    setValue('plans', plan);
    acc.total += value;
    return acc;
  }, obj);
};

export const actions = {
  setCalculationResult: (payload: Interfaces.PayloadType['calculationResult']) =>
    ({
      type: Types.SET_CALCULATION_RESULT,
      payload,
    } as const),

  setCalculating: (payload: Interfaces.PayloadType['loading']) =>
    ({
      type: Types.SET_CALCULATING,
      payload,
    } as const),

  setFileUploaded: (payload: Interfaces.PayloadType['loading']) =>
    ({
      type: Types.SET_FILE_UPLOADED,
      payload,
    } as const),

  setDataLoading: (payload: Interfaces.PayloadType['loading']) =>
    ({
      type: Types.SET_TABLE_DATA_LOADING,
      payload,
    } as const),

  setChartDataLoading: (payload: Interfaces.PayloadType['loading']) =>
    ({
      type: Types.SET_CHART_DATA_LOADING,
      payload,
    } as const),

  setFiltersLoading: (payload: Interfaces.PayloadType['loading']) =>
    ({
      type: Types.SET_FILTERS_LOADING,
      payload,
    } as const),

  setGeneratedReport: (payload: Interfaces.PayloadType['generatedReport']) =>
    ({
      type: Types.SET_GENERATED_REPORT,
      payload,
    } as const),

  setTableData: (payload: Interfaces.PayloadType['paginatedData']) =>
    ({
      type: Types.SET_TABLE_DATA,
      payload,
    } as const),

  setChartData: (payload: Interfaces.PayloadType['chartData']) =>
    ({
      type: Types.SET_CHART_DATA,
      payload,
    } as const),

  setTableFilters: (payload: Interfaces.PayloadType['filters']) =>
    ({
      type: Types.SET_TABLE_FILTERS,
      payload,
    } as const),

  setTotalProvisionByFilter: (payload: Interfaces.PayloadType['totalProvisionByFilter']) =>
    ({
      type: Types.SET_TOTAL_PROVISION_BY_FILTER,
      payload,
    } as const),

  resetTableData: () =>
    ({
      type: Types.RESET_TABLE_DATA,
    } as const),
};

const thunks = {
  getTableData:
    (...params: Parameters<typeof ProvisionalExpenseService.getTableData>): AsyncThunkActionType<void> =>
    async (dispatch, getState) => {
      const [uid, ...rest] = params;
      const reportUId = getState().reports.provisionalExpense.generatedReport || uid;
      try {
        dispatch(actions.setDataLoading(true));
        const responseData = await ProvisionalExpenseService.getTableData(reportUId, ...rest);
        dispatch(actions.setTableData(responseData));
        dispatch(actions.setGeneratedReport(reportUId));
      } catch (err) {
        // INFO: for now request can be canceled by next same request only so we no need to change loading state
        if (!axios.isCancel(err)) dispatch(actions.setDataLoading(false));
      }
    },

  getChartData:
    (...params: Parameters<typeof ProvisionalExpenseService.getChartData>): AsyncThunkActionType<void> =>
    async (dispatch, getState) => {
      const [uid, filter, ...rest] = params;
      const reportUId = getState().reports.provisionalExpense.generatedReport || uid;
      try {
        dispatch(actions.setChartDataLoading(true));
        const responseData = await ProvisionalExpenseService.getChartData(reportUId, filter, ...rest);
        const { total, ...chartData } = countProvisionExpenseValueByKey(responseData);
        dispatch(
          actions.setTotalProvisionByFilter({
            dateFilter: filter?.provisionDate || '',
            valueByAllFilters: total,
          })
        );
        dispatch(actions.setGeneratedReport(reportUId));
        dispatch(actions.setChartData(chartData));
      } catch (err) {
        // INFO: for now request can be canceled by next same request only so we no need to change loading state
        if (!axios.isCancel(err)) dispatch(actions.setChartDataLoading(false));
      }
    },

  getFilters:
    (reportUId: string): AsyncThunkActionType<void> =>
    async (dispatch) => {
      try {
        dispatch(actions.setFiltersLoading(true));
        const responseData = await ProvisionalExpenseService.getFilters(reportUId);
        dispatch(actions.setTableFilters(responseData));
        dispatch(actions.setGeneratedReport(reportUId));
      } catch {
        dispatch(actions.setFiltersLoading(false));
      }
    },

  getTableDataAndChart:
    (...params: Parameters<typeof ProvisionalExpenseService.getTableData>): AsyncThunkActionType<void> =>
    async (dispatch) => {
      const [reportUId, requestData] = params;
      const { provisionDate, location, plan } = requestData || {};
      dispatch(thunks.getChartData(reportUId, { provisionDate, location, plan }));
      dispatch(thunks.getTableData(...params));
    },

  getAllData:
    (...params: Parameters<typeof ProvisionalExpenseService.getTableData>): AsyncThunkActionType<void> =>
    async (dispatch) => {
      dispatch(summaryActions.getDataForFinalization(params[0]));
      dispatch(thunks.getFilters(params[0]));
      dispatch(thunks.getTableDataAndChart(...params));
    },

  calculate:
    (reportUId: string): AsyncThunkActionType<void> =>
    async (dispatch) => {
      try {
        dispatch(actions.setCalculating(true));
        const responseData = await ProvisionalExpenseService.calculate(reportUId);
        dispatch(actions.setCalculationResult(responseData));
      } catch {
        dispatch(actions.setCalculating(false));
      }
    },

  calculateThenFetchTableAndChart:
    (...params: Parameters<typeof ProvisionalExpenseService.getTableData>): AsyncThunkActionType<void> =>
    async (dispatch) => {
      try {
        dispatch(actions.setCalculating(true));
        const responseData = await ProvisionalExpenseService.calculate(params[0]);
        dispatch(actions.setCalculationResult(responseData));
        dispatch(summaryActions.getDataForFinalization(params[0]));
        dispatch(thunks.getTableDataAndChart(...params));
      } catch {
        dispatch(actions.setCalculating(false));
      }
    },

  uploadFile:
    (...params: Parameters<typeof ProvisionalExpenseService.uploadFile>): AsyncThunkActionType<IUploadFileResponse> =>
    async (dispatch) => {
      const uploadResult = await ProvisionalExpenseService.uploadFile(...params);
      dispatch(actions.setFileUploaded(true));
      dispatch(thunks.getAllData(params[1]));
      return uploadResult;
    },

  deleteData: (): AsyncThunkActionType<void> => async (dispatch, getState) => {
    const { generatedReport } = getState().reports.provisionalExpense;
    try {
      if (!generatedReport) throw new Error();
      await ProvisionalExpenseService.delete(generatedReport);
      dispatch(actions.resetTableData());
      dispatch(summaryActions.getDataForFinalization(generatedReport));
    } catch (error) {
      await Promise.reject(error);
    }
  },
};

export default {
  ...actions,
  ...thunks,
};
