import { FC, ReactNode } from 'react';
import {
  Switch as VendorSwitch,
  SwitchProps,
  SwitchChangeEvent as VendorSwitchChangeEvent,
} from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';

import './Switch.scss';

const switchWrapperClassName = 'VendorSwitch__Wrapper';

type LabelPositionType = 'top' | 'bottom' | 'left' | 'right';

export type SwitchChangeEvent = VendorSwitchChangeEvent;

export interface CustomVendorInputProps {
  label?: ReactNode;
  labelPosition?: LabelPositionType;
}

type IProps = SwitchProps & CustomVendorInputProps;

const Switch: FC<IProps> = ({ label, labelPosition = 'top', ...props }) => {
  return (
    <div className={`${switchWrapperClassName}`}>
      <div
        className={`${switchWrapperClassName}__container${
          label ? ` ${switchWrapperClassName}__container-withLabel--${labelPosition}` : ''
        }`}
      >
        {!!label && (
          <Label
            className={`${switchWrapperClassName}__container__label`}
            editorId={props.id}
            editorValid={props.valid}
            editorDisabled={props.disabled}
            optional={false}
          >
            {label}
          </Label>
        )}
        <VendorSwitch {...props} />
      </div>
    </div>
  );
};

export default Switch;
