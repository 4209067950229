import { FC, useEffect, useState } from 'react';
import Input from 'components/vendor/Input';
import { IPerformanceHurdle } from 'shared/models/models.bl';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from '../../../assets/svg/close.svg';
import { ReactComponent as PercentIcon } from '../../../assets/svg/percent.svg';
import './PerformanceHurdle.scss';
import {
  maxPercentage,
  minPercentage,
  setCommonRangeError,
  UnitType,
  validateVestingConditionAmountValues,
  validateVestingConditionPercentageValues,
} from './helper';
import { maxNumber } from '../helpers';

interface IProps {
  isFirstRow: boolean;
  isLastRow: boolean;
  onChange: any;
  subHurdleIndex: number;
  index: number;
  isPercentageSelected: boolean;
  onDeleteVestingCondition: any;
  phData: IPerformanceHurdle;
  readOnlyEditMode: boolean;
}
const VestingConditionControl: FC<IProps> = ({
  isFirstRow,
  isLastRow,
  onChange,
  subHurdleIndex,
  index,
  isPercentageSelected,
  onDeleteVestingCondition,
  phData,
  readOnlyEditMode,
}) => {
  const { t } = useTranslation();
  const [newUnit, setUnit] = useState('P');

  const handleHurdleFromChange = (e: any) => {
    onChange(e, index);
  };
  useEffect(() => {
    setUnit(phData.performanceSubHurdle[subHurdleIndex].unit);
  }, [phData.performanceSubHurdle[subHurdleIndex].unit]);

  const handleHurdleToChange = (e: any) => {
    onChange(e, index);
  };
  const handleVestingFromChange = (e: any) => {
    onChange(e, index);
  };
  const handleVestingToChange = (e: any) => {
    onChange(e, index);
  };
  const deleteVestingCondition = () => {
    onDeleteVestingCondition(index);
  };
  // finding a sub-hurdle with only 2 VCs
  const findIndexOfOnlySHWithTwoVCs = () => {
    let count = 0;
    let starIndex = -1;
    phData.performanceSubHurdle.map((sh, idx) => {
      if (sh.performanceSubHurdleVesting.length >= 2) {
        count += 1;
        starIndex = idx;
      }
      return sh;
    });
    if (
      count > 1 ||
      (phData.performanceSubHurdle.length === 1 &&
        count === 1 &&
        phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting.length > 2) ||
      (phData.performanceSubHurdle.length > 1 &&
        count === 1 &&
        phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting.length > 2)
    ) {
      starIndex = -1;
    }
    return starIndex;
  };
  return (
    <div className="vesting-condition-item">
      <div className="vc-item">
        <Input
          id={`sh-${subHurdleIndex}-hurdleFrom-${index}`}
          name={`sh-${subHurdleIndex}-hurdleFrom-${index}`}
          label={t('PerformanceHurdle.AddModal.VestingCondition.HurdleFrom')}
          placeholder=""
          onChange={handleHurdleFromChange}
          value={
            !isFirstRow
              ? phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].hurdleFrom
              : '0'
          }
          validationMessage={
            newUnit === UnitType.Percentage
              ? setCommonRangeError(
                  t,
                  Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].hurdleFrom),
                  minPercentage,
                  maxPercentage
                )
              : setCommonRangeError(
                  t,
                  Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].hurdleFrom),
                  minPercentage,
                  maxNumber
                )
          }
          valid={
            newUnit === UnitType.Percentage
              ? validateVestingConditionPercentageValues(
                  Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].hurdleFrom)
                )
              : validateVestingConditionAmountValues(
                  Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].hurdleFrom)
                )
          }
          endIcon={isPercentageSelected ? <PercentIcon /> : null}
          disabled={readOnlyEditMode || isFirstRow}
        />
      </div>

      <div className="vc-item">
        <Input
          id={`sh-${subHurdleIndex}-hurdleTo-${index}`}
          name={`sh-${subHurdleIndex}-hurdleTo-${index}`}
          label={t('PerformanceHurdle.AddModal.VestingCondition.HurdleTo')}
          placeholder=""
          onChange={handleHurdleToChange}
          value={
            !isLastRow ? phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].hurdleTo : ''
          }
          validationMessage={
            newUnit === UnitType.Percentage
              ? setCommonRangeError(
                  t,
                  Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].hurdleTo),
                  minPercentage,
                  maxPercentage
                )
              : setCommonRangeError(
                  t,
                  Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].hurdleTo),
                  minPercentage,
                  maxNumber
                )
          }
          valid={
            newUnit === UnitType.Percentage
              ? validateVestingConditionPercentageValues(
                  Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].hurdleTo)
                )
              : validateVestingConditionAmountValues(
                  Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].hurdleTo)
                )
          }
          endIcon={isPercentageSelected ? <PercentIcon /> : null}
          disabled={readOnlyEditMode || isLastRow}
        />
      </div>
      <div className="vc-item">
        <Input
          id={`sh-${subHurdleIndex}-vestingFrom-${index}`}
          name={`sh-${subHurdleIndex}-vestingFrom-${index}`}
          label={t('PerformanceHurdle.AddModal.VestingCondition.VestingFrom')}
          placeholder=""
          onChange={handleVestingFromChange}
          value={
            !isFirstRow
              ? phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].vestingFrom
              : '0'
          }
          validationMessage={setCommonRangeError(
            t,
            Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].vestingFrom),
            minPercentage,
            maxPercentage
          )}
          valid={validateVestingConditionPercentageValues(
            Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].vestingFrom)
          )}
          endIcon={<PercentIcon />}
          disabled={readOnlyEditMode || isFirstRow}
        />
      </div>
      <div className="vc-item">
        <Input
          id={`sh-${subHurdleIndex}-vestingTo-${index}`}
          name={`sh-${subHurdleIndex}-vestingTo-${index}`}
          label={t('PerformanceHurdle.AddModal.VestingCondition.VestingTo')}
          placeholder=""
          onChange={handleVestingToChange}
          value={
            isLastRow &&
            phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].vestingTo.toString() === '0'
              ? ''
              : phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].vestingTo
          }
          validationMessage={setCommonRangeError(
            t,
            Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].vestingTo),
            minPercentage,
            maxPercentage
          )}
          valid={validateVestingConditionPercentageValues(
            Number(phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting[index].vestingTo)
          )}
          endIcon={<PercentIcon />}
          disabled={readOnlyEditMode || isLastRow}
        />
      </div>
      <div
        className={`vesting-cross-icon 
          ${
            // phData.performanceSubHurdle[subHurdleIndex].performanceSubHurdleVesting.length === 2 ||
            readOnlyEditMode || findIndexOfOnlySHWithTwoVCs() === subHurdleIndex ? 'disable-div' : ''
          }`}
        onClick={deleteVestingCondition}
      >
        <CloseIcon />
      </div>
    </div>
  );
};

export default VestingConditionControl;
