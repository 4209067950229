import { PublicFileCode } from 'shared/models/enums.dl';
import FilesService from 'shared/services/bl/files.service';
import LocalizationService from './shared/services/bl/localization.service';

const changeCookiePolicyLink = () => {
  let maxTries = 5;
  const timerId = setInterval(() => {
    const cookieWrapper = document.getElementById('onetrust-consent-sdk');
    if (cookieWrapper) {
      clearInterval(timerId);
      const cookyPolicy: HTMLElement | null = document.getElementById('onetrust-policy-text');
      if (cookyPolicy) {
        const links = cookyPolicy.getElementsByTagName('a') || [];
        const link = links[0];
        const currentLocaleCode = LocalizationService.getLocaleCodeByAuth();
        if (link) {
          link.href = FilesService.getPublicFileLink(PublicFileCode.Cookie, currentLocaleCode);
          links[0].target = '_blank';
        }
      } else {
        maxTries -= 1;
        if (maxTries < 1) {
          clearInterval(timerId);
        }
      }
    }
  }, 500);
};

const runCookieScript = (): void => {
  const { ONE_TRUST } = (window as any).ApiConfig.COOKIE_POLICY;
  const cookiesScript = document.createElement('script');
  cookiesScript.src = ONE_TRUST.SCRIPT_URL;
  cookiesScript.type = 'text/javascript';
  cookiesScript.setAttribute('data-domain-script', ONE_TRUST.SCRIPT_ID);
  // TODO: remove next line when link will be changed by cookie banner team
  cookiesScript.onload = changeCookiePolicyLink;
  document.head.appendChild(cookiesScript);

  /* INFO: OptanonWrapper function is a function passed into another function as an argument,
     which is then invoked inside the outer function to complete some kind of routine or action. 
     It functions as a holder for the helper methods which are used to block or control any script or html tags
     which cause cookies to be set. The wrapper is executed on each page load,
     or whenever the user saves changes to the privacy settings in the Preference Center. */
  (window as any).OptanonWrapper = () => {};
};

export default runCookieScript;
