import { ReactNode } from 'react';
import { NavLinkProps } from 'react-router-dom';
import { ReactComponent as PerformanceHurdlesIcon } from '../../assets/svg/speedometer.svg';
import { ReactComponent as ShareRegisterIcon } from '../../assets/svg/chart/barChartArrowUp.svg';
import { ReactComponent as CustomVestingSchedulesIcon } from '../../assets/svg/files/calendarTick.svg';
import { ReactComponent as GrantValuationIcon } from '../../assets/svg/currency.svg';
import { ReactComponent as EmployeeStatus } from '../../assets/svg/user/userOutlined.svg';
import { ReactComponent as ExpensesProvisionalIcon } from '../../assets/svg/chart/pieChart.svg';
import { ReactComponent as ExpensesTrueUpIcon } from '../../assets/svg/files/compareDocuments.svg';
import { ReactComponent as ExpensesJournalIcon } from '../../assets/svg/book-open.svg';
import { ReactComponent as ExpensesIcon } from '../../assets/svg/calculator.svg';
import { ReactComponent as ExpensesReportsIcon } from '../../assets/svg/chart/barChart.svg';
import { ReactComponent as HomeIcon } from '../../assets/svg/house.svg';
import { ReactComponent as FilesIcon } from '../../assets/svg/folder/openFolder.svg';
import { ReactComponent as ActivityLogsIcon } from '../../assets/svg/backup-logs.svg';
import { ROUTES } from './routes';

export type IconColorType =
  | 'white'
  | 'grey'
  | '#00A3E0'
  | '#0097A9'
  | '#2A5599'
  | '#ED8B00'
  | '#26890D'
  | '#000000'
  | '#004F59'
  | '#6FC2B4'
  | '#86BC25'
  | '#012169'
  | '#004F59';

export interface LinkOptionRouteType extends Omit<NavLinkProps, 'children'> {
  to: ROUTES | '';
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  label: string | ReactNode;
  iconStartColor?: IconColorType;
  iconEndColor?: IconColorType;
  groupHeader?: string;
  groupSubHeader?: string;
  labelForBradCrumbs?: string;
  parents?: ROUTES[];
}

export interface LinkOptionType {
  header?: string;
  subHeader?: string;
  routes: LinkOptionRouteType[];
}

export const manageAccountingInputs: LinkOptionType = {
  header: 'Common.ManageAccountingInputs.Title',
  subHeader: 'Common.ManageAccountingInputs.SubTitle',
  routes: [
    {
      iconStart: <ShareRegisterIcon />,
      label: 'ShareRegister.PageTitle',
      to: ROUTES.shareRegister,
      iconStartColor: '#00A3E0',
    },
    {
      iconStart: <PerformanceHurdlesIcon />,
      label: 'PerformanceHurdles.PageTitle',
      to: ROUTES.performanceHurdles,
      iconStartColor: '#2A5599',
    },
    {
      iconStart: <CustomVestingSchedulesIcon />,
      label: 'CustomVestingSchedules.PageTitle',
      to: ROUTES.customVestingSchedules,
      iconStartColor: '#ED8B00',
    },
    {
      iconStart: <GrantValuationIcon />,
      label: 'GrantValuation.PageTitle',
      to: ROUTES.grantValuation,
      iconStartColor: '#26890D',
    },
    {
      iconStart: <EmployeeStatus />,
      label: 'EmployeeStatus.PageTitle',
      to: ROUTES.employeeStatus,
      iconStartColor: '#000000',
    },
  ],
};

export const generateAndViewExpenses: LinkOptionType = {
  header: 'Common.GenerateAndViewExpenses.Title',
  subHeader: '',
  routes: [
    {
      iconStart: <ExpensesIcon />,
      label: 'GenerateSbpExpenses.PageTitle',
      to: ROUTES.sbpExpenses,
      iconStartColor: '#0097A9',
    },
    {
      iconStart: <ExpensesReportsIcon />,
      label: 'SbpExpensesReports.PageTitle',
      to: ROUTES.sbpExpenseReports,
      iconStartColor: '#86BC25',
    },
    {
      iconStart: <ExpensesJournalIcon />,
      label: 'GenerateJournalEntries.PageTitle',
      labelForBradCrumbs: 'GenerateJournalEntries.PageBreadcrumb',
      to: ROUTES.sbpExpensesJournal,
      iconStartColor: '#012169',
    },
  ],
};

export const additionalFeatures: LinkOptionType = {
  header: 'Common.GenerateAndViewExpenses.Title',
  subHeader: 'Common.GenerateAndViewExpenses.SubTitle',
  routes: [
    {
      iconStart: <ExpensesProvisionalIcon />,
      label: 'SbpExpensesReports.ProvisionalExpensePanel.Title',
      to: ROUTES.sbpExpensesProvisional,
      iconStartColor: '#004F59',
    },
    {
      iconStart: <ExpensesTrueUpIcon />,
      label: 'SbpExpensesReports.ExpenseTrueUpPanel.Title',
      to: ROUTES.sbpExpensesTrueUp,
      iconStartColor: '#6FC2B4',
    },
  ],
};

export const files: LinkOptionType = {
  routes: [
    {
      iconStart: <FilesIcon />,
      label: 'Files.PageTitle',
      to: ROUTES.files,
      iconStartColor: 'grey',
    },
  ],
};

export const home: LinkOptionType = {
  routes: [
    {
      iconStart: <HomeIcon />,
      label: 'Home.PageTitle',
      to: ROUTES.home,
      iconStartColor: 'grey',
    },
  ],
};

export const activityLogs: LinkOptionType = {
  routes: [
    {
      iconStart: <ActivityLogsIcon />,
      label: 'ActivityLogs.PageTitle',
      to: ROUTES.logs,
      iconStartColor: 'grey',
    },
  ],
};

export const generateAndViewExpensesWithAdditionalRouteOptions: LinkOptionRouteType[] = [
  ...generateAndViewExpenses.routes.slice(0, 1),
  ...additionalFeatures.routes,
  ...generateAndViewExpenses.routes.slice(1),
];

const manageAccountingInputsText: Record<string, string> = {
  groupHeader: 'Common.ManageAccountingInputs.Title',
  groupSubHeader: 'Common.ManageAccountingInputs.SubTitle',
};

const generateAndViewExpensesText: Record<string, string> = {
  groupHeader: 'Common.GenerateAndViewExpenses.Title',
};

const additionalFeaturesText: Record<string, string> = {
  groupHeader: 'Common.GenerateAndViewExpenses.Title',
  groupSubHeader: 'Common.GenerateAndViewExpenses.SubTitle',
};

export const breadcrumbOptions: Record<string, LinkOptionRouteType> = {
  [ROUTES.home]: {
    label: 'Home.PageTitle',
    to: ROUTES.home,
  },
  [ROUTES.shareRegister]: {
    ...manageAccountingInputsText,
    label: 'ShareRegister.PageTitle',
    to: ROUTES.shareRegister,
  },
  [ROUTES.performanceHurdles]: {
    ...manageAccountingInputsText,
    label: 'PerformanceHurdles.PageTitle',
    to: ROUTES.performanceHurdles,
  },
  [ROUTES.customVestingSchedules]: {
    ...manageAccountingInputsText,
    label: 'CustomVestingSchedules.PageTitle',
    to: ROUTES.customVestingSchedules,
  },
  [ROUTES.grantValuation]: {
    ...manageAccountingInputsText,
    label: 'GrantValuation.PageTitle',
    to: ROUTES.grantValuation,
  },
  [ROUTES.employeeStatus]: {
    ...manageAccountingInputsText,
    label: 'EmployeeStatus.PageTitle',
    to: ROUTES.employeeStatus,
  },
  [ROUTES.sbpExpenses]: {
    ...generateAndViewExpensesText,
    label: 'GenerateSbpExpenses.PageTitle',
    to: ROUTES.sbpExpenses,
  },
  [ROUTES.sbpExpenseReports]: {
    ...generateAndViewExpensesText,
    label: 'SbpExpensesReports.PageTitle',
    to: ROUTES.sbpExpenseReports,
  },
  [ROUTES.sbpExpensesJournal]: {
    ...generateAndViewExpensesText,
    label: 'GenerateJournalEntries.PageTitle',
    labelForBradCrumbs: 'GenerateJournalEntries.PageBreadcrumb',
    to: ROUTES.sbpExpensesJournal,
  },
  [ROUTES.sbpExpensesProvisional]: {
    ...additionalFeaturesText,
    label: 'SbpExpensesReports.ProvisionalExpensePanel.Title',
    to: ROUTES.sbpExpensesProvisional,
  },
  [ROUTES.sbpExpensesTrueUp]: {
    ...additionalFeaturesText,
    label: 'SbpExpensesReports.ExpenseTrueUpPanel.Title',
    to: ROUTES.sbpExpensesTrueUp,
  },
  [ROUTES.files]: {
    label: 'Files.PageTitle',
    to: ROUTES.files,
  },
  [ROUTES.logs]: {
    label: 'ActivityLogs.PageTitle',
    to: ROUTES.logs,
  },
  [ROUTES.manageEmployeeContracts]: {
    ...manageAccountingInputsText,
    label: 'ManageEmployeeContracts.PageTitle',
    to: ROUTES.manageEmployeeContracts,
    parents: [ROUTES.shareRegister],
  },
  [ROUTES.employeeContractDetails]: {
    label: 'EmployeeContractDetails.PageTitle',
    to: ROUTES.employeeContractDetails,
    parents: [ROUTES.shareRegister, ROUTES.manageEmployeeContracts],
  },
};
