import { TFunction } from 'react-i18next';
import { defaultPagingRequestData as defaultPageDataWithoutOrderBy } from 'shared/configs/grid.config';
import { IColumnConfig } from 'components/Table/interfaces';
import {
  DepartmentViewTableData,
  EmployeeViewTableData,
  ExpenseOutlookTableData,
  GeographicalViewTableData,
  PlanViewTableData,
  SummaryTotalTableData,
} from 'shared/models/models.bl';

export const getTotalProvisionLabel = (
  t: TFunction,
  dateFilter?: string,
  isProvisionCalculationComplete = false
): string => {
  if (!isProvisionCalculationComplete) {
    return t('SbpExpensesReports.ProvisionalExpensePanel.TotalProvisionsBlock.NoProvisionalExpense');
  }

  return dateFilter
    ? t('SbpExpensesReports.ProvisionalExpensePanel.TotalProvisionsBlock.TotalProvisionalExpenseForSelectedMonth', {
        dateFilter,
      })
    : t('SbpExpensesReports.ProvisionalExpensePanel.TotalProvisionsBlock.TotalProvisionalExpenseForAllMonths');
};

export const defaultFilters = {
  location: '',
  plan: '',
};

export const YTD_EXPENSE_NAME = 'SbpExpensesReports.Common.YTDVestedExpenseAsOfYear';

export const defaultPagingSummaryTotalRequestData = {
  ...defaultPageDataWithoutOrderBy,
  orderBy: 'grantDate' as IColumnConfig<SummaryTotalTableData>['name'],
};

export const defaultPagingDepartmentViewRequestData = {
  ...defaultPageDataWithoutOrderBy,
  orderBy: 'GrantPlan' as IColumnConfig<DepartmentViewTableData>['name'],
};

export const defaultPagingEmployeeViewRequestData = {
  ...defaultPageDataWithoutOrderBy,
  orderBy: 'optionholder' as IColumnConfig<EmployeeViewTableData>['name'],
};

export const defaultPagingGeographicalViewRequestData = {
  ...defaultPageDataWithoutOrderBy,
  orderBy: 'grantDate' as IColumnConfig<GeographicalViewTableData>['name'],
};

export const defaultPagingPlanViewRequestData = {
  ...defaultPageDataWithoutOrderBy,
  orderBy: 'grantDate' as IColumnConfig<PlanViewTableData>['name'],
};

export const defaultPagingExpenseOutlookViewRequestData = {
  ...defaultPageDataWithoutOrderBy,
  orderBy: '' as IColumnConfig<ExpenseOutlookTableData>['name'],
};
