import { FC } from 'react';

import ModalLayout from '../../ModalLayout';
import type { IModalFooterProps } from '../../ModalLayout/ModalFooter';

import './ConfirmModal.scss';

interface IProps {
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  icon?: any;
  visible: boolean;
  confirmBtnColor?: IModalFooterProps['confirmBtnColor'];
  confirmDisabled?: IModalFooterProps['confirmDisabled'];
  confirmLoading?: IModalFooterProps['confirmLoading'];
  confirmBtnTitle?: string;
  cancelBtnTitle?: string;
}

const ConfirmModal: FC<IProps> = ({
  onCancel,
  onConfirm,
  title,
  icon,
  visible,
  children,
  confirmBtnColor = 'danger',
  confirmDisabled,
  confirmLoading,
  confirmBtnTitle = 'Proceed',
  cancelBtnTitle,
}) => {
  return visible ? (
    <ModalLayout
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={title}
      icon={icon}
      className="confirm-modal"
      confirmBtnTitle={confirmBtnTitle}
      cancelBtnTitle={cancelBtnTitle}
      confirmBtnColor={confirmBtnColor}
      confirmDisabled={confirmDisabled}
      confirmLoading={confirmLoading}
      cancelDisabled={confirmLoading}
    >
      {children}
    </ModalLayout>
  ) : null;
};

export default ConfirmModal;
