export enum TypeName {
  SET_GENERATED_REPORT = 'SET_GENERATED_REPORT',
  SET_DATA_LOADING = 'SET_DATA_LOADING',
  SET_TABLE_DATA = 'SET_TABLE_DATA',
  SET_TABLE_FILTERS = 'SET_TABLE_FILTERS',
  SET_FILTERS_LOADING = 'SET_FILTERS_LOADING',
  SET_PAGINATED_TABLE_DATA = 'SET_PAGINATED_TABLE_DATA',
  SET_CHART_LOADING = 'SET_CHART_LOADING',
  SET_CHART_DATA = 'SET_CHART_DATA',
}
