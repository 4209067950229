import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PlusIcon } from '../../../assets/svg/plus.svg';
import { ReactComponent as FileExcel } from '../../../assets/svg/File_Excel.svg';
import Button from '../../Button';
import Switch, { SwitchChangeEvent } from '../../vendor/Switch';

import './TableActions.scss';

interface IProps {
  onDelete?: () => void;
  onExport?: () => void;
  onAddNew?: () => void;
  onToggle?: (checked: boolean) => void;
  deleteDisabled?: boolean;
  deleting?: boolean;
  exportDisabled?: boolean;
  exportingtext?: boolean;
  addNewDisabled?: boolean;
  toggleDisabled?: boolean;
  addNewItemName?: string;
  toggleLabel?: string;
  toggled?: boolean;
}

const TableActions: FC<IProps> = ({
  onDelete,
  deleteDisabled = false,
  deleting = false,
  onExport,
  exportingtext = false,
  exportDisabled = false,
  onAddNew,
  addNewDisabled = false,
  addNewItemName = '',
  onToggle,
  toggleDisabled = false,
  toggleLabel = '',
  toggled,
}) => {
  const { t } = useTranslation();

  const handleDelete = () => {
    if (!deleteDisabled) onDelete?.();
  };
  const handleExport = () => {
    if (!exportDisabled) onExport?.();
  };
  const handleAddNew = () => {
    if (!addNewDisabled) onAddNew?.();
  };
  const handleToggle = ({ value }: SwitchChangeEvent) => {
    if (!toggleDisabled) onToggle?.(value);
  };
  return (
    <>
      {!!onToggle && (
        <Switch
          checked={toggled}
          onChange={handleToggle}
          label={toggleLabel}
          id={toggleLabel}
          labelPosition="left"
          offLabel=""
          onLabel=""
          disabled={toggleDisabled}
        />
      )}
      {!!onDelete && (
        <Button
          className="tableDeleteAction"
          color="danger"
          styleType="text"
          onClick={handleDelete}
          disabled={deleteDisabled}
          loading={deleting}
        >
          {t('Common.Table.DeleteButton')}
        </Button>
      )}
      {!!onExport && (
        // <Button
        //   className="tableExportAction"
        //   styleType="outlined"
        //   onClick={handleExport}
        //   iconStart={<FileExcel />}
        //   disabled={exportDisabled}
        // >
        //   {t('Common.Table.ExportToExcelButton')}
        // </Button>
        <Button
          iconStart={<FileExcel />}
          className="tableExportAction"
          onClick={handleExport}
          disabled={exportDisabled}
          styleType="text"
        >
          {exportingtext ? t('Common.Table.Exporting') : t('Common.Table.ExportToExcelButton')}
        </Button>
      )}
      {!!onAddNew && (
        <Button className="tableAddNewAction" onClick={handleAddNew} disabled={addNewDisabled} iconStart={<PlusIcon />}>
          {addNewItemName}
        </Button>
      )}
    </>
  );
};

export default TableActions;
