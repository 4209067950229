export enum TypeName {
  SET_STATUS = 'SET_STATUS',
  STATUS_LOADING = 'STATUS_LOADING',
  SET_VALIDATION_DATA = 'SET_VALIDATION_DATA',
  PROCEED_MODAL_VISIBILITY = 'PROCEED_MODAL_VISIBILITY',
  SHOW_MODAL_WITH_STATUS = 'SHOW_MODAL_WITH_STATUS',
  RESET_STATE_DATA = 'RESET_STATE_DATA',
  SET_IS_CANCEL = 'SET_IS_CANCEL',
  SET_REPORT_PROGRESS_DATA = 'SET_REPORT_PROGRESS_DATA',
}

export enum ModalStatus {
  GRANT_VALUATION_EMPTY = 'GRANT_VALUATION_EMPTY',
  SHARE_REGISTER_EMPTY = 'SHARE_REGISTER_EMPTY',
  INVALID_SHARE_REGISTER = 'INVALID_SHARE_REGISTER',
  INVALID_PERFORMANCE_HURDLE_ID = 'INVALID_PERFORMANCE_HURDLE_ID',
  PROCEED = 'PROCEED',
}
