import Utils from 'shared/shared.utils';
import { ChartStatus } from 'shared/models/enums.dl';
import type * as Interfaces from './interfaces';

type GetTotalExpensesReturnType = Pick<Interfaces.StoreType, 'currentMonthExpense' | 'ytdExpense'>;

const defaultBigNumber = 999999999; // to separate not appropriate dates

export const getYearMonthForCompare = (date: string | Date): { yearNumber: number; monthNumber: number } => {
  const value: Date = date instanceof Date ? date : new Date(date);
  return Utils.formatDate.checkIsDate(value)
    ? { yearNumber: value.getFullYear(), monthNumber: value.getMonth() }
    : { yearNumber: defaultBigNumber, monthNumber: defaultBigNumber };
};

export const isCurrentYearAndBeforeMonth = (date: Date | string, today: Date | string): boolean => {
  const { yearNumber: currentYear, monthNumber: currentMonth } = getYearMonthForCompare(date);
  const { yearNumber: todayYear, monthNumber: todayMonth } = getYearMonthForCompare(today);
  return currentYear === todayYear && currentMonth < todayMonth;
};

const getCurrentMonthYearStr = (date: string | Date, longMonth?: boolean): string =>
  Utils.formatDate.dateFormatter(
    date,
    !longMonth ? Utils.formatDate.DateFormat.MonthYear : Utils.formatDate.DateFormat.FullYearFullMonth
  );

// Aug 2020 -> {{Common.Month.Aug}} 2020
const convertShortMonthYearStrToLocalizationMonthYearKey = (shortDateStr: string): string => {
  const [month, year] = shortDateStr.split(' ');
  return `{{Common.Month.${month}}} ${year}`;
};

// August 2020 -> {{Common.Month.August}} 2020
const convertLongMonthYearStrToLocalizationMonthYearKey = (longDateStr: string): string => {
  const [month, year] = longDateStr.split(' ');
  return `{{Common.Month.FullName.${month}}} ${year}`;
};

// {{Common.Month.Aug}} 2020 -> Aug 2020
const convertLocalizationMonthYearKeyToShortMonthYearStr = (localizationMonthYearKey: string): string =>
  localizationMonthYearKey.replace(/^{{Common.Month.(.+)}} (\d{4})$/, '$1 $2');

export const currentMontYearStr = {
  get today(): Date {
    return new Date();
  },
  get currentShortMonthYear(): string {
    return getCurrentMonthYearStr(this.today);
  },
  get currentLongMonthYear(): string {
    return getCurrentMonthYearStr(this.today, true);
  },
  get currentShortMonthYearLocalizationKey(): string {
    return convertShortMonthYearStrToLocalizationMonthYearKey(this.currentShortMonthYear);
  },
  get currentLongMonthYearLocalizationKey(): string {
    return convertLongMonthYearStrToLocalizationMonthYearKey(this.currentLongMonthYear);
  },
};

export const getTotalExpensesFromChartData = (data: Interfaces.StoreType['chartData']): GetTotalExpensesReturnType => {
  const vestedData = data.find((item) => item.status === ChartStatus.Vested);
  const result: GetTotalExpensesReturnType = {
    currentMonthExpense: 0,
    ytdExpense: 0,
  };

  if (!vestedData) return result;

  result.currentMonthExpense =
    +vestedData.data[currentMontYearStr.currentShortMonthYearLocalizationKey as keyof typeof vestedData] || 0;

  result.ytdExpense = Object.entries(vestedData.data).reduce((acc, [localizationKey, value]) => {
    if (
      value &&
      isCurrentYearAndBeforeMonth(
        convertLocalizationMonthYearKeyToShortMonthYearStr(localizationKey),
        currentMontYearStr.today
      )
    ) {
      acc += value;
    }
    return acc;
  }, result.currentMonthExpense);

  return result;
};

const getValueFromDateValue = (dateValue: string | null = ''): number => {
  if (!dateValue) return 0;
  const [date, value = ''] = dateValue.split('##');
  if (!date || !value) return 0;
  const numberValue = +value;
  return numberValue || 0;
};

export const getValueFromDateValues = (dateValues: string | string[] | null): number => {
  if (Array.isArray(dateValues)) {
    return dateValues.reduce((acc: number, current) => acc + getValueFromDateValue(current), 0);
  }
  return getValueFromDateValue(dateValues);
};
