import { FC, memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import FilesService from 'shared/services/bl/files.service';
import { PublicFileCode } from 'shared/models/enums.dl';
import Services from 'shared/shared.services';
import { selectIsLoadedLocaleKeys } from 'store/localization/selectors';
import { selectCurrentUser } from 'store/user/selectors';
import LocalizationService from 'shared/services/bl/localization.service';
import FooterLink from './FooterLink';
import './Footer.scss';
import CountrySpecificPrivacyLink from './CountrySpecificPrivacyLink/CountrySpecificPrivacyLink';

const Footer: FC = () => {
  const { t } = useTranslation();
  const isLoadedLocaleKeys = useSelector(selectIsLoadedLocaleKeys);
  useSelector(selectCurrentUser);
  const isAuthenticated = Services.BL.AuthorizationService.isAuthorized();
  const isAuth = isAuthenticated;
  const localeCode = LocalizationService.getLocaleCodeByAuth(isAuth);

  return (
    <footer className="appFooter">
      {isLoadedLocaleKeys && (
        <>
          <p>
            <Trans
              i18nKey="Footer.PrivacyCookiesNotices"
              t={t}
              values={{ year: new Date().getFullYear() }}
              components={[
                <FooterLink href={FilesService.getPublicFileLink(PublicFileCode.TermsOfUse, localeCode)} />,
                !isAuth ? (
                  <FooterLink href={FilesService.getPublicFileLink(PublicFileCode.PrivacyPolicy, localeCode)} />
                ) : (
                  <CountrySpecificPrivacyLink />
                ),
                <FooterLink className="optanon-toggle-display" />, // class is used for displaying cookies settings banner from OneTrust
                <FooterLink href={FilesService.getPublicFileLink(PublicFileCode.Notices, localeCode)} />,
              ]}
            />
          </p>
          <p>
            <Trans
              i18nKey="Footer.Description"
              t={t}
              components={[<FooterLink href="http://www.deloitte.com/about" />]}
            />
          </p>
        </>
      )}
    </footer>
  );
};

export default memo(Footer);
