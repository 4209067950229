import { ILocaleOption } from 'shared/models/models.dl';
import type { AppState } from '../interfaces';

export const selectIsLoadedLocales = ({ localization }: AppState): boolean => localization.isLoadedLocales;
export const selectIsErrorLocales = ({ localization }: AppState): boolean => localization.isErrorLocales;
export const selectLocales = ({ localization }: AppState): ILocaleOption[] | null => localization.locales;
export const selectIsLoadedLocaleKeys = ({ localization }: AppState): boolean => localization.isLoadedLocaleKeys;
export const selectIsLoadingLocaleKeys = ({ localization }: AppState): boolean => localization.isLoadingLocaleKeys;
export const selectIsErrorLocaleKeys = ({ localization }: AppState): boolean => localization.isErrorLocaleKeys;
export const selectIsErrorLoginLocaleKeys = ({ localization }: AppState): boolean =>
  localization.isErrorLoginLocaleKeys;
