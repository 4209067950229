import { StoreName } from '../storeName';
import { TypeName } from './enums';

const baseTypeName = `${StoreName.Localization}/` as const;

export const Types = {
  [TypeName.SET_IS_LOADED_LOCALES]: `${baseTypeName}${TypeName.SET_IS_LOADED_LOCALES}`,
  [TypeName.SET_IS_ERROR_LOCALES]: `${baseTypeName}${TypeName.SET_IS_ERROR_LOCALES}`,
  [TypeName.SET_LOCALES]: `${baseTypeName}${TypeName.SET_LOCALES}`,
  [TypeName.SET_IS_LOADED_LOCALE_KEYS]: `${baseTypeName}${TypeName.SET_IS_LOADED_LOCALE_KEYS}`,
  [TypeName.SET_IS_LOADING_LOCALE_KEYS]: `${baseTypeName}${TypeName.SET_IS_LOADING_LOCALE_KEYS}`,
  [TypeName.SET_IS_ERROR_LOCALE_KEYS]: `${baseTypeName}${TypeName.SET_IS_ERROR_LOCALE_KEYS}`,
  [TypeName.SET_IS_ERROR_LOGIN_LOCALE_KEYS]: `${baseTypeName}${TypeName.SET_IS_ERROR_LOGIN_LOCALE_KEYS}`,
} as const;
