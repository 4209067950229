import axios from 'axios';
import { ITableDataInfoRequest } from 'shared/models/models.bl';
import { SelectedRowsType } from '../../../components/Table/interfaces';
import Configs from '../../shared.configs';
import * as Models from '../../shared.models';
import ApiUrlsService from './api-urls.service';
import { getParsedResponseData } from './helpers';

export default class ShareRegister {
  static getShareRegisterTableList(): Promise<Models.BM.ResponseTableDataType<Models.BM.ShareRegisterTableData>> {
    return axios
      .get(ApiUrlsService.getShareRegisterTableList())
      .then(({ data }) =>
        getParsedResponseData<Models.BM.ShareRegisterTableData>(data, Configs.Grid.GridColumnConfig.ShareRegisterConfig)
      )
      .catch(() => null);
  }

  static getShareRegisterData(
    data: ITableDataInfoRequest
  ): Promise<Models.BM.ResponseTableDataType<Models.BM.ShareRegisterTableData>> {
    return axios.post(ApiUrlsService.getShareRegisterData(), data).catch(() => null);
  }

  static uploadShareRegisterTableFile(file: File, forfeitureRate?: number): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('forfeitureRate', `${forfeitureRate || 0}`);
    return axios.post(ApiUrlsService.uploadShareRegisterTableFile(), formData).then(({ data }) => data);
  }

  static deleteShareRegisterTableData(ids: SelectedRowsType<Models.BM.ShareRegisterTableData>): Promise<any> {
    return axios.post(ApiUrlsService.deleteShareRegisterTableData(), ids).then(({ data }) => data);
  }

  static exportShareRegisterTableData(): Promise<any> {
    return axios.post(ApiUrlsService.exportShareRegisterTableData()).then(({ data }) => data);
  }

  static updateShareRegisterItem(data: Models.BM.IGrantRequestData): Promise<any> {
    return axios.put(ApiUrlsService.updateShareRegisterItem(), data).then((response) => response);
  }

  static getVestingSchedules(): Promise<string[]> {
    return axios.get(ApiUrlsService.getVestingSchedules()).then(({ data }) => data);
  }

  static checkCustomVestingScheduleForNewGrantId(
    grantId: Models.BM.IGrantRequestData['id']
  ): Promise<Promise<Models.BM.INewGrantIdValidationResponse>> {
    return axios.post(ApiUrlsService.checkCustomVestingScheduleForNewGrantId(), { grantId }).then(({ data }) => data);
  }
}
