import Services from '../../shared/shared.services';
import { Types } from './types';
import type { PayloadType } from './interfaces';
import type { AsyncThunkActionType } from '../interfaces';

export const actions = {
  setTemplateFilesFetching: (isFetching: PayloadType['isFetching']) =>
    ({
      type: Types.SET_TEMPLATE_FILES_FETCHING,
      payload: isFetching,
    } as const),

  setTemplateFiles: (files: PayloadType['templates']) =>
    ({
      type: Types.SET_TEMPLATE_FILES,
      payload: files,
    } as const),
};

const thunks = {
  getTemplateFiles: (): AsyncThunkActionType<void> => async (dispatch, getState) => {
    const isFetching = getState().files.isTemplatesFetching;
    if (isFetching) return;
    try {
      dispatch(actions.setTemplateFilesFetching(true));
      const fileList = await Services.BL.Files.getTemplateFiles();
      dispatch(actions.setTemplateFiles(fileList));
    } catch {
      dispatch(actions.setTemplateFilesFetching(false));
    }
  },
};

export default {
  ...actions,
  ...thunks,
};
