import RegExps from 'shared/shared.regExps';
import { IPerformanceHurdle, IPerformanceSubHurdle } from 'shared/models/models.bl';
import { maxNumber, trimValue } from '../helpers';

export const maxLengthPerformanceHurdleId = 200;
export const minPercentage = 0;
export const maxPercentage = 100;

export const uid = (function outer() {
  let i = 0;
  return function inner() {
    return ++i;
  };
})();

export const phBasicData = {
  getPhBasicData() {
    const obj = {
      uid: '',
      performanceHurdleId: '',
      performanceSubHurdle: [
        {
          shUid: `sh-${uid()}`,
          subHurdleName: '',
          weightage: '',
          unit: 'P',
          performanceSubHurdleVesting: [
            {
              hurdleFrom: '0',
              hurdleTo: '',
              vestingFrom: '0',
              vestingTo: '',
            },
            {
              hurdleFrom: '',
              hurdleTo: '0',
              vestingFrom: '',
              vestingTo: '',
            },
          ],
        },
      ],
    };
    return obj;
  },
};

export enum UnitType {
  Percentage = 'P',
  Amount = 'A',
}

export const setCommonRangeError = (t: any, value: number, min: number, max: number) => {
  return value >= min && value <= max
    ? ''
    : t('Common.FormValidation.RangeNumbersError', {
        errorPrefix: t('Common.FormValidation.InputValueErrorPrefix'),
        minNumber: min,
        maxNumber: max,
      });
};

export const validatePHAndSubPHIdAndNameAreValid = (value: string | null | undefined): boolean => {
  return (
    !!value?.trim() &&
    trimValue(value).length <= maxLengthPerformanceHurdleId &&
    RegExps.performanceHurdle.test(value.trim())
  );
};

export const validateSubHurdleWeight = (value: number | string): boolean => {
  return (
    trimValue(value) !== '' &&
    value.toString().indexOf('.') === -1 &&
    +value >= minPercentage &&
    +value <= maxPercentage
  );
};

export const validateSubHurdleWeightAmount = (value: number | string): boolean => {
  return (
    trimValue(value) !== '' && value.toString().indexOf('.') === -1 && +value >= minPercentage && +value <= maxNumber
  );
};

export const validateVestingConditionPercentageValues = (value: number | string): boolean => {
  return (
    trimValue(value) !== '' &&
    value.toString().indexOf('.') === -1 &&
    +value >= minPercentage &&
    +value <= maxPercentage
  );
};
export const validateVestingConditionAmountValues = (value: number | string): boolean => {
  return (
    trimValue(value) !== '' && value.toString().indexOf('.') === -1 && +value >= minPercentage && +value <= maxNumber
  );
};

export const validateCombinedSubHurdleWeight = (subHurdles: IPerformanceSubHurdle[] | undefined): boolean => {
  let totalWeight = 0;
  if (subHurdles && subHurdles?.length > 0) {
    for (let i = 0; i < subHurdles.length; i++) {
      totalWeight += +subHurdles[i].weightage;
    }
  }
  return +totalWeight === 100;
};

export const validateSubHurdleNameDuplicacy = (subHurdles: IPerformanceSubHurdle[] | undefined): boolean => {
  let isDuplicate = false;
  if (subHurdles && subHurdles.length > 0) {
    const subHurdleNames = subHurdles.map(function (item: IPerformanceSubHurdle) {
      return item.subHurdleName;
    });
    isDuplicate = subHurdleNames.some(function (item: string, idx) {
      return subHurdleNames?.indexOf(item) !== idx;
    });
  }

  return isDuplicate;
};

export const changeModalPropertyType = (modal: IPerformanceHurdle) => {
  const copyModal: any = JSON.parse(JSON.stringify(modal));
  const objKeys = Object.keys(copyModal);
  for (let i = 0; i < objKeys.length; i++) {
    if (objKeys[i] === 'performanceSubHurdle') {
      for (let k = 0; k < copyModal[objKeys[i]].length; k++) {
        const newArr = [];
        for (let j = 0; j < copyModal[objKeys[i]][k].performanceSubHurdleVesting.length; j++) {
          const item = copyModal[objKeys[i]][k].performanceSubHurdleVesting;
          const newObj: any = {};
          newObj.hurdleFrom = Number.isNaN(parseInt(item[j].hurdleFrom, 10)) ? 0 : parseInt(item[j].hurdleFrom, 10);
          newObj.hurdleTo = Number.isNaN(parseInt(item[j].hurdleTo, 10)) ? 0 : parseInt(item[j].hurdleTo, 10);
          newObj.vestingFrom = Number.isNaN(parseInt(item[j].vestingFrom, 10)) ? 0 : parseInt(item[j].vestingFrom, 10);
          newObj.vestingTo = Number.isNaN(parseInt(item[j].vestingTo, 10)) ? 0 : parseInt(item[j].vestingTo, 10);
          newArr.push(newObj);
        }
        copyModal[objKeys[i]][k].performanceSubHurdleVesting = newArr;
      }
    }
  }
  return copyModal;
};
