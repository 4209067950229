import { StoreName } from '../storeName';
import { TypeName } from './enums';

const baseTypeName = `${StoreName.Expenses}/` as const;

export const Types = {
  [TypeName.SET_STATUS]: `${baseTypeName}${TypeName.SET_STATUS}`,
  [TypeName.STATUS_LOADING]: `${baseTypeName}${TypeName.STATUS_LOADING}`,
  [TypeName.SET_VALIDATION_DATA]: `${baseTypeName}${TypeName.SET_VALIDATION_DATA}`,
  [TypeName.PROCEED_MODAL_VISIBILITY]: `${baseTypeName}${TypeName.PROCEED_MODAL_VISIBILITY}`,
  [TypeName.SHOW_MODAL_WITH_STATUS]: `${baseTypeName}${TypeName.SHOW_MODAL_WITH_STATUS}`,
  [TypeName.RESET_STATE_DATA]: `${baseTypeName}${TypeName.RESET_STATE_DATA}`,
  [TypeName.SET_IS_CANCEL]: `${baseTypeName}${TypeName.SET_IS_CANCEL}`,
  [TypeName.SET_REPORT_PROGRESS_DATA]: `${baseTypeName}${TypeName.SET_REPORT_PROGRESS_DATA}`,
} as const;
