import { ROUTES } from 'shared/constants/routes';
import { store } from 'store';
import { setRedirectTo } from 'store/redirect/actions';
import { OIDCROUTES } from 'OIDCAuth/Routing/routes';

export default class RouteService {
  static get loginRoute(): string {
    return OIDCROUTES.login;
  }

  static get landingPageRoute(): string {
    return ROUTES.landing;
  }

  static get unAuthorizedPage(): string {
    return ROUTES.unauthorized;
  }

  static setRoute(route: ROUTES): void {
    store.dispatch(setRedirectTo(route));
  }
}
