import { onlySumRows } from '../constants/onlySumRows';

export const getOnlySumRows = <T extends Record<any, any>>(data: T[], dataKey: keyof T): T[] => {
  if (!data?.length) return data;
  let onlySumRowsLength = Object.keys(onlySumRows).length;
  const dataLength = data.length;
  let result: T[] = [];
  for (let i = dataLength - 1; i >= 0; i--) {
    if (onlySumRows[data[i][dataKey]]) {
      result = [data[i], ...result];
      onlySumRowsLength -= 1;
    } else break;
    if (!onlySumRowsLength) break;
  }
  return result;
};
