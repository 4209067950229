export enum ReportsStoreName {
  SummaryTotal = 'summaryTotal',
  DepartmentView = 'departmentView',
  EmployeeView = 'employeeView',
  GeographicalView = 'geographicalView',
  PlanView = 'planView',
  ProvisionalExpense = 'provisionalExpense',
  TrueUp = 'trueUp',
  ExpenseOutlookView = 'expenseOutlookView',
}
