import { StoreName } from '../storeName';
import { TypeName } from './enums';

const baseTypeName = `${StoreName.CurrentUser}/` as const;

export const Types = {
  [TypeName.SET_USER]: `${baseTypeName}${TypeName.SET_USER}`,
  [TypeName.SET_TOKEN]: `${baseTypeName}${TypeName.SET_TOKEN}`,
  [TypeName.SET_SETTINGS]: `${baseTypeName}${TypeName.SET_SETTINGS}`,
} as const;
