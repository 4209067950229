import { useState, useCallback } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

export type ForfeitureRate = string | number | undefined;

const errorText = (min: number, max: number, t: TFunction) => t('ShareRegister.ForfeitureRateError', { min, max });

function useForfeitureRate(
  initialRate?: ForfeitureRate,
  min = 0,
  max = 100
): {
  forfeitureRate: ForfeitureRate;
  forfeitureRateError: string;
  setForfeitureRate: (v: any) => void;
  onBlurForfeitureRate: () => void;
} {
  const { t } = useTranslation();
  const [forfeitureRate, setForfeitureRate] = useState<ForfeitureRate>(initialRate);
  const [forfeitureRateError, setForfeitureRateError] = useState<string>('');

  const setRateError = useCallback((rateValue: ForfeitureRate) => {
    setForfeitureRateError(() => {
      if (!rateValue) return '';
      if (Number.isNaN(+rateValue)) return errorText(min, max, t);
      const numberValue = Number.parseFloat(rateValue as string);
      return numberValue > max || numberValue < min ? errorText(min, max, t) : '';
    });
  }, []);

  const handleChangeForfeitureRate = useCallback(
    (value: string | number) => {
      if (value && typeof value === 'string') {
        value = value.trim();
        const prefix = value.startsWith('-') ? '-' : '';
        value = value.replace(/[^.\d]/g, '').replace(/\.+/g, '.');
        if ((!!value && Number.isNaN(+value)) || /\.\d{3}$/.test(value)) value = value.replace(/.$/, '');
        value = prefix + value;
      }
      setForfeitureRate(value);
      setRateError(value);
    },
    [setRateError]
  );

  const onBlurForfeitureRate = useCallback(() => {
    const rate =
      !forfeitureRate || !`${forfeitureRate}`.replace(/[^.\d]/g, '')
        ? ''
        : `${Number.parseFloat(forfeitureRate as string)}`;

    setRateError(rate);
    setForfeitureRate(rate);
  }, [forfeitureRate, setRateError, setForfeitureRate]);

  return {
    forfeitureRate,
    forfeitureRateError,
    setForfeitureRate: handleChangeForfeitureRate,
    onBlurForfeitureRate,
  };
}

export default useForfeitureRate;
