import { ForwardRefRenderFunction, forwardRef, memo, ButtonHTMLAttributes, ReactNode, MouseEvent } from 'react';

import Utils from 'shared/shared.utils';

import Loader from '../Loader/Loader';

import './Button.scss';

export type ButtonColor = 'primary' | 'secondary' | 'danger' | 'success' | 'transparent' | 'dark';
export type ButtonStyleType = 'text' | 'outlined' | 'default';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconStart?: ReactNode;
  iconEnd?: ReactNode;
  loading?: boolean;
  color?: ButtonColor;
  styleType?: ButtonStyleType;
  uppercase?: boolean;
}

const defaultClassName = 'Deloitte__btn';

const getAdditionalClassNames = Utils.makeAdditionalClassNamesGenerator(defaultClassName);

const Button: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
  {
    type = 'button',
    color = 'primary',
    styleType = 'default',
    disabled: buttonDisabled,
    loading,
    className,
    onClick,
    iconStart: buttonIconStart,
    iconEnd: buttonIconEnd,
    children,
    uppercase = false,
    ...props
  },
  ref
) => {
  const disabled = buttonDisabled || loading;
  const iconStart = loading && !!buttonIconStart ? <Loader /> : buttonIconStart;
  const iconEnd = loading && !buttonIconStart && !!buttonIconEnd ? <Loader /> : buttonIconEnd;
  const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
    if (disabled) return;
    onClick?.(e);
  };

  return (
    <button
      ref={ref}
      className={`${defaultClassName} ${defaultClassName}__${styleType}--${color}${getAdditionalClassNames([
        {
          check: iconStart,
          classNameSuffix: 'withStartIcon',
        },
        {
          check: iconEnd,
          classNameSuffix: 'withEndIcon',
        },
        {
          check: uppercase,
          classNameSuffix: 'uppercase',
        },
      ])}${className ? ` ${className}` : ''}`}
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled}
      onClick={handleClick}
      {...props}
    >
      {loading && !iconStart && !iconEnd && <Loader />}
      {!!iconStart && <div className={`${defaultClassName}__icon`}>{iconStart}</div>}
      <span>{children}</span>
      {!!iconEnd && <div className={`${defaultClassName}__icon`}>{iconEnd}</div>}
    </button>
  );
};

export default memo(forwardRef<HTMLButtonElement, ButtonProps>(Button));
