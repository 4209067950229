import { FC } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useInternationalization } from '@progress/kendo-react-intl';
import { checkIsDate } from 'shared/utils/dateFormatter';

interface IProps extends GridCellProps {
  format?: string;
}

const CellDateFormat: FC<IProps> = ({ dataItem, field, format = 'MMM d, yyyy' }) => {
  const intl = useInternationalization();
  const value = field ? dataItem[field] : '';
  const isDate = checkIsDate(value);

  return <td className="cellDateFormat">{isDate ? intl.formatDate(new Date(value), format) : value ?? ''}</td>;
};

export default CellDateFormat;
