import { DataExportingView } from 'shared/models/enums.dl';
import type { ExportingStatusResponseData } from 'shared/models/models.dl';
import DataExportingStatusService from 'shared/services/bl/dataExportingStatus.service';
import { Types } from './types';
import type * as Interfaces from './interfaces';
import { AsyncThunkActionType } from '../interfaces';

export const actions = {
  setIsExporting: (payload: Interfaces.DataExportingPayloadType) =>
    ({
      type: Types.SET_IS_EXPORTING,
      payload,
    } as const),
};

const thunks = {
  getExportingStatusData:
    (view: DataExportingView): AsyncThunkActionType<ExportingStatusResponseData> =>
    async (dispatch) => {
      const data = await DataExportingStatusService.getStatus(view);
      await dispatch(actions.setIsExporting({ view, data }));
      return data;
    },
};

export default {
  ...actions,
  ...thunks,
};
