import { ForwardRefRenderFunction, forwardRef } from 'react';
// import { useDispatch } from 'react-redux';
// import { toggleUserPanel } from '../../store/userPanel/actions';

import './UserAvatar.scss';

interface IProps {
  size?: 'sm' | 'lg';
  src?: string;
  disabled?: boolean;
  userName?: string;
}

const UserAvatar: ForwardRefRenderFunction<HTMLDivElement, IProps> = (
  { size = 'sm', src = '', userName = '', disabled = false },
  ref
) => {
  /* const dispatch = useDispatch();
  const handleToggleUserPanel = () => {
    if (disabled) return;
    dispatch(toggleUserPanel());
  }; */
  let userInitials =
    !!src || !userName
      ? ''
      : userName
          .replace(/[^a-zA-Z- ]/g, '')
          .replace(/\s+/g, ' ')
          .match(/\b\w/g)
          ?.join('') || '';

  if (userInitials.length > 3) {
    userInitials = userInitials[0] + userInitials[userInitials.length - 1];
  }

  return (
    <div
      className={`appUserAvatar appUserAvatar--${size}${disabled ? ' disabled' : ''}`}
      // onClick={handleToggleUserPanel}
      ref={ref}
    >
      {!src ? <span>{userInitials}</span> : <img src={src} alt={userName || 'User avatar'} />}
    </div>
  );
};

export default forwardRef<HTMLDivElement, IProps>(UserAvatar);
