import { checkIsMobileBrowser } from './utils/checkIsMobileDevice';
import { stopPropagation } from './utils/stopPropagation';
import { formatBytes } from './utils/formatBytes';
import { simpleSort } from './utils/simpleSort';
import * as formatDate from './utils/dateFormatter';
import { currencyFormatter } from './utils/currencyFormatter';
import { makeAdditionalClassNamesGenerator } from './utils/makeAdditionalClassNamesGenerator';
import {
  getCorrectTableDataFromResponse,
  getCorrectTableFiltersFromResponse,
  getCorrectChartDataFromResponse,
} from './utils/getCorrectTableDataFromResponse';
import { getSelectOptions } from './utils/getSelectOptions';
import { getOnlySumRows } from './utils/getOnlySumRows';
import { parseErrorResponse } from './utils/parseErrorResponse';
import { isEmptyObject } from './utils/isEmptyObject';

const Utils = {
  checkIsMobileBrowser,
  stopPropagation,
  formatBytes,
  simpleSort,
  formatDate,
  makeAdditionalClassNamesGenerator,
  currencyFormatter,
  getSelectOptions,
  getCorrectTableDataFromResponse,
  getCorrectTableFiltersFromResponse,
  getCorrectChartDataFromResponse,
  getOnlySumRows,
  parseErrorResponse,
  isEmptyObject,
};

export default Utils;
