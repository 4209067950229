import Utils from '../../shared/shared.utils';
import { Types } from './types';
import type * as Interfaces from './interfaces';

export default function sidebarReducer(
  state: Interfaces.StoreType = !Utils.checkIsMobileBrowser(),
  action: Interfaces.SidebarActions = {} as Interfaces.SidebarActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_IS_OPEN: {
      return action.payload;
    }
    default:
      return state;
  }
}
