import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Selectors } from 'store/selectors';
import SingleSelectControl, { DropDownListChangeEvent } from 'components/SingleSelectControl/SingleSelectControl';
import { DEFAULT_LOCALE, USER_LOCALE } from 'localization/helpers';
import { StoreActions } from 'store/actions';
import CustomHooks from 'shared/shared.hooks';
import { setToLocalStorage } from 'shared/utils/localStorage';
import LocalizationService from 'shared/services/bl/localization.service';
import IconTooltip from '../../IconTooltip';

const popupSettings = { className: 'SingleSelectPopup HeaderLanguageSelectPopup' };

export const LoginLanguageSelect: FC = () => {
  const { t } = useTranslation();
  const dispatch = CustomHooks.storeHooks.useAppDispatch();
  const locales = useSelector(Selectors.Localization.selectLocales);
  const isLoadedLocales = useSelector(Selectors.Localization.selectIsLoadedLocales);
  const isErrorLocales = useSelector(Selectors.Localization.selectIsErrorLocales);
  const isErrorLoginLocaleKeys = useSelector(Selectors.Localization.selectIsErrorLoginLocaleKeys);

  const isError = useMemo(() => isErrorLocales || isErrorLoginLocaleKeys, [isErrorLocales, isErrorLoginLocaleKeys]);

  const handleChange = useCallback(({ value }: DropDownListChangeEvent) => {
    dispatch(StoreActions.Localization.getLoginLocaleKeys(value.id));
    setToLocalStorage(USER_LOCALE, value);
  }, []);

  const defaultOption = useMemo(() => {
    const userLocale = LocalizationService.getLoginLocaleCode();
    return locales?.find((locale) => locale.id === userLocale) || DEFAULT_LOCALE;
  }, [locales]);

  return isLoadedLocales ? (
    <>
      {locales && !isError && (
        <SingleSelectControl
          data={locales}
          defaultOption={defaultOption}
          className="HeaderLanguageSelect"
          popupSettings={popupSettings}
          onChange={handleChange}
        />
      )}
      {isError && (
        <IconTooltip
          tooltipText={t('Common.LanguageDropdown.Message.Error')}
          position="bottom"
          icon={<SingleSelectControl defaultOption={DEFAULT_LOCALE} className="HeaderLanguageSelect" disabled />}
        />
      )}
    </>
  ) : null;
};
