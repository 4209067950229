import { Types } from './types';
import type * as Interfaces from './interfaces';

const initialState: Interfaces.StoreType = {
  isOpen: false,
  reload: {
    performanceHurdles: 0,
    shareRegister: 0,
    employeeStatus: 0,
    valuationReference: 0,
    files: 0,
    provisionalExpense: 0,
    customVestingSchedules: 0,
  },
};

export default function sidebarReducer(
  state = initialState,
  action: Interfaces.NotificationCenterActions = {} as Interfaces.NotificationCenterActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_IS_OPEN: {
      return { ...state, isOpen: typeof action.payload === 'boolean' ? action.payload : !state.isOpen };
    }
    case Types.RELOAD_RELATED_DATA: {
      return { ...state, reload: { ...state.reload, [action.payload]: state.reload[action.payload] + 1 } };
    }
    default:
      return state;
  }
}
