import { useEffect } from 'react';
import { ROUTES } from 'shared/constants/routes';
import { UserProfile } from 'shared/models/models.bl';
import { parseFromString } from 'shared/utils/parseFromString';
import { StoreName } from 'store/storeName';
import { useAppSelector } from './storeHooks';

export const useUserLoggedOutFromAnotherTabListener = (): void => {
  const isAuthenticated = useAppSelector(({ currentUser }) => !!currentUser.user);

  useEffect(() => {
    const storageListener = (e: StorageEvent): void => {
      if (!e.key) {
        // INFO: !e.key - if local/sessionstorage.clear();
        window.open(ROUTES.landing, '_self');
      } else if (e.key === StoreName.CurrentUser) {
        const oldUser = parseFromString<UserProfile>(e.oldValue);

        if (oldUser?.user || oldUser?.tokenObj) {
          const newUser = parseFromString<UserProfile>(e.newValue);
          if (!newUser?.user && !newUser?.tokenObj) {
            window.open(ROUTES.landing, '_self');
          }
        }
      }
    };

    const clearListener = () => {
      window.removeEventListener('storage', storageListener);
    };

    if (isAuthenticated) {
      window.addEventListener('storage', storageListener);
    } else {
      clearListener();
    }

    return clearListener;
  }, [isAuthenticated]);
};
