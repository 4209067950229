import { Types } from './types';
import type * as Interfaces from './interfaces';

const initialState = {
  templates: [],
  isTemplatesFetching: false,
} as Interfaces.StoreType;

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action: Interfaces.FilesActions = {} as Interfaces.FilesActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_TEMPLATE_FILES_FETCHING: {
      if (state.isTemplatesFetching === action.payload) return state;
      return { ...state, isTemplatesFetching: action.payload };
    }
    case Types.SET_TEMPLATE_FILES: {
      if (!state.templates.length && !action.payload.length) return { ...state, isTemplatesFetching: false };
      return { ...state, templates: action.payload, isTemplatesFetching: false };
    }
    default:
      return state;
  }
}
