import { FC, useMemo, memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import { ReactComponent as BellIcon } from 'assets/svg/bell/bell-in-circle-outlined.svg';
import CustomHooks from 'shared/shared.hooks';
import { Selectors } from 'store/selectors';
import { StoreActions } from 'store/actions';
import { Badge, BadgeContainer } from 'components/vendor/Badge';
import Loader from 'components/Loader/Loader';
import IconTooltip from 'components/IconTooltip';
import NotificationPlaceholder from '../NotificationPlaceholder';
import NotificationList from '../NotificationList/NotificationList';
import { NotificationStatus } from '../core/enum';
import type { INotificationListProps } from '../core/interface';

import './NotificationWrapper.scss';

const badgeNumberFormatter = new Intl.NumberFormat('en-AU', { notation: 'compact', compactDisplay: 'short' }).format;

const NotificationWrapper: FC<
  Omit<INotificationListProps, 'tooltipWrapper'> & { showUnreadQTY?: boolean; loading?: boolean }
> = ({ notifications, setNotifications, virtualized, showUnreadQTY, loading = false }) => {
  const notificationCenterWrapperRef = useRef<HTMLDivElement>(null);
  const dispatch = CustomHooks.storeHooks.useAppDispatch();
  const isOpen = CustomHooks.storeHooks.useAppSelector(Selectors.Main.selectIsNotificationCenterOpen);
  const { t } = useTranslation();
  const toggleCenter = () => {
    dispatch(StoreActions.NotificationCenter.toggle());
  };
  const closeCenter = () => {
    dispatch(StoreActions.NotificationCenter.toggle(false));
  };
  const unreadQTY = useMemo(
    () =>
      showUnreadQTY
        ? notifications.filter((item) => item.status !== NotificationStatus.Read).length
        : notifications.length,
    [notifications, showUnreadQTY]
  );
  return (
    <>
      <div className="NotificationIcon" onClick={toggleCenter}>
        <BadgeContainer style={{ display: 'flex' }}>
          <IconTooltip icon={<BellIcon />} tooltipText={t('NotificationCenter.Title')} position="bottom" />
          {!!unreadQTY && <Badge>{badgeNumberFormatter(unreadQTY)}</Badge>}
        </BadgeContainer>
      </div>
      <div
        ref={notificationCenterWrapperRef}
        className={`NotificationWrapper${isOpen ? ' open' : ''}${loading ? ' loading' : ''}`}
      >
        <header>
          <span className="NotificationWrapper__title">{t('NotificationCenter.Title')}</span>
          <CloseIcon onClick={closeCenter} />
        </header>
        {!notifications.length ? (
          <NotificationPlaceholder />
        ) : (
          <NotificationList
            notifications={notifications}
            setNotifications={setNotifications}
            virtualized={virtualized}
            tooltipWrapper={notificationCenterWrapperRef}
          />
        )}
        {loading && <Loader local />}
      </div>
    </>
  );
};

export default memo(NotificationWrapper);
