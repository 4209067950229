import type { AppState } from '../../interfaces';
import { ReportsStoreName } from '../storeName';
import type { StoreType } from './interfaces';

const currentStoreName = ReportsStoreName.TrueUp;

export const selectIsLoading = ({ reports }: AppState): StoreType['loading'] => reports[currentStoreName].loading;

export const selectIsCalculating = ({ reports }: AppState): StoreType['calculating'] =>
  reports[currentStoreName].calculating;

export const selectIsFiltering = ({ reports }: AppState): StoreType['filtering'] => reports[currentStoreName].filtering;

export const selectIsCalculationCompleted = ({ reports }: AppState): StoreType['isCalculationCompleted'] =>
  reports[currentStoreName].isCalculationCompleted;

export const selectInfoData = ({ reports }: AppState): StoreType['infoData'] => reports[currentStoreName].infoData;

export const selectTableData = ({ reports }: AppState): StoreType['tableData'] => reports[currentStoreName].tableData;

export const selectNewReportTableData = ({ reports }: AppState): StoreType['newReportTableData'] =>
  reports[currentStoreName].newReportTableData;

export const selectPreviousReportTableData = ({ reports }: AppState): StoreType['previousReportTableData'] =>
  reports[currentStoreName].previousReportTableData;
