import axios from 'axios';

import { ISelected } from 'components/MipTable/MipTable';
import ApiUrlsService from './api-urls.service';
import * as Models from '../../shared.models';

export default class ManageEmployeeContracts {
  static uploadManageEmployeeContractsFiles(
    files: File[],
    params: Models.BM.UploadManageEmployeeContracts
  ): Promise<any> {
    const formData = new FormData();

    files.forEach((file) => formData.append('file', file));
    (Object.keys(params) as Array<keyof Models.BM.UploadManageEmployeeContracts>).forEach((key) =>
      // @ts-ignore
      formData.append(key, params[key])
    );

    return axios.post(ApiUrlsService.uploadManageEmployeeContractsFiles(), formData).then(({ data }) => data);
  }

  static deleteManageEmployeeContractsTableRows(selectedData: ISelected): Promise<any> {
    return axios.post(ApiUrlsService.deleteManageEmployeeContractsTableRows(), selectedData).then(({ data }) => data);
  }

  static getManageEmployeeContractsTableData(
    data: Models.BM.ITableDataInfoRequest
  ): Promise<Models.BM.ResponseTableDataType<Models.BM.ManageEmployeeContractsTableData>> {
    return axios.post(ApiUrlsService.getManageEmployeeContractsTableData(), data).catch(() => null);
  }

  static getGrantPlans(): Promise<string[]> {
    return axios.get(ApiUrlsService.getGrantPlans()).then(({ data }) => data);
  }

  static getManageEmployeeContractsQTY(): Promise<number> {
    return axios.get(ApiUrlsService.getManageEmployeeContractsQTY()).then(({ data }) => data);
  }

  static getExtractedManageEmployeeContractData(uid: string): Promise<Models.BM.EmployeeContractDetailsData> {
    return axios.get(ApiUrlsService.getExtractedManageEmployeeContractData(uid)).then(({ data }) => data);
  }

  static saveManageEmployeeContractDetails(detailsData: Models.BM.EmployeeContractDetailsSave): Promise<any> {
    return axios.post(ApiUrlsService.saveManageEmployeeContractDetails(), detailsData).then(({ data }) => data);
  }
}
