import { useState, useCallback, useMemo, useEffect } from 'react';
import FileUploadingService from '../services/bl/fileUploading.service';
import type { IFileUploadingApiEntity } from '../models/models.bl';
import { FileUploadingState } from '../models/enums.dl';
import useIsMounted from './useIsMounted';

interface IState {
  processing: boolean;
  statusFetching: boolean;
  status?: FileUploadingState;
}

type UseFileUploadingStateReturnType = IState & {
  fetchStatus: () => Promise<void>;
};

export const useFileUploadingState = (type?: IFileUploadingApiEntity): UseFileUploadingStateReturnType => {
  const isMounted = useIsMounted();
  const [data, setData] = useState<IState>({
    processing: false,
    statusFetching: !!type,
  });

  const resolveStatus = useCallback(
    (status?: FileUploadingState) => {
      isMounted(() => {
        setData((prev) => {
          const processing = FileUploadingService.checkIsUploadingStatus(status);
          return prev.status !== status || prev.processing !== processing || prev.statusFetching
            ? {
                status,
                processing,
                statusFetching: false,
              }
            : prev;
        });
      });
    },
    [isMounted]
  );

  const fetchStatus = useCallback(async () => {
    try {
      if (!type) throw new Error();
      setData((prev) => (prev.statusFetching ? prev : { ...prev, statusFetching: true }));
      const status = await FileUploadingService.getFileUploadingState(type);
      resolveStatus(status);
    } catch {
      resolveStatus();
    }
  }, [type, resolveStatus]);

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  const result = useMemo(() => ({ ...data, fetchStatus }), [data, fetchStatus]);

  return result;
};
