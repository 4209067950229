import { FC, useRef, useState, useEffect, memo } from 'react';
import { Input as VendorInput, InputProps, InputChangeEvent as ChangeEvent } from '@progress/kendo-react-inputs';
import { Error, Label } from '@progress/kendo-react-labels';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import Utils from 'shared/shared.utils';

import './Input.scss';

const inputWrapperClassName = 'VendorInput__Wrapper';

export interface CustomVendorInputProps {
  startIcon?: any;
  endIcon?: any;
  isClearable?: boolean;
  labelTootip?: any;
  isRequiredIcon?: boolean;
  optional?: string;
  customMaxLength?: number;
}

export type InputChangeEvent = ChangeEvent;

type IProps = CustomVendorInputProps & InputProps;

const getAdditionalClassNames = Utils.makeAdditionalClassNamesGenerator(inputWrapperClassName);

const Input: FC<IProps> = ({
  startIcon,
  endIcon,
  isClearable: showClearIcon = true,
  validationMessage = '',
  label,
  disabled,
  onChange,
  value: valueProp,
  labelTootip,
  isRequiredIcon = false,
  optional = true,
  customMaxLength,
  ...props
}) => {
  const inputChangeEventRef = useRef<InputChangeEvent>();
  const inputRef = useRef<VendorInput>(null);
  const [value, setValue] = useState<InputProps['value']>(valueProp);
  const isClearable = showClearIcon && !disabled;
  const handleChange = (e: InputChangeEvent) => {
    inputChangeEventRef.current = e;
    // INFO: prevent changing on scroll for input type=number
    if (!e?.nativeEvent?.inputType) return;
    setValue(e.value);
    onChange?.(e);
  };
  const handleClear = () => {
    if (!isClearable) return;
    inputRef.current?.focus();
    setValue('');
    onChange?.(
      inputChangeEventRef.current
        ? { ...inputChangeEventRef.current, value: '' }
        : ({ value: '', target: { name: props.name || '' } } as InputChangeEvent)
    );
  };
  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  return (
    <>
      {!!label && (
        <Label
          className={`${inputWrapperClassName}__label`}
          editorId={props.id}
          editorValid={props.valid}
          editorDisabled={disabled}
        >
          {label}
          {labelTootip} {(props.required || isRequiredIcon) && <span className="required">*</span>}
          {optional}
        </Label>
      )}
      <div
        className={`${inputWrapperClassName}${getAdditionalClassNames([
          {
            check: startIcon,
            classNameSuffix: 'prepend',
          },
          {
            check: endIcon,
            classNameSuffix: 'append',
          },
          {
            check: isClearable,
            classNameSuffix: 'clear',
          },
          {
            check: validationMessage,
            classNameSuffix: 'error',
          },
        ])}`}
      >
        <VendorInput
          ref={inputRef}
          {...props}
          maxLength={props.maxLength || customMaxLength}
          value={valueProp ?? value}
          onChange={handleChange}
          disabled={disabled}
          autoComplete="off"
        />
        {!!isClearable && (
          <div className={`${inputWrapperClassName}__clear__icon`} onClick={handleClear}>
            <CloseIcon />
          </div>
        )}
        {!!startIcon && <div className={`${inputWrapperClassName}__prepend__icon`}>{startIcon}</div>}
        {!!endIcon && <div className={`${inputWrapperClassName}__append__icon`}>{endIcon}</div>}
        <Error
          className={`${inputWrapperClassName}__error__text ${customMaxLength ? 'maxLength' : ''}`}
          id={`${props.id}_error`}
        >
          {validationMessage}
        </Error>
      </div>
    </>
  );
};

export default memo(Input);
