import { FC } from 'react';
import CustomHooks from 'shared/shared.hooks';
import { Selectors } from 'store/selectors';
import Loader from 'components/Loader/Loader';

const GlobalRequestLoader: FC = () => {
  const isLoading = CustomHooks.storeHooks.useAppSelector(Selectors.Main.selectGlobalLoader);
  return isLoading ? <Loader /> : null;
};

export default GlobalRequestLoader;
