import { FC, useState, useRef, useCallback, MouseEvent, ReactNode, RefObject } from 'react';
import CustomHooks from '../../shared/shared.hooks';

import './IconMenu.scss';

const defaultClassName = 'IconMenu';

interface IChildrenProps {
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  menuRef: RefObject<HTMLDivElement>;
  triggerRef: RefObject<HTMLDivElement>;
}

interface IProps {
  icon: ReactNode;
  className?: string;
  children?: ({ onClose, isOpen }: IChildrenProps) => ReactNode;
}

const IconMenu: FC<IProps> = ({ icon, className, children }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleMenu = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  }, []);

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  const handleOpenMenu = () => {
    setIsOpen(true);
  };

  CustomHooks.useOnClickOutside(menuRef, handleCloseMenu, triggerRef);

  return (
    <div className={`${defaultClassName}${className ? ` ${className}` : ''}`}>
      <div
        onClick={handleToggleMenu}
        className={`${defaultClassName}__trigger${className ? ` ${className}__trigger` : ''}`}
        ref={triggerRef}
      >
        {icon}
      </div>
      <div
        className={`${defaultClassName}__menu${className ? ` ${className}__menu` : ''}${
          isOpen ? ` ${defaultClassName}__menu__open${className ? ` ${className}__menu__open` : ''}` : ''
        }`}
        ref={menuRef}
      >
        {children?.({ onClose: handleCloseMenu, onOpen: handleOpenMenu, isOpen, menuRef, triggerRef })}
      </div>
    </div>
  );
};

export default IconMenu;
