import * as SummaryTotal from './summaryTotal/selectors';
import * as DepartmentView from './departmentView/selectors';
import * as EmployeeView from './employeeView/selectors';
import * as GeographicalView from './geographicalView/selectors';
import * as PlanView from './planView/selectors';
import * as ProvisionalExpense from './provisionalExpense/selectors';
import * as TrueUp from './trueUp/selectors';
import * as ExpenseOutlookView from './expenseOutlookView/selectors';

export const Reports = {
  SummaryTotal,
  DepartmentView,
  EmployeeView,
  GeographicalView,
  PlanView,
  ProvisionalExpense,
  TrueUp,
  ExpenseOutlookView,
};
