import { FC } from 'react';

import './Loader.scss';

interface IProps {
  local?: boolean;
  absolute?: boolean;
}

const Loader: FC<IProps> = ({ local, absolute }) => {
  return (
    <>
      <div className={`overlay ${local ? 'local' : ''} ${absolute ? 'absolute' : ''}`}>
        <div className="sk-circle">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </>
  );
};

export default Loader;
