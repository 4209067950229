import { useMemo } from 'react';
import { Selectors } from '../../store/selectors';
import type * as Models from '../shared.models';
import { useAppSelector } from './storeHooks';

export const useDefaultFilterOption = (
  filterName: 'date' | 'plan' | 'location'
): Models.DM.SelectOption | undefined => {
  const filterIdx = filterName === 'date' ? 0 : filterName === 'plan' ? 1 : 2;
  const summaryTotalFilters = useAppSelector(Selectors.Reports.SummaryTotal.selectTableFilters);
  const summaryTotalFirstFilterId = summaryTotalFilters?.[1]?.id; // 1 because 0 is 'All grants'
  const defaultFilterOption: Models.DM.SelectOption | undefined = useMemo(() => {
    if (!summaryTotalFirstFilterId) return undefined;
    const grant = `${summaryTotalFirstFilterId}`; // format: date - plan - location
    const filter = grant.split(' - ')[filterIdx] || '';
    return !filter ? undefined : { id: filter, text: filter };
  }, [summaryTotalFirstFilterId, filterIdx]);
  return defaultFilterOption;
};
