import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsLoadedLocaleKeys } from 'store/localization/selectors';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'OIDCAuth/AuthProvider/authProvider';
import AuthButton from '../../components/Layout/Header/AuthActions/AuthButton/AuthButton';
import landingImg from '../../assets/img/landing.png';
import Feature1 from '../../assets/svg/Feature1.svg';
import Feature2 from '../../assets/svg/Feature2.svg';
import Feature3 from '../../assets/svg/Feature3.svg';

import './Landing.scss';

const Landing: FC = () => {
  const { t } = useTranslation();
  const isLoadedLocaleKeys = useSelector(selectIsLoadedLocaleKeys);
  const { instance } = useMsal();
  const signinRedirect = async () => {
    await instance.loginRedirect(loginRequest);
  };

  const handleRegister = () => {
    const link = (window as any)?.ApiConfig.REACT_APP_REGISTRATION_URL || '';
    if (link) window.open(link, '_self');
  };
  return (
    <div className="landing">
      <div className="landing--content">
        {isLoadedLocaleKeys && (
          <>
            <p className="landing--content-header">{t('LoginPage.Title')}</p>
            <p className="landing--content-info">{t('LoginPage.Description')}</p>
            <div className="landing--content-actions">
              <AuthButton colorVariant="transparent" classStyle="login-button" onClick={signinRedirect}>
                {t('Header.LogInButton')}
              </AuthButton>
              <AuthButton colorVariant="primary" classStyle="register-button" onClick={handleRegister}>
                {t('Header.RegisterButton')}
              </AuthButton>
            </div>

            <div className="landing--content-feature-section">
              <span className="main-text">{t('LoginPage.Keyfeatures.Title')}</span>

              <div className="landing--content-features-subsection1">
                <img alt="Manage Grants" src={Feature1} />
                <span>{t('LoginPage.Keyfeatures.ManageGrants')}</span>
              </div>
              <div className="landing--content-features-subsection2">
                <img alt="Audit Trail" src={Feature2} />
                <span>{t('LoginPage.keyfeatures.AuditTrail')}</span>
              </div>
              <div className="landing--content-features-subsection3">
                <img alt="Real Time Information" src={Feature3} />
                <span>{t('LoginPage.Keyfeatures.Information')}</span>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="landing--image">
        <img src={landingImg} alt="" />
      </div>
    </div>
  );
};
export default Landing;
