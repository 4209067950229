import axios from 'axios';
import type {
  ExpenseReportProgressDataResponse,
  IExpensesCalculationValidationResponse,
  IExportSummaryReportRequest,
} from 'shared/models/models.bl';
import ApiUrlsService from 'shared/services/bl/api-urls.service';

export default class Expenses {
  static getCalculationStatus(): Promise<ExpenseReportProgressDataResponse> {
    return axios.get(ApiUrlsService.getExpenseCalculationStatus()).then(({ data }) => data);
  }

  static validateCalculation(): Promise<IExpensesCalculationValidationResponse> {
    return axios.get(ApiUrlsService.validateExpenseCalculation()).then(({ data }) => data);
  }

  static startCalculation(): Promise<void> {
    return axios.post(ApiUrlsService.startExpenseCalculation()).then(({ data }) => data);
  }

  static exportCalculation(reportUId: string, body: IExportSummaryReportRequest): Promise<void> {
    return axios.post(ApiUrlsService.exportExpensesCalculation(reportUId), body);
  }

  static exportDetailedCalculation(reportUId: string): Promise<void> {
    return axios.post(ApiUrlsService.exportDetailedExpensesCalculation(), null, { params: { reportUId } });
  }

  static cancelExpensesCalculation(): Promise<void> {
    return axios.put(ApiUrlsService.cancelExpensesCalculation()).then(({ data }) => data);
  }
}
