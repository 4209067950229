import { useCallback, useState, useEffect, useMemo } from 'react';
import { Selectors } from 'store/selectors';
import type { AppState } from 'store/interfaces';
import { IRowData } from 'components/Table/interfaces';
import { DropDownListChangeEvent } from 'components/vendor/SingleSelect';
import { useTableActiveState } from './useTableActiveState';
import * as Models from '../shared.models';
import Utils from '../shared.utils';
import { useAppSelector } from './storeHooks';
import { onlySumRows } from '../constants/onlySumRows';

type ReportTimeStamp = Models.BM.AvailableReport['reportTimeStamp'] | null;
const onlySumRowsDefault: any[] = [];

export const useSummaryReportViewState = <T extends IRowData>(
  selectCurrentReportTimeStamp: (state: AppState) => ReportTimeStamp,
  services?: {
    exportData?: (reportUId: string, data: any) => Promise<any>;
    fetchData?: (generatedReportUId: string, data: any) => Promise<Models.BM.ResponseTableDataType<T>>;
    fetchChartData?: (generatedReportTimeStamp: string, filter: string) => Promise<string[] | null>;
    fetchFilters?: (generatedReportTimeStamp: string) => Promise<string[] | null>;
  },
  options?: {
    excludeSumRowsFromFilteredResult?: boolean;
    exportWithFilter?: boolean;
    fetchDataOnMount?: boolean;
    filterPlaceholder?: string;
    defaultFilter?: string | number;
    filterWithoutFetching?: ((data: T[], filter: Models.DM.SelectOption['id']) => T[]) | string;
    selectFiltersFromStore?: (state: AppState) => Models.DM.SelectOption[];
    selectDataFromStore?: (state: AppState) => T[];
    selectChartDataFromStore?: (state: AppState) => string[] | any[]; // TODO fix any type
    selectHeadersFromStore?: (state: AppState) => Models.BM.ColumnConfiguration<T>[] | null;
    selectDataLoadingFromStore?: (state: AppState) => boolean;
    selectOnlySumRowsFromStore?: (state: AppState) => T[];
  }
) => {
  const { fetchDataOnMount = true } = options || {};
  const filtersFromStore = useAppSelector(options?.selectFiltersFromStore || (() => null));
  const dataFromStore = useAppSelector(options?.selectDataFromStore || (() => null));
  const chartDataFromStore = useAppSelector(options?.selectChartDataFromStore || (() => null));
  const headersFromStore = useAppSelector(options?.selectHeadersFromStore || (() => null));
  const onlySumRowsFromStore = useAppSelector(options?.selectOnlySumRowsFromStore || (() => null));
  const onlySumRowsList = onlySumRowsFromStore || (onlySumRowsDefault as T[]);
  const loadingFromStore = useAppSelector(options?.selectDataLoadingFromStore || (() => false));
  const generatedReportTimeStamp = useAppSelector(Selectors.Reports.SummaryTotal.selectGeneratedReport);
  const currentReportTimeStamp = useAppSelector(selectCurrentReportTimeStamp);
  const [showSumsOnly, setShowSumsOnly] = useState<boolean>(false);
  const [localFilters, setFilters] = useState<Models.DM.SelectOption[]>(
    options?.filterPlaceholder ? [{ id: '', text: options.filterPlaceholder }] : []
  );
  const [filter, setFilter] = useState<Models.DM.SelectOption['id']>(options?.defaultFilter || '');
  const [filtersLoading, setFiltersLoading] = useState<boolean>(false);
  const [localChartData, setChartData] = useState<string[]>([]);
  const [chartDataLoading, setChartDataLoading] = useState<boolean>(false);
  const useTableActiveStateProp = useMemo(
    () => ({
      exportData: services?.exportData,
      options: {
        fetchOnMount: fetchDataOnMount,
      },
    }),
    [fetchDataOnMount, services?.exportData]
  );
  const {
    data: localData,
    headers: localHeaders,
    // setDataAndHeaders,
    dataLoading: localDataLoading,
    // setDataLoading,
    exportData,
    dataExporting,
    resetTableAfterFileUploadedRef,
  } = useTableActiveState<T, void>(useTableActiveStateProp);

  const handleExportData = useCallback(
    exportData(null, generatedReportTimeStamp, options?.exportWithFilter ? filter : undefined),
    [exportData, generatedReportTimeStamp, options?.exportWithFilter, filter]
  );

  const chartData = chartDataFromStore || localChartData;
  const data = dataFromStore || localData;
  const headers = headersFromStore || localHeaders;
  const filters = filtersFromStore || localFilters;
  const dataLoading = loadingFromStore || localDataLoading;

  const filtersDisabled =
    filtersLoading ||
    dataLoading ||
    !generatedReportTimeStamp ||
    // (filters.length === 1 && !filters[0].id) ||
    !filters.length;
  const toggleDisabled = !data?.length || dataLoading || !headers?.[0];
  const exportDisabled = !data?.length || dataLoading || dataExporting;

  const onToggleTableState = useCallback((value: boolean) => {
    resetTableAfterFileUploadedRef.current = true;
    setShowSumsOnly(value);
  }, []);

  const getData = useCallback(
    (currentTimeStamp: string, currentFilter: Models.DM.SelectOption['id'], withFilters = false): Promise<void> => {
      if (withFilters && services?.fetchFilters) {
        setFiltersLoading(true);
        services
          .fetchFilters(currentTimeStamp)
          .then((responseData) => {
            const filterSetter = Utils.getCorrectTableFiltersFromResponse(responseData);
            setFilters(filterSetter);
          })
          .finally(() => {
            setFiltersLoading(false);
          });
      }
      if (services?.fetchChartData) {
        setChartDataLoading(true);
        services
          .fetchChartData(currentTimeStamp, currentFilter as string)
          .then((responseData) => {
            const chartDataSetter = Utils.getCorrectChartDataFromResponse(responseData);
            setChartData(chartDataSetter);
          })
          .finally(() => {
            setChartDataLoading(false);
          });
      }

      return Promise.resolve();
    },
    []
  );

  const onChangeFilter = useCallback(
    ({ value }: DropDownListChangeEvent) => {
      if (options?.filterWithoutFetching || options?.exportWithFilter) setFilter(value?.id || '');
      if (!options?.filterWithoutFetching && generatedReportTimeStamp)
        getData(generatedReportTimeStamp as string, value?.id || '');
    },
    [generatedReportTimeStamp]
  );

  const onlySumRowList = useMemo(() => {
    if (!headers?.[0]?.name) return [];
    return onlySumRowsFromStore ? onlySumRowsList : Utils.getOnlySumRows<T>(data, headers[0].name);
  }, [headers?.[0]?.name, onlySumRowsFromStore, onlySumRowsList, data]);

  const resultData = useMemo(() => {
    if (!data) return [];
    if (!data.length) return data;
    if (!!showSumsOnly && !!headers?.[0]?.name) return onlySumRowList;
    if (!!options?.filterWithoutFetching && !!filter) {
      let result = data;
      if (typeof options.filterWithoutFetching === 'function') result = options.filterWithoutFetching(result, filter);
      else
        result = result.filter(
          (row) => `${row[options.filterWithoutFetching as keyof typeof row]}`.indexOf(`${filter}`) !== -1
        );
      return options?.excludeSumRowsFromFilteredResult ? result : [...result, ...onlySumRowList];
    }
    return data;
  }, [
    showSumsOnly,
    onlySumRowList,
    data,
    headers?.[0]?.name,
    options?.filterWithoutFetching,
    filter,
    options?.excludeSumRowsFromFilteredResult,
  ]);

  useEffect(() => {
    if (
      fetchDataOnMount &&
      generatedReportTimeStamp &&
      (generatedReportTimeStamp !== currentReportTimeStamp || (!data?.length && !dataLoading))
    ) {
      getData(generatedReportTimeStamp, filter, true);
    }
  }, [generatedReportTimeStamp, fetchDataOnMount, currentReportTimeStamp]);

  const preventSortValues = useMemo(() => Object.values(onlySumRows), []);
  const filterOption = useMemo(() => filters.find(({ id }) => id === filter), [filters, filter]);

  return {
    data: resultData,
    fullData: data,
    headers,
    filter,
    filterOption,
    filters,
    exportData: handleExportData,
    onChangeFilter,
    onToggleTableState,
    tableStateToggled: showSumsOnly,
    filtersDisabled,
    toggleDisabled,
    exportDisabled,
    dataLoading,
    dataExporting,
    filtersLoading,
    resetTableStateRef: resetTableAfterFileUploadedRef,
    onlySumRows,
    chartData,
    chartDataLoading,
    preventSortValues,
    onlySumRowList,
  };
};
