import axios, { Canceler } from 'axios';
import ApiUrlsService from './api-urls.service';
import { GridColumnConfig, defaultPagingRequestData } from '../../configs/grid.config';
import type {
  IPaginatedTableResponse,
  IPaginatedTableResponseWithHeaders,
  ColumnConfiguration,
  IProvisionalExpenseTableDataResponse,
  IProvisionalExpenseFiltersResponse,
  IProvisionalExpenseCalculationResult,
  IUploadFileResponse,
  IProvisionalExpenseTableDataRequest,
  IProvisionalExpenseFiltersRequest,
  IProvisionalExpenseChartDataResponse,
} from '../../models/models.bl';

enum CancelTokenKey {
  GetTableData = 'GetTableData',
  GetChartData = 'GetChartData',
}

const CancelTokens: Record<CancelTokenKey, Canceler | null> = {
  [CancelTokenKey.GetTableData]: null,
  [CancelTokenKey.GetChartData]: null,
};

const cancelPrevRequest = (tokenKey: CancelTokenKey) => {
  CancelTokens[tokenKey]?.();
  const source = axios.CancelToken.source();
  CancelTokens[tokenKey] = source.cancel;
  return source;
};

const getPaginatedData = async <T>({
  url,
  pagingData,
  cancelTokenKey,
  initialTableConfig,
}: {
  url: string;
  pagingData?: IProvisionalExpenseTableDataRequest;
  initialTableConfig: ColumnConfiguration<T>[];
  cancelTokenKey?: CancelTokenKey;
}): Promise<IPaginatedTableResponseWithHeaders<T>> => {
  const source = cancelTokenKey ? cancelPrevRequest(cancelTokenKey) : null;
  const body = pagingData || defaultPagingRequestData;
  const { data } = await axios.post<IPaginatedTableResponse<T>>(url, body, {
    cancelToken: source?.token,
  });

  return {
    ...data,
    tableData: {
      data: data.tableData,
      header: initialTableConfig,
    },
  };
};

export default class ProvisionalExpense {
  static async getTableData(
    reportUId: string,
    pagingData?: IProvisionalExpenseTableDataRequest
  ): Promise<IPaginatedTableResponseWithHeaders<IProvisionalExpenseTableDataResponse>> {
    const data = await getPaginatedData<IProvisionalExpenseTableDataResponse>({
      url: ApiUrlsService.getProvisionalExpensePaginatedTableData(reportUId),
      pagingData,
      cancelTokenKey: CancelTokenKey.GetTableData,
      initialTableConfig: GridColumnConfig.ProvisionalExpenseConfig,
    });
    return data;
  }

  static async getChartData(
    reportUId: string,
    filter?: IProvisionalExpenseFiltersRequest
  ): Promise<IProvisionalExpenseChartDataResponse[]> {
    const source = cancelPrevRequest(CancelTokenKey.GetChartData);
    const { data } = await axios.post<IProvisionalExpenseChartDataResponse[]>(
      ApiUrlsService.getProvisionalExpenseChartData(reportUId),
      filter,
      { cancelToken: source.token }
    );
    return data || [];
  }

  static getFilters(reportUId: string): Promise<IProvisionalExpenseFiltersResponse> {
    return axios.get(ApiUrlsService.getProvisionalExpenseFilters(reportUId)).then(({ data }) => data);
  }

  static uploadFile(file: File, reportUId: string): Promise<IUploadFileResponse> {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ApiUrlsService.uploadProvisionalExpenseTableFile(reportUId), formData).then(({ data }) => data);
  }

  static delete(reportUId: string): Promise<unknown> {
    return axios.delete(ApiUrlsService.deleteProvisionalExpenseTableData(reportUId)).then(({ data }) => data);
  }

  static export(reportUId: string): Promise<unknown> {
    return axios.post(ApiUrlsService.exportProvisionalExpenseTableData(reportUId)).then(({ data }) => data);
  }

  static calculate(reportUId: string): Promise<IProvisionalExpenseCalculationResult> {
    return axios.post(ApiUrlsService.calculateProvisionalExpenseData(reportUId)).then(({ data }) => data);
  }
}
