import history from 'appHistory';
import UserDataService from '../dl/user.data.service';
import { ROUTES, publicRoutes } from '../../constants/routes';
import AuthenticationService from './authentication.service';
import * as Models from '../../shared.models';
import { UserProfile } from '../../models/models.bl';
import { getFromLocalStorage, removeFromLocalStorage } from '../../utils/localStorage';
import { OIDCUser } from '../../../OIDCAuth/models/OIDCUser';

import { store } from '../../../store';
import { setUser } from '../../../store/user/actions';

export default class AuthorizationService {
  static setUser(user: Models.DM.UserGetDto): void {
    store.dispatch(setUser(user));
  }

  static getUser(user?: Models.DM.UserGetDto): Models.DM.UserGetDto | null {
    return user || store.getState().currentUser.user || getFromLocalStorage<UserProfile>('currentUser')?.user || null;
  }

  static isPreparer = (user?: Models.DM.UserGetDto): boolean => {
    const currentUser = this.getUser(user);
    return !!currentUser && currentUser.role === Models.DE.UserRole.Preparer;
  };

  static isReviewer = (user?: Models.DM.UserGetDto): boolean => {
    const currentUser = this.getUser(user);
    return !!currentUser && currentUser.role === Models.DE.UserRole.Reviewer;
  };

  static isAuthorized(user?: Models.DM.UserGetDto): boolean {
    const currentUser: Models.DM.UserGetDto = user || (store.getState().currentUser.user as Models.DM.UserGetDto);

    return !!currentUser;
  }

  static fetchUser = (): Promise<void> => {
    return UserDataService.getUserProfile().then(this.onSuccessfulAuth).catch(this.onUnSuccessfulAuth);
  };

  static resolveToken = (tokenObj: OIDCUser): void => {
    const isRefreshedToken = getFromLocalStorage<string>('isRefreshToken');

    removeFromLocalStorage('isRefreshToken');
    AuthenticationService.setToken(tokenObj);

    if (!isRefreshedToken || !this.isAuthorized()) {
      this.fetchUser();
    }
  };

  private static onSuccessfulAuth(user: Models.DM.UserGetDto): void {
    store.dispatch(setUser(user));
    const currentRoute = history.location.pathname as ROUTES;
    const whitlistedSuffixEmailAddress = '@deloitte.';
    if (user?.touSignDate != null || user.email.includes(whitlistedSuffixEmailAddress)) {
      if (publicRoutes.includes(currentRoute)) history.replace(ROUTES.home);
    } else {
      window.location.href = (window as any)?.ApiConfig?.ASSURANCE_TOU_URL + user?.countryCode;
    }
  }

  private static onUnSuccessfulAuth(): void {
    AuthenticationService.logOut();
    removeFromLocalStorage('isAuthenticationInProgress');
    history.replace(ROUTES.unauthorized);
  }
}
