import { UserSettings } from '../../shared/models/models.dl';
import { currentUserBrowserStorage } from '../browserStorage';
import { StoreName } from '../storeName';
import { Types } from './types';
import type * as Interfaces from './interfaces';

const userFromBrowserStorage = currentUserBrowserStorage.getItem(StoreName.CurrentUser);

const initialState: Interfaces.StoreType = userFromBrowserStorage
  ? (JSON.parse(userFromBrowserStorage) as Interfaces.StoreType)
  : {
      user: null,
      tokenObj: null,
    };

export default function currentUserReducer(
  state: Interfaces.StoreType = initialState,
  action: Interfaces.CurrentUserActions = {} as Interfaces.CurrentUserActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_USER:
    case Types.SET_TOKEN: {
      const newState = {
        ...state,
        ...action.payload,
      };
      currentUserBrowserStorage.setItem(StoreName.CurrentUser, JSON.stringify(newState));
      return newState;
    }
    case Types.SET_SETTINGS: {
      if (!state.user) return state;
      const newState = {
        ...state,
        user: {
          ...state.user,
          settings: {
            ...(state.user.settings || {}),
            ...action.payload,
          } as UserSettings,
        },
      };
      currentUserBrowserStorage.setItem(StoreName.CurrentUser, JSON.stringify(newState));
      return newState;
    }
    default:
      return state;
  }
}
