import { FC, ReactElement } from 'react';
import { Label } from '@progress/kendo-react-labels';
import './Label.scss';

export interface ILabelProps {
  label: string;
  optional?: string;
  isRequiredIcon?: boolean;
  iconStart?: ReactElement;
  iconEnd?: ReactElement;
  valid?: boolean;
  className?: string;
}

const LabelText: FC<ILabelProps> = ({
  label,
  valid,
  optional,
  isRequiredIcon = true,
  iconStart,
  iconEnd,
  className = '',
}) => {
  return (
    <Label editorValid={valid}>
      <h3 className={`label ${className}`}>
        {iconStart}
        {label} {iconEnd} {isRequiredIcon && <span className="label__required">*</span>}
        {optional}
      </h3>
    </Label>
  );
};

export default LabelText;
