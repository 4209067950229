import * as Models from '../../shared.models';

export default class ValidatorService {
  static validateField(formControl: Models.BM.FormControl, fieldName: string, value: any): Models.BM.ValidationData {
    const vData: Models.BM.ValidationData = new Models.BM.ValidationData();
    vData.isValid = true;

    if (!formControl) {
      return vData;
    }

    if (formControl.validators) {
      formControl.validators.forEach((validator: Models.BM.IValidator) => {
        const v: Models.BM.ValidationData = validator.validate(value);

        if (!v.isValid) {
          vData.isValid = false;
          vData.errorText = vData.errorText.concat(v.errorText);
        }
      });
    }

    return vData;
  }
}
