import { FC, MouseEvent } from 'react';
import Button, { ButtonColor } from '../../../../Button';

import './AuthButton.scss';

interface IProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  colorVariant: ButtonColor;
  classStyle: string;
}

const AuthButton: FC<IProps> = ({ onClick, children, colorVariant, classStyle }) => (
  <Button styleType="text" color={colorVariant} className={classStyle} onClick={onClick}>
    {children}
  </Button>
);

export default AuthButton;
