import { OIDCROUTES, OIDCRoutesList } from '../../OIDCAuth/Routing/routes';

export enum ROUTES {
  landing = '/',
  home = '/home',
  shareRegister = '/share-register',
  performanceHurdles = '/performance-hurdles',
  manageEmployeeContracts = '/extract-share-register-information',
  employeeContractDetails = '/employee-contract-extracted-details',
  customVestingSchedules = '/custom-vesting-schedules',
  grantValuation = '/grant-valuation',
  employeeStatus = '/employee-status',
  sbpExpenses = '/sbp-expenses',
  sbpExpenseReports = '/sbp-expenses-reports',
  sbpExpensesJournal = '/sbp-journal',
  sbpExpensesProvisional = '/sbp-expenses-reports#provisional',
  sbpExpensesTrueUp = '/sbp-expenses-reports#trueUp',
  files = '/files',
  logs = '/logs',
  unauthorized = '/unauthorized',

  users = '/users',

  termsOfUse = '/termsOfUse',
}

export const publicRoutes: Array<ROUTES | OIDCROUTES> = [...OIDCRoutesList, ROUTES.landing, ROUTES.unauthorized];
