import { applyMiddleware, compose, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import mainReducer from './mainReducer';
import { AppActions, AppState } from './interfaces';

const enhancers: Array<any> = [];
const windowIfDefined = typeof window === 'undefined' ? null : (window as any);

// eslint-disable-next-line no-underscore-dangle
if (windowIfDefined?.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-underscore-dangle
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}

export const store = createStore(
  mainReducer,
  compose(applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>), ...enhancers)
);
