import { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ReactComponent as QuestionInCircleOutlined } from 'assets/svg/question/questionInCircleOutlined.svg';
import IconMenu from 'components/IconMenu/IconMenu';
import IconTooltip from 'components/IconTooltip';
import LocalizationService from 'shared/services/bl/localization.service';
import './HelpCenterMenuIcon.scss';

const className = 'helpCenter';

const DescriptionLink: FC<{ onClick: () => void }> = ({ children }) => {
  const localeCode = LocalizationService.getLocaleCode();
  const fileName = (window as any).ApiConfig.HELP_CENTER_SUPPORT;
  const link = `helpcenter/${localeCode}/${fileName}`;

  function base64ToArrayBuffer(base64: string) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  const handlePdfClick = async () => {
    const response: any = await LocalizationService.getAsset(link);
    const data = base64ToArrayBuffer(response);
    const file = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(file);
    window.open(url);
  };

  return (
    <span className={className} onClick={() => handlePdfClick()}>
      {children}
    </span>
  );
};

const HelpCenterMenuIcon: FC = () => {
  const { t } = useTranslation();

  return (
    <IconMenu
      className={className}
      icon={<IconTooltip icon={<QuestionInCircleOutlined />} tooltipText={t('Header.Help')} position="bottom" />}
    >
      {({ onClose }) => (
        <>
          <span className={`${className}__header`}>{t('HelpCenter.Title')}</span>
          <span className={`${className}__text`}>
            <Trans i18nKey="HelpCenter.Description" t={t} components={[<DescriptionLink onClick={onClose} />]} />
          </span>
        </>
      )}
    </IconMenu>
  );
};

export default HelpCenterMenuIcon;
