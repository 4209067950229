import { Types } from './types';
import type * as Interfaces from './interfaces';

export default function sidebarReducer(
  state: Interfaces.StoreType = false,
  action: Interfaces.UserPanelActions = {} as Interfaces.UserPanelActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_IS_OPEN: {
      return typeof action.payload === 'boolean' ? action.payload : !state;
    }
    default:
      return state;
  }
}
