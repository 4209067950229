export enum HttpStatus {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  PreconditionFailed = 412,
  ServerError = 500,
  ServiceUnavailable = 503,
}

export enum ApiEntity {
  Log = 'log',
  User = 'user',
  ShareRegister = 'ShareRegister',
  CustomVestingSchedule = 'CustomVestingSchedule',
  GenericVestingSchedule = 'GenericVestingSchedule',
  GrantValuation = 'valuation',
  EmployeeStatus = 'employee/status',
  ActivityLogs = 'logs',
  ExpenseCalculations = 'expenseCalculations',
  Reports = 'reports',
  ProvisionExpense = 'provisionExpense',
  TrueUp = 'TrueUp',
  JournalEntries = 'journalEntries',
  Files = 'files',
  Notifications = 'notification',
  Localization = 'localization',
  GenerateSbpExpenses = 'generateSbpExpenses',
  PerformanceHurdles = 'PerformanceHurdle',
  ManageEmployeeContracts = 'employeeContracts',
}

export enum ReportsApiEntity {
  MyIncentivePlanSummary = 'MyIncentivePlanSummary',
  SummaryTotal = 'summaryTotal',
  Geographical = 'geographical',
  Plan = 'plan',
  Employee = 'employee',
  Detailed = 'detailed',
  Department = 'department',
  ExpenseOutlook = 'expenseOutlook',
}
