import Expenses from './expenses/actions';
import Files from './files/actions';
import FileUploading from './fileUploading/actions';
import Localization from './localization/actions';
import { Reports } from './reports/actions';
import { toggleUserPanel } from './userPanel/actions';
import { toggleNotificationCenter, reloadRelatedData } from './notificationCenter/actions';
import DataExportingStatus from './dataExporting/actions';
import ManagerEmployeeContracts from './managerEmployeeContracts/actions';

export const StoreActions = {
  Expenses,
  Reports,
  Files,
  FileUploading,
  Localization,
  DataExportingStatus,
  UserPanel: { toggle: toggleUserPanel },
  NotificationCenter: { toggle: toggleNotificationCenter, reloadRelatedData },
  ManagerEmployeeContracts,
};
