import type { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LinkOptionRouteType } from '../../shared/constants/routeLinkOptions';
import './Breadcrumbs.scss';

interface IProps {
  items: LinkOptionRouteType[];
}

const Breadcrumbs: FC<IProps> = ({ items }) => {
  const { t } = useTranslation();
  let disableKey = false;

  return (
    <>
      <nav className="breadcrumbs">
        {items.map(({ to, label, labelForBradCrumbs }, idx, arr) => {
          const isLast = idx === arr.length - 1;
          const labelText = arr[0].label === undefined;
          if (arr.length >= 3) {
            if (idx === 1) {
              disableKey = true;
            }
          }
          const disabledClassName = isLast || disableKey ? 'disabled' : '';
          const breadcrumbLabel = (labelForBradCrumbs || label) as string;
          return (
            <div className="breadcrumbs--item" key={`${label}-${idx}`}>
              <Link to={to} className={disabledClassName}>
                {t(breadcrumbLabel)}
              </Link>
              {!isLast && !labelText && <span className="slashIcon">/</span>}
            </div>
          );
        })}
      </nav>
      <hr className="titleBorder" />
    </>
  );
};

export default Breadcrumbs;
