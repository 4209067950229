import DepartmentView from './departmentView/actions';
import EmployeeView from './employeeView/actions';
import GeographicalView from './geographicalView/actions';
import PlanView from './planView/actions';
import ProvisionalExpense from './provisionalExpense/actions';
import SummaryTotal from './summaryTotal/actions';
import TrueUp from './trueUp/actions';
import { Types } from './types';
import ExpenseOutlookView from './expenseOutlookView/actions';

const resetReportsState = () =>
  ({
    type: Types.RESET_REPORTS_STATE,
  } as const);

export const Reports = {
  SummaryTotal,
  DepartmentView,
  EmployeeView,
  GeographicalView,
  PlanView,
  ProvisionalExpense,
  TrueUp,
  resetReportsState,
  ExpenseOutlookView,
};
