import type { FC } from 'react';
import { useUserActivityListener } from './SessionTimeoutModal.hooks';
import SessionTimeoutModal from './SessionTimeoutModal';

const SessionTimeoutModalContainer: FC = () => {
  const { isInactivityModalOpen, closeInactivityModal } = useUserActivityListener();
  return isInactivityModalOpen ? <SessionTimeoutModal onClose={closeInactivityModal} /> : null;
};

export default SessionTimeoutModalContainer;
