interface DateTimeFormatOptions {
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  day?: 'numeric' | '2-digit';
  weekday?: 'long' | 'short' | 'narrow';
  era?: 'long' | 'short' | 'narrow';
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
  timeZoneName?: 'long' | 'short';
  formatMatcher?: 'basic' | 'best fit';
  hour12?: boolean;
  timeZone?: 'UTC';
}

export enum DateFormat {
  MonthYear = 'MonthYear',
  MonthDayYear = 'MonthDayYear',
  MonthDayYearHoursMinutes = 'MonthDayYearHoursMinutes',
  MonthDayYearHoursMinutes24 = 'MonthDayYearHoursMinutes24',
  MonthDayYearHoursMinutesSeconds = 'MonthDayYearHoursMinutesSeconds',
  MonthDayYearHoursMinutesSeconds24 = 'MonthDayYearHoursMinutesSeconds24',
  FullYearFullMonthDate = 'FullYearFullMonthDate',
  FullYearFullMonth = 'FullYearFullMonth',
  FullWeekdayFullMonthDayHoursMinutesSeconds = 'FullWeekdayFullMonthDayHoursMinutesSeconds',
}

export enum Locales {
  EN = 'en-US',
  RU = 'ru-RU',
  EN_ZA = 'en-ZA',
  EN_AU = 'en-AU',
}

const options: Record<DateFormat, DateTimeFormatOptions> = {
  [DateFormat.MonthYear]: {
    year: 'numeric',
    month: 'short',
  },
  [DateFormat.MonthDayYear]: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  [DateFormat.MonthDayYearHoursMinutesSeconds]: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  },
  [DateFormat.MonthDayYearHoursMinutesSeconds24]: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  },
  [DateFormat.MonthDayYearHoursMinutes]: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
  [DateFormat.MonthDayYearHoursMinutes24]: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
  [DateFormat.FullYearFullMonthDate]: {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  },
  [DateFormat.FullYearFullMonth]: {
    year: 'numeric',
    month: 'long',
  },
  [DateFormat.FullWeekdayFullMonthDayHoursMinutesSeconds]: {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  },
};

export const checkIsDate = (date: any): boolean => {
  if (!date) return false;
  /* let checker = date;
  if (typeof checker === 'string' && Number.isNaN(+checker) && !/\d{2}:\d{2}$/.test(checker)) {
    checker = checker.trim().replace(/\.?\d+$/, '');
  } */
  const dateObj = new Date(date);
  return dateObj instanceof Date && !Number.isNaN(dateObj.getTime());
};

export const normalizeDateString = (
  date: string | Date | number
): { isDate: boolean; value: string | Date | number } => {
  let result = date;
  // INFO: Sometimes BE return dateString in format day/month/year (checkIsDate(result) returns false)
  // but new Date() can convert it just if format will be month/day/year ((checkIsDate(result) returns true))
  if (typeof result === 'string' && /^\d{2}\/\d{2}\/\d{4}$/.test(result) && !checkIsDate(result)) {
    const [day, month, year] = result.split('/');
    result = [month, day, year].join('/');
  }
  if (!checkIsDate(result)) return { isDate: false, value: date };
  return { isDate: true, value: result };
};

export const dateFormatter = (
  date: string | Date | number,
  format: DateFormat,
  locale: Locales | Locales[] = Locales.EN
): string => {
  const { isDate, value } = normalizeDateString(date);
  if (isDate) {
    return new Intl.DateTimeFormat(locale, options[format]).format(new Date(value));
  }
  return (date as string) ?? '';
};

/**
 * returns date in format 'YYYY-MM-DDT00:00:00'
 */
export const prepareDateForBE = (date: Date): string =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().slice(0, -5);
