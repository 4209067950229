import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LOCALE } from './helpers';

class I18Service {
  init = (initialLanguage: string) => {
    i18n.use(initReactI18next).init({
      resources: {
        en: {
          translation: {},
        },
      },
      lng: initialLanguage,
      fallbackLng: DEFAULT_LOCALE.id,
      /**
       * INFO:
       * If you will need nsSeparator - do not use ':' to prevent crashing.
       * Sometimes BE return keys as translation with nested keys and this translation
       * may have text like dates (MM:DD:YYYY) or something else with special characters.
       * */
      nsSeparator: false,
      interpolation: {
        escapeValue: false,
        prefix: '${',
        suffix: '}',
        nestingPrefix: '{{',
        nestingSuffix: '}}',
      },
    });
    return i18n;
  };

  onChangeLocaleBundle = async (bundle: Record<string, string>, localeCode?: string | null) => {
    const locale = localeCode || DEFAULT_LOCALE.id;
    i18n.addResourceBundle(locale, 'translation', bundle);
    await i18n.changeLanguage(locale);
  };

  setDefaultLocale = () => {
    i18n.changeLanguage(DEFAULT_LOCALE.id);
  };
}

export const i18Service = new I18Service();
