import { RadioButton } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import IconTooltip from 'components/IconTooltip';
import Input from 'components/vendor/Input';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPerformanceHurdle, IPerformanceSubHurdle } from 'shared/models/models.bl';
import { ReactComponent as PlusIcon } from '../../../assets/svg/plus-blue.svg';
import { ReactComponent as PercentIcon } from '../../../assets/svg/percent.svg';
import {
  validatePHAndSubPHIdAndNameAreValid,
  UnitType,
  validateSubHurdleWeight,
  minPercentage,
  maxPercentage,
  validateCombinedSubHurdleWeight,
  setCommonRangeError,
  validateSubHurdleNameDuplicacy,
} from './helper';

import './PerformanceHurdle.scss';
import VestingConditionControl from './VestingConditionControl';

interface IProps {
  index: number;
  phData: IPerformanceHurdle;
  setPHData: any;
  isNewSubHurdle: boolean;
  readOnlyEditMode: boolean;
}

const SubhurdleControl: FC<IProps> = ({ index, phData, setPHData, isNewSubHurdle, readOnlyEditMode }) => {
  const { t } = useTranslation();
  const [isNameModified, setNamedModified] = useState(!isNewSubHurdle);
  const [isWeightModified, setWeightModified] = useState(!isNewSubHurdle);

  const convertSubHurdlesObjectToArray = (subHurdles: IPerformanceSubHurdle[]) => {
    return Object.keys(subHurdles).map((data, i: number) => {
      const sub = subHurdles[i];
      return sub;
    });
  };

  // sub-hurdle weight change event
  const handleWeightChange = (e: any) => {
    const updatedWeight = e?.value;
    const subHurdles = [...phData.performanceSubHurdle];
    const subHurdle = { ...subHurdles[index] };
    subHurdle.weightage = updatedWeight;
    subHurdles[index] = subHurdle;
    setWeightModified(true);
    setPHData((prevState: any) => ({
      ...prevState,
      performanceSubHurdle: convertSubHurdlesObjectToArray(subHurdles),
    }));
  };

  // sub-hurdle name change event
  const handleSubHurdleNameChange = (e: any) => {
    const updatedName = e?.value;
    const subHurdles = [...phData.performanceSubHurdle];
    const subHurdle = { ...subHurdles[index] };
    subHurdle.subHurdleName = updatedName;
    subHurdles[index] = subHurdle;
    setNamedModified(true);
    setPHData((prevState: any) => ({
      ...prevState,
      performanceSubHurdle: convertSubHurdlesObjectToArray(subHurdles),
    }));
  };

  // common handler to update vesting condition values
  const handleVCValuesChanges = (e: any, vcIndex: number) => {
    const valueToBeUpdated = e.value;
    const nameArray = e?.target?.name?.split('-');
    const name = nameArray[2];
    const phDataOld = { ...phData };
    const subHurdles = { ...phDataOld.performanceSubHurdle };
    const subHurdle = { ...subHurdles[index] };
    const item = { ...subHurdle.performanceSubHurdleVesting[vcIndex] };
    if (e?.target?.props?.name?.indexOf('hurdleFrom') > -1 || (name === 'hurdleFrom' && valueToBeUpdated === '')) {
      item.hurdleFrom = valueToBeUpdated;
    } else if (e?.target?.props?.name?.indexOf('hurdleTo') > -1 || (name === 'hurdleTo' && valueToBeUpdated === '')) {
      item.hurdleTo = valueToBeUpdated;
    } else if (
      e?.target?.props?.name?.indexOf('vestingFrom') > -1 ||
      (name === 'vestingFrom' && valueToBeUpdated === '')
    ) {
      item.vestingFrom = valueToBeUpdated;
    } else {
      item.vestingTo = valueToBeUpdated;
    }
    subHurdle.performanceSubHurdleVesting[vcIndex] = item;
    subHurdles[index] = subHurdle;
    phDataOld.performanceSubHurdle = convertSubHurdlesObjectToArray(subHurdles);

    setPHData(phDataOld);
  };

  // radio button handler for subhurdle option type: Percentage/ Amount
  const changeVestingOptionType = (e: any) => {
    const subHurdles = { ...phData.performanceSubHurdle };
    const subHurdle = { ...subHurdles[index] };
    subHurdle.unit = e.value === 'Percentage' ? UnitType.Percentage : UnitType.Amount;
    subHurdles[index] = subHurdle;

    setPHData((prevState: any) => ({
      ...prevState,
      performanceSubHurdle: convertSubHurdlesObjectToArray(subHurdles),
    }));
  };

  // @todo: Logic need to be updated for shiting the values
  // on adding new vesting condition
  const addVestingConditionClick = (e: any) => {
    e.preventDefault();
    if (phData.performanceSubHurdle[index].performanceSubHurdleVesting.length >= 5) return;

    const subHurdles = { ...phData.performanceSubHurdle };
    const subHurdle = { ...subHurdles[index] };
    const lastVC = subHurdle.performanceSubHurdleVesting[subHurdle.performanceSubHurdleVesting.length - 1];
    // updating new VC values from last VC values
    const condition = {
      hurdleFrom: lastVC.hurdleFrom,
      hurdleTo: '',
      vestingFrom: lastVC.vestingFrom,
      vestingTo: '',
    };
    // updating last VC
    subHurdle.performanceSubHurdleVesting[subHurdle.performanceSubHurdleVesting.length - 1].hurdleFrom = '';
    subHurdle.performanceSubHurdleVesting[subHurdle.performanceSubHurdleVesting.length - 1].vestingFrom = '';

    subHurdle.performanceSubHurdleVesting.splice(subHurdle.performanceSubHurdleVesting.length - 1, 0, condition);

    subHurdles[index] = subHurdle;
    setPHData((prevState: any) => ({
      ...prevState,
      performanceSubHurdle: convertSubHurdlesObjectToArray(subHurdles),
    }));
  };
  const handleDeleteVestingCondition = (deleteIndex: any) => {
    let subHurdles: IPerformanceSubHurdle[] = { ...phData.performanceSubHurdle };
    subHurdles = convertSubHurdlesObjectToArray(subHurdles);
    const subHurdle = { ...subHurdles[index] };

    // delete sh when only one VC is there and its deletion initiated.
    if (deleteIndex === 0 && deleteIndex === subHurdle.performanceSubHurdleVesting.length - 1) {
      subHurdles.splice(index, 1);
    } else {
      subHurdle.performanceSubHurdleVesting.splice(deleteIndex, 1);
      // last VC hurdleTo always be 0, vestingTo Value always be 100
      subHurdle.performanceSubHurdleVesting[subHurdle.performanceSubHurdleVesting.length - 1].vestingTo = '';
      subHurdle.performanceSubHurdleVesting[subHurdle.performanceSubHurdleVesting.length - 1].hurdleTo = '';

      subHurdles[index] = subHurdle;
    }
    setPHData((prevState: any) => ({
      ...prevState,
      performanceSubHurdle: subHurdles,
    }));
  };

  const setSubHurdleWeightError = () => {
    if (!isWeightModified) return '';

    const value = +phData.performanceSubHurdle[index].weightage;
    let errorText = setCommonRangeError(t, value, minPercentage, maxPercentage);

    if (!errorText && !validateCombinedSubHurdleWeight(phData.performanceSubHurdle)) {
      errorText = t('PerformanceHurdle.AddModal.CombinedWeightageError');
    }
    return errorText;
  };

  const setSubHurdleNameDuplicateError = () => {
    return validateSubHurdleNameDuplicacy(phData.performanceSubHurdle) ? 'Duplicate sub-hurdle name' : '';
  };

  return (
    <div className="sub-hurdle-item">
      <div className="sub-hurdle-basic-info">
        <div className="left-section">
          <Input
            key={`sn-${index}`}
            id={`subhurdlename-${index}`}
            name={`performanceSubHurdle.${index}.subHurdleName`}
            label={t('PerformanceHurdle.AddModal.SubHurdleName')}
            className="sub-hurdlename-field"
            value={phData.performanceSubHurdle[index].subHurdleName}
            valid={
              !isNameModified ||
              (validatePHAndSubPHIdAndNameAreValid(phData.performanceSubHurdle[index].subHurdleName) &&
                setSubHurdleNameDuplicateError() === '')
            }
            validationMessage=""
            onChange={handleSubHurdleNameChange}
            placeholder={t('PerformanceHurdle.AddModal.SubHurdleNamePlaceholder')}
            disabled={readOnlyEditMode || (!!phData.uid && !isNewSubHurdle)}
          />
        </div>
        <div className="right-section">
          <Label
            className={`labelText${
              !isWeightModified ||
              (validateSubHurdleWeight(!isWeightModified ? 0 : phData.performanceSubHurdle[index].weightage) &&
                setSubHurdleWeightError() === '')
                ? ''
                : 'labelInvalidcolor'
            }`}
          >
            {t('PerformanceHurdle.AddModal.Weightage')}
            <span className="tooltip">
              <IconTooltip />
              <span className="tooltiptext">{t('PerformanceHurdle.AddModal.CombinedWeightageError')}</span>
            </span>
          </Label>
          <div className="input_box">
            <Input
              key={`sw-${index}`}
              id={`subhurdleWeight-${index}`}
              name={`subhurdleWeight-${index}`}
              className="sub-hurdleweight-field"
              onChange={handleWeightChange}
              value={phData.performanceSubHurdle[index].weightage.toString()}
              placeholder={t('PerformanceHurdle.AddModal.WeightagePlaceholder')}
              validationMessage={setSubHurdleWeightError()}
              valid={
                !isWeightModified ||
                (validateSubHurdleWeight(!isWeightModified ? 0 : phData.performanceSubHurdle[index].weightage) &&
                  setSubHurdleWeightError() === '')
              }
              endIcon={<PercentIcon />}
              required
              disabled={readOnlyEditMode}
            />
          </div>
        </div>
      </div>
      <div className="vc-header-section">
        <div className="vc-header-title">{t('PerformanceHurdle.AddModal.VestingCondition')}</div>
        <div className="vc-radio-options">
          <RadioButton
            name={`vcOptionType-${index}`}
            value="Percentage"
            checked={phData.performanceSubHurdle[index].unit === UnitType.Percentage}
            label={t('PerformanceHurdle.AddModal.SubHurdle.PercentageUnit')}
            onChange={changeVestingOptionType}
            disabled={readOnlyEditMode}
          />{' '}
          &nbsp;
          <RadioButton
            name={`vcOptionType-${index}`}
            value="Amount"
            checked={phData.performanceSubHurdle[index].unit === UnitType.Amount}
            label={t('PerformanceHurdle.AddModal.SubHurdle.AmountUnit')}
            onChange={changeVestingOptionType}
            disabled={readOnlyEditMode}
          />
        </div>
      </div>

      <div className="vesting-conditions-group">
        {phData.performanceSubHurdle[index]?.performanceSubHurdleVesting.map((vc, idx) => (
          <VestingConditionControl
            key={`sh-${index}-vc-${idx}`}
            phData={phData}
            isFirstRow={idx === 0}
            isLastRow={idx === phData.performanceSubHurdle[index].performanceSubHurdleVesting.length - 1}
            onChange={handleVCValuesChanges}
            subHurdleIndex={index}
            index={idx}
            isPercentageSelected={phData.performanceSubHurdle[index].unit === UnitType.Percentage}
            onDeleteVestingCondition={handleDeleteVestingCondition}
            readOnlyEditMode={readOnlyEditMode}
          />
        ))}
      </div>
      <div
        className={`add-vc-button ${
          readOnlyEditMode || phData.performanceSubHurdle[index]?.performanceSubHurdleVesting.length >= 5
            ? 'disable-div'
            : ''
        }`}
        onClick={addVestingConditionClick}
      >
        <PlusIcon />
        <div>{t('PerformanceHurdle.AddModal.VestingCondition.AddCondition')}</div>
      </div>
    </div>
  );
};

export default SubhurdleControl;
