import axios, { AxiosResponse } from 'axios';
import * as Models from '../../shared.models';
import ApiUrlsService from './api-urls.service';
import { getParsedResponseData } from './helpers';
import Configs from '../../shared.configs';

export default class ActivityLogs {
  static getActivityLogsTableList(): Promise<Models.BM.ResponseTableDataType<Models.BM.ActivityLogsTableData>> {
    return axios
      .get(ApiUrlsService.getActivityLogsTableList())
      .then(({ data }) =>
        getParsedResponseData<Models.BM.ActivityLogsTableData>(data, Configs.Grid.GridColumnConfig.ActivityLogsConfig)
      )
      .catch(() => null);
  }

  static getActivityLogsPaginatedData(
    data: Models.BM.ITableDataInfoRequest
  ): Promise<AxiosResponse<Models.BM.PaginatedResponseTableDataType<Models.BM.ActivityLogsTableData>>> {
    return axios.post(ApiUrlsService.getActivityLogsPaginatedData(), data).then((response) => {
      response.data.tableData = getParsedResponseData<Models.BM.ActivityLogsTableData>(
        response.data.tableData,
        Configs.Grid.GridColumnConfig.ActivityLogsConfig
      );
      return response;
    });
  }

  static exportActivityLogsTableData(): Promise<any> {
    return axios.post(ApiUrlsService.exportActivityLogsTableData()).then(({ data }) => data);
  }
}
