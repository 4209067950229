import axios from 'axios';
import { prepareDateForBE } from '../../utils/dateFormatter';

import * as Models from '../../shared.models';
import ApiUrlsService from './api-urls.service';

export default class ExpensesJournal {
  static getReportList(): Promise<Models.BM.AvailableReport[]> {
    return axios.get(ApiUrlsService.getJournalEntriesReportList()).then(({ data }) => data);
  }

  static getFilterOptionsList(reportUId: string): Promise<Models.DM.IExpensesJournalFiltersData> {
    return axios.get(ApiUrlsService.getFilterOptionsList(), { params: { reportUId } }).then(({ data }) => data);
  }

  static calculateData(
    calculateOptions: Models.BM.IExpensesJournalSelectedOptions
  ): Promise<Models.BM.IExpensesJournalCalculatedData> {
    return axios
      .post(ApiUrlsService.calculateData(), {
        ...calculateOptions,
        monthYear: prepareDateForBE(calculateOptions.monthYear),
      })
      .then(({ data }) => data);
  }

  static exportReport(body: Models.BM.IExpensesJournalExportToExcelRequest): Promise<any> {
    return axios
      .post(ApiUrlsService.exportJournalEntriesTableData(), {
        ...body,
        monthYear: prepareDateForBE(body.monthYear),
      })
      .then((data) => data);
  }
}
