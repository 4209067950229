import { useState, Dispatch, SetStateAction } from 'react';

export const useDataState = <T>(
  initialData?: T,
  initialLoading = false
): {
  data: T | undefined;
  setData: Dispatch<SetStateAction<T | undefined>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
} => {
  const [data, setData] = useState<T | undefined>(initialData);
  const [loading, setLoading] = useState<boolean>(initialLoading);
  return {
    data,
    setData,
    loading,
    setLoading,
  };
};
