import { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { Field, Form } from '@progress/kendo-react-form';

import { UserFormSettings, UserSettings } from 'shared/models/models.dl';
import { UserSettingsDateFormat, UserSettingsNumberFormat } from 'shared/models/enums.dl';
import CustomHooks from 'shared/shared.hooks';
import Services from 'shared/shared.services';
import { setToLocalStorage } from 'shared/utils/localStorage';
import { setUserSettings } from 'store/user/actions';
import { StoreActions } from 'store/actions';
import { Selectors } from 'store/selectors';
import ModalLayout from 'components/ModalLayout';
import FormRadioGroup from 'components/FormRadioGroup';
import { ErrorMessage } from 'components/LanguageSelect/ErrorMessage/ErrorMessage';
import { useSelector } from 'react-redux';
import { useTranslation, TFunction } from 'react-i18next';
import { DEFAULT_LOCALE, USER_LOCALE } from 'localization/helpers';
import LocalizationService from 'shared/services/bl/localization.service';
import SingleSelectControl, { DropDownListChangeEvent } from '../../SingleSelectControl/SingleSelectControl';

import './UserSettingsModal.scss';

const getDateRadioData = (t: TFunction) => [
  {
    label: `MM/DD/YYYY ${t('UserSettingsModal.DefaultOption')}`,
    value: UserSettingsDateFormat.MM_DD_YYYY,
  },
  {
    label: 'YYYY/MM/DD',
    value: UserSettingsDateFormat.YYYY_MM_DD,
  },
  {
    label: 'DD/MM/YYYY',
    value: UserSettingsDateFormat.DD_MM_YYYY,
  },
  {
    label: 'DD Month YYYY',
    value: UserSettingsDateFormat.DD_MMMM_YYYY,
  },
];

const getNumberRadioData = (t: TFunction) => [
  {
    label: `1,000,000.00 ${t('UserSettingsModal.DefaultOption')}`,
    value: UserSettingsNumberFormat['1,000,000.00'],
  },
  {
    label: '1.000.000,00',
    value: UserSettingsNumberFormat['1.000.000,00'],
  },
  {
    label: '1 000 000,00',
    value: UserSettingsNumberFormat['1 000 000,00'],
  },
];

interface IProps {
  onClose: () => void;
}

export const getDataForForm = ({ t, data }: { t: TFunction; data?: UserSettings | null }): UserFormSettings => {
  const {
    dateFormat = getDateRadioData(t)[0].value,
    numberFormat = getNumberRadioData(t)[0].value,
    locale,
  } = data || ({} as UserSettings);
  return {
    dateFormat,
    numberFormat,
    locale: { id: locale || LocalizationService.getLocaleCode(), text: '' },
  };
};

const UserSettingsModal: FC<IProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const user = CustomHooks.storeHooks.useAppSelector((state) => state.currentUser.user);
  const dispatch = CustomHooks.storeHooks.useAppDispatch();
  const locales = useSelector(Selectors.Localization.selectLocales);
  const isLoadedLocales = useSelector(Selectors.Localization.selectIsLoadedLocales);
  const isErrorLocales = useSelector(Selectors.Localization.selectIsErrorLocales);

  const initialData = useMemo(() => getDataForForm({ t, data: user?.settings }), [user?.settings]);
  const isSettingsModal = useMemo(() => !!user?.settings?.locale, [user?.settings?.locale]);

  const [isLoading, setIsLoading] = useState(false);

  const defaultLocale = useMemo(() => {
    const userLocaleCode = LocalizationService.getLocaleCode(isErrorLocales);
    return locales?.find((locale) => locale.id === userLocaleCode) || DEFAULT_LOCALE;
  }, [user, locales, isErrorLocales]);

  useEffect(() => {
    if (!isLoadedLocales) {
      dispatch(StoreActions.Localization.getLocales());
    }
  }, [isLoadedLocales]);

  const updateSettings = useCallback(
    async (settings: UserFormSettings) => {
      const userSettings = { ...settings, locale: settings?.locale?.id };
      setIsLoading(true);
      try {
        await Services.DL.UserDataService.saveUserSettings(userSettings);
        setIsLoading(false);
        dispatch(setUserSettings(userSettings));
        onClose();
      } catch {
        setIsLoading(false);
      }
    },
    [onClose, user]
  );

  const { onCancel, onConfirm, onChange, onSubmit, refForm, validationMessage, formObject } =
    CustomHooks.useModalActiveState({
      onCancel: onClose,
      onConfirm: updateSettings,
      visible: true,
      data: initialData,
      isLoading,
      allowConfirmIfNoChanges: !user?.settings?.locale,
      preventTrimValues: {
        dateFormat: true,
        numberFormat: true,
        locale: false,
      },
    });

  const { isModified } = CustomHooks.useIsFormModified({ initialValues: initialData, currentValues: formObject });

  const handleLanguageChange = useCallback(
    (event: DropDownListChangeEvent) => {
      dispatch(StoreActions.Localization.getLocaleKeys(event.value.id));
      setToLocalStorage(USER_LOCALE, event.value);
      onChange(event);
    },
    [onChange]
  );

  return (
    <ModalLayout
      className="UserSettingsModal"
      onCancel={isSettingsModal ? onCancel : undefined}
      onConfirm={onConfirm}
      title={isSettingsModal ? t('UserSettingsModal.Title') : t('UserSettingsModal.PreferenceTitle')}
      confirmBtnTitle={t('UserSettingsModal.ConfirmButton')}
      cancelBtnTitle={t('UserSettingsModal.CancelButton')}
      confirmDisabled={isLoading || (!isModified && isSettingsModal)}
      validationMessage={validationMessage}
      confirmLoading={isLoading}
    >
      <Form
        ref={refForm}
        onSubmit={onSubmit}
        initialValues={initialData}
        render={({ valueGetter }) => {
          return (
            <>
              <hr className="UserSettingModal-border" />
              {!user?.settings?.locale && (
                <>
                  <Field
                    id="locale"
                    name="locale"
                    label={t('UserSettingsModal.LanguageDropdown.Label')}
                    defaultOption={isLoadedLocales ? defaultLocale : null}
                    data={locales}
                    className={` ${isErrorLocales || !isLoadedLocales ? 'userSettingLocale' : ''}`}
                    component={SingleSelectControl}
                    onChange={handleLanguageChange}
                    disabled={isErrorLocales || !isLoadedLocales}
                  />
                  {isErrorLocales && <ErrorMessage />}
                </>
              )}
              <Field
                id="dateFormat"
                name="dateFormat"
                label={t('UserSettingsModal.DateFormatRadioGroup.Label')}
                value={valueGetter('dateFormat')}
                data={getDateRadioData(t)}
                component={FormRadioGroup}
                onChange={onChange}
                required
                isRequiredIcon={false}
                layout="vertical"
              />
              <Field
                id="numberFormat"
                name="numberFormat"
                label={t('UserSettingsModal.NumberFormatRadioGroup.Label')}
                value={valueGetter('numberFormat')}
                data={getNumberRadioData(t)}
                component={FormRadioGroup}
                onChange={onChange}
                required
                isRequiredIcon={false}
                layout="vertical"
              />
            </>
          );
        }}
      />
    </ModalLayout>
  );
};

export default UserSettingsModal;
