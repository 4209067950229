import axios, { Canceler, AxiosRequestConfig } from 'axios';
import * as Models from '../../shared.models';
import Configs from '../../shared.configs';
import { defaultPagingRequestData } from '../../configs/grid.config';
import ApiUrlsService from './api-urls.service';
import Expenses from './expenses.services';
import { getParsedResponseData } from './helpers';
import { IExportSummaryReportRequest } from '../../models/models.bl';

enum CancelTokenKey {
  SummaryTotalPaginatedTableData = 'SummaryTotalPaginatedTableData',
  SummaryGeographicalPaginatedTableData = 'SummaryGeographicalPaginatedTableData',
  SummaryDepartmentPaginatedTableData = 'SummaryDepartmentPaginatedTableData',
  SummaryPlanPaginatedTableData = 'SummaryPlanPaginatedTableData',
  SummaryEmployeePaginatedTableData = 'SummaryEmployeePaginatedTableData',
  SummaryExpenseOutlookPaginatedTableData = 'SummaryExpenseOutlookPaginatedTableData',
}

export const expensesReportsCancelTokens: Record<CancelTokenKey, Canceler | null> = {
  [CancelTokenKey.SummaryTotalPaginatedTableData]: null,
  [CancelTokenKey.SummaryGeographicalPaginatedTableData]: null,
  [CancelTokenKey.SummaryDepartmentPaginatedTableData]: null,
  [CancelTokenKey.SummaryPlanPaginatedTableData]: null,
  [CancelTokenKey.SummaryEmployeePaginatedTableData]: null,
  [CancelTokenKey.SummaryExpenseOutlookPaginatedTableData]: null,
};

const cancelPrevRequest = (tokenKey: CancelTokenKey) => {
  expensesReportsCancelTokens[tokenKey]?.();
  const source = axios.CancelToken.source();
  expensesReportsCancelTokens[tokenKey] = source.cancel;
  return source;
};

enum FilterName {
  grant = 'grant',
  location = 'location',
  plan = 'plan',
  grantPlan = 'grantPlan',
}

const getRequestParams = (filterName: FilterName, value?: string): AxiosRequestConfig =>
  value
    ? {
        params: {
          [filterName]: value,
        },
      }
    : {};

const getPaginatedData = async <
  T,
  R extends Models.BM.IPaginatedTableResponseWithHeaders<T> = Models.BM.IPaginatedTableResponseWithHeaders<T>
>({
  url,
  pagingData,
  filter,
  filterName,
  cancelTokenKey,
  initialTableConfig,
}: {
  url: string;
  filterName?: FilterName;
  filter?: string;
  initialTableConfig: Models.BM.ColumnConfiguration<T>[];
  pagingData?: Partial<Models.BM.ITableDataInfoRequest>;
  cancelTokenKey?: CancelTokenKey;
}): Promise<R> => {
  const source = cancelTokenKey ? cancelPrevRequest(cancelTokenKey) : null;
  let body = pagingData || defaultPagingRequestData;
  if (!body.pageSize) {
    body = { ...body, ...defaultPagingRequestData };
  }
  if (filterName && filter) {
    body = { ...body, [filterName]: filter };
  }
  const { data } = await axios.post<R>(url, body, {
    cancelToken: source?.token,
  });
  data.tableData = getParsedResponseData<T>(data.tableData, initialTableConfig) as any;
  return data;
};

export default class SBPExpensesReports {
  static getReportList(): Promise<Models.BM.AvailableReport[]> {
    return axios
      .get(ApiUrlsService.getReportList())
      .then(({ data }) => data)
      .catch(() => {});
  }

  static exportReport(reportUId: string, exportData: IExportSummaryReportRequest): Promise<string> {
    return Expenses.exportCalculation(reportUId, exportData).then(() => reportUId);
  }

  static exportDetailedReport(reportUId: string): Promise<string> {
    return Expenses.exportDetailedCalculation(reportUId).then(() => reportUId);
  }

  static deleteReport(reportTimeStamp: string): Promise<void> {
    return axios.delete(ApiUrlsService.deleteExpensesCalculation(reportTimeStamp));
  }

  static getSummaryTotalFilters(reportUId: string): Promise<string[] | null> {
    return axios
      .get(ApiUrlsService.getReportSummaryTotalFilters(reportUId))
      .then(({ data }) => data)
      .catch(() => null);
  }

  static getSummaryTotalTableList(
    reportTimeStamp: string,
    filter?: string
  ): Promise<Models.BM.ResponseTableDataType<Models.BM.SummaryTotalTableData> | null> {
    return axios
      .get(ApiUrlsService.getReportSummaryTotalTableList(reportTimeStamp), getRequestParams(FilterName.grant, filter))
      .then(({ data }) =>
        getParsedResponseData<Models.BM.SummaryTotalTableData>(data, Configs.Grid.GridColumnConfig.SummaryTotalConfig)
      )
      .catch(() => null);
  }

  static exportSummaryTotalTableData(reportUId: string): Promise<string> {
    return axios.post(ApiUrlsService.exportSummaryTotalTableData(reportUId)).then(() => reportUId);
  }

  static getSummaryTotalChart(reportUId: string, filter?: string): Promise<Models.DM.ISummaryTotalChartData[]> {
    return axios
      .get(ApiUrlsService.getReportSummaryTotalChart(reportUId), getRequestParams(FilterName.grant, filter))
      .then(({ data }) => data)
      .catch(() => null);
  }

  static getSummaryEmployeeTableList(
    reportTimeStamp: string
  ): Promise<Models.BM.ResponseTableDataType<Models.BM.EmployeeViewTableData> | null> {
    return axios
      .get(ApiUrlsService.getReportSummaryEmployeeTableList(reportTimeStamp))
      .then(({ data }) =>
        getParsedResponseData<Models.BM.EmployeeViewTableData>(data, Configs.Grid.GridColumnConfig.EmployeeViewConfig)
      )
      .catch(() => null);
  }

  static exportSummaryEmployeeTableData(reportUId: string): Promise<string> {
    return axios.post(ApiUrlsService.exportSummaryEmployeeTableData(reportUId)).then(() => reportUId);
  }

  static getSummaryGeographicalFilters(reportTimeStamp: string): Promise<string[] | null> {
    return axios
      .get(ApiUrlsService.getReportSummaryGeographicalFilters(reportTimeStamp))
      .then(({ data }) => data)
      .catch(() => null);
  }

  static getSummaryGeographicalChart(
    reportUId: string,
    filter?: string
  ): Promise<Models.DM.SummaryGeographicalChartData[]> {
    return axios
      .get(ApiUrlsService.getSummaryGeographicalChart(reportUId), getRequestParams(FilterName.location, filter))
      .then(({ data }) => data)
      .catch(() => null);
  }

  static getSummaryGeographicalTableList(
    reportTimeStamp: string,
    filter?: string
  ): Promise<Models.BM.ResponseTableDataType<Models.BM.GeographicalViewTableData> | null> {
    return axios
      .get(
        ApiUrlsService.getReportSummaryGeographicalTableList(reportTimeStamp),
        getRequestParams(FilterName.location, filter)
      )
      .then(({ data }) =>
        getParsedResponseData<Models.BM.GeographicalViewTableData>(
          data,
          Configs.Grid.GridColumnConfig.GeographicalViewConfig
        )
      )
      .catch(() => null);
  }

  static exportSummaryGeographicalTableData(reportUId: string, filter?: string): Promise<string> {
    return axios
      .post(
        ApiUrlsService.exportSummaryGeographicalTableData(reportUId),
        null,
        getRequestParams(FilterName.location, filter)
      )
      .then(() => reportUId);
  }

  static getSummaryDepartmentUnitFilters(reportUId: string): Promise<Models.DM.IDepartmentFiltersData> {
    return axios.get(ApiUrlsService.getReportSummaryDepartmentFilters(reportUId)).then(({ data }) => data);
  }

  static summaryDepartmentChart(
    reportUId: string,
    filter: Models.DM.IDepartmentFiltersData
  ): Promise<Models.DM.SummaryDepartmentChartData[]> {
    return axios
      .post(ApiUrlsService.summaryDepartmentChart(reportUId), filter)
      .then(({ data }) => data)
      .catch(() => null);
  }

  static getSummaryDepartmentTableList(
    reportUId: string,
    data: any
  ): Promise<Models.BM.ResponseTableDataType<Models.BM.DepartmentViewTableData> | null> {
    return axios
      .post(`${ApiUrlsService.getReportSummaryDepartmentTableList(reportUId)}`, data)
      .then(({ data: responseData }) =>
        getParsedResponseData<Models.BM.DepartmentViewTableData>(
          responseData,
          Configs.Grid.GridColumnConfig.DepartmentViewConfig
        )
      )
      .catch(() => null);
  }

  static exportSummaryDepartmentTableData(
    reportUId: string,
    filter?: Models.DM.IDepartmentFiltersData
  ): Promise<string> {
    return axios.post(ApiUrlsService.exportSummaryDepartmentTableData(reportUId), filter).then(() => reportUId);
  }

  static getSummaryPlanFilters(reportTimeStamp: string): Promise<string[] | null> {
    return axios
      .get(ApiUrlsService.getReportSummaryPlanFilters(reportTimeStamp))
      .then(({ data }) => data)
      .catch(() => null);
  }

  static getSummaryPlanTableList(
    reportTimeStamp: string,
    filter?: string
  ): Promise<Models.BM.ResponseTableDataType<Models.BM.PlanViewTableData> | null> {
    return axios
      .get(ApiUrlsService.getReportSummaryPlanTableList(reportTimeStamp), getRequestParams(FilterName.plan, filter))
      .then(({ data }) =>
        getParsedResponseData<Models.BM.PlanViewTableData>(data, Configs.Grid.GridColumnConfig.PlanViewConfig)
      )
      .catch(() => null);
  }

  static getSummaryPlanChart(
    reportTimeStamp: string,
    filter?: string
  ): Promise<Models.DM.SummaryPlanChartData[] | null> {
    return axios
      .get(ApiUrlsService.getReportSummaryPlanChart(reportTimeStamp), getRequestParams(FilterName.plan, filter))
      .then(({ data }) => data)
      .catch(() => null);
  }

  static getSummaryPlanPieChart(reportUId: string): Promise<string[] | null> {
    return axios
      .get(ApiUrlsService.getReportSummaryPlanPieChart(reportUId))
      .then(({ data }) => data)
      .catch(() => null);
  }

  static exportSummaryPlanTableData(reportUId: string, filter?: string): Promise<string> {
    return axios
      .post(ApiUrlsService.exportSummaryPlanTableData(reportUId), null, getRequestParams(FilterName.plan, filter))
      .then(() => reportUId);
  }

  static getFinalizationData(reportTimeStamp: string): Promise<Models.BM.DataForFinalizationResponse> {
    return axios.get(ApiUrlsService.getReportFinalization(reportTimeStamp)).then(({ data }) => data);
  }

  static finalizeReport(reportTimeStamp: string, finalizationData: Models.BM.DataForFinalizationRequest): Promise<any> {
    return axios.post(ApiUrlsService.finalizeReport(reportTimeStamp), finalizationData).then(({ data }) => data);
  }

  // INFO - paginated table requests

  static async getSummaryTotalPaginatedTableData(
    reportUId: string,
    onlySums: boolean,
    pagingData?: Models.BM.ITableDataInfoRequest,
    filter?: string
  ): Promise<Models.BM.IPaginatedTableResponseWithHeaders<Models.BM.SummaryTotalTableData>> {
    const data = await getPaginatedData<Models.BM.SummaryTotalTableData>({
      url: ApiUrlsService.getReportSummaryTotalPaginatedTableData(reportUId, onlySums),
      pagingData,
      filter,
      filterName: FilterName.grant,
      cancelTokenKey: CancelTokenKey.SummaryTotalPaginatedTableData,
      initialTableConfig: Configs.Grid.GridColumnConfig.SummaryTotalConfig,
    });
    return data;
  }

  static async getReportSummaryGeographicalPaginatedTableData(
    reportUId: string,
    onlySums: boolean,
    pagingData?: Models.BM.ITableDataInfoRequest,
    filter?: string
  ): Promise<Models.BM.IPaginatedTableResponseWithHeaders<Models.BM.GeographicalViewTableData>> {
    const data = await getPaginatedData<Models.BM.GeographicalViewTableData>({
      url: ApiUrlsService.getReportSummaryGeographicalPaginatedTableData(reportUId, onlySums),
      pagingData,
      filter,
      filterName: FilterName.location,
      cancelTokenKey: CancelTokenKey.SummaryGeographicalPaginatedTableData,
      initialTableConfig: Configs.Grid.GridColumnConfig.GeographicalViewConfig,
    });
    return data;
  }

  static async getReportSummaryDepartmentPaginatedTableData(
    reportUId: string,
    onlySums: boolean,
    pagingData: Models.BM.ITableDataInfoRequest,
    filter: Models.DM.IDepartmentFiltersData
  ): Promise<Models.BM.IPaginatedTableResponseWithHeaders<Models.BM.DepartmentViewTableData>> {
    const data = await getPaginatedData<Models.BM.DepartmentViewTableData>({
      url: ApiUrlsService.getReportSummaryDepartmentPaginatedTableData(reportUId, onlySums),
      pagingData: { ...pagingData, ...filter },
      cancelTokenKey: CancelTokenKey.SummaryDepartmentPaginatedTableData,
      initialTableConfig: Configs.Grid.GridColumnConfig.DepartmentViewConfig,
    });
    return data;
  }

  static async getSummaryPlanPaginatedTableData(
    reportUId: string,
    onlySums: boolean,
    pagingData?: Models.BM.ITableDataInfoRequest,
    filter?: string
  ): Promise<Models.BM.IPaginatedTableResponseWithHeaders<Models.BM.PlanViewTableData>> {
    const data = await getPaginatedData<Models.BM.PlanViewTableData>({
      url: ApiUrlsService.getReportSummaryPlanPaginatedTableData(reportUId, onlySums),
      pagingData,
      filter,
      filterName: FilterName.grantPlan,
      cancelTokenKey: CancelTokenKey.SummaryPlanPaginatedTableData,
      initialTableConfig: Configs.Grid.GridColumnConfig.PlanViewConfig,
    });
    return data;
  }

  static async getSummaryEmployeePaginatedTableData(
    reportUId: string,
    onlySums: boolean,
    pagingData?: Models.BM.ITableDataInfoRequest
  ): Promise<Models.BM.IPaginatedTableResponseWithHeaders<Models.BM.EmployeeViewTableData>> {
    const data = await getPaginatedData<Models.BM.EmployeeViewTableData>({
      url: ApiUrlsService.getReportSummaryEmployeePaginatedTableData(reportUId, onlySums),
      pagingData,
      cancelTokenKey: CancelTokenKey.SummaryEmployeePaginatedTableData,
      initialTableConfig: Configs.Grid.GridColumnConfig.EmployeeViewConfig,
    });
    return data;
  }

  // expense outlook services

  static getSummaryExpenseOutlookFilters(reportTimeStamp: string): Promise<string[] | null> {
    return axios
      .get(ApiUrlsService.getReportSummaryExpenseOutlookFilters(reportTimeStamp))
      .then(({ data }) => data)
      .catch(() => null);
  }

  static getSummaryExpenseOutlookChart(
    reportTimeStamp: string,
    filter?: string
  ): Promise<Models.DM.SummaryPlanChartData[] | null> {
    return axios
      .get(
        ApiUrlsService.getReportSummaryExpenseOutlookChart(reportTimeStamp),
        getRequestParams(FilterName.grant, filter)
      )
      .then(({ data }) => data)
      .catch(() => null);
  }

  static async getSummaryExpenseOutlookPaginatedTableData(
    reportUId: string,
    pagingData?: Models.BM.ITableDataInfoRequest,
    filter?: string
  ): Promise<Models.BM.IPaginatedTableResponseWithHeaders<Models.BM.ExpenseOutlookTableData>> {
    const data = await getPaginatedData<Models.BM.ExpenseOutlookTableData>({
      url: ApiUrlsService.getReportSummaryExpenseOutlookPaginatedTableData(reportUId),
      pagingData,
      filter,
      filterName: FilterName.grantPlan,
      cancelTokenKey: CancelTokenKey.SummaryExpenseOutlookPaginatedTableData,
      initialTableConfig: Configs.Grid.GridColumnConfig.ExpenseOutlookViewConfig,
    });
    return data;
  }

  static exportSummaryExpenseOutlookTableData(reportUId: string, filter?: string): Promise<string> {
    return axios
      .post(
        ApiUrlsService.exportSummaryExpenseOutlookTableData(reportUId),
        null,
        getRequestParams(FilterName.plan, filter)
      )
      .then(() => reportUId);
  }
}
