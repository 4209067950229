import { StoreName } from '../../storeName';
import { ReportsStoreName } from '../storeName';
import { TypeName } from './enums';

const baseTypeName = `${StoreName.Reports}/${ReportsStoreName.TrueUp}/` as const;

export const Types = {
  [TypeName.SET_LOADING]: `${baseTypeName}${TypeName.SET_LOADING}`,
  [TypeName.SET_CALCULATING]: `${baseTypeName}${TypeName.SET_CALCULATING}`,
  [TypeName.SET_IS_CALCULATION_COMPLETED]: `${baseTypeName}${TypeName.SET_IS_CALCULATION_COMPLETED}`,
  [TypeName.SET_INFO_DATA]: `${baseTypeName}${TypeName.SET_INFO_DATA}`,
  [TypeName.SET_TABLE_DATA]: `${baseTypeName}${TypeName.SET_TABLE_DATA}`,
  [TypeName.SET_NEW_REPORT_TABLE_DATA]: `${baseTypeName}${TypeName.SET_NEW_REPORT_TABLE_DATA}`,
  [TypeName.SET_PREVIOUS_REPORT_TABLE_DATA]: `${baseTypeName}${TypeName.SET_PREVIOUS_REPORT_TABLE_DATA}`,
  [TypeName.RESET_STATE_DATA]: `${baseTypeName}${TypeName.RESET_STATE_DATA}`,
  [TypeName.SET_FILTERING]: `${baseTypeName}${TypeName.SET_FILTERING}`,
} as const;
