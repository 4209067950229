import axios from 'axios';
import { ITableDataInfoRequest, IPerformanceHurdle, IUpdatePerformanceHurdleEstimate } from 'shared/models/models.bl';
import { SelectedRowsType } from 'components/Table/interfaces';
import * as Models from '../../shared.models';
import ApiUrlsService from './api-urls.service';

export default class PerformanceHurdles {
  // @TODO - updating model, end points, and implementing the methods
  static getPerformanceHurdlesTableList(data: ITableDataInfoRequest): Promise<any> {
    return axios.post(ApiUrlsService.getPerformanceHurdlesTableList(), data).then((response) => response);
  }

  static getPerformanceHurdlesData(
    data: ITableDataInfoRequest
  ): Promise<Models.BM.ResponseTableDataType<Models.BM.PerformanceHurdlesTableData>> {
    return axios.post(ApiUrlsService.getPerformanceHurdlesData(), data).catch(() => null);
  }

  static deletePerformanceHurdlesTableData(ids: SelectedRowsType<Models.BM.PerformanceHurdlesTableData>): Promise<any> {
    return axios.post(ApiUrlsService.deletePerformanceHurdleTableData(), ids).then(({ data }) => data);
  }

  // API call: saving performance hurdle data
  static addAndUpdatePerformanceHurdleData(data: IPerformanceHurdle): Promise<any> {
    return axios[data.uid ? 'put' : 'post'](
      data.uid ? ApiUrlsService.updatePerformanceHurdleData() : ApiUrlsService.addPerformanceHurdleData(),
      data
    ).then((response) => response);
  }

  static addOrUpdateEstimate(data: IUpdatePerformanceHurdleEstimate): Promise<any> {
    return axios.post(ApiUrlsService.addOrUpdateEstimate(), data).then((response) => response);
  }

  static getPerformanceHurdleById(
    performanceHurdleId: Models.BM.IPerformanceHurdle['performanceHurdleId']
  ): Promise<any> {
    return axios
      .get(ApiUrlsService.getPerformanceHurdleDataById(), { params: { performanceHurdleId } })
      .then(({ data }) => data);
  }

  static getUpdatedEstimateById(
    performanceHurdleId: Models.BM.IUpdatePerformanceHurdleEstimate['performanceHurdleId']
  ): Promise<any> {
    return axios.get(ApiUrlsService.getEstimateById(), { params: { performanceHurdleId } }).then(({ data }) => data);
  }

  static clearPerformanceHurdleEstimateById(
    performanceHurdleId: Models.BM.IPerformanceHurdle['performanceHurdleId']
  ): Promise<any> {
    return axios
      .delete(ApiUrlsService.clearPerformanceHurdleEstimateDataById(performanceHurdleId))
      .then((response) => response.data)
      .catch(() => {});
  }
}
