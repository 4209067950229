import axios from 'axios';
import { SelectedRowsType } from '../../../components/Table/interfaces';
import Configs from '../../shared.configs';
import * as Models from '../../shared.models';
import ApiUrlsService from './api-urls.service';
import { getParsedResponseData } from './helpers';

const defaultHeaders = Configs.Grid.GridColumnConfig.GrantValuationConfig.reduce((acc, item) => {
  acc[item.name.toLowerCase() as keyof Models.BM.GrantValuationTableData] = true;
  return acc;
}, {} as Record<keyof Models.BM.GrantValuationTableData, true>);

export default class GrantValuation {
  static async getGrantValuationTableList(): Promise<
    Models.BM.ResponseTableDataType<Models.BM.GrantValuationTableData>
  > {
    return axios
      .get<Models.BM.WithHeaderTableResponse<Models.BM.GrantValuationTableData> | null>(
        ApiUrlsService.getGrantValuationTableList()
      )
      .then(({ data }) => {
        // add edit possibility for custom column headers
        if (Array.isArray(data?.header) && data?.header.length) {
          data.header.forEach((header) => {
            if (!defaultHeaders[header.name.toLowerCase() as keyof typeof defaultHeaders]) {
              header.isColumnTitleLink = true;
            }
          });
        }
        return getParsedResponseData<Models.BM.GrantValuationTableData>(
          data,
          Configs.Grid.GridColumnConfig.GrantValuationConfig
        );
      })
      .catch(() => null);
  }

  static uploadGrantValuationTableFile(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ApiUrlsService.uploadGrantValuationTableFile(), formData).then(({ data }) => data);
  }

  static deleteGrantValuationTableData(ids: SelectedRowsType<Models.BM.GrantValuationTableData>): Promise<any> {
    return axios.post(ApiUrlsService.deleteGrantValuationTableData(), { keys: ids }).then(({ data }) => data);
  }

  static checkIsColumnInUse(params: Models.BM.IOldHeaderData): Promise<boolean> {
    return axios.get(ApiUrlsService.checkIsColumnInUse(), { params }).then(({ data }) => data);
  }

  static updateGrantValuationTableColumn(newData: Models.BM.INewHeaderResultData): Promise<any> {
    return axios.post(ApiUrlsService.editColumn(), newData).then(({ data }) => data);
  }

  static deleteGrantValuationTableColumn(params: Models.BM.IOldHeaderData): Promise<any> {
    return axios.delete(ApiUrlsService.deleteColumn(), { params }).then(({ data }) => data);
  }

  static exportGrantValuationTableData(): Promise<any> {
    return axios.post(ApiUrlsService.exportGrantValuationTableData()).then(({ data }) => data);
  }

  static updateGrantValuationItem(newData: Models.BM.IGrantValuationData): Promise<any> {
    return axios.post(ApiUrlsService.saveGrantValuationData(), newData).then(({ data }) => data);
  }

  static calculateGrantValuationValue(calculateData: Models.BM.ICalculateValuationData): Promise<number> {
    return axios.post(ApiUrlsService.calculateGrantValuationValue(), calculateData).then(({ data = 0 }) => data);
  }
}
