import axios from 'axios';
import { ITableDataInfoRequest } from 'shared/models/models.bl';
import { SelectedRowsType } from '../../../components/Table/interfaces';
import * as Models from '../../shared.models';
import ApiUrlsService from './api-urls.service';
import { getParsedResponseData } from './helpers';
import Configs from '../../shared.configs';

export default class EmployeeStatus {
  static getEmployeeStatusTableList(): Promise<Models.BM.ResponseTableDataType<Models.BM.EmployeeStatusTableData>> {
    return axios
      .get(ApiUrlsService.getEmployeeStatusTableList())
      .then(({ data }) =>
        getParsedResponseData<Models.BM.EmployeeStatusTableData>(
          data,
          Configs.Grid.GridColumnConfig.EmployeeStatusConfig,
          false,
          false
        )
      )
      .catch(() => null);
  }

  static uploadEmployeeStatusTableFile(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ApiUrlsService.uploadEmployeeStatusTableFile(), formData).then(({ data }) => data);
  }

  static getEmployeeStatusTableData(data: ITableDataInfoRequest): Promise<any> {
    return axios.post(ApiUrlsService.getEmployeeStatusTableData(), data).catch(() => null);
  }

  static deleteEmployeeStatusTableData(ids: SelectedRowsType<Models.BM.EmployeeStatusTableData>): Promise<any> {
    return axios.post(ApiUrlsService.deleteEmployeeStatusTableData(), ids).then(({ data }) => data);
  }

  static exportEmployeeStatusTableData(): Promise<any> {
    return axios.post(ApiUrlsService.exportEmployeeStatusTableData()).then(({ data }) => data);
  }

  static updateEmployeeStatusItem(data: Models.BM.IEmployeeStatusData): Promise<any> {
    return axios.post(ApiUrlsService.saveEmployeeStatusData(), data).then((response) => response);
  }
}
