import { FC, ReactElement, useRef, MouseEvent } from 'react';
import { Tooltip, TooltipProps } from '@progress/kendo-react-tooltip';
import { ReactComponent as InfoIcon } from 'assets/svg/info_warning/info.svg';

import './IconTooltip.scss';

interface IProps {
  icon?: ReactElement;
  tooltipText?: string;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  usePortal?: boolean;
}

const IconTooltip: FC<IProps & Omit<TooltipProps, 'parentTitle'>> = ({
  icon = <InfoIcon />,
  tooltipText = '',
  anchorElement = 'target',
  position = 'top',
  openDelay = 100,
  className = '',
  appendTo,
  onClick,
  usePortal,
  ...restProps
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClick?.(e);
  };
  return (
    <div ref={wrapperRef} className="IconTooltip" onClick={onClick ? handleClick : undefined}>
      <Tooltip
        className={`IconTooltip__tooltip${className ? ` ${className}` : ''}`}
        anchorElement={anchorElement}
        position={position}
        openDelay={openDelay}
        appendTo={usePortal ? undefined : appendTo || wrapperRef.current}
        parentTitle
        {...restProps}
      >
        <i title={tooltipText} className="IconTooltip__iconWrapper">
          {icon}
        </i>
      </Tooltip>
    </div>
  );
};

export default IconTooltip;
