import type { FC } from 'react';
import type { PagerProps } from '@progress/kendo-react-data-tools';
import Pagination from '../../Table/Pagination/Pagination';

const Pager: FC<PagerProps> = ({ skip, take, total, onPageChange }) => {
  const currentPage = Math.floor(skip / take);
  const startCounterIdx = total ? skip : -1;
  const endCounterIdx = total ? Math.min(startCounterIdx + take, total) - 1 : -1;
  const totalPages = Math.ceil((total || 0) / take);
  const previousEnabled = currentPage > 0;
  const nextEnabled = currentPage + 1 < totalPages;

  const setPage = (page: number) => {
    onPageChange?.({
      target: {} as any,
      skip: (page > totalPages - 1 ? totalPages - 1 : page) * take,
      take,
      syntheticEvent: {} as any,
      nativeEvent: {} as any,
    });
  };
  const setFirstPage = () => {
    setPage(0);
  };
  const setLastPage = () => {
    setPage(totalPages - 1);
  };
  const setNextPage = () => {
    setPage(currentPage + 1);
  };
  const setPreviousPage = () => {
    setPage(currentPage - 1);
  };
  const handleChangePageSize = (size: number) => {
    onPageChange?.({
      target: {} as any,
      skip: 0,
      take: size,
      syntheticEvent: {} as any,
      nativeEvent: {} as any,
    });
  };
  return (
    <Pagination
      pagination={{
        totalPages,
        currentPage,
        setFirstPage,
        setLastPage,
        setNextPage,
        setPreviousPage,
        setPage,
        previousEnabled,
        nextEnabled,
        setPageSize: handleChangePageSize,
        pageSize: take,
        totalItems: total,
        startIndex: startCounterIdx,
        endIndex: endCounterIdx,
      }}
    />
  );
};

export default Pager;
