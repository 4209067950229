import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BellIcon } from 'assets/svg/bell/bell-outlined.svg';

import './NotificationPlaceholder.scss';

const NotificationPlaceholder: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="NotificationPlaceholder">
      <BellIcon />
      <span>{t('NotificationCenter.NoItems')}</span>
    </div>
  );
};

export default NotificationPlaceholder;
