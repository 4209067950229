import { FC } from 'react';
import ModalLayout from '../../ModalLayout';
import './DisplayContentModal.scss';

export interface IDisplayContentModalProps {
  title: string;
  visible: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  className?: string;
  confirmBtnTitle?: string;
  icon?: any;
}

const DisplayContentModal: FC<IDisplayContentModalProps> = ({
  onClose,
  onConfirm,
  confirmBtnTitle,
  title,
  visible,
  className,
  children,
}) => {
  return visible ? (
    <ModalLayout
      onClose={onClose}
      onConfirm={onConfirm}
      title={title}
      confirmBtnTitle={confirmBtnTitle}
      className={`${className ? ` ${className}` : ''}`}
    >
      <div className="DisplayModal__content">{children}</div>
    </ModalLayout>
  ) : null;
};

export default DisplayContentModal;
