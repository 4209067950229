import { GridCellProps } from '@progress/kendo-react-grid';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './CellEnableLink.scss';

interface IProps extends GridCellProps {
  onClick: (data: GridCellProps['dataItem']) => void;
}

const CellEnableLink: FC<IProps> = ({ dataItem, field, onClick }) => {
  const { t } = useTranslation();
  const value = field ? dataItem[field] : '';
  const onClickHandler = () => {
    onClick(dataItem);
  };

  return (
    <td className="editGrant" onClick={onClickHandler} title={value}>
      {dataItem[field || ''] || t('PerformanceHurdles.Table.Update')}
    </td>
  );
};

export default CellEnableLink;
