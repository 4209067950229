import { ExpenseReportStatus } from 'shared/models/enums.dl';
import ExpensesService from 'shared/services/bl/expenses.services';
import ToasterService from 'shared/services/bl/toaster.service';
import { TFunction } from 'react-i18next';
import { ModalStatus } from './enums';
import { Types } from './types';
import { getValidationModalData } from './helpers';
import type * as Interfaces from './interfaces';
import type { AsyncThunkActionType } from '../interfaces';

export const actions = {
  setStatus: (payload: Interfaces.PayloadType['status']) =>
    ({
      type: Types.SET_STATUS,
      payload,
    } as const),
  setIsCancel: (payload: Interfaces.PayloadType['isCancel']) =>
    ({
      type: Types.SET_IS_CANCEL,
      payload,
    } as const),
  setReportProgressData: (payload: Interfaces.PayloadType['reportProgressData']) =>
    ({
      type: Types.SET_REPORT_PROGRESS_DATA,
      payload,
    } as const),

  setStatusLoading: (payload: Interfaces.PayloadType['loading']) =>
    ({
      type: Types.STATUS_LOADING,
      payload,
    } as const),

  setValidationData: (payload: Interfaces.PayloadType['validationData']) =>
    ({
      type: Types.SET_VALIDATION_DATA,
      payload,
    } as const),

  resetStateData: () =>
    ({
      type: Types.RESET_STATE_DATA,
    } as const),
};

const thunks = {
  getExpenseStatus: (): AsyncThunkActionType<void> => async (dispatch) => {
    try {
      dispatch(actions.setStatusLoading(true));
      const { status, ...reportProgress } = await ExpensesService.getCalculationStatus();
      const isCalculating = status === ExpenseReportStatus.InProgress || status === ExpenseReportStatus.New;

      dispatch(actions.setIsCancel(isCalculating));
      dispatch(actions.setStatus(status));

      if (isCalculating) {
        dispatch(actions.setReportProgressData(reportProgress));
      }
    } catch {
      dispatch(actions.setStatus(null));
      dispatch(actions.setIsCancel(false));
    }
  },

  validateCalculation:
    (t: TFunction): AsyncThunkActionType<void> =>
    async (dispatch, getState) => {
      try {
        const { user } = getState().currentUser;
        const initialCalculationProgressData = {
          createdDate: new Date().toDateString(),
          percent: 0,
          firstName: user?.firstName || '',
          lastName: user?.lastName || '',
        };
        dispatch(actions.setStatus(ExpenseReportStatus.InProgress));
        dispatch(actions.setReportProgressData(user ? initialCalculationProgressData : null));
        const responseData = await ExpensesService.validateCalculation();
        let performanceHurdleIdFlag = true;

        if (responseData.currentReportCountExceeded) {
          const errorMsg = 'SnackBarMessage.ExceededMaximumCalculationLimit';
          ToasterService.error(errorMsg, true);
          throw new Error(errorMsg);
        }

        if (responseData.grantValuationCount === 0) {
          performanceHurdleIdFlag = false;
          dispatch(actions.setValidationData(getValidationModalData(ModalStatus.GRANT_VALUATION_EMPTY)));
          return;
        }

        if (responseData.validVestingFunctionCount === 0) {
          performanceHurdleIdFlag = false;
          dispatch(actions.setValidationData(getValidationModalData(ModalStatus.SHARE_REGISTER_EMPTY)));
          return;
        }

        if (responseData.schemesNotInValuationTable.length > 0) {
          performanceHurdleIdFlag = false;
          dispatch(actions.setValidationData(getValidationModalData(ModalStatus.INVALID_SHARE_REGISTER)));
          return;
        }
        if (responseData.unspecifiedPerformanceHurdleCount > 0 && performanceHurdleIdFlag) {
          dispatch(
            actions.setValidationData(
              getValidationModalData(
                ModalStatus.INVALID_PERFORMANCE_HURDLE_ID,
                responseData.unspecifiedPerformanceHurdleCount,
                t
              )
            )
          );
          return;
        }
        if (responseData.unspecifiedVestingFunctionCount > 0 && responseData.unspecifiedPerformanceHurdleCount === 0) {
          dispatch(
            actions.setValidationData(
              getValidationModalData(ModalStatus.PROCEED, responseData.unspecifiedVestingFunctionCount, t)
            )
          );
          return;
        }
        await dispatch(thunks.startCalculation());
      } catch {
        await dispatch(thunks.getExpenseStatus());
      }
    },

  startCalculation: (): AsyncThunkActionType<void> => async (dispatch) => {
    try {
      dispatch(actions.setValidationData(null));
      dispatch(actions.setStatus(ExpenseReportStatus.InProgress));
      await ExpensesService.startCalculation();
      await dispatch(thunks.getExpenseStatus());
    } catch (error) {
      await dispatch(thunks.getExpenseStatus());
    }
  },
};

export default {
  ...actions,
  ...thunks,
};
