import { FC, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '@progress/kendo-react-form';
import ModalLayout from 'components/ModalLayout';
import { IPerformanceHurdle, IPerformanceSubHurdle, IPerformanceVestingCondition } from 'shared/models/models.bl';
import Input from 'components/vendor/Input';
import Button from '../../Button';
import './PerformanceHurdle.scss';
import {
  validatePHAndSubPHIdAndNameAreValid,
  validateSubHurdleWeight,
  validateVestingConditionPercentageValues,
  UnitType,
  validateVestingConditionAmountValues,
  validateCombinedSubHurdleWeight,
  validateSubHurdleNameDuplicacy,
  phBasicData,
  uid,
} from './helper';
import SubhurdleControl from './SubhurdleControl';

interface IProps {
  visible: boolean;
  onCancel: any;
  data: IPerformanceHurdle | null | any;
  onConfirm: (data: any) => Promise<any>;
  isLoading?: boolean;
  validationMessage: string;
  readOnlyEditMode: boolean;
}

const PerformanceHurdelModal: FC<IProps> = ({
  visible,
  onCancel,
  data,
  onConfirm,
  isLoading,
  validationMessage,
  readOnlyEditMode,
}) => {
  const { t } = useTranslation();
  const refForm = useRef<Form>(null);
  const initialFormValues = useState(!data ? phBasicData : data);
  const [isModalValid, setModalvalid] = useState<boolean>(false);
  const [performanceHurdleData, setPHData] = useState<IPerformanceHurdle | null>(data);
  const isNew = !data?.uid;
  const [isFormModified, setFormModified] = useState(false);
  const initialSubHurdles = !data
    ? phBasicData.getPhBasicData().performanceSubHurdle
    : (data as IPerformanceHurdle).performanceSubHurdle;

  const checkForNewSubHurdle = (subHurdle: IPerformanceSubHurdle) => {
    if (subHurdle.subHurdleName.trim() === '') return true;

    const isFound = initialSubHurdles.find((sh) => {
      return sh.shUid === subHurdle.shUid;
    });
    return !isFound;
  };

  const handleCancel = () => {
    onCancel();
  };

  useEffect(() => {
    setPHData(data);
  }, [data]);

  // adding a new sub-hurdle section in Performance Hurdle dialog
  const addSubhurdleClick = (e: any) => {
    e.preventDefault();
    setModalvalid(false);
    const newSubHurdle: IPerformanceSubHurdle = {
      shUid: `sh-${uid()}`,
      subHurdleName: '',
      weightage: '',
      unit: 'P',
      performanceSubHurdleVesting: [
        {
          hurdleFrom: '',
          hurdleTo: '',
          vestingFrom: '',
          vestingTo: '',
        },
        {
          hurdleFrom: '',
          hurdleTo: '',
          vestingFrom: '',
          vestingTo: '',
        },
      ],
    };
    setPHData((prevState: any) => ({
      ...prevState,
      performanceSubHurdle: [...prevState.performanceSubHurdle, newSubHurdle],
    }));
  };
  const validateVestingConditions = (vestingConditions: IPerformanceVestingCondition[], unit: string) => {
    let isValid = false;
    const lastRow = vestingConditions.length - 1;
    for (let i = 0; i < vestingConditions.length; i++) {
      isValid =
        (unit === UnitType.Percentage
          ? validateVestingConditionPercentageValues(
              i === 0 && (vestingConditions[i].hurdleFrom === '0' || vestingConditions[i].hurdleFrom === '')
                ? 0
                : vestingConditions[i].hurdleFrom
            )
          : validateVestingConditionAmountValues(
              i === 0 && (vestingConditions[i].hurdleFrom === '0' || vestingConditions[i].hurdleFrom === '')
                ? 0
                : vestingConditions[i].hurdleFrom
            )) &&
        (unit === UnitType.Percentage
          ? validateVestingConditionPercentageValues(
              i === lastRow && (vestingConditions[i].hurdleTo === '0' || vestingConditions[i].hurdleTo === '')
                ? 0
                : vestingConditions[i].hurdleTo
            )
          : validateVestingConditionAmountValues(
              i === lastRow && (vestingConditions[i].hurdleTo === '0' || vestingConditions[i].hurdleTo === '')
                ? 0
                : vestingConditions[i].hurdleTo
            )) &&
        validateVestingConditionPercentageValues(
          i === 0 && vestingConditions[i].vestingFrom === '' ? 0 : vestingConditions[i].vestingFrom
        ) &&
        validateVestingConditionPercentageValues(
          i === lastRow && vestingConditions[i].vestingTo === '' ? 0 : vestingConditions[i].vestingTo
        );
      if (!isValid) break;
    }
    return isValid;
  };
  const validateSubHurdles = () => {
    let isValid = false;
    if (performanceHurdleData?.performanceSubHurdle) {
      for (let i = 0; i < performanceHurdleData?.performanceSubHurdle.length; i++) {
        const subHurdle = performanceHurdleData?.performanceSubHurdle[i];
        isValid =
          validatePHAndSubPHIdAndNameAreValid(subHurdle.subHurdleName) &&
          validateSubHurdleWeight(subHurdle.weightage) &&
          subHurdle.performanceSubHurdleVesting.length > 1 &&
          validateVestingConditions(subHurdle.performanceSubHurdleVesting, subHurdle.unit);
        if (!isValid) break;
      }
    }

    return isValid;
  };
  const checkFormValidity = () => {
    return (
      validatePHAndSubPHIdAndNameAreValid(performanceHurdleData?.performanceHurdleId) &&
      validateSubHurdles() &&
      validateCombinedSubHurdleWeight(performanceHurdleData?.performanceSubHurdle) &&
      !validateSubHurdleNameDuplicacy(performanceHurdleData?.performanceSubHurdle)
    );
  };

  const handleChange = (e: any) => {
    const value = e?.value;

    setModalvalid(validatePHAndSubPHIdAndNameAreValid(value));
    setFormModified(true);

    setPHData((prevState: any) => ({
      ...prevState,
      performanceHurdleId: value,
    }));
  };

  const handleConfirm = () => {
    if (isLoading) return;
    // data to save
    const isValid = checkFormValidity();
    if (isValid) onConfirm(performanceHurdleData);
    // @ts-ignorev\
    refForm?.current?.onSubmit?.();
  };

  useEffect(() => {
    setModalvalid(checkFormValidity());
  }, [performanceHurdleData]);

  return visible ? (
    <ModalLayout
      className="performance-hurdle-modal"
      title={t(isNew ? 'PerformanceHurdle.AddModal.Title' : 'PerformanceHurdle.EditModal.Title')}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      confirmBtnTitle={t('GrantValuation.ValuationModal.SaveButton')}
      cancelBtnTitle={t('GrantValuation.ValuationModal.CancelButton')}
      confirmDisabled={readOnlyEditMode || isLoading || !isModalValid}
      validationMessage={validationMessage}
      confirmLoading={isLoading}
    >
      <Form
        ref={refForm}
        initialValues={initialFormValues}
        render={() => {
          return (
            <>
              <hr className="performanceHurdle-border" />
              <div className="performanceHurdle-field">
                <Input
                  key="phId"
                  id="performanceHurdleId"
                  className="performance-hurdle-field"
                  onChange={handleChange}
                  value={performanceHurdleData?.performanceHurdleId}
                  placeholder={t('PerformanceHurdle.AddModal.PerformanceHurdleIdPlaceholder')}
                  validationMessage=""
                  label={t('PerformanceHurdle.AddModal.PerformanceHurdleId')}
                  valid={
                    !isFormModified || validatePHAndSubPHIdAndNameAreValid(performanceHurdleData?.performanceHurdleId)
                  }
                  disabled={readOnlyEditMode || !isNew}
                />
              </div>
              <div className="subhurdles-group">
                {performanceHurdleData?.performanceSubHurdle?.map((subhurdle, idx) => (
                  <SubhurdleControl
                    key={`sh-main-${idx}`}
                    index={idx}
                    phData={performanceHurdleData}
                    setPHData={setPHData}
                    isNewSubHurdle={checkForNewSubHurdle(subhurdle)}
                    readOnlyEditMode={readOnlyEditMode}
                  />
                ))}
              </div>
            </>
          );
        }}
      />
      <div className="add-sh-btn btn-group">
        <Button
          disabled={
            readOnlyEditMode ||
            (performanceHurdleData !== null && performanceHurdleData?.performanceSubHurdle.length >= 5)
          }
          styleType="outlined"
          color="primary"
          onClick={addSubhurdleClick}
        >
          {t('PerformanceHurdle.AddModal.AddSubHurdle')}
        </Button>
      </div>
      <hr className="performanceHurdle-border-bottom" />
    </ModalLayout>
  ) : null;
};
export default PerformanceHurdelModal;
