import axios from 'axios';

import * as Models from '../../shared.models';
import {
  ITrueUpCalculationRequest,
  ITrueUpCalculationResponse,
  ITrueUpExportRequest,
  ITrueUpInfoResponse,
  WithHeaderTableResponse,
  ITrueUpFilterProps,
  ITrueUpTableRequest,
} from '../../models/models.bl';

import ApiUrlsService from './api-urls.service';

const getRequestParams = (data?: Record<string, any>): Record<string, any> => {
  if (!data) return {} as Record<string, any>;
  return Object.keys(data).reduce((acc, key) => {
    if (data[key] || data[key] === 0) {
      acc[key] = data[key];
    }
    return acc;
  }, {} as Record<string, any>);
};

export default class TrueUp {
  static trueUpStartCalculation(fieldValues: ITrueUpCalculationRequest): Promise<ITrueUpCalculationResponse> {
    return axios.post(ApiUrlsService.trueUpStartCalculation(), fieldValues).then(({ data }) => data);
  }

  static getTrueUpInfoData(reportUId: string): Promise<ITrueUpInfoResponse> {
    return axios.post(ApiUrlsService.getTrueUpInfoData(), { reportUId }).then(({ data }) => data);
  }

  static getTrueUpTableData(reportUId: string, filters?: ITrueUpFilterProps): Promise<WithHeaderTableResponse<any>> {
    return axios
      .get(ApiUrlsService.getTrueUpTableData(), { params: { reportUId, ...getRequestParams(filters) } })
      .then(({ data }) => data);
  }

  static getNewReportTrueUpTableData(
    request: ITrueUpTableRequest
  ): Promise<Models.BM.IPaginatedTableResponseWithHeaders<any>> {
    return axios.post(ApiUrlsService.getNewReportTrueUpTableData(), request).then(({ data }) => data);
  }

  static getPreviousReportTrueUpTableData(
    request: ITrueUpTableRequest
  ): Promise<Models.BM.IPaginatedTableResponseWithHeaders<any>> {
    return axios.post(ApiUrlsService.getPreviousReportTrueUpTableData(), request).then(({ data }) => data);
  }

  static deleteAllTrueUpData(timeStamp: string): Promise<null> {
    return axios.delete(ApiUrlsService.deleteAllTrueUpData(timeStamp)).then(({ data }) => data);
  }

  static exportTrueUpTableData(body: ITrueUpExportRequest): Promise<void> {
    return axios.post(ApiUrlsService.exportTrueUpTableData(), body);
  }
}
