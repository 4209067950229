import { store } from '../../../store';
import { setUserToken, setUser } from '../../../store/user/actions';

import * as Models from '../../shared.models';
import { OIDCUser } from '../../../OIDCAuth/models/OIDCUser';

export default class AuthenticationService {
  static logOut = (deleteUser = true): void => {
    if (deleteUser) {
      store.dispatch(setUser(null));
    }
    this.clearToken();
  };

  static setToken(tokenObj: OIDCUser): void {
    store.dispatch(setUserToken(tokenObj));
  }

  static clearToken(): void {
    store.dispatch(setUserToken(null));
  }

  static isAuthenticated(tokenObj: OIDCUser): boolean {
    return !!(tokenObj || store.getState().currentUser.tokenObj);
  }

  static isAuthorized(user?: Models.DM.UserGetDto): boolean {
    const currentUser: Models.DM.UserGetDto = user || (store.getState().currentUser.user as Models.DM.UserGetDto);
    return !!currentUser;
  }

  static getToken(): string {
    const storeCurrentUser: Models.BM.UserProfile = store.getState().currentUser;
    const token: string = storeCurrentUser?.tokenObj?.accessToken || '';
    return token;
  }
}
