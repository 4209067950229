import type * as Interfaces from './interfaces';
import { Types } from './types';

const initialState: Interfaces.StoreType = {
  status: null,
  statusLoading: true,
  validationData: null,
  isCancel: false,
  reportProgressData: null,
};

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action: Interfaces.ExpensesViewActions = {} as Interfaces.ExpensesViewActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_STATUS: {
      return {
        ...state,
        status: action.payload,
        statusLoading: false,
        validationData: null,
      };
    }
    case Types.SET_REPORT_PROGRESS_DATA: {
      return {
        ...state,
        reportProgressData: action.payload,
      };
    }
    case Types.STATUS_LOADING: {
      return {
        ...state,
        statusLoading: action.payload,
      };
    }
    case Types.SET_VALIDATION_DATA: {
      return {
        ...state,
        validationData: action.payload,
        statusLoading: false,
      };
    }
    case Types.SET_IS_CANCEL: {
      return {
        ...state,
        isCancel: action.payload,
      };
    }
    case Types.RESET_STATE_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}
