import { ApiEntity, ReportsApiEntity } from '../../models/enums.bl';
import ConfigService from './config.service';
import { IFileUploadingApiEntity, IExportingDataApiEntity } from '../../models/models.bl';

const separator = '/';

export default class ApiUrlsService {
  static get GeoRelatedUrl(): string {
    return ConfigService.ApiUrls[ConfigService.GeoRelatedCode];
  }

  static logError(): string {
    return [this.GeoRelatedUrl, ApiEntity.Log, 'error'].join(separator);
  }

  static getUserGeoCode(): string {
    return [this.GeoRelatedUrl, ApiEntity.User, 'geoCode'].join(separator);
  }

  static getUserProfile(): string {
    return [this.GeoRelatedUrl, ApiEntity.User, 'profile'].join(separator);
  }

  static saveUserSettings(): string {
    return [this.GeoRelatedUrl, ApiEntity.User, 'settings'].join(separator);
  }

  static clearUserCash(): string {
    return [this.GeoRelatedUrl, ApiEntity.User, 'clearCache'].join(separator);
  }
  // Share Register -->

  static getShareRegisterTableList(): string {
    return [this.GeoRelatedUrl, ApiEntity.ShareRegister].join(separator);
  }

  static getShareRegisterData(): string {
    return [this.GeoRelatedUrl, ApiEntity.ShareRegister].join(separator);
  }

  static updateShareRegisterItem(): string {
    return [this.GeoRelatedUrl, ApiEntity.ShareRegister, 'save'].join(separator);
  }

  static uploadShareRegisterTableFile(): string {
    return [this.GeoRelatedUrl, ApiEntity.ShareRegister, 'upload'].join(separator);
  }

  static deleteShareRegisterTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.ShareRegister, 'delete'].join(separator);
  }

  static exportShareRegisterTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.ShareRegister, 'export'].join(separator);
  }

  static getVestingSchedules(): string {
    return [this.GeoRelatedUrl, ApiEntity.ShareRegister, 'vestingSchedules'].join(separator);
  }

  static checkCustomVestingScheduleForNewGrantId(): string {
    return [this.GeoRelatedUrl, ApiEntity.ShareRegister, 'CheckCustomVestingSchedule'].join(separator);
  }

  // <-- Share Register

  // Custom Vesting Schedule -->

  static getCustomVestingScheduleTableList(): string {
    return [this.GeoRelatedUrl, ApiEntity.CustomVestingSchedule, 'all'].join(separator);
  }

  static generateCustomVestingScheduleItem(): string {
    return [this.GeoRelatedUrl, ApiEntity.CustomVestingSchedule, 'vestingUnits', 'generate'].join(separator);
  }

  static updateCustomVestingScheduleItem(): string {
    return [this.GeoRelatedUrl, ApiEntity.CustomVestingSchedule].join(separator);
  }

  static deleteCustomVestingScheduleTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.CustomVestingSchedule, 'delete'].join(separator);
  }

  static exportCustomVestingScheduleTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.CustomVestingSchedule, 'export'].join(separator);
  }

  static uploadCustomVestingScheduleTableFile(): string {
    return [this.GeoRelatedUrl, ApiEntity.CustomVestingSchedule, 'upload'].join(separator);
  }

  // <-- Custom Vesting Schedule

  // Generic Vesting Schedule -->

  static getGenericVestingScheduleTableList(): string {
    return [this.GeoRelatedUrl, ApiEntity.GenericVestingSchedule].join(separator);
  }

  static updateGenericVestingScheduleItem(): string {
    return [this.GeoRelatedUrl, ApiEntity.GenericVestingSchedule].join(separator);
  }

  static deleteGenericVestingScheduleTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.GenericVestingSchedule, 'delete'].join(separator);
  }

  static validateGenericVestingSchedule(): string {
    return [this.GeoRelatedUrl, ApiEntity.GenericVestingSchedule, 'validate'].join(separator);
  }

  // <-- Generic Vesting Schedule

  // Employee Status -->

  static getEmployeeStatusTableList(): string {
    return [this.GeoRelatedUrl, ApiEntity.EmployeeStatus].join(separator);
  }

  static getEmployeeStatusTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.EmployeeStatus, 'data'].join(separator);
  }

  static saveEmployeeStatusData(): string {
    return [this.GeoRelatedUrl, ApiEntity.EmployeeStatus].join(separator);
  }

  static uploadEmployeeStatusTableFile(): string {
    return [this.GeoRelatedUrl, ApiEntity.EmployeeStatus, 'upload'].join(separator);
  }

  static deleteEmployeeStatusTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.EmployeeStatus, 'delete'].join(separator);
  }

  static exportEmployeeStatusTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.EmployeeStatus, 'export'].join(separator);
  }

  // <-- Employee Status

  // Grant Valuation -->

  static getGrantValuationTableList(): string {
    return [this.GeoRelatedUrl, ApiEntity.GrantValuation, 'valuationRef'].join(separator);
  }

  static uploadGrantValuationTableFile(): string {
    return [this.GeoRelatedUrl, ApiEntity.GrantValuation, 'upload'].join(separator);
  }

  static deleteGrantValuationTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.GrantValuation, 'delete'].join(separator);
  }

  static saveGrantValuationData(): string {
    return [this.GeoRelatedUrl, ApiEntity.GrantValuation, 'editTable'].join(separator);
  }

  static calculateGrantValuationValue(): string {
    return [this.GeoRelatedUrl, ApiEntity.GrantValuation, 'calculateFairValue'].join(separator);
  }

  static exportGrantValuationTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.GrantValuation, 'export'].join(separator);
  }

  static checkIsColumnInUse(): string {
    return [this.GeoRelatedUrl, ApiEntity.GrantValuation, 'planUsed'].join(separator);
  }

  static editColumn(): string {
    return [this.GeoRelatedUrl, ApiEntity.GrantValuation, 'editPlan'].join(separator);
  }

  static deleteColumn(): string {
    return [this.GeoRelatedUrl, ApiEntity.GrantValuation, 'deletePlan'].join(separator);
  }

  // <-- Grant Valuation

  // Activity Logs -->

  static getActivityLogsTableList(): string {
    return [this.GeoRelatedUrl, ApiEntity.ActivityLogs].join(separator);
  }

  static getActivityLogsPaginatedData(): string {
    return [this.GeoRelatedUrl, ApiEntity.ActivityLogs, 'getAll'].join(separator);
  }

  static exportActivityLogsTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.ActivityLogs, 'export'].join(separator);
  }

  // <-- Activity Logs

  // Expenses -->

  static getExpenseCalculationStatus(): string {
    return [this.GeoRelatedUrl, ApiEntity.ExpenseCalculations, 'state'].join(separator);
  }

  static validateExpenseCalculation(): string {
    return [this.GeoRelatedUrl, ApiEntity.ExpenseCalculations, 'data'].join(separator);
  }

  static startExpenseCalculation(): string {
    return [this.GeoRelatedUrl, ApiEntity.ExpenseCalculations, 'startCalculation'].join(separator);
  }

  static exportExpensesCalculation(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.MyIncentivePlanSummary, 'export'].join(
      separator
    );
  }

  static exportDetailedExpensesCalculation(): string {
    return [this.GeoRelatedUrl, ApiEntity.ExpenseCalculations, 'export'].join(separator);
  }

  static cancelExpensesCalculation(): string {
    return [this.GeoRelatedUrl, ApiEntity.ExpenseCalculations, 'cancel'].join(separator);
  }

  static expensesCalculationReportProgressHubUrl(): string {
    return [this.GeoRelatedUrl.replace(/\/api$/, ''), ApiEntity.GenerateSbpExpenses].join(separator);
  }

  // <-- Expenses

  // Reports -->

  static getReportList(): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports].join(separator);
  }

  static getReportSummaryTotalFilters(reportUId: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportUId),
      ReportsApiEntity.SummaryTotal,
      'filter',
    ].join(separator);
  }

  static getReportSummaryTotalTableList(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.SummaryTotal,
      'table',
    ].join(separator);
  }

  static exportSummaryTotalTableData(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.SummaryTotal, 'export'].join(separator);
  }

  static getReportSummaryEmployeeTableList(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.Employee,
      'table',
    ].join(separator);
  }

  static exportSummaryEmployeeTableData(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.Employee, 'export'].join(separator);
  }

  static getReportSummaryGeographicalFilters(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.Geographical,
      'filter',
    ].join(separator);
  }

  static getReportSummaryGeographicalTableList(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.Geographical,
      'table',
    ].join(separator);
  }

  static exportSummaryGeographicalTableData(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.Geographical, 'export'].join(separator);
  }

  static getReportSummaryDepartmentFilters(reportUId: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportUId),
      ReportsApiEntity.Department,
      'filter',
    ].join(separator);
  }

  static summaryDepartmentChart(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.Department, 'chart', 'get'].join(
      separator
    );
  }

  static getReportSummaryDepartmentTableList(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.Department,
      'search',
    ].join(separator);
  }

  static exportSummaryDepartmentTableData(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.Department, 'export'].join(separator);
  }

  static getReportSummaryPlanFilters(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.Plan,
      'filter',
    ].join(separator);
  }

  static getReportSummaryPlanTableList(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.Plan,
      'table',
    ].join(separator);
  }

  static getReportSummaryPlanChart(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.Plan, 'chart'].join(separator);
  }

  static getReportSummaryPlanPieChart(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.Plan, 'pieChart'].join(separator);
  }

  static exportSummaryPlanTableData(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.Plan, 'export'].join(separator);
  }

  static getReportDetailedTableList(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.Detailed,
      'table',
    ].join(separator);
  }

  static getReportFinalization(reportTimeStamp: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, encodeURIComponent(reportTimeStamp), 'finalization'].join(separator);
  }

  static finalizeReport(reportTimeStamp: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, encodeURIComponent(reportTimeStamp), 'finalize'].join(separator);
  }

  static deleteExpensesCalculation(reportTimeStamp: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, encodeURIComponent(reportTimeStamp)].join(separator);
  }

  static getDepartmentEntityList(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.Department,
      'entities',
    ].join(separator);
  }

  static getDepartmentAList(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.Department,
      'departmentAs',
    ].join(separator);
  }

  static getDepartmentBList(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.Department,
      'departmentBs',
    ].join(separator);
  }

  static getDepartmentCList(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.Department,
      'departmentCs',
    ].join(separator);
  }
  // ---------------------------------------ServerSidePaginatedAPI

  static getReportSummaryTotalPaginatedTableData(reportTUid: string, onlySums: boolean): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      reportTUid,
      ReportsApiEntity.SummaryTotal,
      ...(onlySums ? ['sums'] : []),
      'search',
    ].join(separator);
  }

  static getReportSummaryGeographicalPaginatedTableData(reportTUid: string, onlySums: boolean): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      reportTUid,
      ReportsApiEntity.Geographical,
      ...(onlySums ? ['sums'] : []),
      'search',
    ].join(separator);
  }

  static getReportSummaryDepartmentPaginatedTableData(reportTUid: string, onlySums: boolean): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      reportTUid,
      ReportsApiEntity.Department,
      ...(onlySums ? ['sums'] : []),
      'search',
    ].join(separator);
  }

  static getReportSummaryPlanPaginatedTableData(reportTUid: string, onlySums: boolean): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      reportTUid,
      ReportsApiEntity.Plan,
      ...(onlySums ? ['sums'] : []),
      'search',
    ].join(separator);
  }

  static getReportSummaryEmployeePaginatedTableData(reportTUid: string, onlySums: boolean): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      reportTUid,
      ReportsApiEntity.Employee,
      ...(onlySums ? ['sums'] : []),
      'search',
    ].join(separator);
  }
  // ----------------------------

  static getReportSummaryTotalChart(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.SummaryTotal, 'chart'].join(separator);
  }

  static getSummaryGeographicalChart(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.Geographical, 'chart'].join(separator);
  }

  // <-- Reports

  // <-- Provisional Expense

  static getProvisionalExpensePaginatedTableData(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.ProvisionExpense, reportUId, 'table'].join(separator);
  }

  static getProvisionalExpenseChartData(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.ProvisionExpense, reportUId, 'chart'].join(separator);
  }

  static getProvisionalExpenseFilters(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.ProvisionExpense, reportUId, 'filters'].join(separator);
  }

  static calculateProvisionalExpenseData(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.ProvisionExpense, reportUId, 'calculate'].join(separator);
  }

  static uploadProvisionalExpenseTableFile(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.ProvisionExpense, reportUId, 'upload'].join(separator);
  }

  static deleteProvisionalExpenseTableData(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.ProvisionExpense, reportUId].join(separator);
  }

  static exportProvisionalExpenseTableData(reportUId: string): string {
    return `${[this.GeoRelatedUrl, ApiEntity.ProvisionExpense, 'export'].join(separator)}?reportUId=${reportUId}`;
  }

  // <-- Provisional Expense

  // True UP -->

  static trueUpStartCalculation(): string {
    return [this.GeoRelatedUrl, ApiEntity.TrueUp, 'Calculate'].join(separator);
  }

  static getTrueUpInfoData(): string {
    return [this.GeoRelatedUrl, ApiEntity.TrueUp, 'Report'].join(separator);
  }

  static getTrueUpTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.TrueUp, 'TrueUpTable'].join(separator);
  }

  static getNewReportTrueUpTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.TrueUp, 'NewReportTable'].join(separator);
  }

  static getPreviousReportTrueUpTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.TrueUp, 'PreviousReportTable'].join(separator);
  }

  static deleteAllTrueUpData(timeStamp: string): string {
    return [this.GeoRelatedUrl, ApiEntity.TrueUp, encodeURIComponent(timeStamp)].join(separator);
  }

  static exportTrueUpTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.TrueUp, 'export'].join(separator);
  }

  // <-- True Up

  // Journal Entries -->

  static getJournalEntriesReportList(): string {
    return [this.GeoRelatedUrl, ApiEntity.JournalEntries, 'finalReports'].join(separator);
  }

  static getFilterOptionsList(): string {
    return [this.GeoRelatedUrl, ApiEntity.JournalEntries, 'filtersOptions'].join(separator);
  }

  static calculateData(): string {
    return [this.GeoRelatedUrl, ApiEntity.JournalEntries, 'calculate'].join(separator);
  }

  static exportJournalEntriesTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.JournalEntries, 'export'].join(separator);
  }

  //  <-- Journal Entries

  // Files -->

  static getExportedAndPublicFiles(): string {
    return [this.GeoRelatedUrl, ApiEntity.Files, 'search'].join(separator);
  }

  static downloadPrivateFile(uid: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Files, uid, 'download'].join(separator);
  }

  static getTemplateFiles(): string {
    return [this.GeoRelatedUrl, ApiEntity.Files, 'templates'].join(separator);
  }

  static deleteExportReportFiles(): string {
    return [this.GeoRelatedUrl, ApiEntity.Files, 'delete'].join(separator);
  }

  //  <-- Files

  // Notifications -->

  static notificationsHubUrl(): string {
    return [this.GeoRelatedUrl.replace(/\/api$/, ''), ApiEntity.Notifications].join(separator);
  }

  static getNotificationList(): string {
    return [this.GeoRelatedUrl, ApiEntity.Notifications].join(separator);
  }

  static deleteNotification<T>(id: T): string {
    return [this.GeoRelatedUrl, 'notification', id].join(separator);
  }

  //  <-- Notifications

  // FileUploading -->

  static getFileUploadingState(apiEntity: IFileUploadingApiEntity): string {
    return [this.GeoRelatedUrl, apiEntity, 'uploadingState'].join(separator);
  }

  // <-- FileUploading

  // DataExporting -->

  static getDataExportingStatus(apiEntity: IExportingDataApiEntity): string {
    return [this.GeoRelatedUrl, apiEntity, 'exportStatus'].join(separator);
  }

  // <-- DataExporting

  // Localization -->

  static getLocales(): string {
    return [this.GeoRelatedUrl, ApiEntity.Localization, 'locales'].join(separator);
  }

  static getAsset(): string {
    return [this.GeoRelatedUrl, ApiEntity.Localization, 'asset/helpcenter'].join(separator);
  }

  static getLoginLocaleKeys(): string {
    return [this.GeoRelatedUrl, ApiEntity.Localization, 'loginPageKeyValues'].join(separator);
  }

  static getLocaleKeys(): string {
    return [this.GeoRelatedUrl, ApiEntity.Localization, 'KeyValues'].join(separator);
  }

  // <-- Localization

  // Performance Hurdles -->

  static getPerformanceHurdlesTableList(): string {
    return [this.GeoRelatedUrl, ApiEntity.PerformanceHurdles, 'all'].join(separator);
  }

  static getPerformanceHurdlesData(): string {
    return [this.GeoRelatedUrl, ApiEntity.PerformanceHurdles, 'all'].join(separator);
  }

  static deletePerformanceHurdleTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.PerformanceHurdles, 'delete'].join(separator);
  }

  static addPerformanceHurdleData(): string {
    return [this.GeoRelatedUrl, ApiEntity.PerformanceHurdles, 'add'].join(separator);
  }

  static addOrUpdateEstimate(): string {
    return [this.GeoRelatedUrl, ApiEntity.PerformanceHurdles, 'addOrUpdateEstimate'].join(separator);
  }

  static updatePerformanceHurdleData(): string {
    return [this.GeoRelatedUrl, ApiEntity.PerformanceHurdles, 'update'].join(separator);
  }

  static getPerformanceHurdleDataById(): string {
    return [this.GeoRelatedUrl, ApiEntity.PerformanceHurdles].join(separator);
  }

  static getEstimateById(): string {
    return [this.GeoRelatedUrl, ApiEntity.PerformanceHurdles, 'getEstimate'].join(separator);
  }

  static clearPerformanceHurdleEstimateDataById(performanceHurdleId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.PerformanceHurdles, 'deleteEstimates', performanceHurdleId].join(separator);
  }

  // <-- Performance Hurdles

  // Manage Employee Contracts -->

  static uploadManageEmployeeContractsFiles(): string {
    return [this.GeoRelatedUrl, ApiEntity.ManageEmployeeContracts, 'upload'].join(separator);
  }

  static deleteManageEmployeeContractsTableRows(): string {
    return [this.GeoRelatedUrl, ApiEntity.ManageEmployeeContracts, 'delete'].join(separator);
  }

  static getManageEmployeeContractsTableData(): string {
    return [this.GeoRelatedUrl, ApiEntity.ManageEmployeeContracts, 'search'].join(separator);
  }

  static getGrantPlans(): string {
    return [this.GeoRelatedUrl, 'valuation', 'plans'].join(separator);
  }

  static getManageEmployeeContractsQTY(): string {
    return [this.GeoRelatedUrl, ApiEntity.ManageEmployeeContracts, 'completedCount'].join(separator);
  }

  static manageEmployeeContractsHubUrl(): string {
    return [this.GeoRelatedUrl.replace(/\/api$/, ''), ApiEntity.ManageEmployeeContracts].join(separator);
  }

  static getExtractedManageEmployeeContractData(uid: string): string {
    return [this.GeoRelatedUrl, ApiEntity.ManageEmployeeContracts, uid].join(separator);
  }

  static saveManageEmployeeContractDetails(): string {
    return [this.GeoRelatedUrl, ApiEntity.ManageEmployeeContracts, 'saveShareRegister'].join(separator);
  }

  static downloadPDFFile(uid: string): string {
    return [this.GeoRelatedUrl, ApiEntity.ManageEmployeeContracts, uid, 'file'].join(separator);
  }

  // <-- Manage Employee Contracts

  // <-- Expense Outlook

  static getReportSummaryExpenseOutlookFilters(reportTimeStamp: string): string {
    return [
      this.GeoRelatedUrl,
      ApiEntity.Reports,
      encodeURIComponent(reportTimeStamp),
      ReportsApiEntity.ExpenseOutlook,
      'filter',
    ].join(separator);
  }

  static getReportSummaryExpenseOutlookChart(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.ExpenseOutlook, 'chart'].join(separator);
  }

  static getReportSummaryExpenseOutlookPaginatedTableData(reportTUid: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportTUid, ReportsApiEntity.ExpenseOutlook, 'search'].join(
      separator
    );
  }

  static exportSummaryExpenseOutlookTableData(reportUId: string): string {
    return [this.GeoRelatedUrl, ApiEntity.Reports, reportUId, ReportsApiEntity.ExpenseOutlook, 'export'].join(
      separator
    );
  }

  // <-- Expense Outlook
}
