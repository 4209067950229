import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { IntlProvider, LocalizationProvider, load, loadMessages } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import { DEFAULT_LOCALE } from 'localization/helpers';
import LabelText from '../Label';
import CustomToggleIcon from './CustomToggleIcon';
import CustomCalendar from './CustomCalendar';
import CustomYearCalendar from './CustomYearCalendar';
import './FormDatePicker.scss';

const loadTranslations = async (locale: string): Promise<void> => {
  const result = await Promise.all([
    import(`cldr-numbers-full/main/${locale}/numbers.json`),
    import(`cldr-dates-full/main/${locale}/ca-gregorian.json`),
    import(`cldr-dates-full/main/${locale}/dateFields.json`),
    import(`cldr-dates-full/main/${locale}/timeZoneNames.json`),
  ]);
  await load(likelySubtags, currencyData, weekData, ...result.map((module) => module.default));
};

const FormDatePicker: FC<FieldRenderProps | any> = ({
  validationMessage,
  label,
  isRequiredIcon,
  optional,
  formatPlaceholder = { month: 'MM', day: 'DD', year: 'YYYY' },
  placeholder = 'MM/DD/YYYY',
  popupClassName = '',
  ...others
}) => {
  const { i18n, t } = useTranslation();
  const [currentLoadedLocale, setCurrentLoadedLocale] = useState<string>(DEFAULT_LOCALE.id);

  useEffect(() => {
    const loadLocales = async () => {
      if (i18n.language !== currentLoadedLocale) {
        // INFO: datepicker has en-US or en translation by default so do not necessary to load translations for this language
        if (i18n.language !== DEFAULT_LOCALE.id) {
          try {
            await loadTranslations(i18n.language);
          } catch {
            // INFO: we have en-US like language format so if first load fails - we need to check translations for en only
            const langParts = i18n.language.split('-');
            if (langParts.length > 1) {
              try {
                await loadTranslations(langParts[0]);
              } catch {
                // do nothing
              }
            }
          }
        }
        const controls = {
          datepicker: {
            toggleCalendar: t('Common.DatePicker.ToggleCalendarTooltip'),
          },
          calendar: {
            today: t('Common.DatePicker.TodayButton'),
          },
        };
        loadMessages(controls, i18n.language);
        setCurrentLoadedLocale(i18n.language);
      }
    };
    loadLocales();
  }, [i18n.language, currentLoadedLocale, t]);

  const popupSettings = useMemo(
    () => ({
      popupClass: `DeloitteDatePicker ${popupClassName}`,
    }),
    [popupClassName]
  );

  return (
    <div className="datePicker">
      <LabelText valid={others.valid} label={label} isRequiredIcon={isRequiredIcon} optional={optional} />
      <LocalizationProvider language={currentLoadedLocale}>
        <IntlProvider locale={currentLoadedLocale}>
          <DatePicker
            {...others}
            placeholder={placeholder}
            formatPlaceholder={formatPlaceholder}
            toggleButton={CustomToggleIcon}
            popupSettings={popupSettings}
            calendar={others?.topView === 'decade' ? CustomYearCalendar : CustomCalendar}
          />
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
};

export default FormDatePicker;
