import { PublicClientApplication } from '@azure/msal-browser';

enum PROVIDERS {
  AZURE = 'AZURE',
}

export const apiConfig = (window as any)?.ApiConfig || {
  CURRENT_ENV: 'DEV',
  CURRENT_OIDC_PROVIDER: PROVIDERS.AZURE,
};

// Msal Configurations
const providerConfig = apiConfig[apiConfig.CURRENT_OIDC_PROVIDER] || {
  REACT_APP_OIDC_URL: '',
  REACT_APP_OIDC_CLIENT_ID: '',
  REACT_APP_OIDC_REDIRECT_URL: '',
  REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URL: '',
};

const config = {
  auth: {
    authority: `${providerConfig.REACT_APP_OIDC_URL}`,
    clientId: providerConfig.REACT_APP_OIDC_CLIENT_ID,
    redirectUri: `${providerConfig.REACT_APP_OIDC_REDIRECT_URL}`,
    postLogoutRedirectUri: providerConfig.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URL,
  },
  cache: {
    storeAuthStateInCookie: true,
  },
};
// Authentication Parameters
export const loginRequest = {
  scopes: ['user.read'],
};

export const msalInstance = new PublicClientApplication(config);
