import { getInitialPaginatedTableData, GridColumnConfig } from 'shared/configs/grid.config';
import Utils from 'shared/shared.utils';
import type { EmployeeViewTableData } from 'shared/models/models.bl';
import { Types } from './types';
import type * as Interfaces from './interfaces';

const initialPaginatedTableData = getInitialPaginatedTableData<EmployeeViewTableData>(
  GridColumnConfig.GeographicalViewConfig
);

const initialState: Interfaces.StoreType = {
  generatedReport: null,
  loading: false,
  data: [],
  headers: null,
  onlySumRows: [],
  paginatedTableData: initialPaginatedTableData,
};

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action: Interfaces.EmployeeViewActions = {} as Interfaces.EmployeeViewActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_GENERATED_REPORT: {
      return { ...state, generatedReport: action.payload };
    }
    case Types.SET_DATA_LOADING: {
      return { ...state, loading: action.payload };
    }
    case Types.SET_TABLE_DATA: {
      const { getRows, getHeaders } = Utils.getCorrectTableDataFromResponse<EmployeeViewTableData>(action.payload);
      const data = getRows(state.data);
      const headers = getHeaders(state.headers);
      return data !== state.data || headers !== state.headers
        ? {
            ...state,
            data,
            headers,
            onlySumRows: headers?.[0]?.name ? Utils.getOnlySumRows<EmployeeViewTableData>(data, headers[0].name) : [],
          }
        : state;
    }
    case Types.SET_PAGINATED_TABLE_DATA: {
      let paginatedTableData = action.payload || initialPaginatedTableData;
      if (!paginatedTableData.tableData) {
        paginatedTableData = {
          ...paginatedTableData,
          tableData: initialPaginatedTableData.tableData,
        };
      }
      return {
        ...state,
        loading: false,
        paginatedTableData,
      };
    }
    default:
      return state;
  }
}
