import { FC, useRef, ChangeEvent } from 'react';
import { ReactComponent as CheckedIcon } from '../../assets/svg/checkbox/checked.svg';
import { ReactComponent as IndeterminateIcon } from '../../assets/svg/checkbox/indeterminate.svg';
import './FormCheckbox.scss';

interface IProps {
  id?: string;
  checked: boolean;
  indeterminate?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  className?: string;
  disabled?: boolean;
}

const FormCheckbox: FC<IProps> = ({
  id = '',
  checked = false,
  indeterminate = false,
  onChange,
  label = '',
  className = '',
  disabled = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const formCheckboxClassList = new Set(['FormCheckbox', disabled ? 'FormCheckbox--disabled' : '', className]);

  const handleClickLabel = () => {
    if (disabled) return;
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    onChange?.(e);
  };

  const isCheckedClassName = checked || indeterminate;

  return (
    <div className={Array.from(formCheckboxClassList).join(' ')}>
      <input
        ref={inputRef}
        type="checkbox"
        className="FormCheckbox__input"
        id={id}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
      <label htmlFor={id} className="FormCheckbox__label" onClick={handleClickLabel}>
        <div
          className={`FormCheckbox__square${!label ? ' FormCheckbox__square--no-label' : ''}${
            isCheckedClassName ? ' FormCheckbox__square--checked' : ''
          }`}
        >
          <span className={`FormCheckbox__icon${isCheckedClassName ? ' FormCheckbox__icon--checked' : ''}`}>
            {checked ? <CheckedIcon /> : <IndeterminateIcon />}
          </span>
        </div>
        <span className={`FormCheckbox__label-text${isCheckedClassName ? ' FormCheckbox__label-text--checked' : ''}`}>
          {label}
        </span>
      </label>
    </div>
  );
};

export default FormCheckbox;
