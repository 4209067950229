import { IFileUploadingApiEntity } from 'shared/models/models.bl';
import FileUploadingService from 'shared/services/bl/fileUploading.service';
import { FILE_UPLOADING_VIEW_MAP } from 'shared/constants/fileUploading';
import { Types } from './types';
import type * as Interfaces from './interfaces';
import { AsyncThunkActionType } from '../interfaces';

export const actions = {
  setIsUploading: (payload: Interfaces.SetIsFileUploadingPayloadType) =>
    ({
      type: Types.SET_IS_UPLOADING,
      payload,
    } as const),
};

const thunks = {
  getUploadingState:
    (view: IFileUploadingApiEntity): AsyncThunkActionType<boolean> =>
    async (dispatch) => {
      try {
        const reportStatus = await FileUploadingService.getFileUploadingState(view);
        const isUploading = FileUploadingService.checkIsUploadingStatus(reportStatus);

        dispatch(actions.setIsUploading({ value: isUploading, view: FILE_UPLOADING_VIEW_MAP[view] }));
        return isUploading;
      } catch {
        dispatch(actions.setIsUploading({ value: false, view: FILE_UPLOADING_VIEW_MAP[view] }));
        return false;
      }
    },
};

export default {
  ...actions,
  ...thunks,
};
