import axios from 'axios';
import Services from 'shared/shared.services';
import { Types } from './types';
import type { AsyncThunkActionType } from '../../interfaces';
import type * as Interfaces from './interfaces';

export const actions = {
  setDataLoading: (payload: Interfaces.PayloadType['loading']) =>
    ({
      type: Types.SET_DATA_LOADING,
      payload,
    } as const),
  setGeneratedReport: (payload: Interfaces.PayloadType['generatedReport']) =>
    ({
      type: Types.SET_GENERATED_REPORT,
      payload,
    } as const),
  setTableData: (payload: Interfaces.PayloadType['dataAndHeaders']) =>
    ({
      type: Types.SET_TABLE_DATA,
      payload,
    } as const),
  setPaginatedTableData: (payload: Interfaces.PayloadType['paginatedTableData']) =>
    ({
      type: Types.SET_PAGINATED_TABLE_DATA,
      payload,
    } as const),
};

const thunks = {
  getData:
    (reportTimeStamp: string): AsyncThunkActionType<null> =>
    async (dispatch) => {
      try {
        dispatch(actions.setDataLoading(true));
        const responseData = await Services.BL.SBPExpensesReports.getSummaryEmployeeTableList(reportTimeStamp);
        dispatch(actions.setTableData(responseData));
        dispatch(actions.setGeneratedReport(reportTimeStamp));
        dispatch(actions.setDataLoading(false));
        return null;
      } catch {
        dispatch(actions.setDataLoading(false));
        return null;
      }
    },
  getPaginatedData:
    (
      ...params: Parameters<typeof Services.BL.SBPExpensesReports.getSummaryEmployeePaginatedTableData>
    ): AsyncThunkActionType<void> =>
    async (dispatch, getState) => {
      const [uid, ...rest] = params;
      const reportUId = getState().reports.summaryTotal.generatedReport || uid;
      try {
        dispatch(actions.setDataLoading(true));
        const responseData = await Services.BL.SBPExpensesReports.getSummaryEmployeePaginatedTableData(
          reportUId,
          ...rest
        );
        dispatch(actions.setPaginatedTableData(responseData));
        dispatch(actions.setGeneratedReport(params[0]));
      } catch (err) {
        // INFO: for now request can be canceled by next same request only so we no need to change loading state
        if (!axios.isCancel(err)) dispatch(actions.setDataLoading(false));
      }
    },
};

export default {
  ...actions,
  ...thunks,
};
