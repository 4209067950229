import type * as Interfaces from './interfaces';
import { Types } from './types';

const initialState = {
  currentEmployeeContractId: '',
} as Interfaces.StoreType;

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action = {} as Interfaces.ManagerEmployeeContractsActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_CURRENT_EMPLOYEE_CONTRACT_ID: {
      return {
        ...state,
        currentEmployeeContractId: action.payload,
      };
    }
    default:
      return state;
  }
}
