import { Form } from '@progress/kendo-react-form';
import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect, useCallback, RefObject } from 'react';

import { parseErrorsMessages, trimValue } from 'components/Modals/helpers';

interface IProps<T, F> {
  onEdit?: (data: T) => void;
  onCancel: () => void;
  onConfirm: (data: T) => Promise<any>;
  visible: boolean;
  data: F;
  createObjectToSubmit?: (data: F) => T;
  isLoading?: boolean;
  allowConfirmIfNoChanges?: boolean;
  preventTrimValues?: Record<keyof IProps<T, F>['data'], boolean | undefined>;
}

interface IHookReturnType<F> {
  refForm: RefObject<Form>;
  formObject: F;
  validationMessage: string;
  clearDateFlag: boolean;
  onEdit: () => void;
  onCancel: () => void;
  clearDate: (name: string) => void;
  onConfirm: () => void;
  handleConfirmError: (err: any) => void;
  onSubmit: (v: any) => void;
  onChange: (v: any) => void;
  setValueByNameToFormObject: (name: string, value: string) => void;
}

type ConfirmType = 'edit' | 'confirm';

export const useModalActiveState = <T, F>({
  onEdit,
  onCancel,
  onConfirm,
  visible,
  data,
  createObjectToSubmit,
  isLoading,
  allowConfirmIfNoChanges,
  preventTrimValues,
}: IProps<T, F>): IHookReturnType<F> => {
  const confirmType = useRef<ConfirmType>('confirm');
  const { t } = useTranslation();
  const refForm = useRef<Form>(null);
  const [formObject, setFormObject] = useState<F>({} as F);
  const [clearDateFlag, setClearDateFlag] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');

  useEffect(() => {
    setFormObject(data);
  }, [visible]);

  const handleChange = useCallback(({ value, target: { name } }: any) => {
    setFormObject((prev) => ({
      ...prev,
      [name]: !preventTrimValues?.[name as keyof (T | F)] ? trimValue(value) : value,
    }));
  }, []);
  const handleClearDate = useCallback((name: string) => {
    const arr = [
      { name: 'terminationDate', value: null },
      { name: 'terminationType', value: '' },
    ];
    if (name === 'terminationDate') {
      setClearDateFlag(true);
      arr.forEach((element) => {
        refForm?.current?.onChange(element.name, { value: element.value });
        setFormObject((prev) => ({
          ...prev,
          [element.name]: !preventTrimValues?.[element.name as keyof (T | F)]
            ? trimValue(element.value)
            : element.value,
        }));
      });
    } else {
      setClearDateFlag(false);
      refForm?.current?.onChange(name, { value: null });
      const value = null;
      setFormObject((prev) => ({
        ...prev,
        [name]: !preventTrimValues?.[name as keyof (T | F)] ? trimValue(value) : value,
      }));
    }
  }, []);
  const handleCancel = useCallback(() => {
    onCancel();
    setFormObject({} as F);
    setValidationMessage('');
  }, []);

  const handleConfirmError = useCallback((err: any) => {
    if (!err?.response?.data) return;
    let message = '';
    const { data: errorData } = err.response;

    if (errorData && typeof errorData === 'string') {
      message = t(errorData);
    } else if (errorData.errors) {
      message = `${parseErrorsMessages(errorData.errors, t)}`;
    }

    setValidationMessage(message);
  }, []);

  const sendRequest = useCallback(
    (resultData: T | F): void => {
      setValidationMessage('');
      const result = createObjectToSubmit ? createObjectToSubmit({ ...resultData } as F) : (resultData as T);
      if (confirmType.current === 'confirm') {
        onConfirm(result).catch(handleConfirmError);
      } else {
        onEdit?.(result);
      }
    },
    [formObject, onConfirm, createObjectToSubmit, onEdit]
  );

  const onFormSubmit = useCallback(
    (formData: T | F) => {
      if (!formData) return;
      sendRequest(formObject);
    },
    [formObject, sendRequest]
  );

  const runFormSubmitAdapter = useCallback(() => {
    if (isLoading) return;
    if (
      refForm?.current &&
      !refForm.current.isFormModified(refForm.current.modified, refForm.current.fields) &&
      refForm.current.isFormValid(refForm.current.errors) &&
      allowConfirmIfNoChanges
    ) {
      sendRequest(data);
    } else {
      // @ts-ignore
      refForm?.current?.onSubmit();
    }
  }, [data, sendRequest]);

  const handleConfirm = useCallback(() => {
    confirmType.current = 'confirm';
    runFormSubmitAdapter();
  }, [runFormSubmitAdapter]);

  const handleEdit = useCallback(() => {
    confirmType.current = 'edit';
    runFormSubmitAdapter();
  }, [runFormSubmitAdapter]);

  const setValueByNameToFormObject = useCallback((name = '', value = '') => {
    setFormObject((prev) => ({ ...prev, [name]: value }));
  }, []);

  return {
    refForm,
    formObject,
    clearDateFlag,
    validationMessage,
    onEdit: handleEdit,
    onCancel: handleCancel,
    clearDate: handleClearDate,
    onConfirm: handleConfirm,
    handleConfirmError,
    onSubmit: onFormSubmit,
    onChange: handleChange,
    setValueByNameToFormObject,
  };
};
