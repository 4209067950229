import { StoreName } from '../../storeName';
import { ReportsStoreName } from '../storeName';
import { TypeName } from './enums';

const baseTypeName = `${StoreName.Reports}/${ReportsStoreName.SummaryTotal}/` as const;

export const Types = {
  [TypeName.SET_GENERATED_REPORT]: `${baseTypeName}${TypeName.SET_GENERATED_REPORT}`,
  [TypeName.SET_DATA_LOADING]: `${baseTypeName}${TypeName.SET_DATA_LOADING}`,
  [TypeName.SET_TABLE_FILTERS]: `${baseTypeName}${TypeName.SET_TABLE_FILTERS}`,
  [TypeName.RECALCULATE_TOTALS]: `${baseTypeName}${TypeName.RECALCULATE_TOTALS}`,
  [TypeName.SET_DATA_FOR_FINALIZATION]: `${baseTypeName}${TypeName.SET_DATA_FOR_FINALIZATION}`,
  [TypeName.SET_FILTERS_LOADING]: `${baseTypeName}${TypeName.SET_FILTERS_LOADING}`,
  [TypeName.SET_PAGINATED_TABLE_DATA]: `${baseTypeName}${TypeName.SET_PAGINATED_TABLE_DATA}`,
  [TypeName.SET_CHART_LOADING]: `${baseTypeName}${TypeName.SET_CHART_LOADING}`,
  [TypeName.SET_CHART_DATA]: `${baseTypeName}${TypeName.SET_CHART_DATA}`,
} as const;
