import { ITrueUpFilterProps, ColumnConfiguration, TrueUpReportTableData } from 'shared/models/models.bl';

export const getTableHeadersAfterFiltering = (
  filters?: ITrueUpFilterProps,
  newHeaders?: ColumnConfiguration<TrueUpReportTableData>[],
  prevHeaders?: ColumnConfiguration<TrueUpReportTableData>[],
  savePreviousHeaders = false
): ColumnConfiguration<TrueUpReportTableData>[] => {
  const hasFilters = !!filters && Object.values(filters).some((value) => !!value);

  if ((hasFilters || savePreviousHeaders) && !newHeaders?.length) {
    return prevHeaders?.length ? prevHeaders : [];
  }
  return newHeaders?.length ? newHeaders : [];
};
