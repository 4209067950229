import type { FC } from 'react';
import type { GridHeaderCellProps } from '@progress/kendo-react-grid';
import type { ISelected } from 'components/MipTable/MipTable';
import FormCheckbox from 'components/FormCheckbox/FormCheckbox';

interface IProps extends GridHeaderCellProps {
  selected: ISelected;
  setSelected: (param: ISelected) => void;
  disabled?: boolean;
}

const HeaderCellCheckbox: FC<IProps> = ({ selected, setSelected, disabled }) => {
  const onChangeHandler = () => {
    setSelected({ keys: [], deleteAll: !selected.deleteAll });
  };

  return (
    <span className="headerCellCheckbox">
      <FormCheckbox
        checked={selected.deleteAll && selected.keys.length < 1}
        indeterminate={selected.deleteAll && selected.keys.length > 0}
        onChange={onChangeHandler}
        disabled={disabled}
      />
    </span>
  );
};

export default HeaderCellCheckbox;
