import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import UserDataService from 'shared/services/dl/user.data.service';
// import AuthenticationService from '../services/bl/authentication.service';

export const useLogoutFromApp = (/* deleteUser = true */): (() => Promise<void>) => {
  const { instance } = useMsal();
  const getRedirectUri = instance.getConfiguration().auth.postLogoutRedirectUri;
  const logout = (): void => {
    localStorage.clear();
    instance.logoutRedirect({
      postLogoutRedirectUri: getRedirectUri,
    });
  };

  return useCallback(
    () =>
      UserDataService.clearUserCash().then(() => {
        // AuthenticationService.logOut(deleteUser);
        logout();
      }),
    [logout]
  );
};
