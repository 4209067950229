import { cloneElement } from 'react';
import { filterBy } from '@progress/kendo-data-query';
import type { ItemRenderer, ValueRenderer, GetFilteredOptions } from './interfaces';

export const itemRenderer: ItemRenderer = (li, { dataItem }) => {
  return cloneElement(li, { ...li.props, title: dataItem?.text ? `${dataItem.text}` : '' });
};

export const valueRenderer: ValueRenderer = (element, optionData) => {
  return cloneElement(element, {
    ...element.props,
    title: optionData?.text && optionData?.id ? `${optionData.text}` : '',
  });
};

export const getFilteredOptions: GetFilteredOptions = (options, filter) => filterBy([...options], filter);
