import RegExps from 'shared/shared.regExps';
import { normalizeDateString, prepareDateForBE } from 'shared/utils/dateFormatter';
import { TFunction } from 'react-i18next';

export const errorText = 'The input field is not in the correct format.';
export const minNumber = -2147483648;
export const maxNumber = 2147483648;
export const maxLength = 200;
export const maxLengthGrantPlanName = 40;

const numberValidatorCreator =
  (min: number, max: number, text = 'Input value') =>
  (value: number): string => {
    return value >= min && value <= max ? '' : `${text} from ${min} to ${max}`;
  };
export const trimValue = (value: any): any => (typeof value === 'string' ? value.trim() : value);
const inputNumberValidator = numberValidatorCreator(minNumber, maxNumber);
const positiveNumberValidator = numberValidatorCreator(0, maxNumber);
const riskFreeRateAndDividendYieldValidator = numberValidatorCreator(0, 100);
const volatilityValidator = riskFreeRateAndDividendYieldValidator;
const genericVestingMonthsValidator = numberValidatorCreator(1, 60);

export const valuationValidator = (value: number): string => {
  return String(value).length <= 30 ? '' : errorText;
};
export const requiredTextInputValidator = (value: string): string => {
  const result = trimValue(value);
  if (!result && result !== 0) return errorText;
  return '';
};
export const validateSpecialCharacters = (value: string): string => {
  return value && !RegExps.specialCharacters.test(value.trim())
    ? "Field only accepts a-z A-Z 0-9 . , -_ + = ' # @ & ( ) { | } [ ] : ; / ! ? $ % \\s (spaces)"
    : '';
};
export const validatePerformanceHurdleSpecialCharacter = (value: string): string => {
  return value && !RegExps.performanceHurdle.test(value.trim())
    ? 'Field only accepts a-z, A-Z, 0-9, semi colon and colon, hyphens, and spaces'
    : '';
};
export const validateValuationSpecialCharacters = (value: string): string => {
  return value && !RegExps.numbersCharacters.test(value) ? 'Field only accepts a-z A-Z 0-9' : '';
};
export const validateAlphaNumeric = (value: string): string => {
  return value && !RegExps.alphaNumeric.test(value) ? 'Field only accepts a-z A-Z 0-9' : '';
};

export const validSharePrice = [requiredTextInputValidator, inputNumberValidator];
export const validMaturityTimeAndExercisePrice = [requiredTextInputValidator, positiveNumberValidator];
export const validRiskFreeRate = [requiredTextInputValidator, riskFreeRateAndDividendYieldValidator];
export const validVolatility = [requiredTextInputValidator, volatilityValidator];
export const validateFieldByTextNumber = [requiredTextInputValidator, inputNumberValidator];
export const validateFieldByTextSpecialCharacters = [
  requiredTextInputValidator, // validateSpecialCharacters // TODO uncomment this method for validating fields
];
export const validateValuationFieldByTextSpecialCharacters = [
  requiredTextInputValidator,
  validateValuationSpecialCharacters,
];
export const reportNameValidator = [requiredTextInputValidator, validateAlphaNumeric];
export const forfeitureRateValidator = riskFreeRateAndDividendYieldValidator;
export const grantLifeValidator = [
  requiredTextInputValidator,
  numberValidatorCreator(1, 240, 'Field only accepts numbers'),
];
export const validGenericVestingMonths = [requiredTextInputValidator, genericVestingMonthsValidator];

export const parseErrorsMessages = (errorsObj: { [key: string]: string[] }, t: TFunction): string => {
  const cache: { [key: string]: boolean } = {};

  return Object.values(errorsObj)
    .flat()
    .reduce((acc, el: string) => {
      if (!cache[el]) {
        acc += ` ${t(el)} `;
        cache[el] = true;
      }

      return acc;
    }, '');
};

export const formatToDateObject = (date: any): Date | null => {
  const { isDate, value } = normalizeDateString(date);
  return isDate ? new Date(value) : null;
};
export const convertValueToNumber = (value: string): number => (value ? parseInt(value, 10) : 0);
export const convertDateToISO = (date: Date | null): string | null => (date ? prepareDateForBE(date) : null);

export const getYearsData = (): { currentYear: number; availableYears: Array<{ id: number; text: string }> } => {
  const minYear = 2012;
  const currentYear = new Date().getFullYear();
  const maxYear = Math.max(2041, currentYear);

  const availableYears = new Array(maxYear - minYear + 1).fill('').map((_, idx) => {
    const year = minYear + idx;
    return {
      id: year,
      text: `${year}`,
    };
  });
  return {
    currentYear,
    availableYears,
  };
};

export const getYearNameFromColumnName = (columnName?: string): { year: number; name: string } => {
  const [year = '', name = '']: string[] = (columnName || '').split('_');
  return { year: +year, name };
};

export const removeExponentialFormSmallFloat = (value: number | string): string | number => {
  if (Number.isNaN(+value) || +value > 0.000001) return value; // INFO: lower will be exp form
  const str = `${value}`;
  const [main, pow] = str.split('e');
  const powNum = +pow;
  if (Number.isNaN(powNum) || powNum >= 0) return value;
  const [int, rest] = main.split('.');
  const zerosQty = Math.abs(powNum) - int.length;
  return `0.${Array(zerosQty).fill('0').join('')}${int}${rest || ''}`;
};
