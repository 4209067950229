import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Configs from 'shared/shared.configs';
import { UsePaginationType } from 'shared/hooks/usePagination/usePagination';
import { ReactComponent as ForwardLeftIcon } from 'assets/svg/chevrons/chevron-left-forward.svg';
import { ReactComponent as ForwardRightIcon } from 'assets/svg/chevrons/chevron-right-forward.svg';
import { ReactComponent as LeftIcon } from 'assets/svg/chevrons/chevron-left-bold.svg';
import { ReactComponent as RightIcon } from 'assets/svg/chevrons/chevron-right-bold.svg';
import Input, { InputChangeEvent } from 'components/vendor/Input';
import SingleSelect, { DropDownListChangeEvent } from 'components/vendor/SingleSelect';

import './Pagination.scss';

interface IProps {
  pagination: UsePaginationType;
  disabled?: boolean;
}

const dots = '...' as const;
const maxPagesSlice = 4;
const pagesSlice = maxPagesSlice - 1; // if 3 will be 0, 1, 2 (3 items)
const maxPageButtons = 10; // includes ... to

const Pagination: FC<IProps> = ({
  pagination: {
    totalPages,
    currentPage,
    setFirstPage,
    setLastPage,
    setNextPage,
    setPreviousPage,
    setPage,
    previousEnabled,
    nextEnabled,
    setPageSize,
    pageSize,
    totalItems,
    startIndex,
    endIndex,
  },
  disabled,
}) => {
  const { t } = useTranslation();
  const [manualPage, setManualPage] = useState<string>(`${currentPage}`);

  const pages = useMemo(() => {
    let result: Array<typeof dots | number> = totalPages ? new Array(totalPages).fill('').map((_, idx) => idx + 1) : [];
    if (result.length > maxPageButtons) {
      if (currentPage > pagesSlice && currentPage < totalPages - pagesSlice) {
        result = [dots, ...result.slice(currentPage - pagesSlice, currentPage + pagesSlice), dots];
      } else {
        result = [...result.slice(0, maxPagesSlice), dots, ...result.slice(-pagesSlice)];
      }
    }
    return result;
  }, [totalPages, currentPage]);

  const handleChangePage = ({ value }: InputChangeEvent) => {
    if (disabled) return;
    const number = value ? value.replace(/\D/g, '') : '';
    setManualPage(number);
    if (number) setPage(+number ? +number - 1 : 0);
  };

  const handleChangePageSize = ({ value }: DropDownListChangeEvent) => {
    if (disabled) return;
    setPageSize((value?.id || Configs.Grid.PageSizes[0].id) as number);
  };

  const handleChoosePage = (page: typeof dots | number) => () => {
    if (disabled) return;
    if (page === dots) return;
    const pageIdx = page - 1;
    if (currentPage === pageIdx) return;
    setPage(pageIdx);
  };

  const handleBlurManualPage = () => {
    if (disabled) return;
    if (manualPage === '') {
      setManualPage(`${currentPage}`);
    }
  };

  const previousArrowDisabledClassName = !previousEnabled || disabled ? ' Deloitte__Pagination__arrow--disabled' : '';
  const nextArrowDisabledClassName = !nextEnabled || disabled ? ' Deloitte__Pagination__arrow--disabled' : '';

  return (
    <div className="Deloitte__Pagination__wrapper">
      <div className="Deloitte__Pagination">
        <div className="Deloitte__Pagination__colWrapper">
          <div className="Deloitte__Pagination__pageSize">
            <SingleSelect
              options={Configs.Grid.PageSizes}
              value={Configs.Grid.PageSizes.find((item) => item.id === pageSize)}
              onChange={handleChangePageSize}
              disabled={disabled}
            />
            <span>
              {startIndex + 1 < 0 ? 0 : startIndex + 1}—{endIndex + 1 < 0 ? 0 : endIndex + 1}{' '}
              {t('Common.Table.Pagination.Of')} <strong>{totalItems}</strong>
            </span>
          </div>
        </div>
        <div className="Deloitte__Pagination__colWrapper">
          {totalPages > 0 && (
            <div className="Deloitte__Pagination__main">
              <div className="Deloitte__Pagination__block">
                <ul className="Deloitte__Pagination__pageItems">
                  <li
                    onClick={setFirstPage}
                    className={`Deloitte__Pagination__arrow${previousArrowDisabledClassName} Deloitte__Pagination__arrow__forwardLeft`}
                  >
                    <ForwardLeftIcon />
                  </li>
                  <li
                    onClick={setPreviousPage}
                    className={`Deloitte__Pagination__arrow${previousArrowDisabledClassName} Deloitte__Pagination__arrow__left`}
                  >
                    <LeftIcon />
                  </li>

                  {pages.map((item, idx) => (
                    <li
                      onClick={handleChoosePage(item)}
                      className={`Deloitte__Pagination__pageItem${
                        item !== dots && currentPage === item - 1 ? ' Deloitte__Pagination__pageItem--active' : ''
                      }${item === dots ? ' Deloitte__Pagination__pageItem__dots' : ''}`}
                      key={`page-${idx}`}
                    >
                      {item}
                    </li>
                  ))}
                  <li
                    onClick={setNextPage}
                    className={`Deloitte__Pagination__arrow${nextArrowDisabledClassName} Deloitte__Pagination__arrow__right`}
                  >
                    <RightIcon />
                  </li>
                  <li
                    onClick={setLastPage}
                    className={`Deloitte__Pagination__arrow${nextArrowDisabledClassName} Deloitte__Pagination__arrow__forwardRight`}
                  >
                    <ForwardRightIcon />
                  </li>
                </ul>
              </div>
              <div className="Deloitte__Pagination__manualPageField">
                <Input
                  onChange={handleChangePage}
                  value={manualPage === '' ? manualPage : currentPage + 1}
                  isClearable={false}
                  onBlur={handleBlurManualPage}
                  disabled={disabled}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
