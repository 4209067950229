import axios from 'axios';
import { FilesContainerName, PublicFileCode } from 'shared/models/enums.dl';
import type {
  CurrentEnv,
  IExportedAndPublicFilesRequestData,
  IPaginatedTableResponse,
  ITableDataInfoRequest,
} from 'shared/models/models.bl';
import type { PrivateFile, PublicFile, ReportFile, TemplateFile } from 'shared/models/models.dl';
import type { IServerSideSelected } from 'components/Table/interfaces';
import Utils from 'shared/shared.utils';
import ApiUrlsService from './api-urls.service';
import ConfigService from './config.service';

const publicFileNameEnvPart: Record<CurrentEnv, string> = {
  DEV: '-',
  QA: '-',
  LOAD: '-',
  STAGE: '-',
  PROD: '-',
};
type PublicFileFolder = 'acceptableusepolicy' | 'cookies' | 'notices' | 'privacy';

const publicFileFolderByCode: Record<PublicFileCode, PublicFileFolder> = {
  [PublicFileCode.Cookie]: 'cookies',
  [PublicFileCode.Notices]: 'notices',
  [PublicFileCode.PrivacyPolicy]: 'privacy',
  [PublicFileCode.TermsOfUse]: 'acceptableusepolicy',
};
type PublicFileName = 'AUP.pdf' | 'CookiesNotice.pdf' | 'Notices.pdf' | 'Privacy.pdf';
type PublicFileDisplayName = 'Acceptable Use Policy' | 'Cookies Notice' | 'Notices' | 'Privacy';
interface PublicFileNames {
  fileName: PublicFileName;
  displayName: PublicFileDisplayName;
}

const publicFileNameByCode: Record<PublicFileCode, PublicFileNames> = {
  [PublicFileCode.Cookie]: { fileName: 'CookiesNotice.pdf', displayName: 'Cookies Notice' },
  [PublicFileCode.Notices]: { fileName: 'Notices.pdf', displayName: 'Notices' },
  [PublicFileCode.PrivacyPolicy]: { fileName: 'Privacy.pdf', displayName: 'Privacy' },
  [PublicFileCode.TermsOfUse]: { fileName: 'AUP.pdf', displayName: 'Acceptable Use Policy' },
};
export default class Files {
  static formatFileDate = (date: string | Date | number): string => {
    return Utils.formatDate
      .dateFormatter(
        date,
        Utils.formatDate.DateFormat.MonthDayYearHoursMinutesSeconds24,
        Utils.formatDate.Locales.EN_ZA
      )
      .replaceAll('/', '-')
      .replaceAll(',', '');
  };

  static async downloadPrivateFile({ uid, fileName }: PrivateFile): Promise<void> {
    try {
      const { data: blob } = await axios.get<Blob>(ApiUrlsService.downloadPrivateFile(uid), { responseType: 'blob' });
      Files.downloadBlobToLocaleMachine(blob, fileName);
    } catch {
      // just to prevent failure
    }
  }

  private static downloadBlobToLocaleMachine(blob: Blob, fileName: PrivateFile['fileName']) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  private static getPublicFileNameByCode(code: PublicFileCode): PublicFileNames {
    return publicFileNameByCode[code];
  }

  private static isPublicFileCode(code: PublicFileCode): boolean {
    const filesCode = [PublicFileCode.TermsOfUse, PublicFileCode.Cookie];

    return filesCode.includes(code);
  }

  static getPublicFileLink(code: PublicFileCode, localeId: string, countryCode?: string): string {
    const countryCodeUrl = countryCode && this.isPublicFileCode(code) ? `${countryCode}/` : '';

    return `/${publicFileFolderByCode[code]}/${localeId}/${countryCodeUrl}MIP${
      publicFileNameEnvPart[ConfigService.CurrentEnv]
    }${this.getPublicFileNameByCode(code).fileName}`;
  }

  static async getTemplateFiles(): Promise<TemplateFile[]> {
    const { data } = await axios.get<TemplateFile[]>(ApiUrlsService.getTemplateFiles());
    return Array.isArray(data) ? data : [];
  }

  static async getExportedAndPublicFiles(
    requestData: ITableDataInfoRequest
  ): Promise<IPaginatedTableResponse<ReportFile | PublicFile>> {
    const filesRequestData: IExportedAndPublicFilesRequestData = {
      ...requestData,
      containerNames: [FilesContainerName.Reports, FilesContainerName.PublicFiles],
    };
    const { data } = await axios.post<IPaginatedTableResponse<ReportFile | PublicFile>>(
      ApiUrlsService.getExportedAndPublicFiles(),
      filesRequestData
    );
    if (!Array.isArray(data.tableData)) {
      data.tableData = [];
    }
    return data;
  }

  static deleteExportReportFiles(selectedData: IServerSideSelected<ReportFile>): Promise<any> {
    return axios.post(ApiUrlsService.deleteExportReportFiles(), selectedData).then(({ data }) => data);
  }
}
