import axios from 'axios';
import LocalizationService from 'shared/services/bl/localization.service';
import { ILocale } from 'shared/models/models.dl';
import * as Models from 'shared/shared.models';
import { Types } from './types';
import { AsyncThunkActionType } from '../interfaces';
import { i18Service } from '../../localization';
import mockBundle from '../../localization/enFallback.json';

export const actions = {
  setIsLoadedLocales: (payload: boolean) =>
    ({
      type: Types.SET_IS_LOADED_LOCALES,
      payload,
    } as const),
  setIsErrorLocales: (payload: boolean) =>
    ({
      type: Types.SET_IS_ERROR_LOCALES,
      payload,
    } as const),
  setLocales: (payload: ILocale[]) =>
    ({
      type: Types.SET_LOCALES,
      payload,
    } as const),
  setIsLoadedLocaleKeys: (payload: boolean) =>
    ({
      type: Types.SET_IS_LOADED_LOCALE_KEYS,
      payload,
    } as const),
  setIsLoadingLocaleKeys: (payload: boolean) =>
    ({
      type: Types.SET_IS_LOADING_LOCALE_KEYS,
      payload,
    } as const),
  setIsErrorLocaleKeys: (payload: boolean) =>
    ({
      type: Types.SET_IS_ERROR_LOCALE_KEYS,
      payload,
    } as const),
  setIsErrorLoginLocaleKeys: (payload: boolean) =>
    ({
      type: Types.SET_IS_ERROR_LOGIN_LOCALE_KEYS,
      payload,
    } as const),
};

const thunks = {
  getLocales: (): AsyncThunkActionType<void> => async (dispatch) => {
    try {
      const locales = await LocalizationService.getLocales();
      dispatch(actions.setLocales(locales));
    } catch {
      // If API returns an error that means that localization server is unavailable (contract with BE)
      // In this case 'loginPageKeyValues' API returns fallback locale keys (action getLoginLocaleKeys)
      dispatch(actions.setIsErrorLocales(true));
    } finally {
      dispatch(actions.setIsLoadedLocales(true));
    }
  },
  getLoginLocaleKeys:
    (id: string): AsyncThunkActionType<void> =>
    async (dispatch) => {
      try {
        dispatch(actions.setIsLoadingLocaleKeys(true));
        const bundle = await LocalizationService.getLoginLocaleKeys(id);
        await i18Service.onChangeLocaleBundle(bundle, id);
      } catch {
        // If API fails mock locale keys should be shown on Login page
        await i18Service.onChangeLocaleBundle(mockBundle, id);
        dispatch(actions.setIsErrorLoginLocaleKeys(true));
      } finally {
        dispatch(actions.setIsLoadedLocaleKeys(true));
        dispatch(actions.setIsLoadingLocaleKeys(false));
      }
    },
  getLocaleKeys:
    (id?: string): AsyncThunkActionType<void> =>
    async (dispatch) => {
      try {
        const bundle = await LocalizationService.getLocaleKeys(id);
        dispatch(actions.setIsErrorLocaleKeys(false));
        await i18Service.onChangeLocaleBundle(bundle, id);
      } catch (e: any) {
        await dispatch(thunks.handleLocaleKeysError(e));
      }
    },
  getLocaleKeysForAuthorizedUser:
    (id?: string): AsyncThunkActionType<void> =>
    async (dispatch) => {
      try {
        dispatch(actions.setIsLoadingLocaleKeys(true));
        const bundle = await LocalizationService.getLocaleKeys(id);
        await i18Service.onChangeLocaleBundle(bundle, id);
      } catch (e: any) {
        await dispatch(thunks.handleLocaleKeysError(e));
      } finally {
        dispatch(actions.setIsLoadedLocaleKeys(true));
        dispatch(actions.setIsLoadingLocaleKeys(false));
      }
    },
  handleLocaleKeysError:
    (e: any): AsyncThunkActionType<void> =>
    async (dispatch) => {
      if (e?.response?.status === Models.BE.HttpStatus.Unauthorized) {
        // The user is redirected to login page
        const userLocale = LocalizationService.getLoginLocaleCode();
        dispatch(thunks.getLocales());
        await dispatch(thunks.getLoginLocaleKeys(userLocale));
      } else if (!axios.isCancel(e)) {
        // The user sees 'Service is not available' message
        dispatch(actions.setIsErrorLocaleKeys(true));
      }
    },
};

export default {
  ...actions,
  ...thunks,
};
