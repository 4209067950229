import type { AppState } from '../../interfaces';
import { ReportsStoreName } from '../storeName';
import type { StoreType } from './interfaces';

const currentStoreName = ReportsStoreName.EmployeeView;

export const selectDataLoading = ({ reports }: AppState): StoreType['loading'] => reports[currentStoreName].loading;

export const selectGeneratedReport = ({ reports }: AppState): StoreType['generatedReport'] =>
  reports[currentStoreName].generatedReport;

export const selectTableData = ({ reports }: AppState): StoreType['data'] => reports[currentStoreName].data;

export const selectTableHeaders = ({ reports }: AppState): StoreType['headers'] => reports[currentStoreName].headers;

export const selectOnlySumRows = ({ reports }: AppState): StoreType['onlySumRows'] =>
  reports[currentStoreName].onlySumRows;

export const selectPaginatedTableData = ({ reports }: AppState): StoreType['paginatedTableData'] =>
  reports[currentStoreName].paginatedTableData;
