import { ITrueUpCalculationRequest, ITrueUpFilterProps } from 'shared/models/models.bl';
import Services from 'shared/shared.services';

import { defaultPagingRequestData } from 'shared/configs/grid.config';
import type * as BM from 'shared/models/models.bl';
import { getParsedResponseData } from 'shared/services/bl/helpers';
import Configs from 'shared/shared.configs';
import { defaultFilters } from 'Pages/ExpenseReports/helpers';
import type { AsyncThunkActionType } from '../../interfaces';
import { Types } from './types';
import type * as Interfaces from './interfaces';
import { getTableHeadersAfterFiltering } from './helpers';

export const actions = {
  setLoading: (payload: Interfaces.PayloadType['loading']) =>
    ({
      type: Types.SET_LOADING,
      payload,
    } as const),

  setCalculating: (payload: Interfaces.PayloadType['calculating']) =>
    ({
      type: Types.SET_CALCULATING,
      payload,
    } as const),

  setIsCalculationCompleted: (payload: Interfaces.PayloadType['isCalculationCompleted']) =>
    ({
      type: Types.SET_IS_CALCULATION_COMPLETED,
      payload,
    } as const),

  setInfoData: (payload: Interfaces.PayloadType['infoData']) =>
    ({
      type: Types.SET_INFO_DATA,
      payload,
    } as const),

  setTableData: (payload: Interfaces.PayloadType['tableData']) =>
    ({
      type: Types.SET_TABLE_DATA,
      payload,
    } as const),

  setNewReportTableData: (payload: Interfaces.PayloadType['newReportTableData']) =>
    ({
      type: Types.SET_NEW_REPORT_TABLE_DATA,
      payload,
    } as const),

  setPreviousReportTableData: (payload: Interfaces.PayloadType['previousReportTableData']) =>
    ({
      type: Types.SET_PREVIOUS_REPORT_TABLE_DATA,
      payload,
    } as const),

  resetStateData: () =>
    ({
      type: Types.RESET_STATE_DATA,
    } as const),

  setFiltering: (payload: Interfaces.PayloadType['filtering']) =>
    ({
      type: Types.SET_FILTERING,
      payload,
    } as const),
};

const thunks = {
  getInfoData:
    (reportUId: string): AsyncThunkActionType<null> =>
    async (dispatch) => {
      try {
        const infoData = await Services.BL.TrueUp.getTrueUpInfoData(reportUId);
        // infoData DTO contains 'previousReportUID' and 'newReportUID', but in app and in query params we use last 'D' in lowercase

        dispatch(actions.setInfoData(infoData));

        if (infoData.previousReportUID) {
          dispatch(actions.setIsCalculationCompleted(true));
        }

        return null;
      } catch {
        return null;
      }
    },

  getTableData:
    (reportUId: string, filters?: ITrueUpFilterProps): AsyncThunkActionType<null> =>
    async (dispatch, getState) => {
      try {
        const tableData = await Services.BL.TrueUp.getTrueUpTableData(reportUId, filters);
        if (filters) {
          const prevData = getState().reports.trueUp.tableData;
          tableData.header = getTableHeadersAfterFiltering(filters, tableData.header, prevData?.header);
        }
        if (tableData.data.length === 3) {
          tableData.data[0].bgColor = `#F5F5F5`; // total vested of new report
          tableData.data[1].bgColor = `#FFFFFF`; // total vested of prev report
          tableData.data[2].bgColor = `#E0FFFF`; // new report total vested - prev report total vested
        }
        dispatch(actions.setTableData(tableData));
        return null;
      } catch {
        return null;
      }
    },

  getNewReportTableData:
    ({
      reportUId,
      tableParams,
      filters,
    }: {
      reportUId: string;
      tableParams?: BM.ITableDataInfoRequest;
      filters?: ITrueUpFilterProps;
    }): AsyncThunkActionType<null> =>
    async (dispatch, getState) => {
      const requestTableParams = tableParams || defaultPagingRequestData;
      const requestFilters = filters || defaultFilters;

      try {
        const newReportTableData = await Services.BL.TrueUp.getNewReportTrueUpTableData({
          reportUId,
          ...requestTableParams,
          ...requestFilters,
        });

        const prevData = getState().reports.trueUp.newReportTableData;
        // Set previous table header if response tableData is null
        const header = getTableHeadersAfterFiltering(
          filters,
          newReportTableData?.tableData?.header,
          prevData?.tableData?.header,
          true
        );
        newReportTableData.tableData = getParsedResponseData(
          { header, data: newReportTableData?.tableData?.data || [] },
          Configs.Grid.GridColumnConfig.TrueUpReportConfig
        ) as any;
        dispatch(actions.setNewReportTableData(newReportTableData));

        return null;
      } catch {
        return null;
      }
    },

  getPreviousReportTableData:
    ({
      reportUId,
      tableParams,
      filters,
    }: {
      reportUId: string;
      tableParams?: BM.ITableDataInfoRequest;
      filters?: ITrueUpFilterProps;
    }): AsyncThunkActionType<null> =>
    async (dispatch, getState) => {
      try {
        const requestTableParams = tableParams || defaultPagingRequestData;
        const requestFilters = filters || defaultFilters;
        const previousReportTableData = await Services.BL.TrueUp.getPreviousReportTrueUpTableData({
          reportUId,
          ...requestTableParams,
          ...requestFilters,
        });

        const prevData = getState().reports.trueUp.previousReportTableData;
        // Set previous table header if response tableData is null
        const header = getTableHeadersAfterFiltering(
          filters,
          previousReportTableData?.tableData?.header,
          prevData?.tableData?.header,
          true
        );
        previousReportTableData.tableData = getParsedResponseData(
          { header, data: previousReportTableData?.tableData?.data || [] },
          Configs.Grid.GridColumnConfig.TrueUpReportConfig
        ) as any;
        dispatch(actions.setPreviousReportTableData(previousReportTableData));
        return null;
      } catch {
        return null;
      }
    },

  filterTables:
    (reportUId: string, filters?: ITrueUpFilterProps): AsyncThunkActionType<null> =>
    async (dispatch) => {
      dispatch(actions.setFiltering(true));
      try {
        await Promise.all([
          dispatch(thunks.getTableData(reportUId, filters)),
          dispatch(thunks.getNewReportTableData({ reportUId, filters })),
          dispatch(thunks.getPreviousReportTableData({ reportUId, filters })),
        ]);
        dispatch(actions.setFiltering(false));
        return null;
      } catch {
        dispatch(actions.setFiltering(false));
        return null;
      }
    },

  initialRequests:
    ({ reportUId }: { reportUId: string }): AsyncThunkActionType<null> =>
    async (dispatch) => {
      dispatch(actions.setLoading(true));

      try {
        await Promise.all([
          dispatch(thunks.getInfoData(reportUId)),
          dispatch(thunks.getTableData(reportUId)),
          dispatch(thunks.getNewReportTableData({ reportUId })),
          dispatch(thunks.getPreviousReportTableData({ reportUId })),
        ]);

        dispatch(actions.setLoading(false));
        return null;
      } catch {
        dispatch(actions.setLoading(false));
        return null;
      }
    },

  startCalculation:
    (fieldValues: ITrueUpCalculationRequest, successCallback?: () => void): AsyncThunkActionType<null> =>
    async (dispatch) => {
      const { reportUId } = fieldValues;

      dispatch(actions.setCalculating(true));
      dispatch(actions.setLoading(true));

      try {
        const calculationRes = await Services.BL.TrueUp.trueUpStartCalculation(fieldValues);
        successCallback?.();
        if (calculationRes.success) {
          await dispatch(
            thunks.initialRequests({
              reportUId,
            })
          );
          dispatch(actions.setCalculating(false));
        } else {
          dispatch(actions.setCalculating(false));
          dispatch(actions.setLoading(false));
        }

        return null;
      } catch {
        dispatch(actions.setCalculating(false));
        dispatch(actions.setLoading(false));
        return null;
      }
    },

  deleteAllTrueUpData:
    (reportTimeStamp: string, successCallback?: () => void): AsyncThunkActionType<null> =>
    async (dispatch) => {
      try {
        await Services.BL.TrueUp.deleteAllTrueUpData(reportTimeStamp);
        successCallback?.();
        dispatch(actions.resetStateData());

        return null;
      } catch {
        return null;
      }
    },
};

export default {
  ...actions,
  ...thunks,
};
