import React from 'react';
import { useLocation } from 'react-router-dom';
import cs from 'classnames';

import styles from './Tab.module.css';

interface Props {
  children: string | JSX.Element;
  href: string;
  tabRoute: string;
  onClick?: () => void;

  highlightActive?: boolean;
  className?: string;
  isSidePanel?: boolean;
}

export const Tab: React.FC<Props> = ({ highlightActive, children, className, tabRoute, isSidePanel, onClick }) => {
  const location = useLocation();
  const handleClick = () => {
    onClick?.();
  };
  return (
    <div
      className={
        location.pathname.includes(tabRoute) && highlightActive
          ? cs(styles.tab, isSidePanel ? styles.tabActiveSidePanel : styles.tabActive, className)
          : cs(styles.tab, className)
      }
      onClick={handleClick}
    >
      {children}
    </div>
  );
};
