export enum TypeName {
  SET_GENERATED_REPORT = 'SET_GENERATED_REPORT',
  SET_TABLE_DATA_LOADING = 'SET_TABLE_DATA_LOADING',
  SET_FILTERS_LOADING = 'SET_FILTERS_LOADING',
  SET_CALCULATION_RESULT = 'SET_CALCULATION_RESULT',
  SET_CALCULATING = 'SET_CALCULATING',
  SET_TABLE_DATA = 'SET_TABLE_DATA',
  SET_TABLE_FILTERS = 'SET_TABLE_FILTERS',
  SET_FILE_UPLOADED = 'SET_FILE_UPLOADED',
  SET_TOTAL_PROVISION_BY_FILTER = 'SET_TOTAL_PROVISION_BY_FILTER',
  RESET_TABLE_DATA = 'RESET_TABLE_DATA',
  SET_CHART_DATA_LOADING = 'SET_CHART_DATA_LOADING',
  SET_CHART_DATA = 'SET_CHART_DATA',
}
