import { SortDirectionType } from 'components/Table/interfaces';
// import { checkIsDate } from './dateFormatter';

type ObjectType = { [key: string]: any };
type ValueType = string | number | null | undefined | ObjectType;
type FindFieldReturnValueType = string | undefined;

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base', ignorePunctuation: true });

const findField = (a: ObjectType, b: ObjectType, type: string): FindFieldReturnValueType => {
  const getResult = (obj: ObjectType): FindFieldReturnValueType =>
    Object.keys(obj).find((key: string) => typeof obj[key] === type);
  return getResult(a) || getResult(b);
};

const getCorrectValue = (value: ValueType): string | number | ObjectType => {
  let result = value === null || value === undefined ? '' : value;
  if (typeof result === 'string' && !!result) {
    result = result.trim();
    const tempNumber = +result.replace(/,/g, '.');
    if (!Number.isNaN(tempNumber)) {
      result = tempNumber;
    } else if (result) {
      /* if (checkIsDate(result)) {
        result = new Date(result).getTime();
      } else { */
      result = result.replace(/\s+/g, ' ').toUpperCase();
      /* } */
    }
  }
  return result;
};

export const simpleSort =
  (direction: SortDirectionType, searchDepth = 1) =>
  (a: ValueType, b: ValueType, currentDepth = 1): number => {
    let valueA = getCorrectValue(a);
    let valueB = getCorrectValue(b);

    if (typeof valueA === 'object' && typeof valueB === 'object') {
      if (currentDepth > searchDepth) return 0;
      const field = findField(valueA, valueB, 'number') || findField(valueA, valueB, 'string');
      if (field) {
        return simpleSort(direction, searchDepth + 1)(valueA[field], valueB[field]);
      }
      return 0;
    }

    const sortModifier = direction === 'asc' ? 1 : -1;

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return collator.compare(valueA, valueB) * sortModifier;
    }

    if (typeof valueA === 'number' && typeof valueB === 'string') {
      if (!valueB) valueB = 0;
      else return -1 * sortModifier;
    }
    if (typeof valueA === 'string' && typeof valueB === 'number') {
      if (!valueA) valueA = 0;
      else return 1 * sortModifier;
    }

    if (valueA > valueB) {
      return 1 * sortModifier;
    }
    if (valueA < valueB) {
      return -1 * sortModifier;
    }
    return 0;
  };
