import { Types } from './types';
import type * as Interfaces from './interfaces';

export default function loaderReducer(
  state: Interfaces.StoreType = false,
  action: Interfaces.LoaderActions = {} as Interfaces.LoaderActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_IS_LOADING: {
      return action.payload;
    }
    default:
      return state;
  }
}
