export const numbers = /[0-9]/;

export const floatNumbers = /[0-9.]/;

// eslint-disable-next-line
export const specialCharacters = /^[\s*a-zA-Z0-9?!@#$%&{|})([\]\/+=':;.,_-]+$/;

export const numbersCharacters = /^[a-zA-Z0-9]+$/;

export const alphaNumeric = /^[a-zA-Z0-9 ]+$/;

export const performanceHurdle = /^[a-zA-Z0-9\s;:-]*$/;

// 12.3 or 12
export const floatNumberWithDot = /^\d+(\.\d+)?$/;

// 'DD/MM/YYYY' date format
export const dateFormat = /^\d{2}[/]\d{2}[/]\d{4}$/;

// 12
export const integerNumbers = /^\d+$/;
