import { useEffect, useCallback, useRef } from 'react';

export default function useIsMounted(): (callback?: () => void) => boolean {
  const mountedRef = useRef<boolean>(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return useCallback((callback): boolean => {
    if (mountedRef.current && !!callback) {
      callback();
    }
    return mountedRef.current;
  }, []);
}
