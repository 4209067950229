import { FC } from 'react';
import { ToggleButton, ToggleButtonProps } from '@progress/kendo-react-dateinputs';

import { ReactComponent as Calendar } from '../../assets/svg/calendar.svg';

const CustomToggleIcon: FC<ToggleButtonProps> = (props) => {
  return (
    <ToggleButton {...props}>
      <Calendar />
    </ToggleButton>
  );
};

export default CustomToggleIcon;
