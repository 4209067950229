import { FC } from 'react';
import { Form } from '@progress/kendo-react-form';
import Input from 'components/vendor/Input';
import { ReactComponent as PlusIcon } from 'assets/svg/plus-blue.svg';
import FormDatePicker from 'components/FormDatePicker';
import { ReactComponent as PercentIcon } from 'assets/svg/percent.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg';
import CustomCalendar from 'components/FormDatePicker/CustomCalendar';
import CustomToggleIcon from 'components/FormDatePicker/CustomToggleIcon';
import { useTranslation } from 'react-i18next';
import { defaultItemDate } from 'Pages/ExpensesJournal/helper';
import * as Models from '../../../shared/shared.models';
import Button from '../../Button';
import {
  validateSubhurdleDate,
  maxPercentage,
  minPercentage,
  maxNumber,
  setCommonRangeError,
  validateVestingConditionAmountValues,
  validateVestingConditionPercentageValues,
} from './helper';

interface IProps {
  columns: any;
  subhurdleDetail: any;
  subHurdleEstimateValueChange: any;
  subHurdleEstimateRowRemove: any;
  initialValues: Models.BM.IUpdatePerformanceHurdleEstimate;
  subHurdleEstimateAddRow: any;
  finalResult: boolean;
  errorMsg: string;
  data: any;
  isValid: boolean;
  refForm: any;
  validationMessage: string;
}

const guidanceTextForUpdateEstimate = 'PerformanceHurdle.UpdateEstimateDate.GuidanceText';

const EstimateTable: FC<IProps> = ({
  columns,
  subhurdleDetail,
  subHurdleEstimateValueChange,
  subHurdleEstimateRowRemove,
  initialValues,
  subHurdleEstimateAddRow,
  finalResult,
  errorMsg,
  data,
  isValid,
  refForm,
  validationMessage,
}) => {
  const { t } = useTranslation();
  // const [newUnit, setUnit] = useState('P');
  // useEffect(() => {
  //   setUnit(subhurdleDetail[0]?.weight);
  // }, [subhurdleDetail[0]?.weight]);
  return (
    <Form
      ref={refForm}
      initialValues={initialValues}
      render={() => {
        return (
          <>
            <hr className="performanceHurdle-border" />
            <p className="perormanceHurdleID">
              {t('PerformanceHurdle.UpdateEstimateModal.PerformanceHurdleId')} : {data?.performanceHurdleId}
            </p>
            <table>
              <tbody>
                <tr>
                  {columns.map((element: any) => (
                    <th key={element.path} className="tableHeader">
                      {element.name}
                    </th>
                  ))}
                </tr>
                {subhurdleDetail.map((rowData: any, index: any) => (
                  <tr key={index}>
                    {columns.map((element: any, columnindex: any) => (
                      <td key={element.path} className="tableColumn">
                        {element.type === 'date' && (
                          <FormDatePicker
                            key={`date-${index}-${columnindex}`}
                            id={`date-${index}-${columnindex}`}
                            name={`date-${index}-${columnindex}`}
                            value={rowData[element.path].date}
                            className={`date_padding ${rowData.flag === true ? 'final_input' : ''}`}
                            isRequiredIcon={false}
                            bottomView="year"
                            topView="decade"
                            valid={validateSubhurdleDate(
                              rowData[element.path].date,
                              rowData[element.path].initialRender,
                              subhurdleDetail,
                              index
                            )}
                            onChange={(event: any) =>
                              subHurdleEstimateValueChange(
                                event.target.value,
                                index,
                                element.type,
                                element.path,
                                columnindex
                              )
                            }
                            format={{
                              year: 'numeric',
                              month: 'short',
                            }}
                            defaultValue={defaultItemDate}
                            toggleButton={CustomToggleIcon}
                            calendar={CustomCalendar}
                            placeholder="MMM/YYYY"
                          />
                        )}
                        {element.type === 'text' && (
                          <Input
                            key={`subhurdle-${index}-${columnindex}`}
                            id={`subhurdle-${index}-${columnindex}`}
                            name={`subhurdle-${index}-${columnindex}`}
                            type="number"
                            isClearable={false}
                            className={`subhurdle_input ${rowData.flag === true ? 'final_input' : ''}`}
                            onChange={(event) =>
                              subHurdleEstimateValueChange(
                                event.target.value,
                                index,
                                element.type,
                                element.path,
                                columnindex
                              )
                            }
                            value={rowData[element.path].weight}
                            validationMessage={
                              element.unit === 'P'
                                ? setCommonRangeError(
                                    t,
                                    Number(rowData[element.path].weight),
                                    minPercentage,
                                    maxPercentage
                                  )
                                : setCommonRangeError(t, Number(rowData[element.path].weight), minPercentage, maxNumber)
                            }
                            valid={
                              element.unit === 'P'
                                ? validateVestingConditionPercentageValues(Number(rowData[element.path].weight))
                                : validateVestingConditionAmountValues(Number(rowData[element.path].weight))
                            }
                            endIcon={element.unit === 'P' ? <PercentIcon /> : null}
                          />
                        )}
                        {element.type === 'button' && (
                          <div
                            key={`delete-${index}-${columnindex}`}
                            className="update-cross-icon"
                            onClick={() => subHurdleEstimateRowRemove(index)}
                          >
                            <CloseIcon />
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <span className="dateText">{errorMsg}</span>
            <p className="validationText">{validationMessage}</p>
            <p className="guidance_estimatedDate">{t(guidanceTextForUpdateEstimate)}</p>
            <div className="add-sh-btn">
              <div
                className={`add-up-button ${
                  subhurdleDetail?.length > 8 || finalResult === true || isValid ? 'disable-up-div' : ''
                }`}
                onClick={() => subHurdleEstimateAddRow(false)}
              >
                <PlusIcon />
                <div>{t('PerformanceHurdle.UpdateEstimateModal.AddUpdate')}</div>
              </div>
              <Button
                className="finalResultButton"
                styleType="outlined"
                color="primary"
                disabled={finalResult || isValid}
                onClick={() => subHurdleEstimateAddRow(true)}
              >
                {t('PerformanceHurdle.UpdateEstimateModal.AddFinalResult')}
              </Button>
            </div>
          </>
        );
      }}
    />
  );
};
export default EstimateTable;
