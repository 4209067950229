import { FC, lazy, Suspense, memo, ReactElement } from 'react';
import { Route, Navigate, Routes as ReactRoutes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectIsLoadedLocaleKeys, selectIsLoadingLocaleKeys } from 'store/localization/selectors';
import PerformanceHurdles from 'Pages/PerformanceHurdles/PerformanceHurdles';
import { pageTitle } from 'shared/constants/pageTitles';
import { appInsights } from 'appinsights/appInsights';
import { useIsAuthenticated } from '@azure/msal-react';
import Services from 'shared/shared.services';
import { getFromLocalStorage, setToLocalStorage } from 'shared/utils/localStorage';

import { ROUTES } from './shared/constants/routes';
import LocalizationService from './shared/services/bl/localization.service';

import Loader from './components/Loader/Loader';

import Unauthorized from './Pages/Unauthorized/Unauthorized';

import Landing from './Pages/Landing/Landing';

import CustomHooks from './shared/shared.hooks';
import { StoreActions } from './store/actions';
import RoutesWithPageTitle from './shared/Routes/RoutesWithPageTitle';

const Home = lazy(() => import('./Pages/Home/Home'));
const ShareRegister = lazy(() => import('./Pages/ShareRegister/ShareRegister'));
const CustomVestingSchedules = lazy(() => import('./Pages/CustomVestingSchedules'));
const GrantValuation = lazy(() => import('./Pages/GrantValuation/GrantValuation'));
const EmployeeStatus = lazy(() => import('./Pages/EmployeeStatus/EmployeeStatus'));
const Expenses = lazy(() => import('./Pages/Expenses/Expenses'));
const ExpenseReports = lazy(() => import('./Pages/ExpenseReports/ExpenseReports'));
const ExpensesJournal = lazy(() => import('./Pages/ExpensesJournal/ExpensesJournal'));
const Files = lazy(() => import('./Pages/Files/Files'));
const ActivityLogs = lazy(() => import('./Pages/ActivityLogs/ActivityLogs'));
const ManageEmployeeContracts = lazy(() => import('./Pages/ManageEmployeeContracts'));
const EmployeeContractDetails = lazy(() => import('./Pages/EmployeeContractDetails'));
// const Users = lazy(() => import('./Pages/Users/Users'));

const Protected: FC<{ element: ReactElement; title?: string; pathName?: string }> = ({ element, title, pathName }) => {
  const dispatch = CustomHooks.storeHooks.useAppDispatch();
  const isLoadedLocaleKeys = useSelector(selectIsLoadedLocaleKeys);
  const isLoadingLocaleKeys = useSelector(selectIsLoadingLocaleKeys);
  if (!isLoadedLocaleKeys && !isLoadingLocaleKeys && Services.BL.AuthorizationService.isAuthorized()) {
    const locale = LocalizationService.getLocaleCode();
    dispatch(StoreActions.Localization.getLocaleKeysForAuthorizedUser(locale || ''));
  }
  const isAuthenticated = useIsAuthenticated();
  if (!isLoadedLocaleKeys) {
    return null;
  }
  document.title = `${pageTitle.appCommonName} ${title}`;
  appInsights.trackPageView({ uri: pathName });
  return isAuthenticated ? element : <Navigate to={ROUTES.landing} replace />;
};

const Public: FC<{ element: ReactElement; title?: string; pathName?: string }> = ({ element, title, pathName }) => {
  const dispatch = CustomHooks.storeHooks.useAppDispatch();
  const isAuthenticated = useIsAuthenticated();
  const userLocale = LocalizationService.getLoginLocaleCode();
  const isLoadedLocaleKeys = useSelector(selectIsLoadedLocaleKeys);
  const isLoadingLocaleKeys = useSelector(selectIsLoadingLocaleKeys);
  const location = useLocation();
  if (location.hash.includes('#code')) {
    setToLocalStorage('isAuthenticationInProgress', 'true');
  }
  if (!isLoadedLocaleKeys && !isLoadingLocaleKeys && getFromLocalStorage('isAuthenticationInProgress') === null) {
    dispatch(StoreActions.Localization.getLocales());
    dispatch(StoreActions.Localization.getLoginLocaleKeys(userLocale));
  }
  document.title = `${pageTitle.appCommonName} ${title}`;
  appInsights.trackPageView({ uri: pathName });
  return !isAuthenticated || pathName === ROUTES.unauthorized ? element : <Navigate to={ROUTES.home} replace />;
};

const Routes: FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <ReactRoutes>
        <Route
          path={ROUTES.landing}
          element={<Public element={<Landing />} title={pageTitle.landingPage} pathName={ROUTES.landing} />}
        />
        <Route
          path={ROUTES.unauthorized}
          element={<Public element={<Unauthorized />} title={pageTitle.unauthorized} pathName={ROUTES.unauthorized} />}
        />

        <Route
          path={ROUTES.home}
          element={<Protected element={<Home />} title={pageTitle.home} pathName={ROUTES.home} />}
        />
        <Route
          path={ROUTES.shareRegister}
          element={
            <Protected element={<ShareRegister />} title={pageTitle.shareRegister} pathName={ROUTES.shareRegister} />
          }
        />
        <Route
          path={ROUTES.performanceHurdles}
          element={
            <Protected
              element={<PerformanceHurdles />}
              title={pageTitle.performanceHurdles}
              pathName={ROUTES.performanceHurdles}
            />
          }
        />
        <Route
          path={ROUTES.manageEmployeeContracts}
          element={
            <Protected
              element={<ManageEmployeeContracts />}
              title={pageTitle.manageEmployeeContracts}
              pathName={ROUTES.manageEmployeeContracts}
            />
          }
        />
        <Route
          path={ROUTES.employeeContractDetails}
          element={
            <Protected
              element={<EmployeeContractDetails />}
              title={pageTitle.employeeContractDetails}
              pathName={ROUTES.employeeContractDetails}
            />
          }
        />
        <Route
          path={ROUTES.customVestingSchedules}
          element={
            <Protected
              element={<CustomVestingSchedules />}
              title={pageTitle.customVestingSchedules}
              pathName={ROUTES.customVestingSchedules}
            />
          }
        />
        <Route
          path={ROUTES.grantValuation}
          element={
            <Protected element={<GrantValuation />} title={pageTitle.grantValuation} pathName={ROUTES.grantValuation} />
          }
        />
        <Route
          path={ROUTES.employeeStatus}
          element={
            <Protected element={<EmployeeStatus />} title={pageTitle.employeeStatus} pathName={ROUTES.employeeStatus} />
          }
        />
        <Route
          path={ROUTES.sbpExpenses}
          element={<Protected element={<Expenses />} title={pageTitle.sbpExpenses} pathName={ROUTES.sbpExpenses} />}
        />
        <Route
          path={ROUTES.sbpExpenseReports}
          element={
            <Protected
              element={<ExpenseReports />}
              title={pageTitle.sbpExpenseReports}
              pathName={ROUTES.sbpExpenseReports}
            />
          }
        />
        <Route
          path={ROUTES.sbpExpensesJournal}
          element={
            <Protected
              element={<ExpensesJournal />}
              title={pageTitle.sbpExpensesJournal}
              pathName={ROUTES.sbpExpensesJournal}
            />
          }
        />
        <Route
          path={ROUTES.files}
          element={<Protected element={<Files />} title={pageTitle.files} pathName={ROUTES.files} />}
        />
        <Route
          path={ROUTES.logs}
          element={<Protected element={<ActivityLogs />} title={pageTitle.logs} pathName={ROUTES.logs} />}
        />

        <Route
          path="*"
          element={<RoutesWithPageTitle element={<Navigate to={ROUTES.home} />} title={pageTitle.home} path="*" />}
        />
      </ReactRoutes>
    </Suspense>
  );
};

export default memo(Routes);
