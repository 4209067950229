import type { AppState } from '../interfaces';
import type { StoreType } from './interfaces';

export const selectIsNotificationCenterOpen = ({ notificationCenter }: AppState): StoreType['isOpen'] =>
  notificationCenter.isOpen;

export const selectIsReloadShareRegisterDataNeeded = ({
  notificationCenter,
}: AppState): StoreType['reload']['shareRegister'] => notificationCenter.reload.shareRegister;

export const selectIsReloadEmployeeStatusDataNeeded = ({
  notificationCenter,
}: AppState): StoreType['reload']['employeeStatus'] => notificationCenter.reload.employeeStatus;

export const selectIsReloadValuationRefDataNeeded = ({
  notificationCenter,
}: AppState): StoreType['reload']['valuationReference'] => notificationCenter.reload.valuationReference;

export const selectIsReloadFilesDataNeeded = ({ notificationCenter }: AppState): StoreType['reload']['files'] =>
  notificationCenter.reload.files;

export const selectIsReloadProvisionalExpenseDataNeeded = ({
  notificationCenter,
}: AppState): StoreType['reload']['provisionalExpense'] => notificationCenter.reload.provisionalExpense;

export const selectIsReloadCustomVestingSchedulesDataNeeded = ({
  notificationCenter,
}: AppState): StoreType['reload']['customVestingSchedules'] => notificationCenter.reload.customVestingSchedules;

export const selectIsReloadPerformanceHurdlesDataNeeded = ({
  notificationCenter,
}: AppState): StoreType['reload']['performanceHurdles'] => notificationCenter.reload.performanceHurdles;
