import { FC } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';

interface IProps {
  title: string;
  close?: () => void;
  icon?: any;
}

const ModalHeader: FC<IProps> = ({ close, title, icon }) => {
  return (
    <div className="modalLayoutHeader">
      {title !== '' && icon}
      <h2 className="modalLayoutHeader__title">{title}</h2>
      {!!close && <CloseIcon onClick={close} />}
    </div>
  );
};

export default ModalHeader;
