import { transformToSelectFormat } from 'localization/helpers';
import type * as Interfaces from './interfaces';
import { Types } from './types';

const initialState: Interfaces.StoreType = {
  locales: null,
  isLoadedLocales: false,
  isErrorLocales: false,
  isLoadedLocaleKeys: false,
  isLoadingLocaleKeys: false,
  isErrorLocaleKeys: false,
  isErrorLoginLocaleKeys: false,
};

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action: Interfaces.LocalizationActions = {} as Interfaces.LocalizationActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_IS_LOADED_LOCALES: {
      return {
        ...state,
        isLoadedLocales: action.payload,
      };
    }
    case Types.SET_IS_ERROR_LOCALES: {
      return {
        ...state,
        isErrorLocales: action.payload,
      };
    }
    case Types.SET_LOCALES: {
      return {
        ...state,
        locales: transformToSelectFormat(action.payload),
      };
    }
    case Types.SET_IS_LOADED_LOCALE_KEYS: {
      return {
        ...state,
        isLoadedLocaleKeys: action.payload,
      };
    }
    case Types.SET_IS_LOADING_LOCALE_KEYS: {
      return {
        ...state,
        isLoadingLocaleKeys: action.payload,
      };
    }
    case Types.SET_IS_ERROR_LOCALE_KEYS: {
      return {
        ...state,
        isErrorLocaleKeys: action.payload,
      };
    }
    case Types.SET_IS_ERROR_LOGIN_LOCALE_KEYS: {
      return {
        ...state,
        isErrorLoginLocaleKeys: action.payload,
      };
    }
    default:
      return state;
  }
}
