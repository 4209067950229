import axios from 'axios';
import SBPExpensesReports from 'shared/services/bl/expensesReports.service';
import { Types } from './types';
import type { AsyncThunkActionType } from '../../interfaces';
import type * as Interfaces from './interfaces';
import Services from '../../../shared/shared.services';

export const actions = {
  setDataLoading: (payload: Interfaces.PayloadType['loading']) =>
    ({
      type: Types.SET_DATA_LOADING,
      payload,
    } as const),

  setGeneratedReport: (payload: Interfaces.PayloadType['generatedReport']) =>
    ({
      type: Types.SET_GENERATED_REPORT,
      payload,
    } as const),

  setTableFilters: (payload: Interfaces.PayloadType['filters']) =>
    ({
      type: Types.SET_TABLE_FILTERS,
      payload,
    } as const),

  recalculateTotals: (payload: Interfaces.PayloadType['recalculateTotals']) =>
    ({
      type: Types.RECALCULATE_TOTALS,
      payload,
    } as const),

  setDataForFinalization: (payload: Interfaces.PayloadType['dataForFinalization']) =>
    ({
      type: Types.SET_DATA_FOR_FINALIZATION,
      payload,
    } as const),

  setPaginatedTableData: (payload: Interfaces.PayloadType['paginatedTableData']) =>
    ({
      type: Types.SET_PAGINATED_TABLE_DATA,
      payload,
    } as const),

  setFiltersLoading: (payload: Interfaces.PayloadType['loading']) =>
    ({
      type: Types.SET_FILTERS_LOADING,
      payload,
    } as const),

  setChartLoading: (payload: Interfaces.PayloadType['loading']) =>
    ({
      type: Types.SET_CHART_LOADING,
      payload,
    } as const),
  setChart: (payload: Interfaces.PayloadType['chartData']) =>
    ({
      type: Types.SET_CHART_DATA,
      payload,
    } as const),
};

export const thunks = {
  getFilters:
    (reportTimeStamp: string): AsyncThunkActionType<null> =>
    async (dispatch) => {
      try {
        dispatch(actions.setFiltersLoading(true));
        const responseData = await SBPExpensesReports.getSummaryTotalFilters(reportTimeStamp);
        dispatch(actions.setTableFilters(responseData));
        dispatch(actions.setGeneratedReport(reportTimeStamp));
        return null;
      } catch {
        dispatch(actions.setFiltersLoading(false));
        return null;
      }
    },

  getDataForFinalization:
    (reportTimeStamp: string): AsyncThunkActionType<null> =>
    async (dispatch) => {
      try {
        const responseData = await SBPExpensesReports.getFinalizationData(reportTimeStamp);
        dispatch(actions.setDataForFinalization(responseData));
        return null;
      } catch {
        return null;
      }
    },
  getChart:
    (reportUId: string, filter: string): AsyncThunkActionType<null> =>
    async (dispatch) => {
      try {
        dispatch(actions.setChartLoading(true));
        const responseData = await Services.BL.SBPExpensesReports.getSummaryTotalChart(reportUId, filter);

        dispatch(actions.setChart(responseData));
        return null;
      } catch {
        dispatch(actions.setChartLoading(false));
        return null;
      }
    },

  getPaginatedData:
    (...params: Parameters<typeof SBPExpensesReports.getSummaryTotalPaginatedTableData>): AsyncThunkActionType<void> =>
    async (dispatch, getState) => {
      const [uid, ...rest] = params;
      const reportUId = getState().reports.summaryTotal.generatedReport || uid;
      try {
        dispatch(actions.setDataLoading(true));
        const responseData = await SBPExpensesReports.getSummaryTotalPaginatedTableData(reportUId, ...rest);
        dispatch(actions.setPaginatedTableData(responseData));
        dispatch(actions.setGeneratedReport(params[0]));
      } catch (err) {
        // INFO: for now request can be canceled by next same request only so we no need to change loading state
        if (!axios.isCancel(err)) dispatch(actions.setDataLoading(false));
      }
    },
};

export default {
  ...actions,
  ...thunks,
};
