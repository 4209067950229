const summaryTotalView = 'SbpExpensesReports.SummaryReportPanel.SummaryTotalTab.Table.Search.Placeholder';
const employeeView = 'SbpExpensesReports.SummaryReportPanel.EmployeeViewTab.Table.Search.Placeholder';
const geographicalView = 'SbpExpensesReports.SummaryReportPanel.GeographicalViewTab.Table.Search.Placeholder';
const departmentView = 'SbpExpensesReports.SummaryReportPanel.DepartmentViewTab.Table.Search.Placeholder';
const planView = 'SbpExpensesReports.SummaryReportPanel.PlanViewTab.Table.Search.Placeholder';
const trueUpExpenseNew = 'SbpExpensesReports.ExpenseTrueUpPanel.NewReportExpenseTable.Search.Placeholder';
const trueUpExpensePrevious = 'SbpExpensesReports.ExpenseTrueUpPanel.NewReportExpenseTable.Search.Placeholder';
const provisionalExpense = 'SbpExpensesReports.ProvisionalExpensePanel.Table.Search.Placeholder';
const shareRegister = 'ShareRegister.GrantTable.Search.Placeholder';
const employeeStatus = 'EmployeeStatus.Table.Search.Placeholder';
const filesExported = 'Files.FileExportsTable.Search.Placeholder';
const performanceHurdles = 'PerformanceHurdles.Table.Search.Placeholder';

export const tableSearchPlaceholder = {
  summaryTotalView,
  employeeView,
  geographicalView,
  departmentView,
  planView,
  trueUpExpenseNew,
  trueUpExpensePrevious,
  provisionalExpense,
  shareRegister,
  employeeStatus,
  filesExported,
  performanceHurdles,
};
