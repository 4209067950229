/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState, useRef } from 'react';
import Collapsible, { CollapsibleProps } from 'react-collapsible';

import './SidePanelAccordion.scss';

export interface SidePanelAccordionProps extends CollapsibleProps {
  keepMountedAfterFirstOpen?: boolean;
}

const SidePanelAccordion: FC<SidePanelAccordionProps> = ({
  children,
  triggerDisabled,
  open,
  handleTriggerClick,
  accordionPosition,
  keepMountedAfterFirstOpen,
  ...props
}) => {
  const wasOpened = useRef(!!open);
  if (!wasOpened.current && !!open) {
    wasOpened.current = true;
  }
  const [isOpen, setIsOpen] = useState(false);

  const accordionTriggerHandler = () => {
    if (triggerDisabled) {
      return;
    }

    if (handleTriggerClick) {
      handleTriggerClick(accordionPosition);
    } else {
      setIsOpen(!isOpen);
    }
  };

  if (handleTriggerClick) {
    return (
      <div className="sidepanelAccordion">
        <Collapsible
          {...props}
          triggerDisabled={triggerDisabled}
          open={open}
          handleTriggerClick={accordionTriggerHandler}
        >
          {(!wasOpened.current || !keepMountedAfterFirstOpen) && !open ? null : children}
        </Collapsible>
      </div>
    );
  }

  return (
    <div className="sidepanelAccordion">
      <Collapsible {...props} triggerDisabled={triggerDisabled} open={!triggerDisabled && open}>
        {children}
      </Collapsible>
    </div>
  );
};

export default SidePanelAccordion;
