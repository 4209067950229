import { ROUTES } from 'shared/constants/routes';
import { ModalStatus } from 'store/expenses/enums';
import { TFunction } from 'react-i18next';
import type { IValidationModalData } from './interfaces';

const validationModalDataByStatus: Record<ModalStatus, IValidationModalData> = {
  [ModalStatus.GRANT_VALUATION_EMPTY]: {
    status: ModalStatus.GRANT_VALUATION_EMPTY,
    title: 'GenerateSbpExpenses.GrantValuationEmptyModal.Title',
    description: 'GenerateSbpExpenses.GrantValuationEmptyModal.Description',
    link: ROUTES.grantValuation,
  },
  [ModalStatus.SHARE_REGISTER_EMPTY]: {
    status: ModalStatus.SHARE_REGISTER_EMPTY,
    title: 'GenerateSbpExpenses.ShareRegisterEmptyModal.Title',
    description: 'GenerateSbpExpenses.ShareRegisterEmptyModal.Description',
    link: ROUTES.shareRegister,
  },
  [ModalStatus.INVALID_SHARE_REGISTER]: {
    status: ModalStatus.INVALID_SHARE_REGISTER,
    title: 'GenerateSbpExpenses.InvalidShareRegisterModal.Title',
    description: 'GenerateSbpExpenses.InvalidShareRegisterModal.Description',
    link: ROUTES.grantValuation,
  },
  [ModalStatus.INVALID_PERFORMANCE_HURDLE_ID]: {
    status: ModalStatus.INVALID_PERFORMANCE_HURDLE_ID,
    title: '',
    description: 'GenerateSbpExpenses.InvalidPerformanceHurdleId.Description',
    link: ROUTES.shareRegister,
  },
  [ModalStatus.PROCEED]: {
    status: ModalStatus.PROCEED,
    title: 'GenerateSbpExpenses.ProceedModal.Title',
    description: 'GenerateSbpExpenses.ProceedModal.Description',
    link: ROUTES.shareRegister,
  },
};

export const getValidationModalData = (status: ModalStatus, employeeQty = 0, t?: TFunction): IValidationModalData => {
  if (status === ModalStatus.PROCEED && employeeQty && t) {
    return {
      ...validationModalDataByStatus[status],
      description: t(validationModalDataByStatus[status].description, { employeeQty }),
    };
  }
  if (status === ModalStatus.INVALID_PERFORMANCE_HURDLE_ID && employeeQty && t) {
    return {
      ...validationModalDataByStatus[status],
      description: t(validationModalDataByStatus[status].description, { employeeQty }),
    };
  }
  return validationModalDataByStatus[status];
};
