/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { ISelected } from 'components/MipTable/MipTable';
import type { IColumnConfig, IRowData } from 'components/Table/interfaces';
import { BeforeUploadWarningModalData } from '../constants/fileUploading';
import * as Models from '../shared.models';
import Utils from '../shared.utils';

interface IItemInfoModalData<T> {
  data: (T & { columnName?: string }) | null;
  visible: boolean;
}

const initialItemInfoModal = {
  data: null,
  visible: false,
};

export const initialFileUploadWarnModal: Models.BM.IBeforeUploadWarningModalData = {
  visible: false,
  title: '',
  text: '',
};

type ForfeitureRate = string | number | undefined;

export interface IUseTableActiveStateOptions {
  uploadWarningModalType?: Models.BM.IFileUploadingView;
  fetchOnMount?: boolean;
  fetchOnFileUpload?: boolean;
  refreshTableDataToggler?: any;
}
export interface IUsePageWithTableInitialOptions<T, M> {
  fetchData?: (searchParams?: any, data?: any) => Promise<Models.BM.ResponseTableDataType<T>>;
  uploadFile?: (file: File, someValue?: any) => Promise<any>;
  deleteData?: (selected: any) => Promise<any>;
  exportData?: (searchParams?: any, data?: any) => Promise<any>;
  updateRowData?: (newRowData: M) => Promise<any>;
  updateEstimate?: (newRowData: M) => Promise<any>;
  getFileUploadingState?: () => Promise<boolean>;
  getDataExportingStatus?: () => Promise<boolean>;
  options?: IUseTableActiveStateOptions;
}

const isDevMode = process.env.NODE_ENV === 'development';

export const useTableActiveState = <T extends IRowData, M extends IRowData | void>(
  {
    fetchData,
    uploadFile: upload,
    deleteData: deleteRows,
    exportData: exportRows,
    getFileUploadingState,
    getDataExportingStatus,
    updateRowData,
    options,
  } = {} as IUsePageWithTableInitialOptions<T, M>
) => {
  const { t } = useTranslation();
  const commonFinallyAction = useCallback(() => {
    getFileUploadingState?.();
    getDataExportingStatus?.();
  }, [getFileUploadingState, getDataExportingStatus]);
  const { fetchOnMount = true, fetchOnFileUpload = true } = options || ({} as IUseTableActiveStateOptions);
  const forfeitureRateRef = useRef<ForfeitureRate>();
  const resetTableAfterFileUploadedRef = useRef<boolean>(false);
  const [data, setData] = useState<T[]>([]);
  const [headers, setHeaders] = useState<Models.BM.ColumnConfiguration<T>[] | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(!!fetchData && fetchOnMount);
  const [dataDeleting, setDataDeleting] = useState<boolean>(false);

  const [dataExporting, setDataExporting] = useState<boolean>(false);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [forfeitRate, setForfeitRate]: any = useState('');
  const [tempFile, setTempFile] = useState<File | null>(null);
  const [rowInfoModalData, setRowInfoModalData] = useState<IItemInfoModalData<T>>(initialItemInfoModal);
  const [rowUpdateEstimateModalData, setrowUpdateEstimateModalData] =
    useState<IItemInfoModalData<T>>(initialItemInfoModal);
  const [rowInfoModalDataUpdating, setRowInfoModalDataUpdating] = useState<boolean>(false);
  const [fileUploadResultModal, setFileUploadResultModal] = useState<{
    rowsAdded: number;
    rowsAddedOrUpdated: number;
    rowsDeleted: number;
    rowsUpdated: number;
    relatedRowsUpdated?: {
      key: string;
      rowsUpdated: number;
    };
  } | null>(null);
  const [fileUploadErrors, setFileUploadErrors] = useState<string[]>([]);
  const [fileUploadWarnModalData, setFileUploadWarnModalData] =
    useState<Models.BM.IBeforeUploadWarningModalData>(initialFileUploadWarnModal);
  const tableDataRef = useRef<any>();

  useEffect(() => {
    tableDataRef.current = data;
  }, [data]);

  const setDataAndHeaders = useCallback((allData: Models.BM.ResponseTableDataType<T>): void => {
    const { getRows, getHeaders } = Utils.getCorrectTableDataFromResponse<T>(allData);
    setData(getRows);
    setHeaders(getHeaders);
  }, []);

  const getDataFinalization = useCallback(() => {
    setDataLoading(false);
    setFileUploading(false);
    setTempFile(null);
  }, []);

  const getData = useCallback(
    (searchParams?: any): Promise<any> =>
      fetchData
        ? fetchData(searchParams)
            .then((responseData) => {
              setDataAndHeaders(responseData);
              getDataFinalization();
            })
            .catch(getDataFinalization)
        : Promise.resolve().then(getDataFinalization),
    [getDataFinalization]
  );

  const closeFileUploadWarnModal = useCallback(() => {
    setFileUploadWarnModalData(initialFileUploadWarnModal);
  }, []);

  const uploadFile = useCallback(
    (file: File, someValue?: any): Promise<void> => {
      setFileUploadErrors([]);
      if (!upload || !file) {
        if (isDevMode) {
          if (!upload) console.warn('set uploadFile method to useTableActiveState');
          if (!file) console.warn('no file to upload');
        }
        return Promise.resolve();
      }
      setFileUploading(true);
      return upload(file, someValue)
        .then((resultData) => {
          if (resultData?.error) throw new Error(resultData?.error);
          commonFinallyAction();
          let result = resultData?.dbResult ? resultData?.dbResult : resultData;

          if (typeof result === 'number') {
            result = {
              rowsAdded: 0,
              rowsAddedOrUpdated: result,
              rowsDeleted: 0,
              rowsUpdated: 0,
            };
          } else if (result) {
            const relatedRowsUpdatedKey =
              !!resultData && resultData instanceof Object
                ? Object.keys(resultData).find((key) => key !== 'dbResult' && key.startsWith('dbResult'))
                : undefined;
            if (relatedRowsUpdatedKey) {
              result.relatedRowsUpdated = {
                key: relatedRowsUpdatedKey,
                rowsUpdated: resultData[relatedRowsUpdatedKey]?.rowsUpdated || 0,
              };
            }
          }
          // setFileUploadResultModal(result);
          setFileUploading(false);
          setTempFile(null);
          resetTableAfterFileUploadedRef.current = true;
          if (fetchOnFileUpload) {
            getData();
          }
          return Promise.resolve();
        })
        .catch((err) => {
          const errors = Utils.parseErrorResponse(err, t);
          setFileUploadErrors(errors);
          setFileUploading(false);
          commonFinallyAction();
          return Promise.reject(errors);
        });
    },
    [getData, upload, fetchOnFileUpload, commonFinallyAction]
  );

  const exportData = useCallback(
    (resetSelected?: (() => void) | null, ...searchParams: any[]) =>
      () => {
        if (!exportRows) {
          if (isDevMode) {
            console.warn('set exportData method to useTableActiveState');
          }
          return Promise.resolve('');
        }
        setDataExporting(true);
        return exportRows(...searchParams)
          .then(() => {
            resetSelected?.();
          })
          .catch(() => {})
          .finally(() => {
            setDataExporting(false);
            commonFinallyAction();
          });
      },
    [exportRows, commonFinallyAction]
  );

  const deleteData = useCallback(
    (selected?: any | ISelected, resetData?: () => void) => () => {
      const haveSomethingToDelete = ((): boolean => {
        if (!selected) return false;
        if (Array.isArray(selected) && !selected.length) return false;
        if (!Array.isArray(selected) && !selected?.keys?.length && !selected?.deleteAll) return false;
        return true;
      })();
      if (!deleteRows || !haveSomethingToDelete) {
        if (isDevMode) {
          if (!deleteRows) console.warn('set deleteData method to useTableActiveState');
          if (!haveSomethingToDelete) console.warn('no data to delete');
        }
        return Promise.resolve('');
      }
      setDataLoading(true);
      setDataDeleting(true);

      return deleteRows(selected || [])
        .then((/* resultData */) => {
          // TODO: uncomment next line if we need to show result of deletion in modal
          // setFileUploadResultModal(resultData?.dbResult ? resultData?.dbResult : resultData);
          setDataDeleting(false);
          resetData?.();
          commonFinallyAction();
          return getData();
        })
        .catch(() => {
          setDataDeleting(false);
          setDataLoading(false);
          commonFinallyAction();
        });
    },
    [getData, deleteRows, commonFinallyAction]
  );

  const confirmRowInfoModal = useCallback(
    (newRowData: M) => {
      if (!updateRowData || !newRowData) {
        if (isDevMode) {
          if (!updateRowData) console.warn('set updateRowData method to useTableActiveState');
          if (!newRowData) console.warn('no data to add/edit');
        }
        return Promise.resolve();
      }
      setRowInfoModalDataUpdating(true);
      return updateRowData(newRowData)
        .then(() => {
          setRowInfoModalData(initialItemInfoModal);
          return getData();
        })
        .finally(() => {
          setRowInfoModalDataUpdating(false);
          commonFinallyAction();
        });
    },
    [updateRowData, commonFinallyAction]
  );
  const openRowInfoModal = useCallback((row?: T, col?: IColumnConfig<T>) => {
    setRowInfoModalData({
      visible: true,
      data: !row
        ? null
        : {
            ...row,
            columnName: col?.name || '',
          },
    });
  }, []);

  const openUpdateEstimateModal = useCallback((row?: T, col?: IColumnConfig<T>) => {
    setrowUpdateEstimateModalData({
      visible: true,
      data: !row
        ? null
        : {
            ...row,
            columnName: col?.name || '',
          },
    });
  }, []);
  const closeUpdateEstimateModal: () => void = useCallback(() => {
    setrowUpdateEstimateModalData(initialItemInfoModal);
  }, []);
  const closeRowInfoModal: () => void = useCallback(() => {
    setRowInfoModalData(initialItemInfoModal);
  }, []);

  const chooseFile = useCallback(
    async (file: File, forfeitureRate?: number | string) => {
      if (!!forfeitureRate || forfeitureRate === 0 || forfeitureRate === undefined) {
        if (!!tableDataRef?.current?.length && !!options?.uploadWarningModalType) {
          setTempFile(file);
          forfeitureRateRef.current = forfeitureRate;
          setFileUploadWarnModalData(BeforeUploadWarningModalData[options.uploadWarningModalType]);
          throw new Error('');
        } else {
          await uploadFile(file, forfeitureRate).catch(() => {});
        }
      } else {
        setTempFile(file);
        forfeitureRateRef.current = forfeitureRate;
        setFileUploadWarnModalData({
          visible: true,
          title: 'ShareRegister.ForfeitureRateModal.Title',
          text: 'ShareRegister.ForfeitureRateModal.Description',
        });
        throw new Error('');
      }
    },
    [uploadFile, !!data?.length, options?.uploadWarningModalType]
  );

  const confirmFileUpload = useCallback(() => {
    if (
      !!data?.length &&
      !!options?.uploadWarningModalType &&
      fileUploadWarnModalData.title !== BeforeUploadWarningModalData[options.uploadWarningModalType].title
    ) {
      setFileUploadWarnModalData(BeforeUploadWarningModalData[options.uploadWarningModalType]);
    } else {
      if (tempFile) uploadFile(tempFile, forfeitureRateRef.current).catch(() => {});
      forfeitureRateRef.current = undefined;
      closeFileUploadWarnModal();
    }
  }, [data, fileUploadWarnModalData, closeFileUploadWarnModal, tempFile]);

  useEffect(() => {
    if (fetchData && fetchOnMount) {
      getData();
    }
  }, [getData, fetchData, fetchOnMount, options?.refreshTableDataToggler]);

  useEffect(() => {
    if (options?.refreshTableDataToggler) {
      setFileUploadErrors((prev) => (prev.length ? [] : prev));
      resetTableAfterFileUploadedRef.current = true;
    }
  }, [options?.refreshTableDataToggler]);

  return {
    data,
    headers,
    setData,
    setHeaders,
    getData,
    dataLoading,
    setDataLoading,
    fileUploading,
    setFileUploading,
    tempFile,
    setTempFile,
    uploadFile,
    deleteData,
    dataDeleting,
    setDataDeleting,
    exportData,
    dataExporting,
    setDataExporting,
    openRowInfoModal,
    openUpdateEstimateModal,
    closeRowInfoModal,
    closeUpdateEstimateModal,
    rowInfoModalData,
    rowUpdateEstimateModalData,
    fileUploadResultModal,
    closeFileUploadResultModal: useCallback(() => {
      setFileUploadResultModal(null);
    }, []),
    fileUploadErrors,
    fileUploadWarnModalData,
    closeFileUploadWarnModal,
    chooseFile,
    confirmFileUpload,
    confirmRowInfoModal,
    rowInfoModalDataUpdating,
    resetTableAfterFileUploadedRef,
    setDataAndHeaders,
    setFileUploadErrors,
    forfeitRate,
    setForfeitRate,
  };
};
