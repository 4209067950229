import { setToLocalStorage } from 'shared/utils/localStorage';
import { getExpiresFromStorage } from './SessionTimeoutModal.helpers';

interface IIdleTimerProps {
  timeoutMS: number;
  expiresStorageKey: string;
}

interface IEvents {
  onExpired: () => void;
  onRemainingTimeChanged: (remainingTimeMs: number) => void;
}

class IdleTimer {
  private timeout: number;

  private expiresStorageKey: string;

  private onRemainingTimeChanged?: IEvents['onRemainingTimeChanged'];

  private onExpired?: IEvents['onExpired'];

  private updateExpiresTimeoutId: NodeJS.Timeout | null = null;

  private intervalId: NodeJS.Timer | null = null;

  private cleanupEventListeners = () => {};

  private eventNames?: string[];

  private domElementForEventListeners?: Element;

  private updateExpiredTime = (): void => {
    if (this.updateExpiresTimeoutId) {
      clearTimeout(this.updateExpiresTimeoutId);
    }
    this.updateExpiresTimeoutId = setTimeout(() => {
      setToLocalStorage(this.expiresStorageKey, Date.now() + this.timeout);
    }, 300);
  };

  private runEventListeners = (): void => {
    if (this.eventNames?.length && this.domElementForEventListeners) {
      const element = this.domElementForEventListeners;
      const events = this.eventNames;
      events.forEach((eventName) => {
        element.addEventListener(eventName, this.updateExpiredTime, true);
      });
      this.cleanupEventListeners = () => {
        events.forEach((eventName) => {
          element.removeEventListener(eventName, this.updateExpiredTime, true);
        });
      };
    }
  };

  constructor({ timeoutMS, expiresStorageKey }: IIdleTimerProps) {
    this.timeout = timeoutMS;
    this.expiresStorageKey = expiresStorageKey;
  }

  public addOnExpired = (onExpired: IEvents['onExpired']): IdleTimer => {
    this.onExpired = onExpired;
    return this;
  };

  public addOnRemainingTimeChanged = (onRemainingTimeChanged: IEvents['onRemainingTimeChanged']): IdleTimer => {
    this.onRemainingTimeChanged = onRemainingTimeChanged;
    return this;
  };

  public addEventNamesToListen = (events: string[]): IdleTimer => {
    this.eventNames = events;
    return this;
  };

  public addDomElement = (elementId: string): IdleTimer => {
    const element = elementId ? document.getElementById(elementId) : null;
    if (element) {
      this.cleanupEventListeners();
      this.domElementForEventListeners = element;
    }
    return this;
  };

  public startInterval = (): void => {
    this.updateExpiredTime();
    this.runEventListeners();

    this.intervalId = setInterval(() => {
      const expiredTime = getExpiresFromStorage(this.timeout, this.expiresStorageKey);
      const remainingTime = expiredTime - Date.now();
      const isExpired = remainingTime < 1;

      this.onRemainingTimeChanged?.(isExpired ? 0 : remainingTime);

      if (isExpired) {
        this.onExpired?.();
      }
    }, 1000);
  };

  public cleanUp = (): void => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.updateExpiresTimeoutId) {
      clearTimeout(this.updateExpiresTimeoutId);
    }
    this.cleanupEventListeners();
    localStorage.removeItem(this.expiresStorageKey);
  };
}

export default IdleTimer;
