import { useMemo } from 'react';
import { SelectOption } from '../models/models.dl';

interface IProps {
  initialValues: Record<string, any>;
  currentValues: Record<string, any>;
  comparedFields?: Array<keyof IProps['initialValues']>;
}

interface IHookReturnType {
  isModified: boolean;
}

// For select values to compare
// Sometimes initial value is string but current value is an object with fields 'text' and 'id'
const getValue = (value: number | string | SelectOption) => {
  return (value as SelectOption)?.text || value;
};

export const useIsFormModified = ({ initialValues, currentValues, comparedFields }: IProps): IHookReturnType => {
  // By default this hook compares all form values, but we can pass 'comparedFields' param to compare only fields we need
  const fields = useMemo(() => comparedFields || Object.keys(initialValues), [comparedFields, initialValues]);
  const isModified = useMemo(() => {
    if (initialValues && currentValues) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of fields) {
        const isDate = initialValues[key] instanceof Date && currentValues[key] instanceof Date;

        if (isDate && initialValues[key]?.toLocaleDateString() !== currentValues[key]?.toLocaleDateString()) {
          return true;
        }
        // Numeric initial values are numbers but form values are strings
        // eslint-disable-next-line eqeqeq
        if (!isDate && getValue(initialValues[key]) != getValue(currentValues[key])) {
          return true;
        }
      }
    }
    return false;
  }, [initialValues, currentValues, fields]);

  return {
    isModified,
  };
};
