import { FC } from 'react';
import Button, { ButtonColor, ButtonStyleType } from '../Button';

export interface IModalFooterProps {
  cancelBtnTitle?: string;
  confirmBtnTitle?: string;
  confirmDisabled?: boolean;
  confirmLoading?: boolean;
  cancelDisabled?: boolean;
  cancelBtnColor?: ButtonColor;
  cancelBtnStyleType?: ButtonStyleType;
  confirmBtnColor?: ButtonColor;
  confirmBtnStyleType?: ButtonStyleType;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const ModalFooter: FC<IModalFooterProps> = ({
  onCancel,
  onConfirm,
  cancelBtnTitle,
  confirmBtnTitle,
  confirmDisabled,
  confirmLoading,
  cancelDisabled,
  cancelBtnColor,
  cancelBtnStyleType,
  confirmBtnColor,
  confirmBtnStyleType,
  children,
}) => {
  if (!onCancel && !onConfirm) {
    return null;
  }
  return (
    <div className="btn-group">
      {children}
      {!!onCancel && (
        <Button
          styleType={cancelBtnStyleType}
          color={cancelBtnColor}
          onClick={onCancel}
          disabled={cancelDisabled || confirmLoading}
        >
          {cancelBtnTitle}
        </Button>
      )}
      {!!onConfirm && (
        <Button
          styleType={confirmBtnStyleType}
          color={confirmBtnColor}
          onClick={onConfirm}
          disabled={confirmDisabled}
          loading={confirmLoading}
        >
          {confirmBtnTitle}
        </Button>
      )}
    </div>
  );
};

export default ModalFooter;
