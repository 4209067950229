import { FC } from 'react';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { RadioGroup, RadioGroupChangeEvent, RadioGroupProps } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import LabelText, { ILabelProps } from '../Label';
import './FormRadioGroup.scss';

interface IProps extends Omit<ILabelProps, 'iconStart' | 'iconEnd'> {
  labelIconStart?: ILabelProps['iconStart'];
  labelIconEnd?: ILabelProps['iconEnd'];
}

const FormRadioGroup: FC<FieldRenderProps & RadioGroupProps & IProps> = ({
  validationMessage,
  label,
  isRequiredIcon,
  value,
  disabled,
  onChange,
  layout = 'horizontal',
  labelIconStart,
  labelIconEnd,
  className = '',
  ...props
}) => {
  const handleChange = (e: RadioGroupChangeEvent) => {
    if (disabled) return;
    onChange?.(props.name ? ({ ...e, target: { ...e.target, name: props.name } } as RadioGroupChangeEvent) : e);
  };

  return (
    <div className={`FormRadioGroup${className ? ` ${className}` : ''}`}>
      {label && (
        <LabelText
          valid={props.valid}
          label={label}
          isRequiredIcon={isRequiredIcon}
          iconStart={labelIconStart}
          iconEnd={labelIconEnd}
        />
      )}
      <RadioGroup {...props} disabled={disabled} onChange={handleChange} layout={layout} value={value} />
      {props.visited && !!value && !!validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export default FormRadioGroup;
