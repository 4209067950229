import type { AppState } from '../../interfaces';
import { ReportsStoreName } from '../storeName';
import type { StoreType } from './interfaces';

const currentStoreName = ReportsStoreName.GeographicalView;

export const selectDataLoading = ({ reports }: AppState): StoreType['loading'] => reports[currentStoreName].loading;

export const selectGeneratedReport = ({ reports }: AppState): StoreType['generatedReport'] =>
  reports[currentStoreName].generatedReport;

export const selectTableFilters = ({ reports }: AppState): StoreType['filters'] => reports[currentStoreName].filters;

export const selectTableData = ({ reports }: AppState): StoreType['data'] => reports[currentStoreName].data;

export const selectTableHeaders = ({ reports }: AppState): StoreType['headers'] => reports[currentStoreName].headers;

export const selectOnlySumRows = ({ reports }: AppState): StoreType['onlySumRows'] =>
  reports[currentStoreName].onlySumRows;

export const selectPaginatedTableData = ({ reports }: AppState): StoreType['paginatedTableData'] =>
  reports[currentStoreName].paginatedTableData;

export const selectFiltersLoading = ({ reports }: AppState): StoreType['filtersLoading'] =>
  reports[currentStoreName].filtersLoading;

export const selectChartLoading = ({ reports }: AppState): StoreType['chartLoading'] =>
  reports[currentStoreName].chartLoading;

export const selectChartData = ({ reports }: AppState): StoreType['chartData'] => reports[currentStoreName].chartData;
