import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

const TranslatedText: FC<{ message: string }> = ({ message }) => {
  const { t } = useTranslation();

  return t(message);
};

export default TranslatedText;
