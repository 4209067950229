export const pageTitle = {
  appCommonName: 'My Incentive Plan -',
  landingPage: 'Login',
  signin: 'SignIn',
  signout: 'SignOut',
  logout: 'Logout',
  signInSilent: 'SignInSilent',
  unauthorized: 'Unauthorized',
  home: 'Home',
  shareRegister: 'Share Register',
  performanceHurdles: 'Performance Hurdles',
  manageEmployeeContracts: 'Manage Employee Contracts',
  employeeContractDetails: 'Employee Contract Details',
  customVestingSchedules: 'Custom Vesting Schedules',
  grantValuation: 'Grant Valuation',
  employeeStatus: 'Employee Status',
  sbpExpenses: 'Sbp Expenses',
  sbpExpenseReports: 'Sbp Expenses Reports',
  sbpExpensesJournal: 'Sbp Expenses Journal',
  files: 'Files',
  logs: 'Logs',
};
