import { useRef, useCallback, useState, useMemo } from 'react';
import type { IBeforeUploadWarningModalData, IFileUploadingView } from '../models/models.bl';
import { BeforeUploadWarningModalData } from '../constants/fileUploading';
import {
  HandleFileUploadType,
  IOptions,
  IStateWithReset as IUploaderState,
  OnFileUploadType as OnUseUploaderFileUploadType,
  useUploader,
} from './useUploader';

interface IStateToReturn extends Omit<IUploaderState, 'reset'> {
  confirm: () => ReturnType<HandleFileUploadType>;
  cancel: () => void;
  fileUploadWarnModalData: IBeforeUploadWarningModalData;
}

type OnFileUploadType = (
  ...props: Parameters<HandleFileUploadType>
) => Promise<{ isConfirmationNeeded: boolean } | void>;
type UseUploaderWithDataConfirmationReturnType = [upload: OnFileUploadType, state: IStateToReturn];

type UseUploaderWithDataConfirmationType = (
  onFileUpload: OnUseUploaderFileUploadType,
  options: IOptions & { uploadWarningModalConfig?: { type: IFileUploadingView; isDataExists: boolean } }
) => UseUploaderWithDataConfirmationReturnType;

const initialFileUploadWarnModal: IBeforeUploadWarningModalData = {
  visible: false,
  title: '',
  text: '',
};

export const useUploaderWithDataConfirmation: UseUploaderWithDataConfirmationType = (
  onFileUpload,
  { uploadWarningModalConfig, ...options }
) => {
  const uploadDataHolderForConfirmation = useRef<Parameters<HandleFileUploadType> | null>(null);
  const [fileUploadWarnModalData, setFileUploadWarnModalData] =
    useState<IBeforeUploadWarningModalData>(initialFileUploadWarnModal);

  const [upload, { reset: resetUploaderState, ...state }] = useUploader(onFileUpload, options);

  const cancel = useCallback(() => {
    uploadDataHolderForConfirmation.current = null;
    setFileUploadWarnModalData(initialFileUploadWarnModal);
    resetUploaderState();
  }, [resetUploaderState]);

  const uploadFile: OnFileUploadType = useCallback(
    async (...props) => {
      if (uploadWarningModalConfig?.isDataExists) {
        uploadDataHolderForConfirmation.current = props;
        setFileUploadWarnModalData(BeforeUploadWarningModalData[uploadWarningModalConfig.type]);
        return { isConfirmationNeeded: true };
      }
      setFileUploadWarnModalData(initialFileUploadWarnModal);
      uploadDataHolderForConfirmation.current = null;
      // INFO: no needed handle error in here to prevent emitting methods outside of hook if there is error
      return upload(...props);
    },
    [upload, uploadWarningModalConfig?.isDataExists, uploadWarningModalConfig?.type]
  );

  const confirm = useCallback(async () => {
    if (!uploadDataHolderForConfirmation.current) return;
    setFileUploadWarnModalData(initialFileUploadWarnModal);
    // INFO: no needed handle error in here to prevent emitting methods outside of hook if there is error
    await upload(...uploadDataHolderForConfirmation.current);
  }, [upload]);

  const stateToReturn = useMemo(
    () => ({
      ...state,
      cancel,
      confirm,
      fileUploadWarnModalData,
    }),
    [state, cancel, confirm, fileUploadWarnModalData]
  );

  return [uploadFile, stateToReturn];
};
