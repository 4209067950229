import { getInitialPaginatedTableData, GridColumnConfig } from 'shared/configs/grid.config';
import Utils from 'shared/shared.utils';
import type { SummaryTotalTableData } from 'shared/models/models.bl';
import { Types } from './types';
import type * as Interfaces from './interfaces';
import * as helpers from './helpers';

const defaultFilter = {
  id: '',
  text: 'SbpExpensesReports.SummaryReportPanel.SummaryTotalTab.GrantDropdown.DefaultOption',
};

const initialPaginatedTableData = getInitialPaginatedTableData<SummaryTotalTableData>(
  GridColumnConfig.SummaryTotalConfig
);

const initialState: Interfaces.StoreType = {
  generatedReport: null,
  loading: false,
  data: [],
  headers: null,
  filters: [defaultFilter],
  ytdExpense: 0,
  currentMonthExpense: 0,
  onlySumRows: [],
  dataForFinalization: null,
  paginatedTableData: initialPaginatedTableData,
  filtersLoading: false,
  chartLoading: false,
  chartData: [],
};

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action: Interfaces.SummaryTotalActions = {} as Interfaces.SummaryTotalActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_GENERATED_REPORT: {
      return { ...state, generatedReport: action.payload };
    }
    case Types.SET_DATA_LOADING: {
      return { ...state, loading: action.payload };
    }
    case Types.SET_TABLE_FILTERS: {
      const filters = Utils.getCorrectTableFiltersFromResponse(action.payload)(state.filters);
      return { ...state, filters, filtersLoading: false };
    }
    case Types.SET_DATA_FOR_FINALIZATION: {
      return {
        ...state,
        dataForFinalization: action.payload
          ? {
              ...action.payload,
              totalProvisions: helpers.getValueFromDateValues(action.payload.listProvisionExpensesPerMonth),
              totalTrueUp: helpers.getValueFromDateValues(action.payload.listTrueUpExpensesPerMonth),
            }
          : null,
      };
    }
    case Types.SET_PAGINATED_TABLE_DATA: {
      let paginatedTableData = action.payload || initialPaginatedTableData;
      if (!paginatedTableData.tableData) {
        paginatedTableData = {
          ...paginatedTableData,
          tableData: initialPaginatedTableData.tableData,
        };
      }
      return {
        ...state,
        loading: false,
        paginatedTableData,
      };
    }
    case Types.SET_FILTERS_LOADING: {
      return { ...state, filtersLoading: action.payload };
    }
    case Types.SET_CHART_LOADING: {
      return { ...state, chartLoading: action.payload };
    }
    case Types.SET_CHART_DATA: {
      return {
        ...state,
        chartData: action.payload,
        ...helpers.getTotalExpensesFromChartData(action.payload),
        chartLoading: false,
      };
    }
    default:
      return state;
  }
}
