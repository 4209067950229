import { FC } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';

import ModalHeader from './ModalHeader';
import ModalFooter, { IModalFooterProps } from './ModalFooter';

import './Modal.scss';

interface IProps extends IModalFooterProps {
  className?: string;
  title: string;
  icon?: any;
  description?: string;
  onClose?: () => void;
  validationMessage?: string;
  additionalButtons?: JSX.Element | null;
  hideCrossIcon?: boolean;
}

const ModalLayout: FC<IProps> = ({
  title,
  icon,
  className = '',
  description = '',
  onClose,
  onCancel,
  onConfirm,
  hideCrossIcon,
  cancelBtnTitle = 'Cancel',
  confirmBtnTitle = 'Add',
  confirmDisabled = false,
  confirmLoading = false,
  cancelDisabled = false,
  validationMessage = '',
  cancelBtnColor = 'primary',
  cancelBtnStyleType = 'outlined',
  confirmBtnColor = 'primary',
  confirmBtnStyleType = 'default',
  additionalButtons,
  children,
}) => {
  const handleClose = () => {
    if (cancelDisabled || confirmLoading) return;
    (onClose || onCancel)?.();
  };

  return (
    <Dialog className={`modalLayout ${className}`} onClose={handleClose} closeIcon={false}>
      <div className="modalLayout-wrapper">
        <ModalHeader
          title={title}
          icon={icon}
          close={(onClose || onCancel) && !hideCrossIcon ? handleClose : undefined}
        />
        {description && <span className="modalLayout__description">{description}</span>}
        {children}
        {validationMessage && <span className="modalLayout__validation-message">{validationMessage}</span>}
        <ModalFooter
          cancelBtnTitle={cancelBtnTitle}
          confirmBtnTitle={confirmBtnTitle}
          onCancel={onCancel}
          onConfirm={onConfirm}
          confirmDisabled={confirmDisabled}
          confirmLoading={confirmLoading}
          cancelDisabled={cancelDisabled}
          cancelBtnColor={cancelBtnColor}
          cancelBtnStyleType={cancelBtnStyleType}
          confirmBtnColor={confirmBtnColor}
          confirmBtnStyleType={confirmBtnStyleType}
        >
          {additionalButtons}
        </ModalFooter>
      </div>
    </Dialog>
  );
};

export default ModalLayout;
