export enum OIDCROUTES {
  // oidc-client routes
  login = '/login',
  logout = '/logout',
  signin = '/signin-oidc',
  signout = '/signout-callback-oidc',
  // need check for this route
  signinSilent = '/signin-silent-oidc',
}

export const OIDCRoutesList: OIDCROUTES[] = Object.keys(OIDCROUTES).reduce((acc, option) => {
  if (Number.isNaN(+option)) {
    acc.push(OIDCROUTES[option as keyof typeof OIDCROUTES]);
  }
  return acc;
}, [] as OIDCROUTES[]);
