import type { AppState } from '../interfaces';

export const selectIsUploadingShareRegister = ({ fileUploading }: AppState): boolean => fileUploading.shareRegister;

export const selectIsUploadingGrandValuation = ({ fileUploading }: AppState): boolean => fileUploading.grandValuation;

export const selectIsUploadingEmployeeStatus = ({ fileUploading }: AppState): boolean => fileUploading.employeeStatus;

export const selectIsUploadingCustomVestingStatus = ({ fileUploading }: AppState): boolean =>
  fileUploading.customVesting;
