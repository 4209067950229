export const checkIsMobileBrowser = (): boolean => {
  let ua: any;
  if (typeof navigator !== 'undefined') {
    ua = navigator.userAgent;
  }
  if (ua && ua.headers && typeof ua.headers['user-agent'] === 'string') {
    ua = ua.headers['user-agent'];
  }
  if (typeof ua !== 'string') {
    return false;
  }

  const matchedUserAgent =
    ua.match(/Android/i) ||
    ua.match(/webOS/i) ||
    ua.match(/iPhone/i) ||
    ua.match(/iPad/i) ||
    ua.match(/iPod/i) ||
    ua.match(/BlackBerry/i) ||
    ua.match(/Windows Phone/i);

  return matchedUserAgent !== null;
};
