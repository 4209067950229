import * as Models from 'shared/shared.models';
import Utils from 'shared/shared.utils';
import { IExpensesJournalFilters } from 'shared/models/models.bl';

enum RowNames {
  drExpense = 'GenerateJournalEntries.Table.DrExpenseRowTitle',
  drOtherAccrualsProvisions = 'GenerateJournalEntries.Table.DrOtherAccrualsProvisionsRowTitle',
  crEsopEquity = 'GenerateJournalEntries.Table.CrEsopEquityRowTitle',
  crTrueUp = 'GenerateJournalEntries.Table.CrTrueUpRowTitle',
  drEsopEquity = 'GenerateJournalEntries.Table.DrEsopEquityRowTitle',
}

const getCalculationByName = (name: string, data: Models.BM.IExpensesJournalCalculatedData) => {
  const { currentMonthExpense, provisionCurrentMonth, trueUpCurrentMonth, provisionPreviousMonth } = data;
  let debit = null;
  let credit = null;

  switch (name) {
    case RowNames.drExpense:
      debit = currentMonthExpense;
      break;
    case RowNames.drOtherAccrualsProvisions:
      {
        const otherAccruals = provisionCurrentMonth - provisionPreviousMonth;

        if (otherAccruals > 0) {
          credit = otherAccruals;
        } else {
          debit = 0 - otherAccruals;
        }
      }
      break;
    case RowNames.crEsopEquity:
      {
        const otherAccruals = provisionCurrentMonth - provisionPreviousMonth;

        if (otherAccruals > 0) {
          credit = currentMonthExpense - otherAccruals;
        } else {
          credit = currentMonthExpense + (0 - otherAccruals);
        }
      }
      break;
    case RowNames.crTrueUp:
      if (trueUpCurrentMonth > 0) {
        debit = trueUpCurrentMonth;
      } else {
        credit = 0 - trueUpCurrentMonth;
      }
      break;
    case RowNames.drEsopEquity:
      if (trueUpCurrentMonth > 0) {
        credit = trueUpCurrentMonth;
      } else {
        debit = 0 - trueUpCurrentMonth;
      }
      break;
    default:
      break;
  }

  return { debit, credit };
};

export const tableConfig = [
  {
    name: RowNames.drExpense,
    debit: '',
    credit: null,
  },
  {
    name: RowNames.drOtherAccrualsProvisions,
    debit: '',
    credit: '',
  },
  {
    name: RowNames.crEsopEquity,
    debit: null,
    credit: '',
  },
  {
    name: RowNames.crTrueUp,
    debit: '',
    credit: '',
  },
  {
    name: RowNames.drEsopEquity,
    debit: '',
    credit: '',
  },
];

export const getTableDataAndExportData = (data: Models.BM.IExpensesJournalCalculatedData) => {
  const { provisionCurrentMonth, trueUpCurrentMonth, provisionPreviousMonth } = data;
  let dataToExport: string[] = [];
  const tableConfigData = tableConfig.map(({ name }) => {
    const { debit: calculatedDebit, credit: calculatedCredit } = getCalculationByName(name, data);
    const d = typeof calculatedDebit === 'number' ? Utils.currencyFormatter(calculatedDebit) : '';
    const c = typeof calculatedCredit === 'number' ? Utils.currencyFormatter(calculatedCredit) : '';

    switch (name) {
      case RowNames.drExpense:
        dataToExport = [...dataToExport, d];
        break;
      case RowNames.drOtherAccrualsProvisions:
        dataToExport = [...dataToExport, 'Dr', d, c];
        break;
      case RowNames.crEsopEquity:
        dataToExport = [...dataToExport, c];
        break;
      case RowNames.crTrueUp:
        dataToExport = [...dataToExport, 'Cr', d, c];
        break;
      case RowNames.drEsopEquity:
        dataToExport = [
          ...dataToExport,
          'Dr',
          d,
          c,
          Utils.currencyFormatter(provisionCurrentMonth),
          Utils.currencyFormatter(provisionPreviousMonth),
          Utils.currencyFormatter(trueUpCurrentMonth),
        ];
        break;
      default:
        break;
    }

    return {
      name,
      debit: d,
      credit: c,
    };
  });

  return {
    tableConfigData,
    dataToExport: dataToExport.join('||'),
  };
};

export const defaultItemDate = new Date();

export const defaultSelectedOptions: IDefaultSelectedOptions = {
  reportUId: '',
  monthYear: defaultItemDate,
};

export const defaultMultipleSelectedOptions: IDefaultMultipleSelectedOptions = {
  locations: [],
  schemes: [],
  entities: [],
  departmentAs: [],
  departmentBs: [],
  departmentCs: [],
};

export enum JournalFiltersTypes {
  FilterByLocation = 'locations',
  FilterByPlan = 'schemes',
  Entities = 'entities',
  DepartmentAs = 'departmentAs',
  DepartmentBs = 'departmentBs',
  DepartmentCs = 'departmentCs',
}

export const defaultFilterOptions = {
  [JournalFiltersTypes.FilterByLocation]: [],
  [JournalFiltersTypes.FilterByPlan]: [],
  [JournalFiltersTypes.Entities]: [],
  [JournalFiltersTypes.DepartmentAs]: [],
  [JournalFiltersTypes.DepartmentBs]: [],
  [JournalFiltersTypes.DepartmentCs]: [],
};

interface IDefaultSelectedOptions {
  reportUId: string;
  monthYear: Date;
}

interface IDefaultMultipleSelectedOptions {
  locations: string[];
  schemes: string[];
  entities: string[];
  departmentAs: string[];
  departmentBs: string[];
  departmentCs: string[];
}

export const ALL = 'All';

export const changeALLFilterOption = (options: string[], translatedAllOption: string) => {
  const optionsArray = [...options];

  for (let i = 0; i < optionsArray.length; i++) {
    if (optionsArray[i] === translatedAllOption) {
      optionsArray[i] = ALL;

      return optionsArray;
    }
    if (optionsArray[i] === ALL) {
      optionsArray[i] = translatedAllOption;

      return optionsArray;
    }
  }

  return optionsArray;
};

export const updateSelectedOptions = (
  updatedSelectedOptions: Models.BM.IExpensesJournalSelectedOptions,
  defaultAllOption: string
) => {
  Object.keys(updatedSelectedOptions).forEach((key) => {
    const value = updatedSelectedOptions[key as keyof IExpensesJournalFilters];

    if (Array.isArray(value)) {
      updatedSelectedOptions[key as keyof IExpensesJournalFilters] = changeALLFilterOption(value, defaultAllOption);
    }
  });

  return updatedSelectedOptions;
};
