import { FC } from 'react';
import {
  Badge as VendorBadge,
  BadgeContainer as VendorBadgeContainer,
  BadgeProps as VendorBadgeProps,
  BadgeContainerProps as VendorBadgeContainerProps,
} from '@progress/kendo-react-indicators';

import './Badge.scss';

export type BadgeProps = VendorBadgeProps;
export type BadgeContainerProps = VendorBadgeContainerProps;

export const Badge: FC<BadgeProps> = ({ children, className, size = 'small', shape = 'rounded', ...props }) => {
  return (
    <VendorBadge className={`Badge${className ? ` ${className}` : ''}`} size={size} shape={shape} {...props}>
      {children}
    </VendorBadge>
  );
};

export const BadgeContainer: FC<BadgeContainerProps> = ({
  children,
  className,
  style = { display: 'flex' },
  ...props
}) => {
  return (
    <VendorBadgeContainer
      className={`VendorBadgeContainer${className ? ` ${className}` : ''}`}
      style={style}
      {...props}
    >
      {children}
    </VendorBadgeContainer>
  );
};
