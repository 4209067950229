import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CriticalNotificationsIcon } from 'assets/svg/info_warning/critical-notifications.svg';
import Services from 'shared/shared.services';
import { ROUTES } from 'shared/constants/routes';
import Button from 'components/Button';
import { selectIsLoadedLocaleKeys } from 'store/localization/selectors';

import './Unauthorized.scss';

const Unauthorized: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthorized: boolean = Services.BL.AuthorizationService.isAuthorized();
  const isLoadedLocaleKeys = useSelector(selectIsLoadedLocaleKeys);

  const handleRedirect = () => {
    navigate(ROUTES.landing, { replace: true });
  };

  return !isAuthorized ? (
    <div className="unauthorized-container">
      <CriticalNotificationsIcon />
      {isLoadedLocaleKeys && (
        <>
          <div className="title">{t('Unauthorized.PageTitle')}</div>
          <div className="description">{t('Unauthorized.PageDescription')}</div>
          <Button uppercase onClick={handleRedirect}>
            {t('Unauthorized.PageReloadButton')}
          </Button>
        </>
      )}
    </div>
  ) : null;
};

export default Unauthorized;
