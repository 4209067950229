import { getFromLocalStorage, setToLocalStorage } from 'shared/utils/localStorage';

export const getMMSSFromMS = (value: number): string => {
  const ms = Math.floor(value / 1000);
  const minutes = Math.floor(ms / 60);
  const seconds = Math.floor(ms % 60);
  const getLeadingZero = (v: number) => (v < 10 ? `0${v}` : v);
  return `${getLeadingZero(minutes)}:${getLeadingZero(seconds)}`;
};

export const getExpiresFromStorage = (timeoutMS: number, expiresStorageKey: string): number => {
  let expires = getFromLocalStorage<number>(expiresStorageKey);
  if (!expires && expires !== 0) {
    expires = Date.now() + timeoutMS;
    setToLocalStorage(expiresStorageKey, expires);
  }
  return expires;
};
