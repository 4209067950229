import type { AppState } from '../../interfaces';
import { ReportsStoreName } from '../storeName';
import type { StoreType } from './interfaces';

const currentStoreName = ReportsStoreName.ProvisionalExpense;

export const selectDataLoading = ({ reports }: AppState): StoreType['dataLoading'] =>
  reports[currentStoreName].dataLoading;

export const selectFiltersLoading = ({ reports }: AppState): StoreType['filtersLoading'] =>
  reports[currentStoreName].filtersLoading;

export const selectGeneratedReport = ({ reports }: AppState): StoreType['generatedReport'] =>
  reports[currentStoreName].generatedReport;

export const selectCalculationResult = ({ reports }: AppState): StoreType['calculationResult'] =>
  reports[currentStoreName].calculationResult;

export const selectCalculating = ({ reports }: AppState): StoreType['calculating'] =>
  reports[currentStoreName].calculating;

export const selectFileUploaded = ({ reports }: AppState): StoreType['fileUploaded'] =>
  reports[currentStoreName].fileUploaded;

export const selectTableFilters = ({ reports }: AppState): StoreType['filters'] => reports[currentStoreName].filters;

export const selectTableData = ({ reports }: AppState): StoreType['paginatedData'] =>
  reports[currentStoreName].paginatedData;

export const selectTotalProvisionByFilter = ({ reports }: AppState): StoreType['totalProvisionByFilter'] =>
  reports[currentStoreName].totalProvisionByFilter;

export const selectChartData = ({ reports }: AppState): StoreType['chartData'] => reports[currentStoreName].chartData;

export const selectChartDataLoading = ({ reports }: AppState): StoreType['chartDataLoading'] =>
  reports[currentStoreName].chartDataLoading;
