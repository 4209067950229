import { combineReducers } from 'redux';
import { StoreName } from './storeName';
import currentUserReducer from './user/reducer';
import redirectToReducer from './redirect/reducer';
import loaderReducer from './loader/reducer';
import sidebarReducer from './sidebar/reducer';
import userPanelReducer from './userPanel/reducer';
import expensesReducer from './expenses/reducer';
import expensesReportsReducer from './reports/reducer';
import FilesReducer from './files/reducer';
import NotificationCenter from './notificationCenter/reducer';
import fileUploadingReducer from './fileUploading/reducer';
import localizationReducer from './localization/reducer';
import dataExportingReducer from './dataExporting/reducer';
import managerEmployeeContractsReducer from './managerEmployeeContracts/reducer';

const mainReducer = combineReducers({
  [StoreName.CurrentUser]: currentUserReducer,
  [StoreName.RedirectTo]: redirectToReducer,
  [StoreName.Loader]: loaderReducer,
  [StoreName.Sidebar]: sidebarReducer,
  [StoreName.UserPanel]: userPanelReducer,
  [StoreName.Expenses]: expensesReducer,
  [StoreName.Reports]: expensesReportsReducer,
  [StoreName.Files]: FilesReducer,
  [StoreName.NotificationCenter]: NotificationCenter,
  [StoreName.FileUploading]: fileUploadingReducer,
  [StoreName.Localization]: localizationReducer,
  [StoreName.DataExporting]: dataExportingReducer,
  [StoreName.ManagerEmployeeContracts]: managerEmployeeContractsReducer,
});

export default mainReducer;
