import { getInitialPaginatedTableData, GridColumnConfig } from 'shared/configs/grid.config';
import type { DepartmentViewTableData } from 'shared/models/models.bl';
import * as Models from 'shared/shared.models';
import { Types } from './types';
import Utils from '../../../shared/shared.utils';
import type * as Interfaces from './interfaces';

const initialPaginatedTableData = getInitialPaginatedTableData<DepartmentViewTableData>(
  GridColumnConfig.GeographicalViewConfig
);

export const defaultFilter: Interfaces.StoreType['filters'] = {
  [Models.DE.FiltersTypes.Entities]: [],
  [Models.DE.FiltersTypes.DepartmentAs]: [],
  [Models.DE.FiltersTypes.DepartmentBs]: [],
  [Models.DE.FiltersTypes.DepartmentCs]: [],
};

const initialState: Interfaces.StoreType = {
  generatedReport: null,
  loading: false,
  data: [],
  headers: null,
  filters: defaultFilter,
  onlySumRows: [],
  paginatedTableData: initialPaginatedTableData,
  filtersLoading: false,
  chartLoading: false,
  chartData: [],
};

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action: Interfaces.DepartmentViewActions = {} as Interfaces.DepartmentViewActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_GENERATED_REPORT: {
      return { ...state, generatedReport: action.payload };
    }
    case Types.SET_DATA_LOADING: {
      return { ...state, loading: action.payload };
    }
    case Types.SET_TABLE_DATA: {
      const { getRows, getHeaders } = Utils.getCorrectTableDataFromResponse<DepartmentViewTableData>(action.payload);
      const data = getRows(state.data);
      const headers = getHeaders(state.headers);
      return data !== state.data || headers !== state.headers
        ? {
            ...state,
            data,
            headers,
            onlySumRows: headers?.[0]?.name ? Utils.getOnlySumRows<DepartmentViewTableData>(data, headers[0].name) : [],
          }
        : state;
    }
    case Types.SET_TABLE_FILTERS: {
      return { ...state, filters: action.payload, filtersLoading: false };
    }
    case Types.SET_PAGINATED_TABLE_DATA: {
      let paginatedTableData = action.payload || initialPaginatedTableData;
      if (!paginatedTableData.tableData) {
        paginatedTableData = {
          ...paginatedTableData,
          tableData: initialPaginatedTableData.tableData,
        };
      }
      return {
        ...state,
        loading: false,
        paginatedTableData,
      };
    }
    case Types.SET_FILTERS_LOADING: {
      return { ...state, filtersLoading: action.payload };
    }
    case Types.SET_CHART_LOADING: {
      return { ...state, chartLoading: action.payload };
    }
    case Types.SET_CHART_DATA: {
      return { ...state, chartData: action.payload, chartLoading: false };
    }
    default:
      return state;
  }
}
