import { useState } from 'react';
import styles from './SidePanel.module.css';
import { Tab } from '../Tab/Tab';
import PanelIcon1 from '../../assets/svg/PanelIcon1.svg';
import PanelIcon2 from '../../assets/svg/PanelIcon2.svg';
import PanelIcon3 from '../../assets/svg/PanelIcon3.svg';
import PanelIcon4 from '../../assets/svg/PanelIcon4.svg';
import PanelIconGreen1 from '../../assets/svg/PanelIconGreen1.svg';
import PanelIconGreen2 from '../../assets/svg/PanelIconGreen2.svg';
import PanelIconGreen3 from '../../assets/svg/PanelIconGreen3.svg';
import PanelIconGreen4 from '../../assets/svg/PanelIconGreen4.svg';
import arrowLeft from '../../assets/svg/arrowLeft.svg';
import arrowRight from '../../assets/svg/arrowRight.svg';
import { ROUTES } from '../../shared/constants/routes';
import SidePanelRoute from './SidePanelRoute';
import { manageAccountingInputs, generateAndViewExpenses } from '../../shared/constants/routeLinkOptions';

export const SidePanel = (): JSX.Element => {
  const flag = true;
  const [isOpenSideBar, setIsOpenSideBar] = useState(false);
  const [homeFlag, setHomeFlag] = useState(true);
  const [manageAcoountingFlag, setManageAccountingFlag] = useState(false);
  const [generateAndViewExpensesFlag, setGenerateAndViewExpensesFlag] = useState(false);
  const [fileFlag, setFileFlag] = useState(false);

  const openNav = () => {
    setIsOpenSideBar(true);
    document.getElementById('mySidebar')!.style.width = '250px';
    document.getElementById('mySidebar')!.style.marginLeft = '21px';
    document.getElementById('toggle')!.style.boxShadow = 'inset -1px 0px 0px #ffffff';
  };

  const closeNav = () => {
    setIsOpenSideBar(false);
    document.getElementById('mySidebar')!.style.width = '0';
    document.getElementById('mySidebar')!.style.marginLeft = '0';
    document.getElementById('toggle')!.style.boxShadow = 'inset -1px 0px 0px #e0e0e0';
  };
  const handleRedirect = (route: any) => {
    if (ROUTES.home === route) {
      setHomeFlag(true);
      setManageAccountingFlag(false);
      setGenerateAndViewExpensesFlag(false);
      setFileFlag(false);
    }
    if (manageAccountingInputs.subHeader === route) {
      setHomeFlag(false);
      setManageAccountingFlag(true);
      setGenerateAndViewExpensesFlag(false);
      setFileFlag(false);
    }
    if (generateAndViewExpenses.subHeader === route) {
      setHomeFlag(false);
      setManageAccountingFlag(false);
      setGenerateAndViewExpensesFlag(true);
      setFileFlag(false);
    }
    if (ROUTES.files === route) {
      setHomeFlag(false);
      setManageAccountingFlag(false);
      setGenerateAndViewExpensesFlag(false);
      setFileFlag(true);
    }
  };
  const toggleNav = () => {
    setIsOpenSideBar(!isOpenSideBar);
    if (
      document.getElementById('mySidebar')!.style.width === '' ||
      document.getElementById('mySidebar')!.style.width === '0px'
    ) {
      openNav();
    } else {
      closeNav();
    }
  };

  return (
    <div className={styles.sidebarToggle} id="toggle">
      <>
        <div
          onClick={() => {
            openNav();
            handleRedirect(ROUTES.home);
          }}
        >
          <Tab className={styles.tab} highlightActive={homeFlag} href={`${ROUTES.home}`} isSidePanel={flag} tabRoute="">
            {homeFlag ? <img alt="home" src={PanelIconGreen1} /> : <img alt="home" src={PanelIcon1} />}
          </Tab>{' '}
        </div>
        <div
          onClick={() => {
            openNav();
            handleRedirect(manageAccountingInputs.subHeader);
          }}
        >
          <Tab className={styles.tab} highlightActive={manageAcoountingFlag} href="" isSidePanel={flag} tabRoute="">
            {manageAcoountingFlag ? <img alt="home" src={PanelIconGreen2} /> : <img alt="home" src={PanelIcon2} />}
          </Tab>
        </div>
        <div
          onClick={() => {
            openNav();
            handleRedirect(generateAndViewExpenses.subHeader);
          }}
        >
          <Tab
            className={styles.tab}
            highlightActive={generateAndViewExpensesFlag}
            href=""
            isSidePanel={flag}
            tabRoute=""
          >
            {generateAndViewExpensesFlag ? (
              <img alt="generate or view expenses" src={PanelIconGreen3} />
            ) : (
              <img alt="generate or view expenses" src={PanelIcon3} />
            )}
          </Tab>
        </div>
        <div
          onClick={() => {
            openNav();
            handleRedirect(ROUTES.files);
          }}
        >
          <Tab
            className={styles.tab}
            highlightActive={fileFlag}
            href={`${ROUTES.files}`}
            isSidePanel={flag}
            tabRoute=""
          >
            {fileFlag ? <img alt="files" src={PanelIconGreen4} /> : <img alt="files" src={PanelIcon4} />}
          </Tab>
        </div>
      </>
      <button type="button" className={styles.openbtn} onClick={() => toggleNav()}>
        <img alt="arrow" src={isOpenSideBar ? arrowLeft : arrowRight} />
      </button>
      <div className={styles.sidebar} id="mySidebar">
        <SidePanelRoute
          homeFlag={homeFlag}
          manageAcoountingFlag={manageAcoountingFlag}
          flag={flag}
          generateAndViewExpensesFlag={generateAndViewExpensesFlag}
          fileFlag={fileFlag}
          handleRedirect={handleRedirect}
          closeNav={closeNav}
        />
      </div>
    </div>
  );
};
