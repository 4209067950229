import axios from 'axios';
import { GridColumnConfig } from 'shared/configs/grid.config';
import type {
  ResponseTableDataType,
  CustomVestingScheduleTableData,
  IScheduleDataForGenerating,
  IScheduleGeneratedData,
  IScheduleData,
} from 'shared/models/models.bl';
import { SelectedRowsType } from 'components/Table/interfaces';
import ApiUrlsService from './api-urls.service';
import { getParsedResponseData } from './helpers';

export default class CustomVestingSchedules {
  static getCustomVestingScheduleTableList(): Promise<ResponseTableDataType<CustomVestingScheduleTableData>> {
    return axios
      .get(ApiUrlsService.getCustomVestingScheduleTableList())
      .then(({ data }) =>
        getParsedResponseData<CustomVestingScheduleTableData>(data, GridColumnConfig.VestingScheduleConfig, false)
      )
      .catch(() => null);
  }

  static deleteCustomVestingScheduleTableData(ids: SelectedRowsType<CustomVestingScheduleTableData>): Promise<any> {
    return axios.post(ApiUrlsService.deleteCustomVestingScheduleTableData(), ids).then(({ data }) => data);
  }

  static exportCustomVestingScheduleTableData(): Promise<any> {
    return axios.post(ApiUrlsService.exportCustomVestingScheduleTableData()).then(({ data }) => data);
  }

  static generateCustomVestingScheduleItem(saveData: IScheduleDataForGenerating): Promise<IScheduleGeneratedData[]> {
    return axios.post(ApiUrlsService.generateCustomVestingScheduleItem(), saveData).then(({ data }) => data);
  }

  static updateCustomVestingScheduleItem(saveData: IScheduleData): Promise<any> {
    return axios[saveData.uid ? 'put' : 'post'](ApiUrlsService.updateCustomVestingScheduleItem(), saveData).then(
      ({ data }) => data
    );
  }

  static uploadCustomVestingScheduleTableFile(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(ApiUrlsService.uploadCustomVestingScheduleTableFile(), formData).then(({ data }) => data);
  }
}
