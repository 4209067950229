import * as Models from '../../shared.models';

export const getParsedRows = <T>(items: T[], defaultItems: Models.BM.ColumnConfiguration<T>[]): T[] => {
  let result = items;
  if (Array.isArray(result) && result.length) {
    // INFO: if need to use this method somewhere else with not response from API make
    //       const result = {...items} here;
    let defaultHeadersLeft = defaultItems.length;
    const keysToRename = {} as Record<keyof T, keyof T>;
    const responseDataKeys = Object.keys(result[0]) as Array<keyof T>;
    const responseDataKeysLength = responseDataKeys.length;
    for (let i = 0; i < responseDataKeysLength; i++) {
      const defaultHeader = defaultItems.find(
        (defaultItem) => (defaultItem.name as string).toLowerCase() === (responseDataKeys[i] as string).toLowerCase()
      );
      if (defaultHeader) {
        defaultHeadersLeft -= 1;
        if (defaultHeader.name !== responseDataKeys[i]) keysToRename[responseDataKeys[i]] = defaultHeader.name;
      }
      if (!defaultHeadersLeft) break;
    }

    const responseKeysToRename = Object.keys(keysToRename) as Array<keyof T>;

    if (!responseKeysToRename.length) return result;

    result = result.map((dataItem) => {
      responseKeysToRename.forEach((responseKey) => {
        dataItem[keysToRename[responseKey]] = dataItem[responseKey];
        delete dataItem[responseKey];
      });
      return dataItem;
    });
  }
  return result;
};

export const getParsedHeaders = <T>(
  items: Models.BM.ColumnConfiguration<T>[],
  defaultItems: Models.BM.ColumnConfiguration<T>[]
): Models.BM.ColumnConfiguration<T>[] => {
  const result = items;
  if (Array.isArray(result) && result.length) {
    // INFO: if need to use this method somewhere else with not response from API make
    //       const result = {...headers} here;
    const resultLength = result.length;
    let defaultHeadersLeft = defaultItems.length;
    let fileNameHeaderIndex: number | null = null;
    for (let i = 0; i < resultLength; i++) {
      const responseHeaderKey = (result[i].name as string).toLowerCase();
      const defaultHeader = defaultItems.find(
        (defaultItem) => (defaultItem.name as string).toLowerCase() === responseHeaderKey
      );
      if (responseHeaderKey === 'filename') fileNameHeaderIndex = i;
      if (defaultHeader) {
        defaultHeadersLeft -= 1;
        result[i] = { ...defaultHeader, title: result[i].title };
      }
      if (!defaultHeadersLeft) break;
    }
    if (fileNameHeaderIndex !== null && fileNameHeaderIndex !== result.length - 1) {
      const fileNameHead = result.splice(fileNameHeaderIndex, 1);
      result.push(fileNameHead[0]);
    }
  }
  return result;
};

export const getParsedResponseData = <T>(
  data: Models.BM.ResponseTableDataType<T>,
  defaultConfig: Models.BM.ColumnConfiguration<T>[],
  parseData = true,
  parseHeaders = true
): Models.BM.ResponseTableDataType<T> => {
  if (!data) return null;
  const result = data;
  if (Array.isArray(result)) {
    return parseData ? getParsedRows<T>(result, defaultConfig) : result;
  }
  if (Array.isArray(result?.data)) {
    if (parseData) result.data = getParsedRows<T>(result.data, defaultConfig);
  }
  if (Array.isArray(result?.header)) {
    if (parseHeaders) result.header = getParsedHeaders<T>(result.header, defaultConfig);
  }
  return result;
};
