import { FC, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLogoutFromApp } from 'shared/hooks/useLogoutFromApp';
import ModalLayout from 'components/ModalLayout';
import { ReactComponent as InfoErrorFilled } from 'assets/svg/info_warning/error-filled.svg';
import type { SessionTimeoutModalProps } from './SessionTimeoutModal.types';
import { useAutoLogoutTimer } from './SessionTimeoutModal.hooks';

import './SessionTimeoutModal.scss';

const SessionTimeoutModal: FC<SessionTimeoutModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const logout = useLogoutFromApp();
  const handleLogout = useCallback(() => {
    onClose();
    logout();
  }, [onClose]);

  const timeString = useAutoLogoutTimer(handleLogout);

  return (
    <ModalLayout
      onCancel={handleLogout}
      onConfirm={onClose}
      icon={<InfoErrorFilled />}
      cancelBtnTitle={t('SessionTimeoutModal.LogoutButton')}
      confirmBtnTitle={t('SessionTimeoutModal.ContinueSessionButton')}
      title={t('SessionTimeoutModal.Title')}
      className="SessionTimeoutModal"
      hideCrossIcon
    >
      <div className="SessionTimeoutModal__content">
        <Trans
          i18nKey="SessionTimeoutModal.Description"
          t={t}
          values={{ timer: timeString }}
          components={[<span className="SessionTimeoutModal__timer" />]}
        />
      </div>
    </ModalLayout>
  );
};

export default SessionTimeoutModal;
