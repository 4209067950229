import { FC, memo, useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import { HttpStatus } from 'shared/models/enums.bl';
import type { INotificationListProps, INotification } from '../core/interface';
import Notification from '../Notification/Notification';
import { useVirtualListAutoSizer /* , useBatchDeletion */ } from '../core/hooks';
import NotificationService from '../core/notification.service';

import './NotificationList.scss';

const NotificationList: FC<INotificationListProps> = ({
  notifications,
  renderItem,
  setNotifications,
  virtualized,
  tooltipWrapper,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<List<INotification>>(null);
  const { getSize, setSize } = useVirtualListAutoSizer(listRef);
  // const handleDelete = useBatchDeletion(setNotifications);
  const handleDelete = (id: INotification['uId']) => {
    const removeFromList = () => {
      setNotifications?.((prev) => prev.filter((item) => item.uId !== id));
    };
    return NotificationService.deleteOne(id)
      .then(removeFromList)
      .catch((error) => {
        if (error?.response?.status === HttpStatus.NotFound) {
          removeFromList();
        }
      });
  };

  return !virtualized ? (
    <div className="NotificationList" ref={wrapperRef}>
      {notifications.map((data, idx) => {
        const key = `${idx}${data.uId}`;
        if (renderItem) {
          return renderItem({
            data,
            idx,
            onDelete: handleDelete,
            wrapperRef,
            key,
            tooltipWrapper,
          });
        }
        return <Notification key={key} {...data} idx={idx} onDelete={handleDelete} tooltipWrapper={tooltipWrapper} />;
      })}
    </div>
  ) : (
    <List<INotification>
      ref={listRef}
      height={window.innerHeight - 48 - 56 + 200}
      itemCount={notifications.length}
      itemSize={getSize}
      width="100%"
      itemKey={(idx) => `${idx}${notifications[idx].uId}`}
      className="NotificationList"
      outerRef={wrapperRef}
    >
      {({ index: idx, style }) => (
        <div style={style}>
          {renderItem ? (
            renderItem({
              data: notifications[idx],
              idx,
              onDelete: handleDelete,
              wrapperRef,
              key: `${idx}${notifications[idx].uId}`,
              setSize,
              tooltipWrapper,
            })
          ) : (
            <Notification
              key={`${idx}${notifications[idx].uId}`}
              {...notifications[idx]}
              idx={idx}
              onDelete={handleDelete}
              setSize={setSize}
              tooltipWrapper={tooltipWrapper}
            />
          )}
        </div>
      )}
    </List>
  );
};

export default memo(NotificationList);
