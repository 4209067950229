import {
  numbers,
  floatNumbers,
  specialCharacters,
  numbersCharacters,
  alphaNumeric,
  performanceHurdle,
  floatNumberWithDot,
  dateFormat,
  integerNumbers,
} from './regExps/regExps';

const RegExps = {
  numbers,
  floatNumbers,
  specialCharacters,
  numbersCharacters,
  alphaNumeric,
  performanceHurdle,
  floatNumberWithDot,
  dateFormat,
  integerNumbers,
};

export default RegExps;
