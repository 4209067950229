import { trimValue } from '../helpers';

export const maxLengthPerformanceHurdleId = 200;

export const minPercentage = 0;

export const maxPercentage = 100;
export const maxNumber = 2147483648;
export const maxLength = 200;

let months;
export const validateSubHurdleWeight = (value: number, initialRender: boolean): boolean => {
  let validationFlag = true;
  if (initialRender === false) {
    validationFlag = trimValue(value) !== '';
  }
  return validationFlag;
};
const monthDiff = (d1: any, d2: any): boolean => {
  let validationFlag = true;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  if (months <= 0) {
    validationFlag = false;
  } else {
    validationFlag = true;
  }
  return validationFlag;
};

export const validateSubhurdleDate = (value: any, initialRender: boolean, list: any, index: number): boolean => {
  const subhurdle = [...list];
  let validationFlag = true;
  if (initialRender === false) {
    if (index > 0) {
      validationFlag = monthDiff(subhurdle[index - 1].date.date, value);
    }
  }
  return validationFlag;
};
const checkValidaity = (currentValue: boolean) => currentValue === true;
export const validationDateMsg = (subhurdle: any) => {
  const validateDateArray: any = [];
  let validity = false;
  subhurdle.forEach((element: any, index: number) => {
    validity = validateSubhurdleDate(element.date.date, element.date.initialRender, subhurdle, index);
    validateDateArray.push(validity);
  });
  validity = validateDateArray.every(checkValidaity);

  return validity;
};
export const setCommonRangeError = (t: any, value: number, min: number, max: number) => {
  return value >= min && value <= max
    ? ''
    : t('Common.FormValidation.RangeNumbersError', {
        errorPrefix: t('Common.FormValidation.InputValueErrorPrefix'),

        minNumber: min,

        maxNumber: max,
      });
};

export enum UnitType {
  Percentage = 'P',

  Amount = 'A',
}

export const validateVestingConditionPercentageValues = (value: number | string): boolean => {
  return (
    trimValue(value) !== '' &&
    value.toString().indexOf('.') === -1 &&
    +value >= minPercentage &&
    +value <= maxPercentage
  );
};
export const validateVestingConditionAmountValues = (value: number | string): boolean => {
  return (
    trimValue(value) !== '' && value.toString().indexOf('.') === -1 && +value >= minPercentage && +value <= maxNumber
  );
};
