import { selectCurrentUser } from './user/selectors';
import { selectGlobalRedirectTo } from './redirect/selectors';
import { selectIsSidebarOpen } from './sidebar/selectors';
import { selectIsUserPanelOpen } from './userPanel/selectors';
import { selectGlobalLoader } from './loader/selectors';
import * as Expenses from './expenses/selectors';
import { Reports } from './reports/selectors';
import * as Files from './files/selectors';
import * as FileUploading from './fileUploading/selectors';
import * as Localization from './localization/selectors';
import {
  selectIsNotificationCenterOpen,
  selectIsReloadEmployeeStatusDataNeeded,
  selectIsReloadShareRegisterDataNeeded,
  selectIsReloadValuationRefDataNeeded,
  selectIsReloadFilesDataNeeded,
  selectIsReloadProvisionalExpenseDataNeeded,
  selectIsReloadCustomVestingSchedulesDataNeeded,
  selectIsReloadPerformanceHurdlesDataNeeded,
} from './notificationCenter/selectors';
import * as ManageEmployeeContracts from './managerEmployeeContracts/selectors';

export const Selectors = {
  Expenses,
  Reports,
  Files,
  FileUploading,
  Localization,
  ManageEmployeeContracts,
  Main: {
    selectGlobalLoader,
    selectIsUserPanelOpen,
    selectIsSidebarOpen,
    selectGlobalRedirectTo,
    selectCurrentUser,
    selectIsNotificationCenterOpen,
    reloadData: {
      shareRegister: selectIsReloadShareRegisterDataNeeded,
      employeeStatus: selectIsReloadEmployeeStatusDataNeeded,
      grantValuation: selectIsReloadValuationRefDataNeeded,
      customVestingSchedules: selectIsReloadCustomVestingSchedulesDataNeeded,
      files: selectIsReloadFilesDataNeeded,
      provisionalExpense: selectIsReloadProvisionalExpenseDataNeeded,
      performanceHurdles: selectIsReloadPerformanceHurdlesDataNeeded,
    },
  },
};
