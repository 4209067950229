import { useState, useEffect, Dispatch, SetStateAction, useRef, useCallback } from 'react';
import { ITableDataInfoRequest } from 'shared/models/models.bl';
import type { SortDirectionType, SelectedRowsType, IColumnConfig } from '../../components/Table/interfaces';
import Utils from '../shared.utils';

interface SortColumnData<T> {
  sortDirection: SortDirectionType;
  activeColumnName?: keyof T;
}

interface UseTableResultType<T> {
  selected: SelectedRowsType<T>;
  setSelected: Dispatch<SetStateAction<SelectedRowsType<T>>>;
  sortColumnData: SortColumnData<T>;
  setSortColumnData: Dispatch<SetStateAction<SortColumnData<T>>>;
  search?: string;
  debouncedSearch?: string;
  setSearch: (value: string) => void;
  filteredRows: T[];
  setIsServerSideAllChecked: Dispatch<SetStateAction<boolean>>;
  isServerSideAllChecked: boolean;
  isServerSideTable: boolean;
}

interface UseTableProps<T> {
  rows?: T[];
  columns?: IColumnConfig<T>[];
  defaultSortKey?: IColumnConfig<T>['name'] | null;
  preventSortValues?: Array<T[keyof T]>;
  onSetTableInfoChange?: Dispatch<SetStateAction<ITableDataInfoRequest>>;
  defaultSortDirection?: SortDirectionType;
}

function useTable<T>({
  rows,
  columns,
  defaultSortKey,
  preventSortValues,
  onSetTableInfoChange,
  defaultSortDirection,
}: UseTableProps<T>): UseTableResultType<T> {
  const isServerSideTable = !!onSetTableInfoChange;

  const searchTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [selected, setSelected] = useState<SelectedRowsType<T>>([]);
  const [isServerSideAllChecked, setIsServerSideAllChecked] = useState(false);
  const [sortColumnData, setSortColumnData] = useState<SortColumnData<T>>({
    sortDirection: defaultSortDirection || 'asc',
    ...(columns?.[0]?.name && defaultSortKey !== null ? { activeColumnName: defaultSortKey || columns[0].name } : {}),
  });
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');

  const [filteredRows, setFilteredRows] = useState<T[]>(rows || ([] as T[]));

  const handleSearch = useCallback((value: string) => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(() => {
      setDebouncedSearch(value);
    }, 500);
  }, []);

  useEffect(
    () => () => {
      if (searchTimeoutRef.current) clearTimeout(searchTimeoutRef.current);
    },
    []
  );

  useEffect(() => {
    if ((rows?.length || onSetTableInfoChange) && columns?.length) {
      if (onSetTableInfoChange) {
        onSetTableInfoChange((prev) => {
          const orderBy = (sortColumnData.activeColumnName as string) || '';
          const asc = sortColumnData.sortDirection === 'asc';

          if (prev.searchBy === debouncedSearch && (!orderBy || (prev.orderBy === orderBy && prev.asc === asc))) {
            return prev;
          }
          return {
            ...prev,
            searchBy: debouncedSearch,
            orderBy,
            asc,
          };
        });
      } else if (rows?.length) {
        const { sortDirection, activeColumnName } = sortColumnData;
        setSortColumnData((prev) =>
          !prev.activeColumnName && defaultSortKey !== null
            ? {
                ...prev,
                activeColumnName: defaultSortKey || columns[0].name,
              }
            : prev
        );
        setFilteredRows((prev) => {
          if (!activeColumnName && !columns[0].name) return prev;
          let newRows = rows;
          if (debouncedSearch) {
            newRows = newRows.filter((row) =>
              columns.some((column) => {
                // @ts-ignore
                let value = row[column.name] === 0 || !!row[column.name] ? `${row[column.name]}` : '';
                if (column.dateFormat) value = Utils.formatDate.dateFormatter(value, column.dateFormat);
                return value?.toLowerCase()?.indexOf(debouncedSearch.toLowerCase()) > -1;
              })
            ) as T[];
          }
          const activeSortName = activeColumnName;
          const sortColumnName =
            defaultSortKey === null && !activeSortName ? null : activeColumnName || defaultSortKey || columns[0].name;
          if (sortColumnName) {
            newRows = [...newRows].sort((a, b) =>
              preventSortValues?.includes(a[sortColumnName])
                ? 0
                : Utils.simpleSort(sortDirection)(a[sortColumnName], b[sortColumnName])
            );
          }
          return newRows;
        });
      }
    }
  }, [debouncedSearch, rows, columns, sortColumnData, defaultSortKey, onSetTableInfoChange]);

  useEffect(() => {
    if (!rows?.length) {
      setSelected([]);
      setSortColumnData({
        sortDirection: defaultSortDirection || 'asc',
        ...(columns?.[0]?.name && defaultSortKey !== null
          ? { activeColumnName: defaultSortKey || columns[0].name }
          : {}),
      });
      setFilteredRows([]);
    }
  }, [rows, columns?.[0]?.name, defaultSortKey, defaultSortDirection]);

  return {
    selected,
    setSelected,
    sortColumnData,
    setSortColumnData,
    search: undefined,
    debouncedSearch,
    setSearch: handleSearch,
    filteredRows: onSetTableInfoChange ? rows || [] : filteredRows,
    setIsServerSideAllChecked,
    isServerSideAllChecked,
    isServerSideTable,
  };
}

export default useTable;
