export enum TypeName {
  SET_LOADING = 'SET_LOADING',
  SET_CALCULATING = 'SET_CALCULATING',
  SET_IS_CALCULATION_COMPLETED = 'SET_IS_CALCULATION_COMPLETED',
  SET_INFO_DATA = 'SET_INFO_DATA',
  SET_TABLE_DATA = 'SET_TABLE_DATA',
  SET_NEW_REPORT_TABLE_DATA = 'SET_NEW_REPORT_TABLE_DATA',
  SET_PREVIOUS_REPORT_TABLE_DATA = 'SET_PREVIOUS_REPORT_TABLE_DATA',
  RESET_STATE_DATA = 'RESET_STATE_DATA',
  SET_FILTERING = 'SET_FILTERING',
}
