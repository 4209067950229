import Expenses from './services/bl/expenses.services';
import ErrorLogDataService from './services/dl/error-log.data.service';
import UserDataService from './services/dl/user.data.service';
import ApiUrlsService from './services/bl/api-urls.service';
import LoaderService from './services/bl/loader.service';
import RouteService from './services/bl/route.service';
import ToasterService from './services/bl/toaster.service';
import ValidatorService from './services/bl/validation.service';
import AuthorizationService from './services/bl/authorization.service';
import AuthenticationService from './services/bl/authentication.service';
import CommonService from './services/bl/common.service';
import ConfigService from './services/bl/config.service';
import ShareRegister from './services/bl/shareRegister.service';
import CustomVestingSchedules from './services/bl/customVestingSchedules.service';
import GrantValuation from './services/bl/grantValuation.service';
import EmployeeStatus from './services/bl/employeeStatus.service';
import ActivityLogs from './services/bl/activityLogs.service';
import SBPExpensesReports from './services/bl/expensesReports.service';
import ProvisionalExpense from './services/bl/provisionalExpense.service';
import TrueUp from './services/bl/trueUp.service';
import ExpensesJournal from './services/bl/expensesJournal.service';
import Files from './services/bl/files.service';
import PerformanceHurdles from './services/bl/performanceHurdles.service';
import ManageEmployeeContractsService from './services/bl/manageEmployeeContracts.service';

const Services = {
  BL: {
    AuthenticationService,
    AuthorizationService,
    ApiUrlsService,
    LoaderService,
    RouteService,
    ToasterService,
    ValidatorService,
    CommonService,
    ConfigService,
    ShareRegister,
    CustomVestingSchedules,
    GrantValuation,
    EmployeeStatus,
    ActivityLogs,
    SBPExpensesReports,
    Expenses,
    ProvisionalExpense,
    TrueUp,
    ExpensesJournal,
    Files,
    PerformanceHurdles,
    ManageEmployeeContractsService,
  },
  DL: {
    ErrorLogDataService,
    UserDataService,
  },
};

export default Services;
