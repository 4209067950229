export enum NotificationSeverity {
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
  Success = 'Success',
}

export enum NotificationArea {
  SbpExpenseCalculation = 'SbpExpenseCalculation',
  ProvisionCalculation = 'ProvisionCalculation',
  JournalEntryCalculation = 'JournalEntryCalculation',
  ShareRegisterUpload = 'ShareRegisterUpload',
  CustomVestingUpload = 'CustomVestingUpload',
  EmployeeStatusUpload = 'EmployeeStatusUpload',
  ValuationReferenceUpload = 'ValuationReferenceUpload',
  ProvisionUpload = 'ProvisionUpload',
  Files = 'Files',
  SaveReports = 'SaveReports',
  ReportFinalization = 'ReportFinalization',
  ManageEmployeeContracts = 'ManageEmployeeContracts',
}

export enum NotificationStatus {
  New = 'New',
  Read = 'Read',
}

export enum ConnectionEvent {
  GetNotificationMessage = 'GetNotificationMessage',
  OnConnectionClose = 'OnConnectionClose',
}
