import { FC, MouseEvent } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { LinkOptionRouteType } from '../../shared/constants/routeLinkOptions';

import './NavItem.scss';

const getLinkClassName: NavLinkProps['className'] = ({ isActive }) => `appNavItem${isActive ? ' active' : ''}`;

const NavItem: FC<LinkOptionRouteType> = ({
  iconStart,
  iconEnd,
  label = '',
  iconStartColor = '',
  iconEndColor = '',
  onClick,
  labelForBradCrumbs,
  ...props
}) => {
  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (!props.to) e.preventDefault();
    onClick?.(e);
  };
  return (
    <NavLink {...props} onClick={handleClick} className={getLinkClassName}>
      {!!iconStart && <div className={`appNavItem--iconStartWrapper ${iconStartColor}`}>{iconStart}</div>}
      {!!label && <span>{label}</span>}
      {!!iconEnd && <div className={`appNavItem--iconEndWrapper ${iconEndColor}`}>{iconEnd}</div>}
    </NavLink>
  );
};

export default NavItem;
