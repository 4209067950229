import axios from 'axios';
import type { ExportingStatusResponseData } from '../../models/models.dl';
import type { IExportingDataApiEntity } from '../../models/models.bl';
import { DataExportingView } from '../../models/enums.dl';
import { ApiEntity, ReportsApiEntity } from '../../models/enums.bl';
import ApiUrlsService from './api-urls.service';

const apiEntityByExportView: Record<DataExportingView, IExportingDataApiEntity> = {
  [DataExportingView.shareRegister]: ApiEntity.ShareRegister,
  [DataExportingView.activityLogs]: ApiEntity.ActivityLogs,
  [DataExportingView.customVesting]: ApiEntity.CustomVestingSchedule,
  [DataExportingView.employeeStatus]: ApiEntity.EmployeeStatus,
  [DataExportingView.grandValuation]: ApiEntity.GrantValuation,
  [DataExportingView.journalEntries]: ApiEntity.JournalEntries,
  [DataExportingView.sbpExpensesReportsMyIncentivePlanSummary]: `${ApiEntity.Reports}/${ReportsApiEntity.MyIncentivePlanSummary}`,
  [DataExportingView.sbpExpensesReportsDepartmentView]: `${ApiEntity.Reports}/${ReportsApiEntity.Department}`,
  [DataExportingView.sbpExpensesReportsDetailed]: ApiEntity.ExpenseCalculations,
  [DataExportingView.sbpExpensesReportsSummaryTotal]: `${ApiEntity.Reports}/${ReportsApiEntity.SummaryTotal}`,
  [DataExportingView.sbpExpensesReportsEmployeeView]: `${ApiEntity.Reports}/${ReportsApiEntity.Employee}`,
  [DataExportingView.sbpExpensesReportsGeographicalView]: `${ApiEntity.Reports}/${ReportsApiEntity.Geographical}`,
  [DataExportingView.sbpExpensesReportsPlanView]: `${ApiEntity.Reports}/${ReportsApiEntity.Plan}`,
  [DataExportingView.sbpExpensesReportsProvisionExpense]: ApiEntity.ProvisionExpense,
  [DataExportingView.sbpExpensesReportsTrueUpExpense]: ApiEntity.TrueUp,
  [DataExportingView.sbpExpensesReportsExpenseOutlookView]: `${ApiEntity.Reports}/${ReportsApiEntity.ExpenseOutlook}`,
};

export default class DataExportingStatus {
  static async getStatus(view: DataExportingView): Promise<ExportingStatusResponseData> {
    try {
      const { data } = await axios.get<ExportingStatusResponseData>(
        ApiUrlsService.getDataExportingStatus(apiEntityByExportView[view])
      );
      return data;
    } catch {
      return { isExportInProgress: false, message: '' };
    }
  }
}
