import type { IProvisionalExpenseTableDataResponse } from 'shared/models/models.bl';
import { getInitialPaginatedTableData, GridColumnConfig } from 'shared/configs/grid.config';
import type { SelectOption } from 'shared/models/models.dl';
import type * as Interfaces from './interfaces';
import Utils from '../../../shared/shared.utils';
import { Types } from './types';

const getDefaultFilter = (text: SelectOption['text']): SelectOption => ({ id: '', text });

export const initialProvisionExpenseFilters: Interfaces.StoreType['filters'] = {
  dates: [getDefaultFilter('SbpExpensesReports.ProvisionalExpensePanel.ProvisionDateDropdown.AllOptionLabel')],
  locations: [getDefaultFilter('SbpExpensesReports.ProvisionalExpensePanel.PlanDropdown.AllOptionLabel')],
  schemes: [getDefaultFilter('SbpExpensesReports.ProvisionalExpensePanel.LocationDropdown.AllOptionLabel')],
};

const initialPaginatedTableData = getInitialPaginatedTableData<IProvisionalExpenseTableDataResponse>(
  GridColumnConfig.ProvisionalExpenseConfig
);

const initialState: Interfaces.StoreType = {
  generatedReport: null,
  dataLoading: false,
  filtersLoading: false,
  calculating: false,
  calculationResult: null,
  fileUploaded: false,
  paginatedData: initialPaginatedTableData,
  filters: initialProvisionExpenseFilters,
  totalProvisionByFilter: null,
  chartData: { plans: {}, locations: {}, dates: {} },
  chartDataLoading: false,
};

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action: Interfaces.ProvisionalExpenseActions = {} as Interfaces.ProvisionalExpenseActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_FILE_UPLOADED: {
      return { ...state, fileUploaded: action.payload };
    }
    case Types.SET_GENERATED_REPORT: {
      return { ...state, generatedReport: action.payload };
    }
    case Types.SET_TABLE_DATA_LOADING: {
      return { ...state, dataLoading: action.payload };
    }
    case Types.SET_FILTERS_LOADING: {
      return { ...state, filtersLoading: action.payload };
    }
    case Types.SET_CALCULATING: {
      return { ...state, calculating: action.payload };
    }
    case Types.SET_CALCULATION_RESULT: {
      return { ...state, calculationResult: action.payload, calculating: false };
    }
    case Types.SET_TABLE_DATA: {
      let paginatedData = action.payload || initialPaginatedTableData;
      if (!paginatedData.tableData) {
        paginatedData = {
          ...paginatedData,
          tableData: initialPaginatedTableData.tableData,
        };
      }
      return {
        ...state,
        dataLoading: false,
        paginatedData,
        fileUploaded: paginatedData.totalCount ? true : state.fileUploaded,
      };
    }
    case Types.SET_CHART_DATA: {
      return {
        ...state,
        chartDataLoading: false,
        chartData: action.payload,
      };
    }
    case Types.SET_CHART_DATA_LOADING: {
      return {
        ...state,
        chartDataLoading: action.payload,
      };
    }
    case Types.SET_TABLE_FILTERS: {
      const dates = Utils.getCorrectTableFiltersFromResponse(action.payload?.dates)(state.filters.dates);
      const locations = Utils.getCorrectTableFiltersFromResponse(action.payload?.locations)(state.filters.locations);
      const schemes = Utils.getCorrectTableFiltersFromResponse(action.payload?.schemes)(state.filters.schemes);
      const filters =
        dates !== state.filters.dates || locations !== state.filters.locations || schemes !== state.filters.schemes
          ? {
              dates,
              locations,
              schemes,
            }
          : state.filters;
      return { ...state, filters, filtersLoading: false };
    }
    case Types.SET_TOTAL_PROVISION_BY_FILTER: {
      return {
        ...state,
        totalProvisionByFilter: action.payload,
      };
    }
    case Types.RESET_TABLE_DATA: {
      return {
        ...initialState,
        generatedReport: state.generatedReport,
      };
    }
    default:
      return state;
  }
}
