import { ILocale, ILocaleOption } from 'shared/models/models.dl';

export const USER_LOCALE = 'user_locale';

export const DEFAULT_LOCALE: ILocaleOption = {
  id: 'en-US',
  text: 'English (United States)',
};

export const transformToSelectFormat = (locales: ILocale[]): ILocaleOption[] => {
  if (!Array.isArray(locales)) {
    return [DEFAULT_LOCALE];
  }
  return locales.map((locale) => ({ text: locale.nativeName, id: locale.localeCode }));
};
