import Utils from 'shared/shared.utils';
import type { TrueUpReportTableData } from 'shared/models/models.bl';
import { getInitialPaginatedTableData, GridColumnConfig } from 'shared/configs/grid.config';

import { Types } from './types';
import type * as Interfaces from './interfaces';

const defaultFilter = { id: '', text: 'All' };

const initialPaginatedTableData = getInitialPaginatedTableData<TrueUpReportTableData>(
  GridColumnConfig.TrueUpReportConfig
);

const initialState: Interfaces.StoreType = {
  loading: false,
  calculating: false,
  filtering: false,
  isCalculationCompleted: false,
  infoData: {
    reportStartDate: '',
    reportEndDate: '',
    previousReportUId: '',
    newReportUId: '',
    success: true,
    trueUpExpense: 0,
    employeesInReport1NotIn2: '',
    employeesInReport2NotIn1: '',
    grantsInReport1NotIn2: '',
    grantsInReport2NotIn1: '',
    locations: [defaultFilter],
    schemes: [defaultFilter],
  },
  tableData: null,
  newReportTableData: initialPaginatedTableData,
  previousReportTableData: initialPaginatedTableData,
};

const blankSymbol = '--';

const prepareTextField = (text: string | undefined): string => (text ? `${text.split(',').length}` : blankSymbol);

export default function reducer(
  state: Interfaces.StoreType = initialState,
  action: Interfaces.TrueUpActions = {} as Interfaces.TrueUpActions
): Interfaces.StoreType {
  switch (action.type) {
    case Types.SET_LOADING: {
      return { ...state, loading: action.payload };
    }
    case Types.SET_CALCULATING: {
      return { ...state, calculating: action.payload };
    }
    case Types.SET_FILTERING: {
      return { ...state, filtering: action.payload };
    }
    case Types.SET_IS_CALCULATION_COMPLETED: {
      return { ...state, isCalculationCompleted: action.payload };
    }
    case Types.SET_INFO_DATA: {
      const infoData = {
        reportStartDate: action.payload?.reportStartDate || '',
        reportEndDate: action.payload?.reportEndDate || '',
        previousReportUId: action.payload?.previousReportUID || '',
        newReportUId: action.payload?.newReportUID || '',
        success: true,
        trueUpExpense: action.payload?.trueUpExpense || 0,
        employeesInReport1NotIn2: prepareTextField(action.payload?.employeesInReport1NotIn2),
        employeesInReport2NotIn1: prepareTextField(action.payload?.employeesInReport2NotIn1),
        grantsInReport1NotIn2: prepareTextField(action.payload?.grantsInReport1NotIn2),
        grantsInReport2NotIn1: prepareTextField(action.payload?.grantsInReport2NotIn1),
        locations: Utils.getCorrectTableFiltersFromResponse(action.payload?.locations)(state.infoData.locations),
        schemes: Utils.getCorrectTableFiltersFromResponse(action.payload?.schemes)(state.infoData.schemes),
      };

      return { ...state, infoData };
    }
    case Types.SET_TABLE_DATA: {
      return { ...state, tableData: action.payload };
    }
    case Types.SET_NEW_REPORT_TABLE_DATA: {
      return { ...state, newReportTableData: action.payload };
    }
    case Types.SET_PREVIOUS_REPORT_TABLE_DATA: {
      return { ...state, previousReportTableData: action.payload };
    }
    case Types.RESET_STATE_DATA: {
      return initialState;
    }
    default:
      return state;
  }
}
